var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm._m(0),
    _vm.result
      ? _c("div", {
          staticClass: "alert my-2 text-bold",
          class: {
            "alert-success": _vm.result.success,
            "alert-danger": _vm.result.error,
          },
          domProps: { innerHTML: _vm._s(_vm.result.text) },
        })
      : _vm._e(),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-5" },
        [
          _vm._m(1),
          _vm._l(_vm.team, function (user) {
            return _c(
              "button",
              {
                key: user.user_id,
                staticClass: "btn d-block fw my-2 c-default",
                class: {
                  "btn-primary": _vm.selected_user_id === user.user_id,
                  "btn-outline-primary": _vm.selected_user_id !== user.user_id,
                },
                on: {
                  mouseover: function ($event) {
                    _vm.selected_user_id = user.user_id
                  },
                  click: function ($event) {
                    $event.preventDefault()
                    return (() => {
                      return false
                    }).apply(null, arguments)
                  },
                },
              },
              [
                _c("b", [_vm._v(_vm._s(user.name))]),
                _vm._v(
                  " (" +
                    _vm._s(
                      _vm.spaces
                        ? _vm.spaces.filter((sp) => {
                            return sp.members.indexOf(user.user_id) >= 0
                          }).length
                        : 0
                    ) +
                    ") "
                ),
              ]
            )
          }),
        ],
        2
      ),
      _c("div", { staticClass: "col-1" }),
      this.team && this.team.length > 0
        ? _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("div", { staticClass: "mb-2" }, [
                _c("b", [_vm._v("Active Spaces")]),
                _c("div", { staticClass: "float-right" }, [
                  _c("small", [
                    _vm._v(" Select "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "#",
                          title:
                            "Make " +
                            _vm.selected_user_name +
                            " a manager in every active Space",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.select_all_spaces()
                          },
                        },
                      },
                      [_vm._v("all")]
                    ),
                    _vm._v(" / "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "#",
                          title:
                            "Remove " +
                            _vm.selected_user_name +
                            " from the managers of every active Space",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.select_none_spaces()
                          },
                        },
                      },
                      [_vm._v("none")]
                    ),
                  ]),
                ]),
              ]),
              _vm._l(_vm.active_spaces, function (space) {
                return _c(
                  "button",
                  {
                    key: space.id,
                    staticClass: "btn d-block fw my-2 text-bold",
                    class: {
                      "btn-success": _vm.my_space_ids.indexOf(space.id) >= 0,
                      "btn-outline-success text-gray":
                        _vm.my_space_ids.indexOf(space.id) < 0,
                    },
                    attrs: {
                      title:
                        space.members.indexOf(_vm.selected_user_id) < 0
                          ? "Make " +
                            _vm.selected_user_name +
                            " a manager of this space"
                          : "Remove " +
                            _vm.selected_user_name +
                            " from the managers of this space",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.space_clicked(space)
                      },
                    },
                  },
                  [
                    _vm._v(" " + _vm._s(space.client_name)),
                    space.loading
                      ? _c("i", { staticClass: "ml-2 ion-load-c spin" })
                      : _vm._e(),
                  ]
                )
              }),
              _vm._m(2),
              _vm._l(_vm.archived_spaces, function (space) {
                return _c(
                  "button",
                  {
                    key: space.id,
                    staticClass: "btn d-block fw my-2 text-italic",
                    class: {
                      "btn-success": _vm.my_space_ids.indexOf(space.id) >= 0,
                      "btn-outline-secondary text-gray":
                        _vm.my_space_ids.indexOf(space.id) < 0,
                    },
                    attrs: {
                      title:
                        space.members.indexOf(_vm.selected_user_id) < 0
                          ? "Make " +
                            _vm.selected_user_name +
                            " a manager of this space"
                          : "Remove " +
                            _vm.selected_user_name +
                            " from the managers of this space",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.space_clicked(space)
                      },
                    },
                  },
                  [
                    _vm._v(" " + _vm._s(space.client_name) + " (archived)"),
                    space.loading
                      ? _c("i", { staticClass: "ml-2 ion-load-c spin" })
                      : _vm._e(),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 text-center" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary btn-gradient",
            on: {
              click: function ($event) {
                return _vm.close_modal()
              },
            },
          },
          [_vm._v("Done")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "alert alert-info mb-3 text-bold" }, [
      _vm._v(
        " Select a team member to see which Spaces they are managers of. "
      ),
      _c("br"),
      _vm._v(" Click a Space to add/remove the selected person. "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-2" }, [
      _c("b", [_vm._v("Team Members")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-4 mb-2" }, [
      _c("b", [_vm._v("Archived Spaces")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }