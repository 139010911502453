var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cardbox b", staticStyle: { "box-shadow": "none" } },
    [
      _vm.invite
        ? _c("div", { staticClass: "cardbox-body" }, [
            _c(
              "div",
              {
                staticClass:
                  "fw pb-3 text-center text-bold text-14 color-skyflok",
              },
              [
                _vm.is_free_trial
                  ? _c("strong", [_vm._v("Free Trial")])
                  : _vm.invite.invited_team_name
                  ? _c("strong", [_vm._v(_vm._s(_vm.invite.invited_team_name))])
                  : _c("strong", [_vm._v("Your SkyFlok Team")]),
              ]
            ),
            _vm.invite.config.time_limit_days
              ? _c("p", { staticClass: "mb-3" }, [
                  _c("em", { staticClass: "ion-checkmark-round mx-3" }),
                  _c("span", [
                    _vm._v(_vm._s(_vm.invite.config.time_limit_days) + " Days"),
                  ]),
                ])
              : _vm._e(),
            _c("p", { staticClass: "mb-3" }, [
              _c("em", { staticClass: "ion-checkmark-round mx-3" }),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.invite.config.initial_storage_gb || "Unlimited") +
                    " GB Storage"
                ),
              ]),
            ]),
            _c("p", { staticClass: "mb-3" }, [
              _c("em", { staticClass: "ion-checkmark-round mx-3" }),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.invite.config.initial_users || "Unlimited") +
                    " Team Members"
                ),
              ]),
            ]),
            _c(
              "p",
              { staticClass: "mb-3" },
              [
                _c("em", { staticClass: "ion-checkmark-round mx-3" }),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.invite.config.active_spaces_limit || "Unlimited"
                    ) +
                      " Client Space" +
                      _vm._s(
                        _vm.invite.config.active_spaces_limit == 1 ? "" : "s"
                      )
                  ),
                ]),
                _vm._v(" "),
                _c("info-icon", {
                  attrs: {
                    content:
                      "A secure folder where you and your clients can share files with each other. GDPR-compatible alternative to sending files in email.",
                  },
                }),
              ],
              1
            ),
            _c("p", { staticClass: "mb-3" }, [
              _c("em", { staticClass: "ion-checkmark-round mx-3" }),
              _c(
                "span",
                [
                  _vm._v("Data Location Selection "),
                  _c("info-icon", {
                    attrs: {
                      content:
                        "You can select where your files are stored physically",
                    },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _c("div", { staticClass: "cardbox-body text-center" }, [
            _c("i", { staticClass: "ion-load-c spin color-skyflok text-md" }),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }