var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "section-container" }, [
    _vm.client_spaces_disabled
      ? _c(
          "div",
          { staticClass: "container" },
          [_c("upgrade-warning-box", { attrs: { feature: "Client Spaces" } })],
          1
        )
      : _c("div", { staticClass: "container-fluid" }, [
          false
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-2" }),
                _vm._m(0),
              ])
            : _vm._e(),
          _vm.user && _vm.spaces
            ? _c("div", { staticClass: "fw" }, [
                _c("h3", { staticClass: "fw d-flex justify-content-between" }, [
                  _c("div", [
                    _c("span", [_vm._v("Active Spaces")]),
                    _c("small", [
                      _vm._v(" (" + _vm._s(_vm.active_spaces_num)),
                      _vm.active_spaces_limit &&
                      _vm.active_spaces_num >= _vm.active_spaces_limit
                        ? _c("span", [
                            _vm._v(
                              " out of " + _vm._s(_vm.active_spaces_limit)
                            ),
                            _vm.active_spaces.length >= _vm.active_spaces_limit
                              ? _c("i", {
                                  staticClass:
                                    "ml-1 icon ion-alert-circled text-warning",
                                  attrs: {
                                    title:
                                      "You reached the limit of active Client Spaces. Upgrade your Team to use more Spaces!",
                                  },
                                })
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._v(") "),
                      _c(
                        "a",
                        {
                          staticClass: "ml-1 text-inherit",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.load_spaces()
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "ion-refresh",
                            class: { spin: _vm.spaces_loading },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-center gap-3",
                    },
                    [
                      _c("div", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-pill-left",
                            class: {
                              "btn-secondary": _vm.view_mode != "grid",
                              "btn-gradient btn-secondary":
                                _vm.view_mode == "grid",
                            },
                            on: {
                              click: function ($event) {
                                _vm.view_mode = "grid"
                              },
                            },
                          },
                          [
                            _vm._v(" Grid "),
                            _c("i", { staticClass: "ion-grid ml-1" }),
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-pill-right",
                            class: {
                              "btn-secondary": _vm.view_mode != "table",
                              "btn-gradient btn-secondary":
                                _vm.view_mode == "table",
                            },
                            on: {
                              click: function ($event) {
                                _vm.view_mode = "table"
                              },
                            },
                          },
                          [
                            _vm._v(" Table "),
                            _c("i", { staticClass: "ion-navicon ml-1" }),
                          ]
                        ),
                      ]),
                      _vm.user.is_admin
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-info btn-gradient ion-person-stalker",
                              on: {
                                click: function ($event) {
                                  _vm.show_edit_managers_modal = true
                                  _vm.open_dialog("edit_managers", null)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "d-none d-sm-inline-block ml-2",
                                },
                                [_vm._v("Edit Managers")]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-primary btn-gradient ion-plus-round",
                          attrs: {
                            disabled:
                              _vm.active_spaces_limit &&
                              _vm.active_spaces_num >= _vm.active_spaces_limit,
                            title:
                              _vm.active_spaces_limit &&
                              _vm.active_spaces.length >=
                                _vm.active_spaces_limit
                                ? "Upgrade your Team for more active Client Spaces!"
                                : "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.create_space()
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "d-none d-sm-inline-block ml-2" },
                            [_vm._v("Create Space")]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
                _vm.active_spaces_num > 0 && _vm.view_mode === "grid"
                  ? _c(
                      "div",
                      { staticClass: "row mt-3" },
                      [
                        _vm._l(_vm.active_spaces, function (space) {
                          return _c("client-space-box", {
                            key: space.id,
                            attrs: {
                              space: space,
                              user: _vm.user,
                              watch_clicked: () => {
                                _vm.change_watch(space)
                              },
                              reload_spaces_callback: () => {
                                _vm.load_spaces()
                              },
                            },
                            on: {
                              edit: () => {
                                _vm.edit_space(space)
                              },
                              open_dialog: (dialog_id) => {
                                _vm.open_dialog(dialog_id, space)
                              },
                            },
                          })
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-12 col-sm-6 col-md-4 col-xl-3 text-center",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "p-5 add_space_btn text-gray-light c-pointer",
                                on: {
                                  click: function ($event) {
                                    return _vm.create_space()
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "ion-plus-round icon-3x",
                                }),
                                _c("h5", { staticClass: "mt-2 text-bold" }, [
                                  _vm._v("Add Space"),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.active_spaces_num > 0 && _vm.view_mode === "table"
                  ? _c("div", { staticClass: "row mt-3" }, [
                      _c("div", { staticClass: "cardbox fw mx-3" }, [
                        _vm.active_spaces_with_expired_invite.length > 0 &&
                        !_vm.dismiss_expired_invites_alert
                          ? _c("div", { staticClass: "cardbox-body" }, [
                              _c("div", { staticClass: "fw text-center" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "alert alert-warning d-inline-block text-bold",
                                  },
                                  [
                                    _vm._v(
                                      " There " +
                                        _vm._s(
                                          _vm.active_spaces_with_expired_invite
                                            .length === 1
                                            ? "is"
                                            : "are"
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm.active_spaces_with_expired_invite
                                            .length
                                        ) +
                                        " space" +
                                        _vm._s(
                                          _vm.active_spaces_with_expired_invite
                                            .length === 1
                                            ? ""
                                            : "s"
                                        ) +
                                        " with expired invite! "
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-secondary ml-2 text-sm",
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.show_expired_invites()
                                          },
                                        },
                                      },
                                      [_vm._v("Show")]
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "ml-2 text-normal text-white text-sm",
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            _vm.dismiss_expired_invites_alert = true
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "ion-close-round",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "cardbox-body" }, [
                          _vm.show_filters
                            ? _c("div", { staticClass: "filters mb-2" }, [
                                _c("div", { staticClass: "filter" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "filter_name",
                                      attrs: { for: "filter_client_name" },
                                    },
                                    [_vm._v("Client Name")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.table_filter.client_name,
                                        expression: "table_filter.client_name",
                                      },
                                    ],
                                    staticClass: "form-control form-control-sm",
                                    staticStyle: { width: "150px" },
                                    attrs: {
                                      type: "text",
                                      id: "filter_client_name",
                                    },
                                    domProps: {
                                      value: _vm.table_filter.client_name,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.table_filter,
                                          "client_name",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                                _c("div", { staticClass: "filter" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "filter_name",
                                      attrs: { for: "filter_email" },
                                    },
                                    [_vm._v("E-mail address")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.table_filter.email,
                                        expression: "table_filter.email",
                                      },
                                    ],
                                    staticClass: "form-control form-control-sm",
                                    staticStyle: { width: "150px" },
                                    attrs: { type: "text", id: "filter_email" },
                                    domProps: { value: _vm.table_filter.email },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.table_filter,
                                          "email",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "filter" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "filter_name",
                                        attrs: { for: "" },
                                      },
                                      [_vm._v("Client Joined")]
                                    ),
                                    _c("checkbox", {
                                      attrs: {
                                        label: "Yes",
                                        value: _vm.table_filter.joined_yes,
                                      },
                                      on: {
                                        changed: (newval) =>
                                          (_vm.table_filter.joined_yes =
                                            newval),
                                      },
                                    }),
                                    _c("checkbox", {
                                      attrs: {
                                        label: "No",
                                        value: _vm.table_filter.joined_no,
                                      },
                                      on: {
                                        changed: (newval) =>
                                          (_vm.table_filter.joined_no = newval),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "filter" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "filter_name",
                                        attrs: { for: "" },
                                      },
                                      [_vm._v("Invite Sent")]
                                    ),
                                    _c("checkbox", {
                                      attrs: {
                                        label: "Yes",
                                        value: _vm.table_filter.invite_sent_yes,
                                      },
                                      on: {
                                        changed: (newval) =>
                                          (_vm.table_filter.invite_sent_yes =
                                            newval),
                                      },
                                    }),
                                    _c("checkbox", {
                                      attrs: {
                                        label: "No",
                                        value: _vm.table_filter.invite_sent_no,
                                      },
                                      on: {
                                        changed: (newval) =>
                                          (_vm.table_filter.invite_sent_no =
                                            newval),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "filter" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "filter_name",
                                        attrs: { for: "" },
                                      },
                                      [_vm._v("Invite Expired")]
                                    ),
                                    _c("checkbox", {
                                      attrs: {
                                        label: "Yes",
                                        value:
                                          _vm.table_filter.invite_expired_yes,
                                      },
                                      on: {
                                        changed: (newval) =>
                                          (_vm.table_filter.invite_expired_yes =
                                            newval),
                                      },
                                    }),
                                    _c("checkbox", {
                                      attrs: {
                                        label: "No",
                                        value:
                                          _vm.table_filter.invite_expired_no,
                                      },
                                      on: {
                                        changed: (newval) =>
                                          (_vm.table_filter.invite_expired_no =
                                            newval),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "filter" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "filter_name",
                                        attrs: { for: "" },
                                      },
                                      [_vm._v("Client Role")]
                                    ),
                                    _c("checkbox", {
                                      attrs: {
                                        label: "Full Control",
                                        value:
                                          _vm.table_filter.role_full_control,
                                      },
                                      on: {
                                        changed: (newval) =>
                                          (_vm.table_filter.role_full_control =
                                            newval),
                                      },
                                    }),
                                    _c("checkbox", {
                                      attrs: {
                                        label: "Download Only",
                                        value: _vm.table_filter.role_readonly,
                                      },
                                      on: {
                                        changed: (newval) =>
                                          (_vm.table_filter.role_readonly =
                                            newval),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "filter" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "filter_name",
                                        attrs: { for: "" },
                                      },
                                      [_vm._v("Two-Factor Auth")]
                                    ),
                                    _c("checkbox", {
                                      attrs: {
                                        label: "Yes",
                                        value: _vm.table_filter.mfa_enabled_yes,
                                      },
                                      on: {
                                        changed: (newval) =>
                                          (_vm.table_filter.mfa_enabled_yes =
                                            newval),
                                      },
                                    }),
                                    _c("checkbox", {
                                      attrs: {
                                        label: "No",
                                        value: _vm.table_filter.mfa_enabled_no,
                                      },
                                      on: {
                                        changed: (newval) =>
                                          (_vm.table_filter.mfa_enabled_no =
                                            newval),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "filter" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "filter_name",
                                        attrs: { for: "" },
                                      },
                                      [_vm._v("Files")]
                                    ),
                                    _c("checkbox", {
                                      attrs: {
                                        label: "Empty",
                                        value: _vm.table_filter.files_empty,
                                      },
                                      on: {
                                        changed: (newval) =>
                                          (_vm.table_filter.files_empty =
                                            newval),
                                      },
                                    }),
                                    _c("checkbox", {
                                      attrs: {
                                        label: "Not Empty",
                                        value: _vm.table_filter.files_not_empty,
                                      },
                                      on: {
                                        changed: (newval) =>
                                          (_vm.table_filter.files_not_empty =
                                            newval),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.is_search
                            ? _c("div", { staticClass: "text-dark" }, [
                                _c("i", { staticClass: "ion-funnel mr-1" }),
                                _c("small", [
                                  _vm._v("Showing "),
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.active_spaces_filtered.length
                                      ) +
                                        " of " +
                                        _vm._s(_vm.active_spaces.length)
                                    ),
                                  ]),
                                  _vm._v(" spaces"),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.checked_spaces && _vm.checked_spaces.length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "card card-info text-white d-inline-block p-3",
                                },
                                [
                                  _c("div", { staticClass: "mb-2" }, [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(_vm.checked_spaces.length) +
                                          " space" +
                                          _vm._s(
                                            _vm.checked_spaces.length === 1
                                              ? ""
                                              : "s"
                                          ) +
                                          " selected "
                                      ),
                                    ]),
                                    _c("a", {
                                      staticClass:
                                        "ion-close-round ml-2 text-white",
                                      attrs: {
                                        title: "Clear selection",
                                        href: "#",
                                      },
                                      on: {
                                        click: () => {
                                          _vm.active_spaces.forEach((s) => {
                                            s.checked = false
                                          })
                                          _vm.table_check_all = false
                                        },
                                      },
                                    }),
                                  ]),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-secondary text-bold mr-2",
                                      attrs: {
                                        id: "resend_invite_batch_btn",
                                        "data-toggle": "popover",
                                        "data-animation": "false",
                                        "data-trigger": "hover",
                                        "data-html": "true",
                                        "data-placement": "top",
                                        disabled:
                                          _vm.resend_invite_batch_loading ||
                                          _vm.checked_spaces_no_password
                                            .length === 0,
                                        "data-content":
                                          "Send an invite email to the selected spaces where the client has not joined yet (" +
                                          _vm.checked_spaces_no_password
                                            .length +
                                          " out of " +
                                          _vm.checked_spaces.length +
                                          " selected spaces)",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.resend_invite_batch(
                                            _vm.checked_spaces_no_password
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(" Re-send Invite "),
                                      _c("small", {}, [
                                        _vm._v(
                                          "(" +
                                            _vm._s(
                                              _vm.checked_spaces_no_password
                                                .length
                                            ) +
                                            "/" +
                                            _vm._s(_vm.checked_spaces.length) +
                                            ")"
                                        ),
                                      ]),
                                      _c("i", {
                                        staticClass:
                                          "hover-available ion-information-circled ml-1",
                                      }),
                                      _vm.resend_invite_batch_loading
                                        ? _c("i", {
                                            staticClass: "ion-load-c spin ml-1",
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-secondary text-bold mr-2",
                                      attrs: {
                                        disabled:
                                          _vm.multiselect_csv_export_loading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.multiselect_csv_export(
                                            _vm.checked_spaces
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(" Export to CSV "),
                                      _vm.multiselect_csv_export_loading
                                        ? _c("i", {
                                            staticClass: "ion-load-c spin ml-1",
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "table",
                            {
                              staticClass: "table table-hover",
                              class: {
                                any_checked:
                                  _vm.checked_spaces &&
                                  _vm.checked_spaces.length > 0,
                              },
                              attrs: { id: "spaces_table" },
                            },
                            [
                              _c("thead", [
                                _c(
                                  "th",
                                  {
                                    staticClass: "d-none d-sm-table-cell",
                                    attrs: {
                                      title:
                                        "Select or deselect all displayed spaces",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.table_check_all =
                                          !_vm.table_check_all
                                      },
                                    },
                                  },
                                  [
                                    _c("checkbox", {
                                      attrs: { value: _vm.table_check_all },
                                      on: {
                                        changed: (newval) =>
                                          (_vm.table_check_all = newval),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("th", {
                                  staticClass: "width-0 d-none d-sm-table-cell",
                                }),
                                _c(
                                  "th",
                                  {
                                    staticClass: "c-pointer nowrap",
                                    on: {
                                      click: function ($event) {
                                        return _vm.sort_by("client_name")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v("Client Name"),
                                    _vm.table_sort.field == "client_name"
                                      ? _c("i", {
                                          staticClass: "ml-1",
                                          class: {
                                            "ion-arrow-up-c":
                                              !_vm.table_sort.desc,
                                            "ion-arrow-down-c":
                                              _vm.table_sort.desc,
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass: "c-pointer nowrap",
                                    on: {
                                      click: function ($event) {
                                        return _vm.sort_by("client_email")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v("E-mail Address"),
                                    _vm.table_sort.field == "client_email"
                                      ? _c("i", {
                                          staticClass: "ml-1",
                                          class: {
                                            "ion-arrow-up-c":
                                              !_vm.table_sort.desc,
                                            "ion-arrow-down-c":
                                              _vm.table_sort.desc,
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "d-none d-md-table-cell c-pointer text-center nowrap",
                                    on: {
                                      click: function ($event) {
                                        return _vm.sort_by("watch_managers")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(" Watch"),
                                    _c("info-icon", {
                                      attrs: {
                                        content:
                                          "Whether Space Managers are subscribed to email notification of new file uploads by the Client",
                                      },
                                    }),
                                    _vm.table_sort.field == "watch_managers"
                                      ? _c("i", {
                                          staticClass: "ml-1",
                                          class: {
                                            "ion-arrow-up-c":
                                              !_vm.table_sort.desc,
                                            "ion-arrow-down-c":
                                              _vm.table_sort.desc,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "text-center d-none d-sm-table-cell c-pointer text-center nowrap",
                                    on: {
                                      click: function ($event) {
                                        return _vm.sort_by("joined")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v("Joined"),
                                    _vm.table_sort.field == "joined"
                                      ? _c("i", {
                                          staticClass: "ml-1",
                                          class: {
                                            "ion-arrow-up-c":
                                              !_vm.table_sort.desc,
                                            "ion-arrow-down-c":
                                              _vm.table_sort.desc,
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "text-center d-none d-md-table-cell c-pointer text-center nowrap",
                                    on: {
                                      click: function ($event) {
                                        return _vm.sort_by("client_role")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(" Role"),
                                    _c("info-icon", {
                                      attrs: {
                                        content:
                                          "What the client is allowed to do in the Space.<br/><span class='text-primary'>Download</span>: Download files but no uploading or deleting anything<br/><span class='text-success'>Full</span>: Upload, download, delete, etc",
                                      },
                                    }),
                                    _vm.table_sort.field == "client_role"
                                      ? _c("i", {
                                          staticClass: "ml-1",
                                          class: {
                                            "ion-arrow-up-c":
                                              !_vm.table_sort.desc,
                                            "ion-arrow-down-c":
                                              _vm.table_sort.desc,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "text-center d-none d-lg-table-cell c-pointer nowrap",
                                    on: {
                                      click: function ($event) {
                                        return _vm.sort_by("mfa_enabled")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(" 2FA"),
                                    _c("info-icon", {
                                      attrs: {
                                        content:
                                          "Whether the Client enabled Two-Factor Authentication",
                                      },
                                    }),
                                    _vm.table_sort.field == "mfa_enabled"
                                      ? _c("i", {
                                          staticClass: "ml-1",
                                          class: {
                                            "ion-arrow-up-c":
                                              !_vm.table_sort.desc,
                                            "ion-arrow-down-c":
                                              _vm.table_sort.desc,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "text-center d-none d-lg-table-cell c-pointer text-center nowrap",
                                    on: {
                                      click: function ($event) {
                                        return _vm.sort_by("files_num")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v("Files"),
                                    _vm.table_sort.field == "files_num"
                                      ? _c("i", {
                                          staticClass: "ml-1",
                                          class: {
                                            "ion-arrow-up-c":
                                              !_vm.table_sort.desc,
                                            "ion-arrow-down-c":
                                              _vm.table_sort.desc,
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "text-center d-none d-md-table-cell c-pointer nowrap",
                                    on: {
                                      click: function ($event) {
                                        return _vm.sort_by("managers_num")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v("Managers"),
                                    _vm.table_sort.field == "managers_num"
                                      ? _c("i", {
                                          staticClass: "ml-1",
                                          class: {
                                            "ion-arrow-up-c":
                                              !_vm.table_sort.desc,
                                            "ion-arrow-down-c":
                                              _vm.table_sort.desc,
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "d-none d-lg-table-cell c-pointer nowrap",
                                    on: {
                                      click: function ($event) {
                                        return _vm.sort_by("created")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v("Created"),
                                    _vm.table_sort.field == "created"
                                      ? _c("i", {
                                          staticClass: "ml-1",
                                          class: {
                                            "ion-arrow-up-c":
                                              !_vm.table_sort.desc,
                                            "ion-arrow-down-c":
                                              _vm.table_sort.desc,
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                                _c("th", { staticClass: "width-0" }),
                                _c("th", { staticClass: "width-0" }),
                              ]),
                              _c(
                                "tbody",
                                _vm._l(
                                  _vm.active_spaces_filtered_sorted,
                                  function (space) {
                                    return _c(
                                      "tr",
                                      {
                                        key: space.id,
                                        class: { checked: space.checked },
                                        on: {
                                          click: () => {
                                            /* Multiselect return false;  space.checked = !space.checked */ 0
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "d-none d-sm-table-cell c-pointer pr-0 mr-0",
                                          },
                                          [
                                            _c("checkbox", {
                                              attrs: { value: space.checked },
                                              on: {
                                                changed: (newval) =>
                                                  (_vm.table_check_all =
                                                    space.checked),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "d-none d-sm-table-cell pl-0 v-middle",
                                            staticStyle: { height: "48px" },
                                          },
                                          [
                                            space.client_logo
                                              ? _c("img", {
                                                  staticClass:
                                                    "rounded fit-contain shadow-z1",
                                                  staticStyle: {
                                                    "max-width": "48px",
                                                    "max-height": "48px",
                                                  },
                                                  attrs: {
                                                    src: _vm.ClientSpacesService.is_skyflok_logo(
                                                      space
                                                    )
                                                      ? _vm.Utils.client_space_logo_src(
                                                          space.client_logo
                                                        )
                                                      : space.client_logo,
                                                  },
                                                })
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "shadow-z1 thumb48 rounded bg-gradient-secondary d-inline-block",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex align-items-center justify-content-center fh fw text-white text-bold",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              space.client_name
                                                                .split(" ")
                                                                .map((frag) =>
                                                                  frag.length >
                                                                  0
                                                                    ? frag[0].toUpperCase()
                                                                    : ""
                                                                )
                                                                .splice(0, 3)
                                                                .join("")
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "break-word" },
                                          [
                                            _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.highlight_search(
                                                    space.client_name,
                                                    _vm.table_filter.client_name
                                                  )
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "break-word" },
                                          [
                                            _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.highlight_search(
                                                    space.client_email,
                                                    _vm.table_filter.email
                                                  )
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "d-none d-md-table-cell text-center",
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-secondary btn-flat btn-flat-icon c-pointer",
                                                attrs: {
                                                  type: "button",
                                                  "data-toggle": "popover",
                                                  "data-animation": "false",
                                                  "data-trigger": "hover",
                                                  "data-html": "true",
                                                  "data-placement": "top",
                                                  "data-content":
                                                    "Click to turn " +
                                                    (space.watch_managers
                                                      ? "off"
                                                      : "on") +
                                                    " email notifications<br/>when the client uploads a file",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.change_watch(
                                                      space
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  class: {
                                                    "ion-android-notifications":
                                                      space.watch_managers,
                                                    "ion-android-notifications-none text-gray-light":
                                                      !space.watch_managers,
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "d-none d-sm-table-cell text-center",
                                          },
                                          [
                                            space.password_set
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-success",
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "ion-checkmark-circled mr-1",
                                                    }),
                                                    _vm._v(" Yes "),
                                                  ]
                                                )
                                              : !space.invite_code
                                              ? _c("span", [
                                                  _vm._v(" Invite not sent"),
                                                  _c("br"),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "btn btn-sm text-sm btn-primary text-bold mt-1",
                                                      attrs: {
                                                        href: "#",
                                                        disabled:
                                                          space.invite_sending,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.resend_client_invite(
                                                            space
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" Send invite "),
                                                      space.invite_sending
                                                        ? _c("i", {
                                                            staticClass:
                                                              "ion-load-c spin ml-1",
                                                          })
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ])
                                              : space.invite_code &&
                                                space.is_invite_expired
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "ion-alert-circled mr-1",
                                                    }),
                                                    _vm._v("Expired!"),
                                                    _c("br"),
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "btn btn-sm text-sm btn-primary text-bold mt-1",
                                                        attrs: {
                                                          href: "#",
                                                          disabled:
                                                            space.invite_sending,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.resend_client_invite(
                                                              space
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" Send invite "),
                                                        space.invite_sending
                                                          ? _c("i", {
                                                              staticClass:
                                                                "ion-load-c spin ml-1",
                                                            })
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : space.invite_code &&
                                                !space.is_invite_expired
                                              ? _c("span", [
                                                  _vm._v(" Invite sent "),
                                                  _c("small", [
                                                    _c("i", {
                                                      staticClass:
                                                        "ion-information-circled ml-1 c-help",
                                                      attrs: {
                                                        "data-container":
                                                          "body",
                                                        "data-toggle":
                                                          "popover",
                                                        "data-placement": "top",
                                                        "data-html": "true",
                                                        "data-trigger": "hover",
                                                        "data-content":
                                                          space.will_expire_text,
                                                      },
                                                    }),
                                                  ]),
                                                  _c("br"),
                                                  _c("small", [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: "#",
                                                          disabled:
                                                            space.invite_sending,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.resend_client_invite(
                                                              space
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Re-send invite? "
                                                        ),
                                                        space.invite_sending
                                                          ? _c("i", {
                                                              staticClass:
                                                                "ion-load-c spin ml-1",
                                                            })
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "d-none d-md-table-cell text-center",
                                          },
                                          [
                                            space.client_readonly
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-primary",
                                                  },
                                                  [_vm._v("Download")]
                                                )
                                              : _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-success",
                                                  },
                                                  [_vm._v("Full")]
                                                ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "d-none d-lg-table-cell text-center nowrap",
                                          },
                                          [
                                            space.mfa_enabled
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-success",
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "ion-locked mr-1",
                                                    }),
                                                    _vm._v(" Yes "),
                                                  ]
                                                )
                                              : _c(
                                                  "span",
                                                  { staticClass: "text-muted" },
                                                  [_vm._v("No")]
                                                ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "d-none d-lg-table-cell text-center",
                                          },
                                          [
                                            !space.ns_stats
                                              ? _c("i", {
                                                  staticClass:
                                                    "ion-load-c spin",
                                                })
                                              : space.ns_stats.active_files == 0
                                              ? _c("i", [_vm._v("Empty")])
                                              : _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        space.ns_stats
                                                          .active_files
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "d-none d-md-table-cell",
                                          },
                                          [
                                            space.members.length > 1
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      space.members.length
                                                    ) + " managers"
                                                  ),
                                                ])
                                              : _vm.user.team
                                              ? _c("user-box", {
                                                  staticClass: "nowrap",
                                                  attrs: {
                                                    avatar_left: true,
                                                    team: _vm.user.team,
                                                    userId: space.members[0],
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "d-none d-lg-table-cell",
                                          },
                                          [
                                            _c("timestamp", {
                                              attrs: {
                                                timestamp: space.created,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("td", [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "btn btn-sm text-sm btn-info ml-2 text-bold",
                                              attrs: {
                                                href: _vm._f(
                                                  "client_space_link"
                                                )(space.key),
                                                target: "_BLANK",
                                                title:
                                                  "Open the Space of " +
                                                  space.client_name,
                                              },
                                              on: {
                                                click: () => {
                                                  return false
                                                  space.checked = !space.checked
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-none d-md-inline-block mr-1",
                                                },
                                                [_vm._v("Open")]
                                              ),
                                              _c("i", {
                                                staticClass: "ion-share",
                                              }),
                                            ]
                                          ),
                                        ]),
                                        _c("td", [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "dropdown",
                                              on: {
                                                click: () => {
                                                  return false
                                                  space.checked = !space.checked
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-secondary btn-flat btn-flat-icon",
                                                  attrs: {
                                                    "data-toggle": "dropdown",
                                                    id: "dropdown_" + space.id,
                                                    type: "button",
                                                    "aria-expanded": "false",
                                                  },
                                                },
                                                [
                                                  _c("em", {
                                                    staticClass:
                                                      "ion-android-more-horizontal",
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "dropdown-menu dropdown-scale dropdown-menu-right",
                                                  attrs: { role: "menu" },
                                                },
                                                [
                                                  !space.is_archived
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "dropdown-item",
                                                          attrs: { href: "#" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.edit_space(
                                                                space
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "ion-edit mr-2",
                                                          }),
                                                          _vm._v(" Edit Space"),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  !space.is_archived
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "dropdown-item d-flex align-items-center",
                                                          attrs: { href: "#" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.open_dialog(
                                                                "reset_password",
                                                                space
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "ion-refresh mr-2",
                                                          }),
                                                          _vm._v(
                                                            " Reset Client password"
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  space.mfa_enabled &&
                                                  !space.is_archived
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "dropdown-item d-flex align-items-center",
                                                          attrs: { href: "#" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.open_dialog(
                                                                "mfa_backup_codes",
                                                                space
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "ion-help-buoy mr-2",
                                                          }),
                                                          _vm._v(
                                                            " 2FA Backup Codes"
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  !space.is_archived
                                                    ? _c("div", {
                                                        staticClass:
                                                          "dropdown-divider",
                                                        attrs: {
                                                          role: "separator",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  !space.is_archived
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "dropdown-item text-danger",
                                                          attrs: { href: "#" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.open_dialog(
                                                                "archive_space",
                                                                space
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "ion-trash-a mr-2",
                                                          }),
                                                          _vm._v(
                                                            " Archive or Delete..."
                                                          ),
                                                        ]
                                                      )
                                                    : _vm.activatedisabled !==
                                                      true
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "dropdown-item text-success",
                                                          attrs: { href: "#" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.activateclicked(
                                                                space
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "ion-wand mr-2",
                                                          }),
                                                          _vm._v(
                                                            " Activate Space"
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm.active_spaces_num === 0
                  ? _c("div", { staticClass: "row mb-3" }, [
                      _c("div", { staticClass: "col-4" }),
                      _c("div", { staticClass: "col-4" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "cardbox text-white bg-gradient-success b0 c-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.create_space()
                              },
                            },
                          },
                          [_vm._m(1)]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.archived_spaces && _vm.archived_spaces.length > 0
                  ? _c("div", [
                      _c("h3", [
                        _vm._v("Archived Spaces "),
                        _c("small", [
                          _vm._v(
                            "(" + _vm._s(_vm.archived_spaces.length) + ")"
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            on: {
                              click: function ($event) {
                                _vm.show_archived = !_vm.show_archived
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.show_archived ? "Hide" : "Show"))]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "row",
                          class: { "d-none": !_vm.show_archived },
                          attrs: { id: "archived_spaces_container" },
                        },
                        _vm._l(_vm.archived_spaces, function (space) {
                          return _c("client-space-box", {
                            key: space.id,
                            attrs: {
                              space: space,
                              user: _vm.user,
                              activatedisabled:
                                _vm.active_spaces_limit &&
                                _vm.active_spaces.length >=
                                  _vm.active_spaces_limit,
                              activateclicked: (space) => {
                                _vm.activate_space(space)
                              },
                            },
                          })
                        }),
                        1
                      ),
                    ])
                  : _vm._e(),
              ])
            : _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-3" }, [_vm._v(" ")]),
                _vm._m(2),
              ]),
        ]),
    _c("div", { staticClass: "modal", attrs: { id: "client_space_form" } }, [
      _vm.show_space_form
        ? _c("div", { staticClass: "modal-dialog modal-lg" }, [
            _c("div", { staticClass: "modal-content" }, [
              _c(
                "div",
                { staticClass: "modal-header bg-gradient-info text-white" },
                [
                  _c("h5", { staticClass: "mt-0 modal-title" }, [
                    _vm.space_to_edit && _vm.space_to_edit.id
                      ? _c("div", [
                          _vm._v(" Edit Client Space of "),
                          _c("b", [
                            _vm._v(_vm._s(_vm.space_to_edit.client_name)),
                          ]),
                        ])
                      : _c("div", [_vm._v(" Create new Client Space ")]),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: { type: "button", "aria-label": "Close" },
                      on: {
                        click: function ($event) {
                          return _vm.close_modal("client_space_form")
                        },
                      },
                    },
                    [_c("span", [_vm._v("×")])]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "modal-body" },
                [
                  _vm.space_to_edit === null && !_vm.hide_form_switcher
                    ? _c(
                        "div",
                        { staticClass: "d-none d-md-block mb-2 text-center" },
                        [
                          !_vm.batch_create_mode
                            ? _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.batch_create_mode = true
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "badge badge-info mr-2" },
                                    [_vm._v("NEW")]
                                  ),
                                  _vm._v(
                                    "Creating many Spaces? Try the new batch mode! "
                                  ),
                                ]
                              )
                            : _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.batch_create_mode = false
                                    },
                                  },
                                },
                                [_vm._v(" Create a single Space ")]
                              ),
                        ]
                      )
                    : _vm._e(),
                  _vm.user && _vm.user.team
                    ? _c("client-space-form", {
                        ref: "space_form",
                        attrs: {
                          space: _vm.space_to_edit,
                          team: _vm.user.team,
                          user: _vm.user,
                          batch_mode: _vm.batch_create_mode,
                          hide_form_switch: () => {
                            _vm.hide_form_switcher = true
                          },
                          reload_spaces_callback: () => {
                            _vm.load_spaces()
                          },
                          closeDialog: () => {
                            _vm.close_modal("client_space_form")
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
    ]),
    _c("div", { staticClass: "modal", attrs: { id: "archive_space_modal" } }, [
      _vm.space_to_archive
        ? _c("div", { staticClass: "modal-dialog modal-lg" }, [
            _c("div", { staticClass: "modal-content" }, [
              _c(
                "div",
                { staticClass: "modal-header bg-gradient-info text-white" },
                [
                  _c("h5", { staticClass: "mt-0 modal-title" }, [
                    _vm._v("Archive Client Space of "),
                    _c("b", [_vm._v(_vm._s(_vm.space_to_archive.client_name))]),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: { type: "button", "aria-label": "Close" },
                      on: {
                        click: function ($event) {
                          return _vm.close_modal("archive_space_modal")
                        },
                      },
                    },
                    [_c("span", [_vm._v("×")])]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "modal-body" },
                [
                  _c("archive-space-modal", {
                    attrs: { space: _vm.space_to_archive },
                    on: {
                      archived: (space_id) => {
                        _vm.on_space_updated(space_id)
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
    ]),
    _c("div", { staticClass: "modal", attrs: { id: "disable_space_modal" } }, [
      _vm.space_to_disable
        ? _c("div", { staticClass: "modal-dialog modal-lg" }, [
            _c("div", { staticClass: "modal-content" }, [
              _c(
                "div",
                { staticClass: "modal-header bg-gradient-info text-white" },
                [
                  _c("h5", { staticClass: "mt-0 modal-title" }, [
                    _vm._v("Disable access of "),
                    _c("b", [_vm._v(_vm._s(_vm.space_to_disable.client_name))]),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: { type: "button", "aria-label": "Close" },
                      on: {
                        click: function ($event) {
                          return _vm.close_modal("disable_space_modal")
                        },
                      },
                    },
                    [_c("span", [_vm._v("×")])]
                  ),
                ]
              ),
              _c("div", { staticClass: "modal-body" }, [
                _vm._v(" Disable access "),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "modal", attrs: { id: "mfa_backup_codes_modal" } },
      [
        _vm.mfa_backup_codes_space
          ? _c("div", { staticClass: "modal-dialog modal-lg" }, [
              _c("div", { staticClass: "modal-content" }, [
                _c(
                  "div",
                  { staticClass: "modal-header bg-gradient-info text-white" },
                  [
                    _c("h5", { staticClass: "mt-0 modal-title" }, [
                      _vm._v(" Two-Factor Authentication Backup Codes "),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "close",
                        attrs: { type: "button", "aria-label": "Close" },
                        on: {
                          click: function ($event) {
                            return _vm.close_modal("mfa_backup_codes_modal")
                          },
                        },
                      },
                      [_c("span", [_vm._v("×")])]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "modal-body" },
                  [
                    _c("mfa-backup-codes", {
                      attrs: {
                        space_id: _vm.mfa_backup_codes_space.id,
                        client_name: _vm.mfa_backup_codes_space.client_name,
                        user_email: _vm.mfa_backup_codes_space.client_email,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
      ]
    ),
    _c("div", { staticClass: "modal", attrs: { id: "reset_password_modal" } }, [
      _vm.password_reset_space
        ? _c("div", { staticClass: "modal-dialog modal-lg" }, [
            _c("div", { staticClass: "modal-content" }, [
              _c(
                "div",
                { staticClass: "modal-header bg-gradient-info text-white" },
                [
                  _c("h5", { staticClass: "mt-0 modal-title" }, [
                    _vm._v(" Reset password of "),
                    _c("b", [
                      _vm._v(_vm._s(_vm.password_reset_space.client_name)),
                    ]),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: { type: "button", "aria-label": "Close" },
                      on: {
                        click: function ($event) {
                          return _vm.close_modal("reset_password_modal")
                        },
                      },
                    },
                    [_c("span", [_vm._v("×")])]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "modal-body text-black" },
                [
                  _c("space-psw-reset-modal", {
                    attrs: {
                      space: _vm.password_reset_space,
                      space_updated: (updated_space) => {
                        _vm.password_reset_space.invite_expires =
                          updated_space.invite_expires
                        _vm.password_reset_space.password_set =
                          updated_space.password_set
                        _vm.password_reset_space.invite_code =
                          updated_space.invite_code
                        _vm.init_space(_vm.password_reset_space)
                      },
                      close_modal: () => {
                        _vm.close_modal("reset_password_modal")
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
    ]),
    _c("div", { staticClass: "modal", attrs: { id: "edit_managers_modal" } }, [
      _vm.show_edit_managers_modal
        ? _c("div", { staticClass: "modal-dialog modal-lg" }, [
            _c("div", { staticClass: "modal-content" }, [
              _c(
                "div",
                { staticClass: "modal-header bg-gradient-info text-white" },
                [
                  _c("h5", { staticClass: "mt-0 modal-title text-bold" }, [
                    _vm._v(" Edit Managers "),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: { type: "button", "aria-label": "Close" },
                      on: {
                        click: function ($event) {
                          return _vm.close_modal("edit_managers_modal")
                        },
                      },
                    },
                    [_c("span", [_vm._v("×")])]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "modal-body text-black" },
                [
                  _c("edit-managers-modal", {
                    attrs: {
                      team: _vm.user ? _vm.user.team : [],
                      spaces: _vm.spaces,
                      close_modal: () => {
                        _vm.close_modal("edit_managers_modal")
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 col-lg-8" }, [
      _c("div", { staticClass: "cardbox text-white bg-gradient-success b0" }, [
        _c("div", { staticClass: "cardbox-body text-center text-12" }, [
          _vm._v(" Client Spaces are "),
          _c("b", [_vm._v("secure, confidential shared folders")]),
          _vm._v(
            " between you and your clients, where both of you can upload and download files. It's a simple but very "
          ),
          _c("b", [
            _vm._v("secure alternative of emailing files to each other"),
          ]),
          _vm._v(
            ", and you don't even need to make your clients Team Members. "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cardbox-body text-center" }, [
      _c("div", { staticClass: "text-bold display-1" }, [
        _c("i", { staticClass: "ion-plus-round" }),
      ]),
      _c("p", { staticClass: "text-bold text-md" }, [
        _vm._v("Create Client Space"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6" }, [
      _c("div", [
        _c(
          "div",
          { staticClass: "cardbox text-white bg-gradient-success b0" },
          [
            _c("div", { staticClass: "cardbox-body text-center" }, [
              _c("div", { staticClass: "text-bold display-1" }, [
                _c("div", { staticClass: "loader" }),
              ]),
            ]),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }