var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "section-container" }, [
    _c(
      "div",
      { staticClass: "container-overlap bg-gradient-info text-center" },
      [
        _vm.user
          ? _c("div", { staticClass: "mb-3 text-center" }, [
              _c("img", {
                staticClass: "wd-sm rounded-circle img-thumbnail fit-cover",
                staticStyle: { height: "150px" },
                attrs: { src: _vm.avatar_url(_vm.user), alt: "user" },
              }),
              _c(
                "a",
                {
                  staticClass: "text-white text-sm",
                  staticStyle: { display: "block", "margin-top": "-2em" },
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.change_image()
                    },
                  },
                },
                [_vm._v("Change")]
              ),
            ])
          : _vm._e(),
        _vm.user
          ? _c("div", { staticClass: "text-white" }, [
              _c("div", { staticClass: "h3" }, [_vm._v(_vm._s(_vm.user.name))]),
            ])
          : _vm._e(),
      ]
    ),
    _c("div", { staticClass: "container container-md" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-2" }),
        _c("div", { staticClass: "col-12 col-lg-8 cardbox" }, [
          _c("h5", { staticClass: "cardbox-heading pb-0" }, [
            _vm._v("Profile "),
            _c(
              "a",
              {
                staticClass: "btn btn-sm btn-secondary float-right",
                on: {
                  click: function ($event) {
                    _vm.edit_mode = true
                  },
                },
              },
              [
                _c("i", { staticClass: "ion-edit text-grey mr-2" }),
                _vm._v("Edit"),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "cardbox-body" },
            [
              _c("edit-user-form", {
                attrs: {
                  user: _vm.user,
                  "close-form": _vm.closeForm,
                  "edit-mode": _vm.edit_mode,
                  "browse-image": _vm.browse_image,
                },
              }),
            ],
            1
          ),
          _c("h5", { staticClass: "cardbox-heading pb-0" }, [
            _vm._v(" Two-Factor Authentication "),
            _vm.mfa_status &&
            _vm.mfa_status.has_2fa &&
            _vm.mfa_content == "status"
              ? _c("div", { staticClass: "float-right dropdown btn-group" }, [
                  _vm._m(0),
                  _c(
                    "div",
                    {
                      staticClass: "dropdown-menu dropdown-menu-right",
                      attrs: { role: "menu" },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return (() => {
                                _vm.mfa_content = "show_qr"
                              }).apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "ion-lock-combination mr-2" }),
                          _vm._v(" Set up a new phone or app"),
                        ]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return (() => {
                                _vm.mfa_content = "recovery_codes"
                              }).apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "ion-help-buoy mr-2" }),
                          _vm._v(" Show my backup codes"),
                        ]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return (() => {
                                _vm.mfa_content = "history"
                              }).apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "ion-calendar mr-2" }),
                          _vm._v(" Event Log"),
                        ]
                      ),
                      _c("div", { staticClass: "dropdown-divider" }),
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item text-danger",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return (() => {
                                _vm.mfa_content = "lost_phone"
                              }).apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "ion-alert-circled mr-2" }),
                          _vm._v(" I lost my phone"),
                        ]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item text-danger",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return (() => {
                                _vm.mfa_content = "delete"
                              }).apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "ion-trash-a mr-2" }),
                          _vm._v(" Disable two factor auth"),
                        ]
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.mfa_content == "show_qr"
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary btn-sm float-right",
                    on: {
                      click: function ($event) {
                        _vm.mfa_content = "status"
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "ion-close-round text-grey mr-2" }),
                    _vm._v("Cancel"),
                  ]
                )
              : _vm._e(),
          ]),
          _c(
            "div",
            { staticClass: "cardbox-body" },
            [
              _c("mfa", {
                attrs: {
                  user_email: _vm.user ? _vm.user.email_address : false,
                  mfa_content: _vm.mfa_content,
                },
                on: {
                  mfa_status_loaded: (status) => {
                    _vm.mfa_status = status
                  },
                  mfa_content_change: (new_content) => {
                    _vm.mfa_content = new_content
                  },
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "container container-lg" }, [
      _c("h3", { staticClass: "mb-3" }, [
        _vm._v("Active Sessions "),
        _c("i", {
          staticClass: "ion-refresh text-muted",
          class: { spin: _vm.sessions_loading },
          staticStyle: { "font-size": ".6em", cursor: "pointer" },
          on: {
            click: function ($event) {
              return _vm.load_sessions()
            },
          },
        }),
      ]),
      _c(
        "div",
        { staticClass: "row" },
        _vm._l(_vm.sessions, function (session) {
          return _c(
            "div",
            {
              key: session.id,
              staticClass: "col-sm-4",
              attrs: {
                "data-session-id": session.id,
                "data-device-id": session.device_id,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "cardbox",
                  class: { "text-success border-l-4": session.is_current },
                },
                [
                  _c("div", { staticClass: "cardbox-body" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-start align-items-center",
                      },
                      [
                        _c("div", { staticClass: "wd-xxs" }, [
                          _c("img", {
                            attrs: {
                              src: _vm.get_browser_logo_img(
                                session.client_name
                              ),
                              width: "60",
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "ml-3" }, [
                          _c(
                            "p",
                            {
                              staticClass: "lead mb-0",
                              staticStyle: { display: "inline-block" },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "text-transform": "capitalize",
                                  },
                                },
                                [_vm._v(_vm._s(session.client_name))]
                              ),
                            ]
                          ),
                          session.is_current
                            ? _c("small", { staticClass: "ml-2" }, [
                                _vm._v("(this session)"),
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "text-muted" }, [
                            session.geo_country
                              ? _c("img", {
                                  staticClass: "mr-2",
                                  attrs: {
                                    src: _vm.get_flag_img(session.geo_country),
                                  },
                                })
                              : _vm._e(),
                            session.location_str
                              ? _c("span", [
                                  _vm._v(_vm._s(session.location_str)),
                                ])
                              : _c("span", [
                                  _c("i", [_vm._v("Unknown location")]),
                                ]),
                          ]),
                          _c("div", { staticClass: "text-muted" }, [
                            _vm._v(
                              "Last used " +
                                _vm._s(
                                  _vm._f("timestamp_rel")(session.last_used)
                                )
                            ),
                            _c("i", {
                              staticClass: "ion-calendar ml-1 text-muted",
                              staticStyle: { cursor: "help" },
                              attrs: {
                                title: _vm._f("timestamp")(
                                  session.issue_timestamp
                                ),
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "ml-auto" }, [
                          _c(
                            "a",
                            {
                              staticClass: "text-muted",
                              attrs: { href: "#", title: "Kill this session" },
                              on: {
                                click: function ($event) {
                                  return _vm.kill_session(session)
                                },
                              },
                            },
                            [_c("i", { staticClass: "ion-close-round" })]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-secondary btn-sm",
        staticStyle: { "font-size": "1rem" },
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-expanded": "false",
        },
      },
      [
        _c("i", { staticClass: "ion-gear-b text-grey mr-2" }),
        _vm._v(" Options "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }