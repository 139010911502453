var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "layout-container",
      class: {
        "bg-gradient-danger": _vm.error !== false,
        "bg-gradient-secondary": _vm.error === false,
      },
      staticStyle: { overflow: "overlay" },
    },
    [
      _c("div", { staticClass: "page-container" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c(
            "div",
            { staticClass: "row" },
            [
              _vm.blob_url
                ? _c("file-preview", {
                    staticClass: "col-12 text-center",
                    attrs: {
                      blob_url: _vm.blob_url,
                      file_name: _vm.file_name,
                      mime_type: _vm.mime_type,
                      viewer_name: _vm.viewer_name,
                    },
                  })
                : _c("div", [_vm._v(" No file ")]),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }