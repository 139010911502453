var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.file_to_preview
    ? _c("div", { staticClass: "fw" }, [
        _vm.file
          ? _c("table", { attrs: { id: "container-table" } }, [
              _c("tr", [
                _vm.prev_file
                  ? _c(
                      "td",
                      {
                        staticClass:
                          "pager fh text-center c-pointer mr-3 no-text-select d-none d-md-table-cell",
                        attrs: { title: _vm.prev_file.name },
                        on: {
                          click: function ($event) {
                            _vm.file_to_preview = _vm.prev_file
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "ion-chevron-left" }),
                        _c("br"),
                        _c("small", [
                          _vm._v(
                            _vm._s(_vm.prev_file.name.substr(0, 11)) + "..."
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "td",
                  {
                    staticClass: "fw text-center",
                    staticStyle: { "min-height": "500px" },
                  },
                  [
                    _vm.file_to_preview.loading
                      ? _c("div", [
                          _vm._v(" Downloading "),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm.file_to_preview
                                  ? _vm.file_to_preview.name
                                  : "-"
                              )
                            ),
                          ]),
                          _vm._v(
                            ": " +
                              _vm._s(Math.round(_vm.file_to_preview.loading)) +
                              "% "
                          ),
                          _c("div", { staticClass: "progress mt-2 mx-5" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "progress-bar progress-bar-info progress-bar-striped progress-bar-animated",
                                style: {
                                  width:
                                    Math.round(_vm.file_to_preview.loading) +
                                    "%",
                                },
                                attrs: {
                                  role: "progressbar",
                                  "aria-valuenow": _vm.file_to_preview.loading,
                                  "aria-valuemin": "0",
                                  "aria-valuemax": "100",
                                },
                              },
                              [
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v(
                                    _vm._s(
                                      Math.round(_vm.file_to_preview.loading)
                                    ) + "% complete"
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ])
                      : _vm.file_to_preview.blob_url
                      ? [
                          _c("p", [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "btn btn-gradient btn-secondary ml-2",
                                attrs: {
                                  href: _vm.file_to_preview.blob_url,
                                  download: _vm.file_to_preview.name,
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "ion-arrow-down-a mr-2",
                                }),
                                _vm._v(" Save "),
                                _c("b", [
                                  _vm._v(_vm._s(_vm.file_to_preview.name)),
                                ]),
                              ]
                            ),
                          ]),
                          _c("file-preview", {
                            attrs: {
                              blob_url: _vm.file_to_preview.blob_url,
                              file_name: _vm.file_to_preview.name,
                              mime_type: _vm.file_to_preview.mime_type,
                              viewer_name: _vm.file_to_preview.viewer.name,
                            },
                          }),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _vm.next_file
                  ? _c(
                      "td",
                      {
                        staticClass:
                          "pager fh text-center c-pointer ml-3 no-text-select d-none d-md-table-cell",
                        attrs: { title: _vm.next_file.name },
                        on: {
                          click: function ($event) {
                            _vm.file_to_preview = _vm.next_file
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "ion-chevron-right" }),
                        _c("br"),
                        _c("small", [
                          _vm._v(
                            _vm._s(_vm.next_file.name.substr(0, 11)) + "..."
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }