var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm.deleted_groups
      ? _c(
          "div",
          [
            _vm._l(_vm.deleted_groups, function (group, index) {
              return _c("div", { key: index, staticClass: "cardbox" }, [
                _c(
                  "div",
                  {
                    staticClass: "cardbox-body text-center c-pointer",
                    class: { "text-bold": group.open },
                    attrs: {
                      title:
                        "Click to " +
                        (group.open ? "hide" : "show") +
                        " details",
                    },
                    on: {
                      click: function ($event) {
                        group.open = !group.open
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "float-right",
                      class: {
                        "ion-chevron-down": !group.open,
                        "ion-chevron-up": group.open,
                      },
                    }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.deleted_label ? _vm.deleted_label : "Deleted"
                        ) +
                        " "
                    ),
                    group.deleted_by !== undefined
                      ? _c(
                          "span",
                          { staticClass: "mr-2" },
                          [
                            _vm._v(" by "),
                            group.deleted_by !== undefined &&
                            _vm.user &&
                            _vm.user.team
                              ? _c("user-box", {
                                  attrs: {
                                    "user-id": group.deleted_by,
                                    team: _vm.user.team,
                                    "external-actor": _vm.modifiedBoxExternal,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("timestamp_date")(group.deleted_at)) +
                        ", " +
                        _vm._s(_vm._f("timestamp_time")(group.deleted_at)) +
                        " "
                    ),
                  ]
                ),
                group.open
                  ? _c("div", { staticClass: "cardbox-body" }, [
                      !_vm.disableRestore && group.is_deleted_folder
                        ? _c("div", { staticClass: "fw text-center" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary btn-gradient",
                                attrs: {
                                  disabled: group.entities[0].restoring,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.restore(group.entities[0])
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "ion-arrow-return-right mr-2",
                                }),
                                _vm._v("Restore folder"),
                                group.entities[0].restoring
                                  ? _c("i", {
                                      staticClass: "ion-load-c spin ml-1",
                                    })
                                  : _vm._e(),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _c("table", { staticClass: "table table-hover mb-0" }, [
                        _vm._m(0, true),
                        _c(
                          "tbody",
                          _vm._l(group.entities, function (file) {
                            return _c("tr", { key: file.id }, [
                              _c("td", { staticClass: "text-center" }, [
                                file.entity_type === "FILE"
                                  ? _c("img", {
                                      staticClass: "file-icon",
                                      attrs: {
                                        src: _vm._f("filetype_img_src")(
                                          file.extension
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                                file.entity_type === "FOLDER"
                                  ? _c("i", {
                                      staticClass: "ion-folder",
                                      staticStyle: { "font-size": "25px" },
                                    })
                                  : _vm._e(),
                              ]),
                              _c("td", [
                                _c(
                                  "span",
                                  { staticClass: "text-bold filename" },
                                  [_vm._v(_vm._s(file.name))]
                                ),
                                _c("div", { staticClass: "path" }, [
                                  _c("small", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$options.filters.path(file.path)
                                      ),
                                    },
                                  }),
                                ]),
                              ]),
                              _c("td", [
                                file.entity_type === "FILE"
                                  ? _c("span", { staticClass: "text-upper" }, [
                                      _vm._v(_vm._s(file.extension)),
                                    ])
                                  : _vm._e(),
                                file.entity_type === "FOLDER"
                                  ? _c("span", [_c("i", [_vm._v("Directory")])])
                                  : _vm._e(),
                              ]),
                              !_vm.disableRestore && !group.is_deleted_folder
                                ? _c("td", [
                                    !file.restoring
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-secondary btn-gradient",
                                            on: {
                                              click: function ($event) {
                                                return _vm.restore(file)
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "ion-arrow-return-right",
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "ml-2" },
                                              [_vm._v("Restore")]
                                            ),
                                          ]
                                        )
                                      : _c("i", {
                                          staticClass: "ion-load-c spin",
                                        }),
                                  ])
                                : _vm._e(),
                            ])
                          }),
                          0
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ])
            }),
            !_vm.deleted_groups.length
              ? _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-3" }),
                    _c("div", { staticClass: "col-12 col-lg-6" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "cardbox text-white bg-gradient-warning b0",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "cardbox-body text-center" },
                            [
                              _vm._m(1),
                              _c("p", { staticClass: "text-bold text-md" }, [
                                _vm._v("No "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "text-transform": "lowercase",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.deleted_label
                                          ? _vm.deleted_label
                                          : "deleted"
                                      ) + " "
                                    ),
                                  ]
                                ),
                                _vm._v(" files"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
    _vm.deleted_groups === null
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-3" }),
          _vm._m(2),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("th", { staticClass: "nowrap", staticStyle: { width: "1px" } }),
      _c("th", [_vm._v("Name")]),
      _c("th", { staticClass: "nowrap" }, [_vm._v("Type")]),
      _c("th", { staticClass: "nowrap", staticStyle: { width: "1px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-bold display-1" }, [
      _c("i", { staticClass: "ion-qr-scanner" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 col-lg-6" }, [
      _c("div", { staticClass: "cardbox text-white bg-gradient-warning b0" }, [
        _c("div", { staticClass: "cardbox-body text-center" }, [
          _c("div", { staticClass: "text-bold display-1" }, [
            _c("div", { staticClass: "loader" }),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }