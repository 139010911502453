var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.viewer_name == "image"
      ? _c("div", [
          _c("img", {
            staticClass: "preview d-inline-block",
            attrs: { src: _vm.blob_url },
          }),
        ])
      : _vm.viewer_name == "video"
      ? _c("div", [
          _c("video", {
            staticClass: "fw d-block",
            attrs: { src: _vm.blob_url, controls: "true", autoplay: "true" },
          }),
        ])
      : _vm.viewer_name == "audio"
      ? _c("div", [
          _c("audio", {
            staticClass: "fw d-block",
            attrs: { src: _vm.blob_url, controls: "true", autoplay: "true" },
          }),
        ])
      : _vm.viewer_name == "text"
      ? _c("div", [_c("pre", [_vm._v(_vm._s(_vm.text_file_contents))])])
      : _c("div", { staticClass: "d-block embedded-object" }, [
          _c(
            "object",
            {
              staticClass: "fw d-block embedded-object",
              attrs: {
                data: _vm.blob_url,
                type: _vm.mime_type,
                title: _vm.file_name,
                name: _vm.file_name,
                height: "800",
              },
            },
            [
              _c("p", [
                _vm._v(
                  "Your browser cannot preview " +
                    _vm._s(_vm.file_name) +
                    ". Please try downloading."
                ),
              ]),
            ]
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }