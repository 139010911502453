var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ol",
    { staticClass: "dd-list list-unstyled" },
    _vm._l(_vm.children, function (folder) {
      return _c(
        "li",
        { key: folder.name, staticClass: "dd-item" },
        [
          _c(
            "div",
            {
              staticClass: "cardbox b0 dd-handle",
              class: { "text-white bg-info": folder.selected },
            },
            [
              _c(
                "div",
                {
                  staticClass: "cardbox-body c-pointer namespace-folder-box",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.clicked(folder)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex align-items-center",
                      class: "color-" + folder.color + "-400",
                    },
                    [
                      _c("div", { staticClass: "mr-2" }, [
                        _c("i", {
                          staticClass: "ion-folder",
                          class: { "text-white": folder.selected },
                        }),
                      ]),
                      _c("div", [
                        _c(
                          "div",
                          {
                            class: { "text-white text-bold": folder.selected },
                          },
                          [_vm._v(_vm._s(folder.name))]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
          folder.children && folder.children.length > 0
            ? _c("namespace-tree-row", {
                attrs: { children: folder.children, clicked: _vm.clicked },
              })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }