var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "fw" }, [
    _vm._v(" You can reset the password and send a new invite to "),
    _c("b", [_vm._v(_vm._s(_vm.space.client_name))]),
    _vm._v(". "),
    _vm._m(0),
    _c("p", { staticClass: "mb-1" }, [
      _c("i", { staticClass: "ion-checkmark mr-2 ml-4" }),
      _vm._v(" A new invite is sent to "),
      _c("b", [_vm._v(_vm._s(_vm.space.client_email))]),
    ]),
    _vm._m(1),
    _c("div", { staticClass: "mt-3 text-center" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary text-bold",
          attrs: { disabled: _vm.loading },
          on: {
            click: function ($event) {
              return _vm.reset_space_password()
            },
          },
        },
        [
          _vm._v(" Reset password and send new invite "),
          _vm.loading
            ? _c("i", { staticClass: "ion-load-c spin ml-1" })
            : _vm._e(),
        ]
      ),
      _vm.error
        ? _c("div", {
            staticClass: "mt-2 text-danger",
            domProps: { innerHTML: _vm._s(_vm.error) },
          })
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mt-3 mb-1" }, [
      _c("i", { staticClass: "ion-checkmark mr-2 ml-4" }),
      _vm._v(" The current invite (if any) will be invalid immediately "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-1" }, [
      _c("i", { staticClass: "ion-checkmark mr-2 ml-4" }),
      _vm._v(" The new link can be used in the next 24 hours "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }