var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex align-items-center",
      class: { "gap-2": !_vm.Utils.is_mobile() },
    },
    [
      _c("img", {
        staticClass: "mr-2",
        attrs: {
          src: _vm.Utils.flag_img_src(_vm.location.countrycode),
          height: "20",
        },
      }),
      _vm.Utils.is_mobile()
        ? _c("div", [
            _c("div", [_vm._v(_vm._s(_vm.location.city))]),
            _c("div", { staticClass: "text-sm" }, [
              _vm._v(_vm._s(_vm.location.country)),
            ]),
          ])
        : _c("span", [
            _vm._v(
              _vm._s(_vm.location.city) + ", " + _vm._s(_vm.location.country)
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }