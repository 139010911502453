var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "layout-container" }, [
    _vm.space
      ? _c("div", { staticClass: "layout-container layout-container-main" }, [
          _c("aside", { staticClass: "sidebar-container" }, [
            _c("div", { staticClass: "px-3" }, [
              _c("div", { staticClass: "text-center py-3" }, [
                _vm.acting_user
                  ? _c("div", [
                      _vm.acting_user.avatar_url
                        ? _c("img", {
                            staticClass: "avatar_url mr-2 mb-3",
                            class: {
                              "initial128 round cover-fit": _vm.is_skyflok_user,
                            },
                            attrs: {
                              src: _vm.is_skyflok_user
                                ? _vm.acting_user.avatar_url
                                : _vm.ClientSpacesService.is_skyflok_logo(
                                    _vm.space
                                  )
                                ? _vm.Utils.client_space_logo_src(
                                    _vm.acting_user.avatar_url
                                  )
                                : _vm.acting_user.avatar_url,
                            },
                          })
                        : _vm._e(),
                      _vm.is_skyflok_user
                        ? _c(
                            "h5",
                            { staticClass: "text-lightgray text-bold mb-0" },
                            [_vm._v(" " + _vm._s(_vm.acting_user.name) + " ")]
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "c-pointer",
                              attrs: { title: "Click to edit your info" },
                              on: {
                                click: function ($event) {
                                  return _vm.open_client_form()
                                },
                              },
                            },
                            [
                              _c(
                                "h5",
                                {
                                  staticClass:
                                    "text-lightgray text-bold d-inline",
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.acting_user.name) + " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                      _c(
                        "small",
                        {
                          staticClass: "text-muted",
                          staticStyle: { "word-break": "break-word" },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.is_skyflok_user
                                ? _vm.acting_user.company_name
                                : _vm.space.client_email
                            )
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
              !_vm.is_skyflok_user
                ? _c("div", { staticClass: "mb-3 fw text-center text-white" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn fw btn-outline-secondary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.open_client_form()
                          },
                        },
                      },
                      [
                        _vm._v(" My Settings "),
                        _c("i", { staticClass: "ion-gear-a ml-2 text-muted" }),
                      ]
                    ),
                  ])
                : _vm._e(),
              !_vm.is_skyflok_user
                ? _c("div", { staticClass: "mb-2" }, [
                    !_vm.mfa_status || _vm.mfa_enabled
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "btn fw btn-outline-secondary btn-flat text-normal",
                            staticStyle: { "text-transform": "none" },
                            on: {
                              click: function ($event) {
                                return _vm._open_modal("2fa-modal")
                              },
                            },
                          },
                          [
                            _vm._v(" Two-Factor Auth: "),
                            !_vm.mfa_status
                              ? _c("i", { staticClass: "ion-load-c spin ml-2" })
                              : _c(
                                  "span",
                                  {
                                    staticClass: "ml-1 text-success text-bold",
                                  },
                                  [
                                    _vm._v("ON "),
                                    _c("i", { staticClass: "ion-locked ml-1" }),
                                  ]
                                ),
                          ]
                        )
                      : _c(
                          "button",
                          {
                            staticClass: "btn fw btn-success",
                            on: {
                              click: function ($event) {
                                return _vm._open_modal("2fa-modal")
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "ion-locked mr-2" }),
                            _vm._v("Enable Two-Factor Auth "),
                          ]
                        ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "mb-2 fw text-center text-white" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn fw btn-outline-secondary btn-flat text-normal",
                    staticStyle: { "text-transform": "none" },
                    attrs: {
                      title:
                        "Click to " +
                        (_vm.email_notifications
                          ? "stop receiving"
                          : "receive") +
                        " email notifications",
                      disabled: !_vm.space || _vm.change_watch_loading,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.change_watch()
                      },
                    },
                  },
                  [
                    _vm._v(" Email Notifications: "),
                    _vm.change_watch_loading
                      ? _c("i", { staticClass: "ion-load-c spin ml-1" })
                      : !_vm.email_notifications
                      ? _c("span", { staticClass: "ml-1 text-bold" }, [
                          _vm._v(" OFF "),
                          _c("i", {
                            staticClass: "ion-android-notifications-none ml-1",
                          }),
                        ])
                      : _vm.email_notifications
                      ? _c(
                          "span",
                          { staticClass: "ml-1 text-success text-bold" },
                          [
                            _vm._v(" ON "),
                            _c("i", {
                              staticClass: "ion-android-notifications ml-1",
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
              _vm.is_skyflok_user || !_vm.space.client_readonly
                ? _c("div", { staticClass: "mb-2" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn fw",
                        class: {
                          "btn-outline-secondary btn-flat": !_vm.show_trash,
                          "btn-primary": _vm.show_trash,
                        },
                        staticStyle: { "text-transform": "none" },
                        on: {
                          click: function ($event) {
                            _vm.show_trash = !_vm.show_trash
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.show_trash ? "Active Files" : "Recycle Bin"
                          ) + " "
                        ),
                        _c("i", {
                          staticClass: "icon-lg ml-1",
                          class: {
                            "ion-trash-a": !_vm.show_trash,
                            "ion-document-text": _vm.show_trash,
                          },
                        }),
                      ]
                    ),
                  ])
                : _vm._e(),
              _c(
                "section",
                [
                  _c("div", { staticClass: "sidebar-nav-heading px-0 pb-0" }, [
                    _vm._v(" LATEST EVENTS "),
                    _c("i", {
                      staticClass: "ion-refresh ml-1 text-muted c-pointer",
                      class: { spin: _vm.recent_events_loading },
                      on: {
                        click: function ($event) {
                          return _vm.load_recent_events()
                        },
                      },
                    }),
                    false
                      ? _c("span", { staticClass: "float-right" }, [
                          _c(
                            "span",
                            {
                              staticClass: "c-pointer text-12",
                              class: {
                                "text-success":
                                  _vm.recent_events_filter.downloads,
                                "text-muted":
                                  !_vm.recent_events_filter.downloads,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.recent_events_filter.downloads =
                                    !_vm.recent_events_filter.downloads
                                },
                              },
                            },
                            [_c("i", { staticClass: "ion-arrow-down-a" })]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "ml-2 c-pointer text-12",
                              class: {
                                "text-info": _vm.recent_events_filter.uploads,
                                "text-muted": !_vm.recent_events_filter.uploads,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.recent_events_filter.uploads =
                                    !_vm.recent_events_filter.uploads
                                },
                              },
                            },
                            [_c("i", { staticClass: "ion-arrow-up-a" })]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._l(_vm.recent_events_filtered, function (event) {
                    return _c("event-box", {
                      key: event.id,
                      attrs: {
                        is_clickable: true,
                        event: event,
                        space: _vm.space,
                        skyflok_team: _vm.acting_user.team,
                      },
                      on: {
                        file_clicked: (file) => {
                          _vm.sidebar_file_clicked(file)
                        },
                      },
                    })
                  }),
                  _vm.recent_events !== null && _vm.recent_events.length === 0
                    ? _c(
                        "i",
                        {
                          staticClass:
                            "d-block text-center text-muted text-sm py-2",
                        },
                        [_vm._v("No events")]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "fw text-right" }, [
                    _c("button", {
                      staticClass:
                        "btn btn-flat btn-sm ion-chevron-left text-muted",
                      attrs: {
                        disabled: _vm.recent_events_pager.start_offset <= 0,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.recent_events_page(false)
                        },
                      },
                    }),
                    _c("button", {
                      staticClass:
                        "btn btn-flat btn-sm ion-chevron-right text-muted",
                      attrs: {
                        disabled:
                          _vm.recent_events && _vm.recent_events.length === 0,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.recent_events_page(true)
                        },
                      },
                    }),
                  ]),
                ],
                2
              ),
              _vm.space.locations && _vm.skyflok_team
                ? _c(
                    "div",
                    { staticClass: "my-3", attrs: { id: "data-locations" } },
                    [
                      _c("locations", {
                        attrs: {
                          locations: _vm.space.locations,
                          disable_popover: _vm.is_narrow,
                          placement: _vm.is_narrow ? "right" : "left",
                          "team-name": _vm.skyflok_team.name,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "container color-selector" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-2" }),
                  _c("div", { staticClass: "col-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-outline-secondary text-sm",
                        on: {
                          click: function ($event) {
                            return _vm.set_theme("theme-dark")
                          },
                        },
                      },
                      [_vm._v("Dark")]
                    ),
                  ]),
                  _c("div", { staticClass: "col-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm text-sm",
                        on: {
                          click: function ($event) {
                            return _vm.set_theme("theme-default")
                          },
                        },
                      },
                      [_vm._v("Bright")]
                    ),
                  ]),
                ]),
              ]),
              !_vm.is_skyflok_user
                ? _c("div", { staticClass: "fw text-center mt-3" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-outline-danger btn-sm text-sm text-bold",
                        attrs: { disabled: _vm.logout_loading },
                        on: {
                          click: function ($event) {
                            return _vm.logout_client()
                          },
                        },
                      },
                      [
                        _vm._v(" Log out "),
                        _vm.logout_loading
                          ? _c("i", { staticClass: "ion-load-c spin ml-2" })
                          : _vm._e(),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticClass: "sidebar-layout-obfuscator" }),
          _c(
            "main",
            {
              staticClass: "main-container",
              class: { archived_space_bg: _vm.space.is_archived },
            },
            [
              _c(
                "header",
                { staticClass: "header-container" },
                [
                  _c("global-alert", { attrs: { alert: _vm.alert } }),
                  _c(
                    "nav",
                    { staticClass: "text-center" },
                    [
                      _c("ul", { staticClass: "d-none d-lg-block" }, [
                        _c("li", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm._f("client_space_link")(
                                  _vm.space.key
                                ),
                                title: "Space Home",
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/img/skyflok_logo_black.png"),
                                  width: "130",
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._m(0),
                      _vm._m(1),
                      _vm._m(2),
                      _c("div", { staticClass: "d-none d-sm-inline-block" }, [
                        _vm.skyflok_team
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-between align-items-center",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: { cursor: "default" },
                                    attrs: {
                                      title:
                                        "Secure, private shared space between " +
                                        _vm.skyflok_team.name +
                                        " and " +
                                        _vm.space.client_name,
                                    },
                                  },
                                  [
                                    _vm.skyflok_team.logo_url &&
                                    _vm.skyflok_team.logo_url != ""
                                      ? _c("img", {
                                          staticClass:
                                            "d-none d-md-inline-block",
                                          staticStyle: {
                                            "max-width": "100px",
                                            "max-height": "48px",
                                          },
                                          attrs: {
                                            src: _vm.skyflok_team.logo_url,
                                          },
                                        })
                                      : _vm._e(),
                                    _c("h5", { staticClass: "d-inline" }, [
                                      _vm._v(_vm._s(_vm.skyflok_team.name)),
                                    ]),
                                    _c("i", {
                                      staticClass: "ion-code icon-lg mx-4",
                                    }),
                                    _vm.space.client_logo
                                      ? _c("img", {
                                          staticClass:
                                            "d-none d-md-inline-block",
                                          staticStyle: {
                                            "max-width": "60px",
                                            "max-height": "48px",
                                          },
                                          attrs: {
                                            src: _vm.ClientSpacesService.is_skyflok_logo(
                                              _vm.space
                                            )
                                              ? _vm.Utils.client_space_logo_src(
                                                  _vm.space.client_logo
                                                )
                                              : _vm.space.client_logo,
                                          },
                                        })
                                      : _vm._e(),
                                    _c(
                                      "h5",
                                      {
                                        staticClass: "d-inline",
                                        class: {
                                          "text-italic c-help":
                                            _vm.space.client_readonly,
                                        },
                                        attrs: {
                                          title: _vm.space.client_readonly
                                            ? _vm.space.client_name +
                                              " can only browse and download files in this space, cannot upload or edit."
                                            : null,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.space.client_name) +
                                            " "
                                        ),
                                        _vm.space.client_readonly
                                          ? _c("i", {
                                              staticClass:
                                                "ion-locked ml-1 text-sm text-gray",
                                              attrs: {
                                                "data-toggle": "popover",
                                                "data-content":
                                                  "The Client can only browse and download files, but not allowed to upload, delete or change the folder structure.",
                                                "data-html": "true",
                                                "data-trigger": "hover",
                                                "data-placement": "bottom",
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _c("search-box", {
                        staticClass: "float-right mr-2",
                        attrs: {
                          placeholder: "Search",
                          hideinput: true,
                          "redirect-when-clicked": false,
                          "custom-namespace": true,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.space.is_archived
                ? _c(
                    "div",
                    {
                      staticClass:
                        "fw text-center py-2 text-white text-bold bg-gradient-warning",
                    },
                    [
                      _c("i", { staticClass: "ion-locked mr-2" }),
                      _vm._v(
                        " This Space is archived, you can browse it but cannot upload, download or change anything. "
                      ),
                      _c("i", { staticClass: "ion-locked ml-2" }),
                    ]
                  )
                : _vm._e(),
              !_vm.show_trash
                ? _c("file-list-view", {
                    staticStyle: { "margin-top": "0px" },
                    attrs: {
                      user: _vm.acting_user,
                      "share-allowed": false,
                      "upload-allowed": !_vm.is_space_readonly,
                      "download-allowed": !_vm.space.is_archived,
                      "namespace-ops-allowed": !_vm.is_space_readonly,
                      "modified-box-external": _vm.external_actor,
                      file_uploaded_callback: (file) => {
                        _vm.on_file_uploaded(file)
                      },
                      file_download_started: (file) => {
                        _vm.report_file_event("download_start", file)
                      },
                      file_download_error: (file) => {
                        _vm.report_file_event("download_error", file, _vm.error)
                      },
                    },
                    on: {
                      file_download_succeeded: (file) => {
                        _vm.report_file_event("download_finished", file)
                        _vm.load_recent_events()
                      },
                    },
                  })
                : _c(
                    "div",
                    {
                      staticClass: "container",
                      staticStyle: {
                        "padding-top": "100px",
                        "margin-top": "0px",
                      },
                    },
                    [
                      _c("div", { staticClass: "row pt-3" }, [
                        _c("div", { staticClass: "col-1" }),
                        _c("div", { staticClass: "col-12 col-lg-10" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "cardbox text-white bg-gradient-warning b0",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "cardbox-body text-center text-bold",
                                  staticStyle: { "font-size": "1.3rem" },
                                },
                                [
                                  _vm._v(
                                    " Deleted files and directories are stored here for 30 days. "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " You can recover them during this period, afterwards they are deleted automatically. "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-2" }),
                        _c(
                          "div",
                          { staticClass: "col-12 col-lg-8" },
                          [
                            _c("deleted-groups", {
                              attrs: {
                                user: _vm.acting_user,
                                "modified-box-external": _vm.external_actor,
                                "restore-allowed": !_vm.space.is_archived,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
            ],
            1
          ),
          !_vm.is_skyflok_user
            ? _c(
                "div",
                { staticClass: "modal", attrs: { id: "client-form-modal" } },
                [
                  _vm.client_form
                    ? _c("div", { staticClass: "modal-dialog modal-lg" }, [
                        _c("div", { staticClass: "modal-content" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "modal-header bg-gradient-info text-white",
                            },
                            [
                              _c("h5", { staticClass: "mt-0 modal-title" }, [
                                _vm._v("Edit your information"),
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "close",
                                  attrs: {
                                    type: "button",
                                    "aria-label": "Close",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.close_modal(
                                        "client-form-modal"
                                      )
                                    },
                                  },
                                },
                                [_c("span", [_vm._v("×")])]
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "modal-body" }, [
                            _c(
                              "section",
                              { staticClass: "d-none d-md-block" },
                              [
                                _vm.client_form.error
                                  ? _c(
                                      "div",
                                      { staticClass: "alert alert-danger" },
                                      [_vm._v(_vm._s(_vm.client_form.error))]
                                    )
                                  : _vm._e(),
                                _vm.client_form.success
                                  ? _c(
                                      "div",
                                      { staticClass: "alert alert-success" },
                                      [_vm._v(_vm._s(_vm.client_form.success))]
                                    )
                                  : _vm._e(),
                                _c(
                                  "form",
                                  {
                                    on: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                        return _vm.client_form_submit.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "table",
                                      { staticClass: "table form-table mb-0" },
                                      [
                                        _c("tbody", [
                                          _c("tr", [
                                            _c("th", [_vm._v("Name:")]),
                                            _c("td", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.client_form.name,
                                                    expression:
                                                      "client_form.name",
                                                  },
                                                ],
                                                staticClass:
                                                  "form-control form-control-sm",
                                                attrs: {
                                                  type: "text",
                                                  maxlength: "255",
                                                },
                                                domProps: {
                                                  value: _vm.client_form.name,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.client_form,
                                                      "name",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]),
                                          ]),
                                          _c("tr", [
                                            _c(
                                              "th",
                                              { staticClass: "nowrap" },
                                              [_vm._v("E-mail:")]
                                            ),
                                            _c("td", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.trim",
                                                    value:
                                                      _vm.client_form.email,
                                                    expression:
                                                      "client_form.email",
                                                    modifiers: { trim: true },
                                                  },
                                                ],
                                                staticClass:
                                                  "form-control form-control-sm",
                                                attrs: {
                                                  type: "email",
                                                  maxlength: "255",
                                                },
                                                domProps: {
                                                  value: _vm.client_form.email,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.client_form,
                                                      "email",
                                                      $event.target.value.trim()
                                                    )
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.$forceUpdate()
                                                  },
                                                },
                                              }),
                                            ]),
                                          ]),
                                          _c(
                                            "tr",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    !_vm.client_change_pass,
                                                  expression:
                                                    "!client_change_pass",
                                                },
                                              ],
                                            },
                                            [
                                              _c("th", [_vm._v("Password:")]),
                                              _c("td", [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-secondary btn-sm text-sm",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.client_change_pass = true
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("Change password")]
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "tr",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.client_change_pass,
                                                  expression:
                                                    "client_change_pass",
                                                },
                                              ],
                                            },
                                            [
                                              _c("th", [
                                                _vm._v("Current password"),
                                              ]),
                                              _c("td", [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.client_form
                                                          .current_pass,
                                                      expression:
                                                        "client_form.current_pass",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "form-control form-control-sm",
                                                  attrs: { type: "password" },
                                                  domProps: {
                                                    value:
                                                      _vm.client_form
                                                        .current_pass,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.client_form,
                                                        "current_pass",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "tr",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.client_change_pass,
                                                  expression:
                                                    "client_change_pass",
                                                },
                                              ],
                                            },
                                            [
                                              _c("th", [
                                                _vm._v("New password"),
                                              ]),
                                              _c("td", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "fw d-flex align-items-center",
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.client_form
                                                              .psw1,
                                                          expression:
                                                            "client_form.psw1",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control form-control-sm mr-1",
                                                      attrs: {
                                                        type: "password",
                                                        placeholder:
                                                          "New password",
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.client_form.psw1,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.client_form,
                                                            "psw1",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.client_form
                                                              .psw2,
                                                          expression:
                                                            "client_form.psw2",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control form-control-sm ml-1",
                                                      attrs: {
                                                        type: "password",
                                                        placeholder:
                                                          "Repeat new password",
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.client_form.psw2,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.client_form,
                                                            "psw2",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c("tr", [
                                            _c("th", { staticClass: "v-top" }, [
                                              _vm._v("Logo:"),
                                            ]),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  width: "80%",
                                                  "padding-top": "0px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "mb-2",
                                                    attrs: {
                                                      id: "client_space_logos_carousel",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.client_form.logos,
                                                    function (logo) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: logo.path,
                                                          staticClass:
                                                            "slide d-inline-block",
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "builtin-logo logo c-pointer m-1",
                                                            class: {
                                                              selected:
                                                                _vm.client_form
                                                                  .selected_logo ===
                                                                logo.path,
                                                            },
                                                            attrs: {
                                                              title:
                                                                logo.name +
                                                                " (Click to " +
                                                                (logo.selected
                                                                  ? "un"
                                                                  : "") +
                                                                "select)",
                                                              src: _vm.Utils.client_space_logo_src(
                                                                logo.path
                                                              ),
                                                              width: "84",
                                                              height: "84",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.select_logo(
                                                                  logo
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _vm.client_form
                                                  .custom_avatar_url !== null
                                                  ? _c("div", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-sm mr-2",
                                                        },
                                                        [_vm._v("Custom logo:")]
                                                      ),
                                                      _c("img", {
                                                        staticClass:
                                                          "avatar_url",
                                                        attrs: {
                                                          src: _vm.client_form
                                                            .custom_avatar_url,
                                                        },
                                                      }),
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-sm btn-secondary",
                                                          attrs: {
                                                            type: "button",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.client_form.custom_avatar_url =
                                                                null
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("Clear")]
                                                      ),
                                                    ])
                                                  : _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-sm mr-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Upload custom image:"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "profile-image-selector",
                                                          {
                                                            attrs: {
                                                              avatar_changed: (
                                                                avatar_url
                                                              ) => {
                                                                _vm.client_form.custom_avatar_url =
                                                                  avatar_url
                                                              },
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                        _c("tfoot", [
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticClass: "text-right",
                                                attrs: { colspan: "2" },
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-secondary mr-2",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.close_modal(
                                                          "client-form-modal"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("Cancel")]
                                                ),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-info btn-gradient",
                                                    attrs: {
                                                      type: "submit",
                                                      disabled:
                                                        _vm.client_form.loading,
                                                    },
                                                  },
                                                  [
                                                    _vm._v("Save"),
                                                    _vm.client_form.loading
                                                      ? _c("i", {
                                                          staticClass:
                                                            "ion-load-c spin ml-2",
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "section",
                              { staticClass: "d-block d-xl-none mb-4" },
                              [
                                _vm.client_form.error
                                  ? _c(
                                      "div",
                                      { staticClass: "alert alert-danger" },
                                      [_vm._v(_vm._s(_vm.client_form.error))]
                                    )
                                  : _vm._e(),
                                _vm.client_form.success
                                  ? _c(
                                      "div",
                                      { staticClass: "alert alert-success" },
                                      [_vm._v(_vm._s(_vm.client_form.success))]
                                    )
                                  : _vm._e(),
                                _c(
                                  "form",
                                  {
                                    staticClass: "text-sm",
                                    on: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                        return _vm.client_form_submit.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "mobile-form-field" },
                                      [
                                        _c("b", [_vm._v("Name")]),
                                        _c("br"),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.client_form.name,
                                              expression: "client_form.name",
                                            },
                                          ],
                                          staticClass:
                                            "form-control form-control-sm",
                                          attrs: {
                                            type: "text",
                                            maxlength: "255",
                                          },
                                          domProps: {
                                            value: _vm.client_form.name,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.client_form,
                                                "name",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "mobile-form-field" },
                                      [
                                        _c("b", [_vm._v("E-mail")]),
                                        _c("br"),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model.trim",
                                              value: _vm.client_form.email,
                                              expression: "client_form.email",
                                              modifiers: { trim: true },
                                            },
                                          ],
                                          staticClass:
                                            "form-control form-control-sm",
                                          attrs: {
                                            type: "email",
                                            maxlength: "255",
                                          },
                                          domProps: {
                                            value: _vm.client_form.email,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.client_form,
                                                "email",
                                                $event.target.value.trim()
                                              )
                                            },
                                            blur: function ($event) {
                                              return _vm.$forceUpdate()
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "mobile-form-field" },
                                      [
                                        _c("b", [_vm._v("Password")]),
                                        _c("br"),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-secondary btn-sm text-sm",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                _vm.client_change_pass = true
                                              },
                                            },
                                          },
                                          [_vm._v("Change password")]
                                        ),
                                      ]
                                    ),
                                    _vm.client_change_pass
                                      ? _c(
                                          "div",
                                          { staticClass: "mobile-form-field" },
                                          [
                                            _c("b", [
                                              _vm._v("Current password"),
                                            ]),
                                            _c("br"),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center justify-content-center",
                                              },
                                              [
                                                _vm.client_form_show_current_pass
                                                  ? _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.client_form
                                                              .current_pass,
                                                          expression:
                                                            "client_form.current_pass",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control form-control-sm",
                                                      attrs: { type: "text" },
                                                      domProps: {
                                                        value:
                                                          _vm.client_form
                                                            .current_pass,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.client_form,
                                                            "current_pass",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    })
                                                  : _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.client_form
                                                              .current_pass,
                                                          expression:
                                                            "client_form.current_pass",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control form-control-sm",
                                                      attrs: {
                                                        type: "password",
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.client_form
                                                            .current_pass,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.client_form,
                                                            "current_pass",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                _c("i", {
                                                  staticClass: "ion-eye ml-2",
                                                  class: {
                                                    "ion-eye":
                                                      !_vm.client_form_show_current_pass,
                                                    "ion-eye-disabled":
                                                      _vm.client_form_show_current_pass,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.client_form_show_current_pass =
                                                        !_vm.client_form_show_current_pass
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.client_change_pass
                                      ? _c(
                                          "div",
                                          { staticClass: "mobile-form-field" },
                                          [
                                            _c("b", [_vm._v("New Password")]),
                                            _c("br"),
                                            _c(
                                              "section",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center",
                                              },
                                              [
                                                _vm.client_form_show_new_pass
                                                  ? _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.client_form
                                                              .psw1,
                                                          expression:
                                                            "client_form.psw1",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control form-control-sm mr-1",
                                                      class: {
                                                        "form-control-success":
                                                          _vm.client_form
                                                            .psw1 &&
                                                          _vm.client_form
                                                            .psw2 &&
                                                          _vm.client_form
                                                            .psw1 ==
                                                            _vm.client_form
                                                              .psw2 &&
                                                          _vm.client_form.psw1
                                                            .length >= 8,
                                                        "form-control-danger":
                                                          _vm.client_form
                                                            .psw1 &&
                                                          _vm.client_form
                                                            .psw2 &&
                                                          (_vm.client_form
                                                            .psw1 !==
                                                            _vm.client_form
                                                              .psw2 ||
                                                            _vm.client_form.psw1
                                                              .length < 8),
                                                      },
                                                      attrs: { type: "text" },
                                                      domProps: {
                                                        value:
                                                          _vm.client_form.psw1,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.client_form,
                                                            "psw1",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    })
                                                  : _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.client_form
                                                              .psw1,
                                                          expression:
                                                            "client_form.psw1",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control form-control-sm mr-1",
                                                      class: {
                                                        "form-control-success":
                                                          _vm.client_form
                                                            .psw1 &&
                                                          _vm.client_form
                                                            .psw2 &&
                                                          _vm.client_form
                                                            .psw1 ==
                                                            _vm.client_form
                                                              .psw2 &&
                                                          _vm.client_form.psw1
                                                            .length >= 8,
                                                        "form-control-danger":
                                                          _vm.client_form
                                                            .psw1 &&
                                                          _vm.client_form
                                                            .psw2 &&
                                                          (_vm.client_form
                                                            .psw1 !==
                                                            _vm.client_form
                                                              .psw2 ||
                                                            _vm.client_form.psw1
                                                              .length < 8),
                                                      },
                                                      attrs: {
                                                        type: "password",
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.client_form.psw1,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.client_form,
                                                            "psw1",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                _vm.client_form_show_new_pass
                                                  ? _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.client_form
                                                              .psw2,
                                                          expression:
                                                            "client_form.psw2",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control form-control-sm ml-1",
                                                      class: {
                                                        "form-control-success":
                                                          _vm.client_form
                                                            .psw1 &&
                                                          _vm.client_form
                                                            .psw2 &&
                                                          _vm.client_form
                                                            .psw1 ==
                                                            _vm.client_form
                                                              .psw2 &&
                                                          _vm.client_form.psw1
                                                            .length >= 8,
                                                        "form-control-danger":
                                                          _vm.client_form
                                                            .psw1 &&
                                                          _vm.client_form
                                                            .psw2 &&
                                                          (_vm.client_form
                                                            .psw1 !==
                                                            _vm.client_form
                                                              .psw2 ||
                                                            _vm.client_form.psw1
                                                              .length < 8),
                                                      },
                                                      attrs: { type: "text" },
                                                      domProps: {
                                                        value:
                                                          _vm.client_form.psw2,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.client_form,
                                                            "psw2",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    })
                                                  : _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.client_form
                                                              .psw2,
                                                          expression:
                                                            "client_form.psw2",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control form-control-sm ml-1",
                                                      class: {
                                                        "form-control-success":
                                                          _vm.client_form
                                                            .psw1 &&
                                                          _vm.client_form
                                                            .psw2 &&
                                                          _vm.client_form
                                                            .psw1 ==
                                                            _vm.client_form
                                                              .psw2 &&
                                                          _vm.client_form.psw1
                                                            .length >= 8,
                                                        "form-control-danger":
                                                          _vm.client_form
                                                            .psw1 &&
                                                          _vm.client_form
                                                            .psw2 &&
                                                          (_vm.client_form
                                                            .psw1 !==
                                                            _vm.client_form
                                                              .psw2 ||
                                                            _vm.client_form.psw1
                                                              .length < 8),
                                                      },
                                                      attrs: {
                                                        type: "password",
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.client_form.psw2,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.client_form,
                                                            "psw2",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                _c("i", {
                                                  staticClass: "ion-eye ml-2",
                                                  class: {
                                                    "ion-eye":
                                                      !_vm.client_form_show_new_pass,
                                                    "ion-eye-disabled":
                                                      _vm.client_form_show_new_pass,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.client_form_show_new_pass =
                                                        !_vm.client_form_show_new_pass
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm.client_form.psw1 &&
                                            _vm.client_form.psw1.length < 8
                                              ? _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Minimum 8 characters required"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "mobile-form-field" },
                                      [
                                        _c("b", [_vm._v("Logo")]),
                                        _c("br"),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "mb-2",
                                            attrs: {
                                              id: "client_space_logos_carousel",
                                            },
                                          },
                                          _vm._l(
                                            _vm.client_form.logos,
                                            function (logo) {
                                              return _c(
                                                "div",
                                                {
                                                  key: logo.path,
                                                  staticClass:
                                                    "slide d-inline-block",
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "builtin-logo logo c-pointer m-1",
                                                    class: {
                                                      selected:
                                                        _vm.client_form
                                                          .selected_logo ===
                                                        logo.path,
                                                    },
                                                    attrs: {
                                                      title:
                                                        logo.name +
                                                        " (Click to " +
                                                        (logo.selected
                                                          ? "un"
                                                          : "") +
                                                        "select)",
                                                      src: logo.path,
                                                      type: "button",
                                                      width: "50",
                                                      height: "50",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.select_logo(
                                                          logo
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                        _vm.client_form.custom_avatar_url !==
                                        null
                                          ? _c("div", [
                                              _c(
                                                "span",
                                                { staticClass: "text-sm mr-2" },
                                                [_vm._v("Custom logo:")]
                                              ),
                                              _c("img", {
                                                staticClass: "avatar_url",
                                                attrs: {
                                                  src: _vm.client_form
                                                    .custom_avatar_url,
                                                },
                                              }),
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-sm btn-secondary",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.client_form.custom_avatar_url =
                                                        null
                                                    },
                                                  },
                                                },
                                                [_vm._v("Clear")]
                                              ),
                                            ])
                                          : _c(
                                              "div",
                                              [
                                                !_vm.client_form
                                                  .custom_avatar_url
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-sm mr-2",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Or upload custom image:"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c("profile-image-selector", {
                                                  attrs: {
                                                    avatar_changed: (
                                                      avatar_url
                                                    ) => {
                                                      _vm.client_form.custom_avatar_url =
                                                        avatar_url
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "fw d-flex align-items-center justify-content-center mt-3",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "fw btn btn-secondary mr-1",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.close_modal(
                                                  "client-form-modal"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Cancel")]
                                        ),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "fw btn btn-gradient btn-info ml-1",
                                            attrs: {
                                              type: "submit",
                                              disabled: _vm.client_form.loading,
                                            },
                                          },
                                          [
                                            _vm._v("Save"),
                                            _vm.client_form.loading
                                              ? _c("i", {
                                                  staticClass:
                                                    "ion-load-c spin",
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c("section", { staticClass: "fw text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-outline-danger text-bold",
                                  attrs: { disabled: _vm.logout_loading },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.logout_client()
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "ion-power mr-2" }),
                                  _vm._v("Log out from this Space "),
                                  _vm.logout_loading
                                    ? _c("i", {
                                        staticClass: "ion-load-c spin ml-1",
                                      })
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                            _c("hr"),
                            _vm._m(3),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          !_vm.is_skyflok_user
            ? _c(
                "div",
                { staticClass: "modal", attrs: { id: "welcome-modal" } },
                [
                  _c("div", { staticClass: "modal-dialog modal-lg" }, [
                    _c("div", { staticClass: "modal-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "modal-header bg-gradient-primary text-white",
                        },
                        [
                          _c("h5", { staticClass: "mt-0 modal-title" }, [
                            _vm._v("Welcome to SkyFlok!"),
                          ]),
                          _c(
                            "button",
                            {
                              staticClass: "close",
                              attrs: { type: "button", "aria-label": "Close" },
                              on: {
                                click: function ($event) {
                                  return _vm.close_modal("welcome-modal")
                                },
                              },
                            },
                            [_c("span", [_vm._v("×")])]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "modal-body",
                          class: { "px-4 mx-4": !_vm.is_mobile },
                        },
                        [
                          _c("div", { staticClass: "fw text-center mb-4" }, [
                            _c("h4", [
                              _vm._v("Hi "),
                              _c("b", [_vm._v(_vm._s(_vm.space.client_name))]),
                              _vm._v(", welcome to you new Client Space!"),
                            ]),
                          ]),
                          _c("p", [
                            _vm._v(
                              " This is a secure, private shared folder between you and "
                            ),
                            _c("strong", [
                              _vm._v(_vm._s(_vm.skyflok_team.name)),
                            ]),
                            _vm._v(". "),
                            !_vm.space.client_readonly
                              ? _c("span", [
                                  _vm._v(
                                    " You can share files with each other, organize them into folders, delete and restore them as you wish. "
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    " You can see and download the files that " +
                                      _vm._s(_vm.skyflok_team.name) +
                                      " uploads here for you. "
                                  ),
                                ]),
                            _vm._v(
                              " In the sidebar you can see the last few files uploads and downloads. "
                            ),
                          ]),
                          _c("p", [
                            _vm.space.watch_client
                              ? _c("span", [
                                  _vm._v(" You will "),
                                  _c("b", [_vm._v("receive an email")]),
                                  _vm._v(
                                    " when new files has been uploaded. You can turn this feature off in the sidebar any time. "
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    " It's recommended to turn on email notifications, so you know when there's something new for you here. "
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "fw text-center mt-2" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-default btn-bold",
                                          attrs: {
                                            disabled: _vm.change_watch_loading,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.change_watch()
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "mr-2",
                                            class: {
                                              "ion-android-notifications":
                                                !_vm.change_watch_loading,
                                              "ion-load-c spin":
                                                _vm.change_watch_loading,
                                            },
                                          }),
                                          _vm._v(
                                            " Turn on email notifications "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                          ]),
                          !_vm.mfa_enabled
                            ? _c("p", [
                                _vm._v(
                                  " To maximize security, it is highly recommended to enable Two-Factor Authentication. "
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "d-block mt-2 fw text-center",
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-success btn-bold",
                                        on: {
                                          click: () => {
                                            _vm.close_modal("welcome-modal")
                                            _vm._open_modal("2fa-modal")
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "ion-locked mr-2",
                                        }),
                                        _vm._v(
                                          " Setup Two-Factor Authenticaion "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm._m(4),
                          _c("p", { staticClass: "text-center" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary",
                                on: {
                                  click: function ($event) {
                                    return _vm.close_modal("welcome-modal")
                                  },
                                },
                              },
                              [_vm._v("Close")]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          !_vm.is_skyflok_user
            ? _c("div", { staticClass: "modal", attrs: { id: "2fa-modal" } }, [
                _c("div", { staticClass: "modal-dialog modal-lg" }, [
                  _c("div", { staticClass: "modal-content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "modal-header bg-gradient-primary text-white",
                      },
                      [
                        _c("h5", { staticClass: "mt-0 modal-title" }, [
                          _vm._v("Two-Factor Authentication"),
                        ]),
                        _c(
                          "button",
                          {
                            staticClass: "close",
                            attrs: { type: "button", "aria-label": "Close" },
                            on: {
                              click: function ($event) {
                                return _vm.close_modal("2fa-modal")
                              },
                            },
                          },
                          [_c("span", [_vm._v("×")])]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "modal-body",
                        class: { "px-4 mx-4": !_vm.is_mobile },
                      },
                      [
                        _vm.mfa_alert
                          ? _c(
                              "div",
                              {
                                staticClass: "alert",
                                class: {
                                  "alert-danger": _vm.mfa_alert.type == "error",
                                  "alert-success":
                                    _vm.mfa_alert.type == "success",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "float-right clickable",
                                    staticStyle: { "margin-right": "-.5em" },
                                    attrs: { "data-dismiss": "modal" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        _vm.mfa_alert = null
                                      },
                                    },
                                  },
                                  [_c("em", { staticClass: "ion-close-round" })]
                                ),
                                _vm._v(" " + _vm._s(_vm.mfa_alert.msg) + " "),
                              ]
                            )
                          : _vm._e(),
                        _c("h5", { staticClass: "cardbox-heading pb-0" }, [
                          _vm._v("   "),
                          _vm.mfa_status &&
                          _vm.mfa_status.has_2fa &&
                          _vm.mfa_content == "status"
                            ? _c(
                                "div",
                                {
                                  staticClass: "float-right dropdown btn-group",
                                },
                                [
                                  _vm._m(5),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "dropdown-menu dropdown-menu-right",
                                      attrs: { role: "menu" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return (() => {
                                                _vm.mfa_content = "show_qr"
                                              }).apply(null, arguments)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "ion-lock-combination mr-2",
                                          }),
                                          _vm._v(" Set up a new phone or app"),
                                        ]
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return (() => {
                                                _vm.mfa_content =
                                                  "recovery_codes"
                                              }).apply(null, arguments)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "ion-help-buoy mr-2",
                                          }),
                                          _vm._v(" Show my backup codes"),
                                        ]
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return (() => {
                                                _vm.mfa_content = "history"
                                              }).apply(null, arguments)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "ion-calendar mr-2",
                                          }),
                                          _vm._v(" Event Log"),
                                        ]
                                      ),
                                      _c("div", {
                                        staticClass: "dropdown-divider",
                                      }),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "dropdown-item text-danger",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return (() => {
                                                _vm.mfa_content = "lost_phone"
                                              }).apply(null, arguments)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "ion-alert-circled mr-2",
                                          }),
                                          _vm._v(" I lost my phone"),
                                        ]
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "dropdown-item text-danger",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return (() => {
                                                _vm.mfa_content = "delete"
                                              }).apply(null, arguments)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "ion-trash-a mr-2",
                                          }),
                                          _vm._v(" Disable two factor auth"),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.mfa_content == "show_qr"
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-secondary btn-sm float-right",
                                  on: {
                                    click: function ($event) {
                                      _vm.mfa_content = "status"
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "ion-close-round text-grey mr-2",
                                  }),
                                  _vm._v("Cancel"),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _c(
                          "div",
                          { staticClass: "cardbox-body" },
                          [
                            _c("mfa", {
                              attrs: {
                                user_email: _vm.space
                                  ? _vm.space.client_email
                                  : false,
                                mfa_content: _vm.mfa_content,
                                is_client_space: true,
                                show_success: (msg) => {
                                  _vm.mfa_alert = { type: "success", msg: msg }
                                },
                                show_error: (msg) => {
                                  _vm.mfa_alert = { type: "error", msg: msg }
                                },
                              },
                              on: {
                                mfa_status_loaded: (status) => {
                                  _vm.mfa_status = status
                                },
                                mfa_content_change: (new_content) => {
                                  _vm.mfa_content = new_content
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ])
      : _c("div", { staticClass: "layout-container" }, [
          _c("div", { staticClass: "page-container bg-blue-grey-900" }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-items-center align-items-center-ie bg-gradient-info",
              },
              [
                _c("div", { staticClass: "fw" }, [
                  _c("div", { staticClass: "container container-xs" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "cardbox cardbox-flat text-white form-validate text-color",
                      },
                      [
                        _vm._m(6),
                        _vm.login.error
                          ? _c("div", {
                              staticClass: "alert alert-danger mb-3",
                              domProps: { innerHTML: _vm._s(_vm.login.error) },
                            })
                          : _vm._e(),
                        !_vm.disable_login
                          ? _c("section", [
                              _vm.login_form == "autologin"
                                ? _c("div", [_vm._m(7)])
                                : _vm.login_form == "login"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "cardbox-body",
                                      staticStyle: { "margin-bottom": "200px" },
                                    },
                                    [
                                      _c("div", { staticClass: "mb-5" }, [
                                        _c(
                                          "form",
                                          {
                                            on: {
                                              submit: function ($event) {
                                                $event.preventDefault()
                                                return _vm.login_client()
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "input-group" },
                                              [
                                                _vm.key
                                                  ? _c("input", {
                                                      staticStyle: {
                                                        display: "none",
                                                      },
                                                      attrs: {
                                                        type: "hidden",
                                                        name: "space_key",
                                                      },
                                                      domProps: {
                                                        value: _vm.key,
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.login.password,
                                                      expression:
                                                        "login.password",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "form-control form-control-inverse",
                                                  attrs: {
                                                    type: "password",
                                                    name: "password",
                                                    id: "password_field",
                                                    required: "required",
                                                    placeholder: "Password",
                                                    "data-msg":
                                                      "Please type your password",
                                                  },
                                                  domProps: {
                                                    value: _vm.login.password,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.login,
                                                        "password",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "input-group-btn",
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-secondary btn-gradient",
                                                        attrs: {
                                                          type: "submit",
                                                          disabled:
                                                            !_vm.login
                                                              .password ||
                                                            _vm.login.loading,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" Login "),
                                                        _c("i", {
                                                          staticClass:
                                                            "icon-lg ml-2",
                                                          class: {
                                                            "ion-ios-locked-outline":
                                                              !_vm.login
                                                                .loading,
                                                            "ion-load-c spin":
                                                              _vm.login.loading,
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "form-group mt-2",
                                              },
                                              [
                                                _c("checkbox", {
                                                  attrs: {
                                                    value:
                                                      _vm.login.stay_logged_in,
                                                    label: "Keep me logged in",
                                                    name: "client_space_login_stay_logged_in",
                                                  },
                                                  on: {
                                                    changed: (newval) =>
                                                      (_vm.login.stay_logged_in =
                                                        newval),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "fw text-center mt-2",
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "text-white text-sm",
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    _vm.login_form =
                                                      "forgotten-password"
                                                  },
                                                },
                                              },
                                              [_vm._v("Forgot your password?")]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm.team_info
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-center mt-4 mb-4 mt-0",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "mb-4 text-center",
                                                  staticStyle: {
                                                    "font-size": "1.4em",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " Log in to access your Client Space with"
                                                  ),
                                                  _c("br"),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(_vm.team_info.name)
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c("login-logos", {
                                                attrs: {
                                                  team_logo:
                                                    _vm.team_info.logo_url,
                                                  client_logo:
                                                    _vm.client_has_custom_logo
                                                      ? _vm.client_logo
                                                      : null,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm.login_form == "email_password"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "cardbox-body",
                                      staticStyle: { "margin-bottom": "200px" },
                                    },
                                    [
                                      _c("div", { staticClass: "mb-5" }, [
                                        _c(
                                          "form",
                                          {
                                            on: {
                                              submit: function ($event) {
                                                $event.preventDefault()
                                                return _vm.login_client()
                                              },
                                            },
                                          },
                                          [
                                            _vm._m(8),
                                            _c(
                                              "div",
                                              { staticClass: "cardbox-body" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "px-5" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group",
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.login.email,
                                                              expression:
                                                                "login.email",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control form-control-inverse",
                                                          attrs: {
                                                            id: "email",
                                                            name: "space_email",
                                                            type: "email",
                                                            placeholder:
                                                              "Email address",
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm.login.email,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.$set(
                                                                _vm.login,
                                                                "email",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group",
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.login
                                                                  .password,
                                                              expression:
                                                                "login.password",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control form-control-inverse",
                                                          attrs: {
                                                            id: "password",
                                                            name: "space_password",
                                                            type: "password",
                                                            placeholder:
                                                              "Password",
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm.login
                                                                .password,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.$set(
                                                                _vm.login,
                                                                "password",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group",
                                                      },
                                                      [
                                                        _c("checkbox", {
                                                          attrs: {
                                                            value:
                                                              _vm.login
                                                                .stay_logged_in,
                                                            label:
                                                              "Keep me logged in",
                                                            name: "client_space_login_stay_logged_in",
                                                          },
                                                          on: {
                                                            changed: (newval) =>
                                                              (_vm.login.stay_logged_in =
                                                                newval),
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-lg btn-oval btn-gradient text-bold btn-secondary btn-block",
                                                        attrs: {
                                                          type: "submit",
                                                          disabled:
                                                            _vm.login.loading ||
                                                            !_vm.login.email ||
                                                            !_vm.login.password,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" Login "),
                                                        _vm.login.loading
                                                          ? _c("i", {
                                                              staticClass:
                                                                "ion-load-c icon-lg spin ml-2",
                                                            })
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm.login_form == "2fa"
                                ? _c(
                                    "div",
                                    { staticClass: "text-center" },
                                    [
                                      _c("mfa-login", {
                                        attrs: {
                                          mfa_mode: _vm.mfa_mode,
                                          user_email: null,
                                          sms_phone_number:
                                            _vm.mfa_sms_phone_number,
                                          mfa_cooldown_seconds:
                                            _vm.mfa_cooldown,
                                        },
                                        on: {
                                          code_changed: (code) => {
                                            _vm.mfa_mode == "backup"
                                              ? (_vm.login.mfa_backup_code =
                                                  code)
                                              : (_vm.login.mfa_code = code)
                                          },
                                          change_mfa_mode: (new_mode) => {
                                            _vm.mfa_mode = new_mode
                                          },
                                        },
                                      }),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-lg btn-oval btn-gradient text-bold btn-secondary px-5 mt-4",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.login_client()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(" Login "),
                                          _vm.login.loading
                                            ? _c("i", {
                                                staticClass:
                                                  "ion-load-c icon-lg spin ml-2",
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm.login_form == "invite"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "cardbox-body",
                                      staticStyle: { "margin-bottom": "100px" },
                                    },
                                    [
                                      _vm.invite_loading
                                        ? _c("h3", [
                                            _vm._v("Loading your invite..."),
                                            _c("i", {
                                              staticClass:
                                                "ion-load-c spin ml-2",
                                            }),
                                          ])
                                        : _vm.invite
                                        ? _c(
                                            "section",
                                            [
                                              _c("login-logos", {
                                                staticClass: "mb-3",
                                                attrs: {
                                                  team_logo:
                                                    _vm.invite.team_logo_url,
                                                  client_logo:
                                                    _vm.invite.client_logo,
                                                },
                                              }),
                                              _c("h3", [
                                                _vm._v(
                                                  "Hello, " +
                                                    _vm._s(
                                                      _vm.invite.client_name
                                                    ) +
                                                    "!"
                                                ),
                                              ]),
                                              _c("br"),
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "text-justified",
                                                },
                                                [
                                                  _vm._v(
                                                    " You have been invited by "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.invite.team_name
                                                      )
                                                    ),
                                                  ]),
                                                  _vm._v(
                                                    " to this online shared folder called Client Space. "
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    "In this folder, you can: "
                                                  ),
                                                ]
                                              ),
                                              _c("div", {}, [
                                                _vm._m(9),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "d-block pl-3",
                                                    class: {
                                                      "text-muted":
                                                        _vm.invite
                                                          .client_readonly,
                                                      "text-bold":
                                                        !_vm.invite
                                                          .client_readonly,
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass: "mr-2",
                                                      class: {
                                                        "ion-checkmark":
                                                          !_vm.invite
                                                            .client_readonly,
                                                        "ion-close-round":
                                                          _vm.invite
                                                            .client_readonly,
                                                      },
                                                    }),
                                                    _vm._v(
                                                      "Upload, move, delete or restore files and folders"
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "text-justified mt-4",
                                                },
                                                [
                                                  _vm._v(
                                                    " Set your private password to accept the invitation and join the Space! "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "mt-2" },
                                                [
                                                  !_vm.invite_password_generated
                                                    ? _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-sm text-sm",
                                                          attrs: {
                                                            type: "button",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.generate_password()
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Generate strong password "
                                                          ),
                                                          _c("i", {
                                                            staticClass:
                                                              "ion-locked ml-1",
                                                          }),
                                                        ]
                                                      )
                                                    : _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-sm",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Your password: "
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "bg-gray-dark px-1 mr-1 py-1 text-bold text-monospace",
                                                              staticStyle: {
                                                                "font-size":
                                                                  "1.2em",
                                                              },
                                                              attrs: {
                                                                id: "generated_psw",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .invite_form
                                                                    .psw1
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c("i", {
                                                            staticClass:
                                                              "ion-refresh c-pointer",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.generate_password()
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                              _c(
                                                "form",
                                                {
                                                  attrs: { method: "post" },
                                                  on: {
                                                    submit: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.submit_invite_form.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-items-center justify-content-between py-2",
                                                    },
                                                    [
                                                      _vm.invite_password_visible
                                                        ? _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .invite_form
                                                                    .psw1,
                                                                expression:
                                                                  "invite_form.psw1",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control form-control-inverse mr-1",
                                                            attrs: {
                                                              type: "text",
                                                              name: "password",
                                                              placeholder:
                                                                "Password",
                                                              required:
                                                                "required",
                                                              autocomplete:
                                                                "off",
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.invite_form
                                                                  .psw1,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  _vm.invite_form,
                                                                  "psw1",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          })
                                                        : _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .invite_form
                                                                    .psw1,
                                                                expression:
                                                                  "invite_form.psw1",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control form-control-inverse mr-1",
                                                            attrs: {
                                                              type: "password",
                                                              name: "password",
                                                              placeholder:
                                                                "Password",
                                                              required:
                                                                "required",
                                                              autocomplete:
                                                                "off",
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.invite_form
                                                                  .psw1,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  _vm.invite_form,
                                                                  "psw1",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          }),
                                                      _vm.invite_password_visible
                                                        ? _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .invite_form
                                                                    .psw2,
                                                                expression:
                                                                  "invite_form.psw2",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control form-control-inverse ml-1",
                                                            attrs: {
                                                              type: "text",
                                                              required:
                                                                "required",
                                                              name: "password2",
                                                              autocomplete:
                                                                "off",
                                                              placeholder:
                                                                "Repeat password",
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.invite_form
                                                                  .psw2,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  _vm.invite_form,
                                                                  "psw2",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          })
                                                        : _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .invite_form
                                                                    .psw2,
                                                                expression:
                                                                  "invite_form.psw2",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control form-control-inverse ml-1",
                                                            attrs: {
                                                              type: "password",
                                                              required:
                                                                "required",
                                                              name: "password2",
                                                              autocomplete:
                                                                "off",
                                                              placeholder:
                                                                "Repeat password",
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.invite_form
                                                                  .psw2,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  _vm.invite_form,
                                                                  "psw2",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          }),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "ml-2 text-white",
                                                          attrs: {
                                                            href: "#",
                                                            title:
                                                              "Show/hide password",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              _vm.invite_password_visible =
                                                                !_vm.invite_password_visible
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "ion-eye",
                                                            class: {
                                                              "ion-eye":
                                                                !_vm.invite_password_visible,
                                                              "ion-eye-disabled":
                                                                _vm.invite_password_visible,
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.invite_show_mfa
                                                    ? _c(
                                                        "div",
                                                        { staticClass: "mb-3" },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-12 mt-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Two-Factor Authentication required"
                                                              ),
                                                            ]
                                                          ),
                                                          _c("mfa-login", {
                                                            attrs: {
                                                              mfa_mode:
                                                                _vm.mfa_mode,
                                                              user_email: null,
                                                              sms_phone_number:
                                                                _vm.mfa_sms_phone_number,
                                                              mfa_cooldown_seconds:
                                                                _vm.mfa_cooldown,
                                                            },
                                                            on: {
                                                              code_changed: (
                                                                code
                                                              ) => {
                                                                _vm.mfa_mode ==
                                                                "backup"
                                                                  ? (_vm.invite_form.mfa_backup_code =
                                                                      code)
                                                                  : (_vm.invite_form.mfa_code =
                                                                      code)
                                                              },
                                                              change_mfa_mode: (
                                                                new_mode
                                                              ) => {
                                                                _vm.mfa_mode =
                                                                  new_mode
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-italic",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Note: You can set up Two-Factor Authentication after joining the space. "
                                                          ),
                                                          _c("info-icon", {
                                                            attrs: {
                                                              content:
                                                                "You can substantially increase security of your shared folder if you enable Two-Factor Authentication. Entering the folder will be secured both by your password and a one-time code that you receive on your phone.",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group my-3",
                                                    },
                                                    [
                                                      _c("checkbox", {
                                                        attrs: {
                                                          label:
                                                            "Notify me in email when a new file is uploaded (recommended)",
                                                          name: "watch_client",
                                                          value:
                                                            _vm.invite_form
                                                              .watch_client,
                                                        },
                                                        on: {
                                                          changed: (newval) =>
                                                            (_vm.invite_form.watch_client =
                                                              newval),
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group my-3",
                                                    },
                                                    [
                                                      _c("checkbox", {
                                                        attrs: {
                                                          label:
                                                            "Keep me logged in",
                                                          name: "stay_logged_in",
                                                          value:
                                                            _vm.invite_form
                                                              .stay_logged_in,
                                                        },
                                                        on: {
                                                          changed: (newval) =>
                                                            (_vm.invite_form.stay_logged_in =
                                                              newval),
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm.invite_form.error
                                                    ? _c("div", {
                                                        staticClass:
                                                          "alert alert-danger",
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.invite_form
                                                              .error
                                                          ),
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  !_vm.invite_form.success
                                                    ? _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "mt-2 btn btn-secondary text-bold text-primary fw",
                                                          attrs: {
                                                            type: "submit",
                                                            disabled:
                                                              _vm.invite_form
                                                                .loading,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Join Client Space"
                                                          ),
                                                          _vm.invite_form
                                                            .loading
                                                            ? _c("i", {
                                                                staticClass:
                                                                  "ion-load-c ml-2 spin",
                                                              })
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "alert alert-success",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Success, entering Space..."
                                                          ),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm.login_form == "forgotten-password"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "cardbox-body",
                                      staticStyle: { "margin-bottom": "100px" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "fw text-center mt-2" },
                                        [
                                          !_vm.resend_invite.success
                                            ? _c("section", [
                                                _c("p", [
                                                  _vm._v(
                                                    "Click the button below to reset your password and receive a new invite link to "
                                                  ),
                                                ]),
                                                _vm.team_info &&
                                                _vm.team_info
                                                  .redacted_client_email
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-bold redacted_email",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.team_info
                                                              .redacted_client_email
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-lg btn-secondary text-bold text-primary text-12 mt-4",
                                                    attrs: {
                                                      disabled:
                                                        _vm.resend_invite
                                                          .loading,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.forgotten_password()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(" Reset password"),
                                                    _vm.resend_invite.loading
                                                      ? _c("i", {
                                                          staticClass:
                                                            "ion-load-c ml-2 spin",
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _vm.resend_invite.error
                                                  ? _c("div", {
                                                      staticClass:
                                                        "alert alert-danger mt-3",
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.resend_invite
                                                            .error
                                                        ),
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._m(10),
                                                _c(
                                                  "div",
                                                  { staticClass: "mt-5" },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "text-white text-sm",
                                                        attrs: { href: "#" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            _vm.login_form =
                                                              "login"
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "ion-arrow-left-c mr-2",
                                                        }),
                                                        _vm._v("Back to login"),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            : _c("section", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "alert alert-success",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Your password has been reset and we sent a new invite email "
                                                    ),
                                                    _vm.team_info &&
                                                    _vm.team_info
                                                      .redacted_client_email
                                                      ? [
                                                          _vm._v(" to "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "my-2 text-bold redacted_email",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.team_info
                                                                    .redacted_client_email
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      : _vm._e(),
                                                    _c("div", [
                                                      _vm._v(
                                                        "The invite expires in 24 hours."
                                                      ),
                                                    ]),
                                                  ],
                                                  2
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "mt-4 text-12",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Close this page now and click the invite link in the email!"
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm.login_form == "forgotten-password-nospace"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "cardbox-body",
                                      staticStyle: { "margin-bottom": "100px" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "fw text-center mt-2" },
                                        [
                                          _vm._v(" TODO email + button form "),
                                          _c("div", { staticClass: "mt-5" }, [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "text-white text-sm",
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    _vm.login_form =
                                                      "email_password"
                                                  },
                                                },
                                              },
                                              [_vm._v("Back to login")]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "d-inline-block d-lg-none text-left" }, [
      _c("li", [
        _c(
          "a",
          {
            staticClass: "sidebar-toggler menu-link menu-link-close",
            attrs: { href: "#" },
          },
          [_c("span", [_c("em")])]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "d-none d-xs-block text-left" }, [
      _c("li", [
        _c(
          "a",
          {
            staticClass: "covermode-toggler menu-link menu-link-close",
            attrs: { href: "#" },
          },
          [_c("span", [_c("em")])]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "d-inline-block d-sm-none",
        attrs: { id: "skyflok_center_logo" },
      },
      [
        _c("img", {
          attrs: {
            src: require("@/assets/img/skyflok_logo_black.png"),
            width: "100",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "text-center" }, [
      _c("small", { staticClass: "text-info" }, [
        _vm._v(" Hello and welcome to SkyFlok!"),
        _c("br"),
        _vm._v(
          "If you have any questions or requests about privacy, how your personal information is handled or what happened to your uploaded files, don't hesitate to contact us via email at "
        ),
        _c("a", { attrs: { href: "mailto:support@skyflok.com" } }, [
          _vm._v("support@skyflok.com"),
        ]),
        _vm._v(". "),
        _c("br"),
        _vm._v("Best regards from the SkyFlok Team! "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mt-3" }, [
      _vm._v(
        " If you have any questions or requests about privacy, how your personal information is handled or what happened to your uploaded files, don't hesitate to contact us via email at "
      ),
      _c("a", { attrs: { href: "mailto:support@skyflok.com" } }, [
        _vm._v("support@skyflok.com"),
      ]),
      _vm._v(". "),
      _c("br"),
      _vm._v("Best regards from the SkyFlok Team! "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-secondary btn-sm",
        staticStyle: { "font-size": "1rem" },
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-expanded": "false",
        },
      },
      [
        _c("i", { staticClass: "ion-gear-b text-grey mr-2" }),
        _vm._v(" Settings "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cardbox-heading text-center" }, [
      _c("img", {
        staticClass: "block-center img-fluid",
        staticStyle: { "max-width": "40%" },
        attrs: {
          src: require("@/assets/img/skyflok_logo_lg.png"),
          alt: "SkyFlok",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h4", { staticClass: "fw text-center" }, [
        _vm._v(" Loading Space... "),
        _c("i", { staticClass: "ion-load-c spin ml-2" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cardbox-heading" }, [
      _c("div", { staticClass: "cardbox-title text-center" }, [
        _c("h4", [_vm._v("Log in to your Client Space")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "d-block pl-3 text-bold" }, [
      _c("i", { staticClass: "ion-checkmark mr-2" }),
      _vm._v("Browse and download any file you want"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-4 text-justify" }, [
      _c("i", { staticClass: "ion-information-circled mr-2" }),
      _vm._v(
        " If you have Two-Factor Authentication enabled, you will have to provide the authentication code (or backup code) as part of the password reset process. "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }