var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("i", {
    staticClass: "ion-information-circled ml-1 info-icon c-help",
    class: _vm.custom_class,
    attrs: {
      "data-toggle": "popover",
      "data-html": "true",
      "data-content": _vm.content,
      "data-placement": _vm.placement || "top",
      "data-trigger": _vm.trigger || (_vm.is_mobile ? "click" : "hover"),
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }