var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "share_form" }, [
    _vm.error
      ? _c("div", { staticClass: "alert alert-danger" }, [
          _vm._v(_vm._s(_vm.error)),
        ])
      : _vm._e(),
    _vm.success
      ? _c("div", { staticClass: "alert alert-success" }, [
          _vm._v(_vm._s(_vm.success)),
        ])
      : _vm._e(),
    !_vm.new_share
      ? _c("div", [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "text-center fw" },
              _vm._l(_vm.entities, function (entity) {
                return _c("sharedFile", {
                  key: entity.id,
                  attrs: { entity: entity },
                })
              }),
              1
            ),
          ]),
          _vm.invalid_share
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v(_vm._s(_vm.invalid_share)),
              ])
            : _c("div", { staticClass: "row mt-4" }, [
                _c("div", { staticClass: "col-1" }),
                _c("div", { staticClass: "col-12 col-lg-10" }, [
                  _c(
                    "form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.submit_share.apply(null, arguments)
                        },
                      },
                    },
                    [
                      !_vm.share_expire_days_to
                        ? _c(
                            "div",
                            {
                              staticClass: "form-group",
                              class: {
                                "text-gray": !_vm.share_expire_checkbox,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "form-check d-flex align-items-center",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.share_expire_checkbox,
                                        expression: "share_expire_checkbox",
                                      },
                                    ],
                                    staticClass: "form-check-input",
                                    attrs: {
                                      type: "checkbox",
                                      id: "share_expires",
                                    },
                                    domProps: {
                                      value: true,
                                      checked: Array.isArray(
                                        _vm.share_expire_checkbox
                                      )
                                        ? _vm._i(
                                            _vm.share_expire_checkbox,
                                            true
                                          ) > -1
                                        : _vm.share_expire_checkbox,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.share_expire_checkbox,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = true,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.share_expire_checkbox =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.share_expire_checkbox = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.share_expire_checkbox = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "form-check-label",
                                      attrs: { for: "share_expires" },
                                    },
                                    [
                                      _c(
                                        "section",
                                        {
                                          attrs: {
                                            clasS:
                                              " d-flex align-items-center gap-2",
                                          },
                                        },
                                        [
                                          _c("span", [_vm._v("Share expires")]),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.form.expire_days,
                                                expression: "form.expire_days",
                                              },
                                            ],
                                            staticClass:
                                              "form-control form-control-sm",
                                            class: {
                                              "text-muted":
                                                !_vm.share_expire_checkbox,
                                            },
                                            staticStyle: {
                                              display: "inline",
                                              width: "50px",
                                            },
                                            attrs: {
                                              type: "number",
                                              min: 1,
                                              max: _vm.share_expire_days_to
                                                ? Math.min(
                                                    365,
                                                    _vm.share_expire_days_to
                                                  )
                                                : 365,
                                              disabled:
                                                !_vm.share_expire_checkbox,
                                            },
                                            domProps: {
                                              value: _vm.form.expire_days,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.form,
                                                  "expire_days",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _c("span", [
                                            _vm._v("days from today "),
                                            _vm.form.expire_days
                                              ? _c("i", [
                                                  _vm._v(
                                                    "(on " +
                                                      _vm._s(_vm.expire_date) +
                                                      ")"
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.password_protection_enabled
                        ? _c(
                            "div",
                            {
                              staticClass: "form-group",
                              class: {
                                "text-muted": !_vm.form.enable_password,
                              },
                            },
                            [
                              _vm.teamSettings.is_passwordless_share_allowed
                                ? _c("div", { staticClass: "form-check" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.form.enable_password,
                                          expression: "form.enable_password",
                                        },
                                      ],
                                      staticClass: "form-check-input",
                                      attrs: {
                                        type: "checkbox",
                                        id: "share_enable_password",
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.form.enable_password
                                        )
                                          ? _vm._i(
                                              _vm.form.enable_password,
                                              null
                                            ) > -1
                                          : _vm.form.enable_password,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.form.enable_password,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.form,
                                                  "enable_password",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.form,
                                                  "enable_password",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.form,
                                              "enable_password",
                                              $$c
                                            )
                                          }
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "form-check-label",
                                        attrs: { for: "share_enable_password" },
                                      },
                                      [_vm._v("Password protection")]
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(" Password protection: "),
                                    _vm._m(0),
                                  ]),
                            ]
                          )
                        : _vm._e(),
                      !_vm.is_folder_share
                        ? _c(
                            "div",
                            {
                              staticClass: "d-flex align-items-center gap-2",
                              class: {
                                "text-gray": !_vm.download_limit_checkbox,
                              },
                            },
                            [
                              _c("div", { staticClass: "form-check" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.download_limit_checkbox,
                                      expression: "download_limit_checkbox",
                                    },
                                  ],
                                  staticClass: "form-check-input",
                                  attrs: {
                                    type: "checkbox",
                                    id: "share_max_downloads",
                                  },
                                  domProps: {
                                    value: true,
                                    checked: Array.isArray(
                                      _vm.download_limit_checkbox
                                    )
                                      ? _vm._i(
                                          _vm.download_limit_checkbox,
                                          true
                                        ) > -1
                                      : _vm.download_limit_checkbox,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.download_limit_checkbox,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = true,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.download_limit_checkbox =
                                              $$a.concat([$$v]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.download_limit_checkbox = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.download_limit_checkbox = $$c
                                      }
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-check-label",
                                    attrs: { for: "share_max_downloads" },
                                  },
                                  [_vm._v("Maximum file downloads: ")]
                                ),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.download_limit,
                                    expression: "form.download_limit",
                                  },
                                ],
                                staticClass: "form-control form-control-sm",
                                class: {
                                  "text-muted": !_vm.download_limit_checkbox,
                                },
                                staticStyle: {
                                  display: "inline",
                                  width: "50px",
                                },
                                attrs: {
                                  type: "number",
                                  min: "1",
                                  max: "100",
                                  disabled: !_vm.download_limit_checkbox,
                                },
                                domProps: { value: _vm.form.download_limit },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.form,
                                      "download_limit",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c("i", {
                                staticClass: "ion-help-circled",
                                attrs: {
                                  "data-toggle": "popover",
                                  "data-placement": "top",
                                  "data-content":
                                    "Limit how many times the shared file can be downloaded. When the download limit is reached, the share is disabled automatically.",
                                  "data-html": "true",
                                  "data-trigger": "hover",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "form-group mt-4" }, [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "message-content" },
                          },
                          [
                            _vm._v(
                              "Message above the shared " +
                                _vm._s(
                                  _vm.is_folder_share ? "folder" : "files"
                                ) +
                                ":"
                            ),
                          ]
                        ),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.message,
                              expression: "form.message",
                            },
                          ],
                          staticClass: "form-control ml-1",
                          attrs: { placeholder: "Optional" },
                          domProps: { value: _vm.form.message },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.form, "message", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-center" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary mr-2",
                              class: { "btn-sm text-sm": _vm.is_mobile },
                              attrs: { type: "button", "aria-label": "Close" },
                              on: {
                                click: function ($event) {
                                  return _vm.close()
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-info btn-gradient",
                              class: { "btn-sm text-sm": _vm.is_mobile },
                              attrs: {
                                type: "submit",
                                disabled:
                                  _vm.form.loading || _vm.disable_create,
                              },
                            },
                            [
                              _vm._v("Create Share"),
                              _vm.form.loading
                                ? _c("i", {
                                    staticClass: "ion-load-c spin ml-2",
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
        ])
      : _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-1" }),
          _c("div", { staticClass: "col-12 col-lg-10" }, [
            _c("div", [
              _c("label", { attrs: { for: "new_share_link" } }, [
                _vm._v("Share Link:"),
              ]),
              _c("div", { staticClass: "input-group mt-0" }, [
                _c("input", {
                  staticClass: "form-control",
                  attrs: { type: "text", id: "new_share_link" },
                  domProps: { value: _vm.new_share.link },
                }),
                !_vm.is_mobile
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-gradient btn-secondary ml-1",
                        class: { "btn-sm text-sm": _vm.is_mobile },
                        on: {
                          click: function ($event) {
                            return _vm.to_clipboard("new_share_link")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "ion-clipboard icon-lg" }),
                        _c(
                          "span",
                          { staticClass: "d-none d-md-inline-block ml-1" },
                          [_vm._v("Copy")]
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-primary ml-1",
                    class: { "btn-sm text-sm": _vm.is_mobile },
                    attrs: { href: _vm.new_share.link, target: "_BLANK" },
                  },
                  [
                    _c("i", { staticClass: "ion-share icon-lg mr-1" }),
                    _vm._v(" Open"),
                  ]
                ),
              ]),
            ]),
            _vm.new_share.password_protected
              ? _c("div", { staticClass: "mt-2" }, [
                  _c("label", { attrs: { for: "new_share_pass" } }, [
                    _vm._v("Password:"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "input-group",
                      staticStyle: { width: "300px" },
                    },
                    [
                      _c("input", {
                        staticClass: "form-control",
                        attrs: { type: "text", id: "new_share_pass" },
                        domProps: { value: _vm.new_share.password },
                      }),
                      !_vm.is_mobile
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-gradient btn-secondary",
                              class: { "btn-sm text-sm": _vm.is_mobile },
                              on: {
                                click: function ($event) {
                                  return _vm.to_clipboard("new_share_pass")
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "ion-clipboard icon-lg" }),
                              _c(
                                "span",
                                {
                                  staticClass: "d-none d-md-inline-block ml-1",
                                },
                                [_vm._v("Copy")]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                  false
                    ? _c("div", { staticClass: "text-center" }, [_vm._m(1)])
                    : _vm._e(),
                ])
              : _vm._e(),
            _c("div", { staticClass: "text-center mt-3" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline btn-secondary",
                  class: { "btn-sm text-sm": _vm.is_mobile },
                  on: {
                    click: function ($event) {
                      _vm.close ? _vm.close() : false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ]),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      {
        staticClass: "text-success",
        staticStyle: { cursor: "help" },
        attrs: {
          title:
            "This is a team setting, only Team Adminstrators can change it.",
        },
      },
      [_vm._v("Yes"), _c("i", { staticClass: "ion-locked ml-1" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "alert alert-info mt-2 d-inline-block" }, [
      _vm._v(
        "You need to send both the link and the password to the recipients."
      ),
      _c("br"),
      _vm._v(
        "It's a lot more secure to use two different channels, like email and text message!"
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }