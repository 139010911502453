import { render, staticRenderFns } from "./archive-space.vue?vue&type=template&id=43d601d9&scoped=true"
import script from "./archive-space.vue?vue&type=script&lang=js"
export * from "./archive-space.vue?vue&type=script&lang=js"
import style0 from "./archive-space.vue?vue&type=style&index=0&id=43d601d9&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43d601d9",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/mfeher/Code/SkyFlok/FileManager/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('43d601d9')) {
      api.createRecord('43d601d9', component.options)
    } else {
      api.reload('43d601d9', component.options)
    }
    module.hot.accept("./archive-space.vue?vue&type=template&id=43d601d9&scoped=true", function () {
      api.rerender('43d601d9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/modals/client-space/archive-space.vue"
export default component.exports