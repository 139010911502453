var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "section-container" }, [
    _vm.shares_disabled
      ? _c(
          "div",
          { staticClass: "container" },
          [_c("upgrade-warning-box", { attrs: { feature: "Shares" } })],
          1
        )
      : _c("div", { staticClass: "container container-lg pt-4" }, [
          _vm.shares
            ? _c("div", [
                _c("h3", [
                  _vm._v(" Active Shares "),
                  _c("small", [
                    _vm._v(" (" + _vm._s(_vm.active_shares.length)),
                    _vm.active_shares_limit &&
                    _vm.active_shares.length >= _vm.active_shares_limit
                      ? _c("span", [
                          _vm._v(" out of " + _vm._s(_vm.active_shares_limit)),
                          _vm.active_shares.length >= _vm.active_shares_limit
                            ? _c("i", {
                                staticClass:
                                  "ml-1 icon ion-alert-circled text-warning",
                                attrs: {
                                  title:
                                    "You reached the limit of active Shares. Upgrade your Team to use more Shares!",
                                },
                              })
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(") "),
                    _c(
                      "a",
                      {
                        staticClass: "ml-1 text-inherit",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.load_shares()
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "ion-refresh",
                          class: { spin: _vm.shares_loading },
                        }),
                      ]
                    ),
                  ]),
                ]),
                _vm.active_shares && _vm.active_shares.length > 0
                  ? _c(
                      "div",
                      { staticClass: "row" },
                      _vm._l(_vm.active_shares, function (share) {
                        return _c("share-box", {
                          key: share.id,
                          attrs: {
                            share: share,
                            user: _vm.user,
                            revokeHandler: _vm.revoke_share,
                          },
                        })
                      }),
                      1
                    )
                  : _c("div", { staticClass: "row mb-3" }, [
                      _c("div", { staticClass: "col-3" }),
                      _vm._m(0),
                    ]),
                _vm.expired_shares && _vm.expired_shares.length > 0
                  ? _c("div", [
                      _c(
                        "h3",
                        {
                          attrs: {
                            title:
                              "These shares are either expired or manually disabled",
                          },
                        },
                        [
                          _vm._v("Inactive Shares "),
                          _c("small", [
                            _vm._v(
                              "(" + _vm._s(_vm.expired_shares.length) + ")"
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary",
                              on: {
                                click: function ($event) {
                                  _vm.show_expired = !_vm.show_expired
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.show_expired ? "Hide" : "Show"))]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "row",
                          class: { "d-none": !_vm.show_expired },
                          attrs: { id: "expired_shares_container" },
                        },
                        _vm._l(_vm.expired_shares, function (share) {
                          return _c("share-box", {
                            key: share.id,
                            attrs: {
                              share: share,
                              user: _vm.user,
                              deleteHandler: _vm.delete_share,
                              activateHandler: _vm.activate_share,
                              activatedisabled:
                                _vm.active_shares_limit &&
                                _vm.active_shares.length >=
                                  _vm.active_shares_limit,
                            },
                          })
                        }),
                        1
                      ),
                    ])
                  : _vm._e(),
              ])
            : _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-3" }),
                _c(
                  "div",
                  { staticClass: "col-12 col-lg-6" },
                  [
                    _c("hero-box", {
                      attrs: {
                        "bg-class": "bg-gradient-info",
                        icon: "loader d-block",
                      },
                    }),
                  ],
                  1
                ),
              ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 col-lg-6" }, [
      _c("div", { staticClass: "cardbox text-white bg-gradient-info b0" }, [
        _c("div", { staticClass: "cardbox-body text-center" }, [
          _c("div", { staticClass: "text-bold display-1" }, [
            _c("i", { staticClass: "ion-link" }),
          ]),
          _c("p", { staticClass: "text-bold text-md" }, [
            _vm._v("No active shares"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }