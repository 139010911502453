var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "namespace-tree" } }, [
    _vm.error
      ? _c("div", { staticClass: "alert alert-danger" }, [
          _vm._v(_vm._s(_vm.error)),
        ])
      : _vm._e(),
    _c("div", { staticClass: "text-right" }, [
      !_vm.submit_loading
        ? _c("div", [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary mr-1",
                class: { "btn-sm text-sm": _vm.is_mobile },
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              },
              [_vm._v("Cancel")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-info text-bold",
                class: { "btn-sm text-sm": _vm.is_mobile },
                attrs: { disabled: !_vm.selected_folder },
                on: {
                  click: function ($event) {
                    return _vm.submit()
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(_vm.action.startsWith("copy") ? "Copy" : "Move") +
                    " to " +
                    _vm._s(
                      _vm.selected_folder ? _vm.selected_folder.name : "..."
                    )
                ),
              ]
            ),
          ])
        : _c("i", { staticClass: "ion-load-c spin" }),
    ]),
    _c(
      "div",
      { staticClass: "dd mt-2 mb-2" },
      [
        _vm.namespace_loading
          ? _c(
              "div",
              {
                staticClass:
                  "d-flex align-items-center justify-content-center gap-1",
              },
              [
                _c("span", [_vm._v("Loading")]),
                _c("i", { staticClass: "ion-load-c spin" }),
              ]
            )
          : _vm.namespace
          ? _c("namespace-tree-row", {
              attrs: {
                children: _vm.namespace,
                clicked: _vm.tree_folder_selected,
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _c("div", { staticClass: "text-right" }, [
      !_vm.submit_loading
        ? _c("div", [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary mr-1",
                class: { "btn-sm text-sm": _vm.is_mobile },
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              },
              [_vm._v("Cancel")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-info text-bold",
                class: { "btn-sm text-sm": _vm.is_mobile },
                attrs: { disabled: !_vm.selected_folder },
                on: {
                  click: function ($event) {
                    return _vm.submit()
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(_vm.action.startsWith("copy") ? "Copy" : "Move") +
                    " to " +
                    _vm._s(
                      _vm.selected_folder
                        ? "to " + _vm.selected_folder.name
                        : "..."
                    )
                ),
              ]
            ),
          ])
        : _c("i", { staticClass: "ion-load-c spin" }),
    ]),
    _vm.error
      ? _c("div", { staticClass: "alert alert-danger mt-2" }, [
          _vm._v(_vm._s(_vm.error)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }