var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _vm.admin_folder_disabled
        ? _c(
            "div",
            { staticClass: "container pt-5" },
            [_c("upgrade-warning-box", { attrs: { feature: "Admin Folder" } })],
            1
          )
        : _vm.user && _vm.user.is_admin
        ? _c("file-list-view", {
            attrs: {
              user: _vm.user,
              "share-allowed": false,
              is_admin_folder: true,
            },
          })
        : _c(
            "div",
            { staticClass: "container pt-5" },
            [_c("admin-warning-box")],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }