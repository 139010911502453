var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fw text-center" }, [
    _vm.client_logo && _vm.team_logo
      ? _c("div", { staticClass: "nowrap" }, [
          _c("img", {
            staticClass: "thumb80 shadow-z5",
            attrs: { src: _vm.team_logo },
          }),
          _c("i", { staticClass: "ion-code icon-lg mx-4" }),
          _c("img", {
            staticClass: "thumb80 shadow-z5",
            attrs: { src: _vm.client_logo },
          }),
        ])
      : _vm.team_logo
      ? _c("div", [
          _vm.team_logo
            ? _c("img", { attrs: { src: _vm.team_logo } })
            : _vm._e(),
        ])
      : _vm.client_logo
      ? _c("div", [
          _vm.client_logo
            ? _c("img", { attrs: { src: _vm.client_logo } })
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }