var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user
    ? _c(
        "div",
        {
          staticClass: "d-flex justify-content-center align-items-center",
          class: { "pull-right": _vm.pullRight === true },
        },
        [
          _c("div", { staticClass: "text-center text-nowrap" }, [
            _c("div", {}, [
              _vm._v(_vm._s(_vm._f("timestamp_rel")(_vm.timestamp)) + " "),
              _c("i", {
                staticClass: "ion-calendar ml-1 text-muted",
                staticStyle: { cursor: "help" },
                attrs: { title: _vm._f("timestamp")(_vm.timestamp) },
              }),
            ]),
            _c(
              "div",
              { class: { "deleted text-muted text-nowrap": _vm.user.deleted } },
              [_vm._v("by " + _vm._s(_vm.user.name))]
            ),
          ]),
          _vm.user.avatar_url
            ? _c("div", { staticClass: "ml-2" }, [
                _c("img", {
                  staticClass: "fit-cover thumb32 rounded-circle shadow-z2",
                  attrs: {
                    src: _vm.is_client_space_logo
                      ? _vm.Utils.client_space_logo_src(_vm.user.avatar_url)
                      : _vm.user.avatar_url,
                  },
                }),
              ])
            : _vm._e(),
        ]
      )
    : _c("div", [_c("i", { staticClass: "ion-load-c spin" })])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }