var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.avatar_url
      ? _c("span", [
          _c("input", {
            staticClass: "form-control form-control-sm",
            attrs: { type: "file", id: "new_profile_photo_input" },
            on: { change: _vm.image_changed },
          }),
        ])
      : _c("span", [
          _c("img", {
            staticClass: "fit-cover img-thumbnail",
            class: [_vm.imgclass],
            staticStyle: { "max-width": "80%" },
            attrs: { src: _vm.avatar_url },
          }),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-secondary",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  _vm.avatar_url = null
                },
              },
            },
            [_vm._v("Clear")]
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }