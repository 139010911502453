// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2716%27 height=%2716%27 class=%27bi bi-search%27 viewBox=%270 0 16 16%27%3E%3Cpath d=%27M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z%27%3E%3C/path%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.flot-chart[data-v-34cdce66]{\n    display: block;\n    width: 100%;\n    height: 180px !important;\n    padding: 0px;\n    position: relative;\n}\n.flot-chart.latency[data-v-34cdce66]{\n  height: 150px;\n}\n.hidden[data-v-34cdce66]{\n  visibility: hidden;\n}\ninput[type=\"search\"][data-v-34cdce66] {\n  margin: 0;\n  padding: 7px 8px 7px 40px;\n  background: white url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 13px center;\n}\ninput[type=\"search\"][data-v-34cdce66]::-moz-placeholder {\n  color: #bbb;\n}\ninput[type=\"search\"][data-v-34cdce66]::placeholder {\n  color: #bbb;\n}\n\n\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
