var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user && !_vm.user.is_admin
    ? _c("div", { staticClass: "container pt-5" }, [_c("admin-warning-box")], 1)
    : _vm.user
    ? _c("section", { staticClass: "section-container" }, [
        _c("div", { staticClass: "container container-lg" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _vm.user.team_settings
                ? _c(
                    "div",
                    { staticClass: "mt-2 alert alert-info text-center" },
                    [
                      _c("h5", [
                        _vm._v(
                          "Join link to " +
                            _vm._s(_vm.user.team_settings.name) +
                            ":"
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "ml-2 text-bold",
                          staticStyle: {
                            "font-family": "monospace",
                            "font-size": "1.5em",
                          },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "text-white",
                              attrs: {
                                href: _vm._f("join_team_link")(
                                  _vm.user.team_settings
                                ),
                                target: "_BLANK",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("join_team_link")(
                                    _vm.user.team_settings
                                  )
                                ) + " "
                              ),
                              _c("i", { staticClass: "ion-share text-white" }),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "i",
                        [
                          _vm._v("New members can join if "),
                          _vm.user.team_settings.email_domain
                            ? _c("span", [
                                _vm._v("they have a "),
                                _c("b", [
                                  _vm._v(
                                    "@" +
                                      _vm._s(
                                        _vm.user.team_settings.email_domain
                                      )
                                  ),
                                ]),
                                _vm._v(" email address or "),
                              ])
                            : _vm._e(),
                          _vm._v("they are "),
                          _c(
                            "router-link",
                            {
                              staticClass: "text-white",
                              attrs: { to: "/team-settings" },
                            },
                            [_c("u", [_vm._v("invited")])]
                          ),
                          _vm._v(" by their email address. "),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "cardbox" }, [
                _c("div", { staticClass: "cardbox-body" }, [
                  _vm.user && _vm.team
                    ? _c("table", { staticClass: "table table-hover mb-0" }, [
                        _vm._m(0),
                        _c(
                          "tbody",
                          _vm._l(_vm.team, function (member) {
                            return _c(
                              "tr",
                              { key: member.id, staticClass: "member-row" },
                              [
                                _c("td", { staticStyle: { width: "1px" } }, [
                                  _c("img", {
                                    staticClass:
                                      "thumb40 rounded fit-cover shadow-z3",
                                    attrs: {
                                      src: member.avatar_url,
                                      alt: "user",
                                    },
                                  }),
                                ]),
                                _c(
                                  "td",
                                  {
                                    class: {
                                      "text-bold":
                                        member.user_id === _vm.user.user_id,
                                    },
                                  },
                                  [
                                    _vm._v(_vm._s(member.name)),
                                    member.user_id === _vm.user.user_id
                                      ? _c("span", [_vm._v(" (me)")])
                                      : _vm._e(),
                                    member.job_title
                                      ? _c("div", [
                                          _c(
                                            "small",
                                            { staticClass: "text-muted" },
                                            [_vm._v(_vm._s(member.job_title))]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "d-none d-sm-table-cell",
                                    class: {
                                      "text-muted text-it": !member.confirmed,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(member.email_address) + " "
                                    ),
                                    !member.confirmed
                                      ? _c("div", [
                                          _c(
                                            "small",
                                            { staticClass: "text-muted" },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "mr-1" },
                                                [_vm._v("Not confirmed")]
                                              ),
                                              !member.re_sending_email
                                                ? _c(
                                                    "a",
                                                    {
                                                      attrs: { href: "#" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.resend_email(
                                                            member
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("Re-send email")]
                                                  )
                                                : _vm._e(),
                                              member.re_sending_email ===
                                              _vm.resend_status.in_progress
                                                ? _c("i", {
                                                    staticClass:
                                                      "ion-load-c spin",
                                                  })
                                                : _vm._e(),
                                              member.re_sending_email >
                                              _vm.resend_status.in_progress
                                                ? _c(
                                                    "span",
                                                    {
                                                      class: {
                                                        "text-success":
                                                          member.re_sending_email ==
                                                          _vm.resend_status
                                                            .success,
                                                        "text-danger":
                                                          member.re_sending_email ==
                                                          _vm.resend_status
                                                            .failed,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          member.re_sending_email ===
                                                            _vm.resend_status
                                                              .success
                                                            ? "Email sent"
                                                            : member.re_sending_email_err
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                                _c("td", [
                                  member.roles
                                    ? _c("div", { staticClass: "text-sm" }, [
                                        _c(
                                          "span",
                                          [
                                            _vm._l(
                                              member.roles,
                                              function (role) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: role.role,
                                                    staticClass: "nowrap",
                                                    class: {
                                                      "text-muted text-it":
                                                        !role.has,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("rolename")(
                                                            role.role
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                    role.has
                                                      ? _c("i", {
                                                          staticClass:
                                                            "ion-checkmark-round ml-1",
                                                          class: {
                                                            view_status:
                                                              role.role != "EM",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "edit_status",
                                                      },
                                                      [
                                                        role.has &&
                                                        role.role != "EM"
                                                          ? _c(
                                                              "span",
                                                              {
                                                                attrs: {
                                                                  title:
                                                                    "Remove role",
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "icon-lg ml-1",
                                                                  class: {
                                                                    "ion-load-c spin":
                                                                      role.loading,
                                                                    "text-danger ion-minus-circled pointer":
                                                                      !role.loading,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.change_role(
                                                                          member,
                                                                          role
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        !role.has
                                                          ? _c(
                                                              "span",
                                                              {
                                                                attrs: {
                                                                  title:
                                                                    "Add role",
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "icon-lg ml-1",
                                                                  class: {
                                                                    "ion-load-c spin":
                                                                      role.loading,
                                                                    "text-success ion-plus-circled pointer":
                                                                      !role.loading,
                                                                  },
                                                                  attrs: {
                                                                    disabled: true,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.change_role(
                                                                          member,
                                                                          role
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            member.change_role_error
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-danger text-sm",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        member.change_role_error
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ])
                                    : _c("i", {
                                        staticClass: "ion-load-c spin",
                                      }),
                                ]),
                                _c(
                                  "td",
                                  { staticClass: "d-none d-sm-table-cell" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("timestamp_rel")(member.created)
                                      ) + " "
                                    ),
                                    _c("i", {
                                      staticClass:
                                        "ion-calendar ml-1 text-muted",
                                      staticStyle: { cursor: "help" },
                                      attrs: {
                                        title: _vm._f("timestamp")(
                                          member.created
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                                _c("td", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-secondary",
                                      attrs: {
                                        type: "button",
                                        "data-toggle": "modal",
                                        "data-target": "#member-details",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.view_member = member
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(" Edit "),
                                      _c("i", { staticClass: "ion-edit ml-2" }),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "modal", attrs: { id: "member-details" } }, [
          _vm.view_member
            ? _c("div", { staticClass: "modal-dialog modal-lg" }, [
                _c("div", { staticClass: "modal-content" }, [
                  _c(
                    "div",
                    { staticClass: "modal-header bg-gradient-info text-white" },
                    [
                      _c("h5", { staticClass: "mt-0 modal-title" }, [
                        _c("img", {
                          staticClass: "thumb40 rounded fit-cover mr-2",
                          staticStyle: { border: "2px solid #ebebeb" },
                          attrs: {
                            src: _vm._f("avatar_url")(_vm.view_member),
                            alt: "user",
                          },
                        }),
                        _c("b", [_vm._v(_vm._s(_vm.view_member.name))]),
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "close",
                          attrs: { type: "button", "aria-label": "Close" },
                          on: {
                            click: function ($event) {
                              return _vm.closeModal()
                            },
                          },
                        },
                        [_c("span", [_vm._v("×")])]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "modal-body" },
                    [
                      _c("edit-user-form", {
                        attrs: {
                          user: _vm.view_member,
                          "close-form": _vm.closeModal,
                          "edit-mode": true,
                          "browse-image": false,
                          "is-admin": _vm.user && _vm.user.is_admin,
                        },
                      }),
                      _c("hr"),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-center align-items-center mt-2",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-danger btn-gradient",
                              attrs: {
                                type: "button",
                                disabled: _vm.view_member.delete_loading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.delete_user(_vm.view_member)
                                },
                              },
                            },
                            [
                              _vm._v("Delete " + _vm._s(_vm.view_member.name)),
                              _vm.view_member.delete_loading
                                ? _c("i", {
                                    staticClass: "ion-load-c spin ml-2",
                                  })
                                : _vm._e(),
                            ]
                          ),
                          !_vm.view_member.delete_error
                            ? _c("small", { staticClass: "ml-3 text-danger" }, [
                                _vm._v(
                                  " When you delete someone, they are logged out and immediately lose access to all files and the SkyFlok applications. However, the files they already downloaded stay on their devices. "
                                ),
                              ])
                            : _c("div", { staticClass: "ml-3 text-danger" }, [
                                _vm._v(_vm._s(_vm.view_member.delete_error)),
                              ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("th"),
      _c("th", [_vm._v("Name")]),
      _c("th", { staticClass: "d-none d-sm-table-cell" }, [
        _vm._v("Email address"),
      ]),
      _c("th", [_vm._v("Roles")]),
      _c("th", { staticClass: "d-none d-sm-table-cell" }, [
        _vm._v("Registered"),
      ]),
      _c("th"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }