var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "layout-container" }, [
    _c("div", { staticClass: "page-container bg-blue-grey-900" }, [
      _c(
        "div",
        {
          staticClass:
            "d-flex align-items-center align-items-center-ie bg-gradient-primary",
        },
        [
          _c("div", { staticClass: "fw container container-xs text-center" }, [
            _vm.google_user
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-center mt-5",
                    },
                    [
                      _vm.google_user.image_url
                        ? _c("img", {
                            staticClass: "initial64 rounded-circle shadow-z2",
                            attrs: { src: _vm.google_user.image_url },
                          })
                        : _vm._e(),
                      _c("div", { staticClass: "ml-3" }, [
                        _c("h4", [_vm._v(_vm._s(_vm.google_user.name))]),
                        _c("div", [_vm._v(_vm._s(_vm.google_user.email))]),
                      ]),
                    ]
                  ),
                  _vm._m(0),
                ])
              : _c("section", [
                  _vm._m(1),
                  _c("div", { staticClass: "cardbox-heading mb-5" }, [
                    _c("div", { staticClass: "cardbox-title text-center" }, [
                      _c("h4", [
                        _vm._v(
                          _vm._s(
                            _vm.show_mfa
                              ? "Two-Factor Authentication"
                              : "Sign in to your SkyFlok Team"
                          )
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "section",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.show_mfa && !_vm.email_prefilled,
                          expression: "!show_mfa && !email_prefilled",
                        },
                      ],
                    },
                    [
                      _c("div", {
                        attrs: {
                          id: "g_id_onload",
                          "data-client_id":
                            "444227039307-bngumuhfaapuvkldqbqul0oe66vju5rn.apps.googleusercontent.com",
                          "data-context": "signin",
                          "data-ux_mode": "redirect",
                          "data-login_uri":
                            "https://auth-dot-storageaggregator-dev.ey.r.appspot.com/login",
                          "data-auto_select": "true",
                          "data-itp_support": "true",
                        },
                      }),
                      _vm._m(2),
                      _vm._m(3),
                    ]
                  ),
                  _c("div", { staticClass: "cardbox-body" }, [
                    _vm.confirm_result
                      ? _c(
                          "div",
                          {
                            staticClass: "alert",
                            class: {
                              "alert-success": _vm.confirm_result.ok,
                              "alert-danger": !_vm.confirm_result.ok,
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.confirm_result.msg) + " ")]
                        )
                      : _vm._e(),
                    _vm.error_msg
                      ? _c("div", { staticClass: "alert alert-danger" }, [
                          _vm._v(" " + _vm._s(_vm.error_msg) + " "),
                        ])
                      : _vm._e(),
                    _c(
                      "form",
                      {
                        staticClass:
                          "cardbox cardbox-flat text-white form-validate text-color",
                        attrs: {
                          id: "user-login",
                          action: "",
                          name: "loginForm",
                          novalidate: "",
                        },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.login.apply(null, arguments)
                          },
                        },
                      },
                      [
                        !_vm.show_mfa
                          ? _c("div", { staticClass: "px-5" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.email,
                                      expression: "form.email",
                                    },
                                  ],
                                  staticClass:
                                    "form-control form-control-inverse",
                                  attrs: {
                                    id: "email",
                                    type: "email",
                                    required: "",
                                    placeholder: "Email address",
                                    autocomplete: "email",
                                  },
                                  domProps: { value: _vm.form.email },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.form,
                                        "email",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "form-group" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.pass,
                                      expression: "form.pass",
                                    },
                                  ],
                                  staticClass:
                                    "form-control form-control-inverse",
                                  attrs: {
                                    id: "password",
                                    type: "password",
                                    required: "",
                                    placeholder: "Password",
                                    autocomplete: "current-password",
                                  },
                                  domProps: { value: _vm.form.pass },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.form,
                                        "pass",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ])
                          : _c(
                              "section",
                              { staticClass: "pb-4" },
                              [
                                _c("mfa-login", {
                                  attrs: {
                                    mfa_mode: _vm.mfa_mode,
                                    user_email: _vm.form.email,
                                    sms_phone_number: _vm.mfa_sms_phone_number,
                                    mfa_cooldown_seconds: _vm.mfa_cooldown,
                                  },
                                  on: {
                                    code_changed: (code) => {
                                      _vm.mfa_mode == "backup"
                                        ? (_vm.form.mfa_backup_code = code)
                                        : (_vm.form.mfa_code = code)
                                    },
                                    change_mfa_mode: (new_mode) => {
                                      _vm.mfa_mode = new_mode
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                        _c("div", { staticClass: "text-center my-4" }, [
                          !_vm.login_loading
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-lg px-5 text-bold",
                                  attrs: {
                                    disabled: _vm.login_btn_disabled,
                                    type: "submit",
                                  },
                                },
                                [_vm._v(" Login to my Team ")]
                              )
                            : _c("div", [
                                _c("i", {
                                  staticClass: "ion-load-c icon-lg spin",
                                }),
                              ]),
                        ]),
                      ]
                    ),
                  ]),
                  _c(
                    "section",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.show_mfa && !_vm.email_prefilled,
                          expression: "!show_mfa && !email_prefilled",
                        },
                      ],
                    },
                    [
                      _vm._m(4),
                      _c(
                        "div",
                        { staticClass: "cardbox-footer text-center b0 mt-5" },
                        [
                          _c("span", { staticClass: "mr-1" }, [
                            _vm._v("No account yet?"),
                          ]),
                          _c(
                            "router-link",
                            {
                              staticClass: "text-inherit text-bold",
                              attrs: { to: "/register_trial" },
                            },
                            [_vm._v("Create your SkyFlok team now!")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", { staticClass: "mt-5" }, [
      _vm._v("Logging you in... "),
      _c("i", { staticClass: "ion-load-c spin" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3" }, [
      _c("img", {
        staticStyle: { "max-height": "40px" },
        attrs: { src: require("@/assets/img/skyflok_logo_lg.png") },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex justify-content-center" }, [
      _c("div", {
        staticClass: "g_id_signin",
        attrs: {
          "data-type": "standard",
          "data-shape": "pill",
          "data-theme": "outline",
          "data-text": "signin_with",
          "data-size": "large",
          "data-logo_alignment": "left",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex align-items-center my-3" }, [
      _c("hr", { staticClass: "fw ml-5 mr-3 my-0" }),
      _c("span", [_vm._v(" OR ")]),
      _c("hr", { staticClass: "fw mr-5 ml-3 my-0" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "h5",
      { staticClass: "cardbox-footer text-center b0 px-5 mt-5" },
      [
        _c("p", [_vm._v("Are you a Client of a SkyFlok Team?")]),
        _c(
          "a",
          {
            staticClass: "btn btn-info px-4 btn-oval text-bold",
            attrs: { href: "https://spaces.skyflok.com" },
          },
          [
            _vm._v("Client Space Login "),
            _c("i", { staticClass: "ion-arrow-right-c ml-1" }),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }