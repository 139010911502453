var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm.versions_limit >= 0
      ? _c("div", { staticClass: "fw alert alert-sm alert-info" }, [
          _c("i", { staticClass: "ion-information-circled mr-1" }),
          _c("b", [
            _vm._v(
              "Maximum " +
                _vm._s(_vm.versions_limit + 1) +
                " file versions are stored."
            ),
          ]),
          _vm._v(" Upgrade your plan for unlimited versions. "),
        ])
      : _vm._e(),
    _vm.versions
      ? _c("section", [
          _c(
            "table",
            {
              staticClass: "table table-sm d-none d-lg-block mx-auto",
              staticStyle: { width: "fit-content" },
              attrs: { id: "fileversions-table" },
            },
            [
              _vm._m(0),
              _c(
                "tbody",
                _vm._l(_vm.versions, function (version, idx) {
                  return _c("tr", { key: version.id }, [
                    _c(
                      "td",
                      {
                        staticClass: "text-center",
                        class: { "text-bold": idx === _vm.versions.length - 1 },
                      },
                      [
                        idx === _vm.versions.length - 1
                          ? _c("span", [_vm._v("Latest")])
                          : _c("span", [_vm._v(_vm._s(idx + 1) + ".")]),
                      ]
                    ),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        _vm._s(_vm._f("timestamp_time_date")(version.timestamp))
                      ),
                    ]),
                    _c(
                      "td",
                      { staticClass: "text-center" },
                      [
                        _vm.user && _vm.user.team
                          ? _c("user-box", {
                              attrs: {
                                "external-actor": _vm.modifiedBoxExternal,
                                team: _vm.user.team,
                                "user-id": version.user_id,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("td", { staticClass: "nowrap text-center" }, [
                      _vm._v(_vm._s(_vm._f("format_bytes")(version.size))),
                    ]),
                    _vm.download_file_fn
                      ? _c("td", { staticClass: "width-0" }, [
                          _vm.file.viewer && _vm.open_file_fn
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn text-sm btn-flat",
                                  class: [_vm.file.viewer.btn_class],
                                  attrs: {
                                    disabled: _vm.open_file_fn === null,
                                    title:
                                      "Open this version with " +
                                      _vm.file.viewer.label,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.open_file_fn(
                                        _vm.file,
                                        version.id || version.version
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.file.viewer.verb) + " "
                                  ),
                                  _c("i", {
                                    staticClass: "icon-lg ml-2",
                                    class: _vm.file.viewer.icon_class,
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm.download_file_fn
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn text-sm btn-sm btn-primary",
                                  attrs: { disabled: _vm.file.loading },
                                  on: {
                                    click: function ($event) {
                                      _vm.download_file_fn(
                                        _vm.file,
                                        version.id || version.version
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "icon-lg",
                                    class: {
                                      "ion-arrow-down-a": !_vm.file.loading,
                                      "ion-load-c spin": _vm.file.loading,
                                    },
                                  }),
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v("Download"),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ])
                }),
                0
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "d-block d-lg-none" },
            _vm._l(_vm.versions, function (version, idx) {
              return _c("div", { key: version.id }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "version-header text-bold d-flex align-items-center justify-content-between mt-2 mb-2",
                  },
                  [
                    _c("span", [_vm._v("Version " + _vm._s(idx + 1))]),
                    _vm.download_file_fn
                      ? _c(
                          "button",
                          {
                            staticClass: "btn text-sm btn-sm btn-primary mb-1",
                            attrs: { disabled: _vm.file.loading },
                            on: {
                              click: function ($event) {
                                _vm.download_file_fn(
                                  _vm.file,
                                  version.id || version.version
                                )
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "icon-lg",
                              class: {
                                "ion-arrow-down-a": !_vm.file.loading,
                                "ion-load-c spin": _vm.file.loading,
                              },
                            }),
                            _c("span", { staticClass: "ml-1" }, [
                              _vm._v("Download"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _c("div", { staticClass: "version-details ml-2" }, [
                  _c(
                    "div",
                    [
                      _c("b", [_vm._v("Uploader: ")]),
                      _vm.user && _vm.user.team
                        ? _c("user-box", {
                            attrs: {
                              "external-actor": _vm.modifiedBoxExternal,
                              team: _vm.user.team,
                              "user-id": version.user_id,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", [
                    _c("b", [_vm._v("Created: ")]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("timestamp_time_date")(version.timestamp)
                        ) +
                        " "
                    ),
                  ]),
                  _c("div", [
                    _c("b", [_vm._v("Size: ")]),
                    _vm._v(
                      " " + _vm._s(_vm._f("format_bytes")(version.size)) + " "
                    ),
                  ]),
                ]),
              ])
            }),
            0
          ),
        ])
      : _c("i", { staticClass: "ion-load-c spin" }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("th", { staticClass: "text-center width-0" }, [_vm._v("Version")]),
      _c("th", { staticClass: "text-center" }, [_vm._v("Created")]),
      _c("th", { staticClass: "text-center" }, [_vm._v("Uploader")]),
      _c("th", { staticClass: "text-center" }, [_vm._v("Size")]),
      _c("th", { staticClass: "width-0" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }