var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm._m(0),
    !_vm.hide_form
      ? _c("div", { staticClass: "cardbord-body text-center" }, [
          _c("div", { staticClass: "text-14" }, [
            _vm._v(
              "Try SkyFlok free for " +
                _vm._s(
                  _vm.team_config ? _vm.team_config.time_limit_days : "30"
                ) +
                " days!"
            ),
          ]),
          _vm._m(1),
        ])
      : _vm._e(),
    _vm.error
      ? _c("div", {
          staticClass: "alert alert-danger mt-2",
          domProps: { innerHTML: _vm._s(_vm.error) },
        })
      : _vm.success
      ? _c("div", { staticClass: "fw text-center my-3" }, [
          _vm._m(2),
          _vm._m(3),
        ])
      : _vm._e(),
    !_vm.hide_form
      ? _c("div", { staticClass: "cardbox-body row pb-0" }, [
          _c("div", { staticClass: "col-12 col-md-6" }, [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit()
                  },
                },
              },
              [
                _c(
                  "label",
                  {
                    staticStyle: { "padding-top": "0px" },
                    attrs: { for: "register_name pt-0 mt-0" },
                  },
                  [_vm._v("Name")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.name,
                      expression: "form.name",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    id: "register_name",
                    name: "name",
                    maxlength: "255",
                    placeholder: "Your name (optional)",
                  },
                  domProps: { value: _vm.form.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "name", $event.target.value)
                    },
                  },
                }),
                _c(
                  "label",
                  { staticClass: "mt-3", attrs: { for: "register_email" } },
                  [_vm._v("Email Address")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.email,
                      expression: "form.email",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "email",
                    id: "register_email",
                    name: "email",
                    placeholder: "Your email address",
                  },
                  domProps: { value: _vm.form.email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "email", $event.target.value)
                    },
                  },
                }),
                _vm.email_suggested
                  ? _c("small", { staticClass: "d-block" }, [
                      _vm._v("Did you mean "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              _vm.form.email = _vm.email_suggested
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.email_suggested))]
                      ),
                      _vm._v("?"),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "mt-5" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-block btn-lg text-bold bg-skyflok text-white text-12",
                      attrs: {
                        type: "submit",
                        disabled: !_vm.form_ok || _vm.loading,
                      },
                    },
                    [
                      _vm._v(" Sign Up "),
                      _vm.loading
                        ? _c("i", { staticClass: "ion-load-c spin ml-2" })
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "col-12 col-md-6" }, [
            _c("div", { staticClass: "d-block d-md-none pt-3" }, [_vm._v(" ")]),
            _c(
              "div",
              {
                staticClass: "mt-4",
                class: { "mx-3": !_vm.Utils.is_mobile() },
              },
              [_c("invite-config-box", { attrs: { invite: _vm.invite } })],
              1
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cardbox-heading" }, [
      _c("div", { staticClass: "cardbox-title text-center" }, [
        _c("a", { attrs: { href: "https://skyflok.com", target: "_blank" } }, [
          _c("img", {
            staticClass: "mr-2",
            staticStyle: { "max-height": "60px" },
            attrs: { src: require("@/assets/img/skyflok_logo_green.png") },
          }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-sm" }, [
      _c("span", [_vm._v("No credit card required")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-success text-14" }, [
      _vm._v(" Success!"),
      _c("br"),
      _vm._v("Check your email for the invite! "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-5" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/img/illustrations/mail_sent-success.svg"),
          height: "150",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }