var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "nowrap" }, [
    _vm._v(" " + _vm._s(_vm._f("timestamp_rel")(_vm.timestamp))),
    _c("i", {
      staticClass: "ion-calendar text-muted ml-2",
      attrs: { title: _vm._f("timestamp")(_vm.timestamp) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }