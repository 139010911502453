var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "layout-container",
      class: {
        "bg-gradient-danger": _vm.error !== false,
        "bg-gradient-info": _vm.error === false,
      },
      staticStyle: { overflow: "overlay" },
    },
    [
      _c("div", { staticClass: "page-container" }, [
        _c("div", { staticClass: "container", class: { narrow: !_vm.share } }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-1" }),
            _c("div", { staticClass: "col-12 col-lg-10" }, [
              _c(
                "div",
                { staticClass: "cardbox mt-5", attrs: { id: "main-box" } },
                [
                  _c("div", { staticClass: "cardbox-heading" }, [
                    _vm.share && _vm.share.team_info
                      ? _c("div", { staticClass: "cardbox-title" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-center align-items-center",
                            },
                            [
                              _c("div", { staticClass: "text-md" }, [
                                _vm._v(
                                  "Your shared files from " +
                                    _vm._s(_vm.share.team_info.name)
                                ),
                              ]),
                              _vm.share.team_info.logo_url &&
                              _vm.share.team_info.logo_url != ""
                                ? _c("img", {
                                    staticClass: "ml-2",
                                    staticStyle: { "max-height": "48px" },
                                    attrs: {
                                      src: _vm.share.team_info.logo_url,
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _c("div", { staticStyle: { clear: "both" } }),
                        ])
                      : _vm._e(),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "cardbox-body",
                      attrs: { id: "main-box-body" },
                    },
                    [
                      _vm.error
                        ? _c("div", { staticClass: "text-center" }, [
                            _c("div", {
                              staticClass: "alert alert-danger",
                              staticStyle: {
                                display: "inline-block",
                                "min-width": "60%",
                              },
                              domProps: { innerHTML: _vm._s(_vm.error) },
                            }),
                          ])
                        : _vm._e(),
                      _vm.share_loading
                        ? _c("div", [
                            _c("i", {
                              staticClass: "ion-load-c icon-lg spin text-info",
                            }),
                          ])
                        : _vm._e(),
                      _vm.share_password_needed && !_vm.share
                        ? _c(
                            "div",
                            {
                              staticClass: "text-center",
                              staticStyle: {
                                width: "450px",
                                margin: "0px auto",
                                display: "grid",
                                "grid-template-columns": "140px 1fr",
                              },
                            },
                            [
                              _vm._m(0),
                              _c(
                                "div",
                                {
                                  staticClass: "text-left",
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "column",
                                    "justify-content": "center",
                                  },
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "form",
                                      {
                                        on: {
                                          submit: function ($event) {
                                            $event.preventDefault()
                                            return _vm.unlock_share()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "share_password" } },
                                          [_vm._v("Share password:")]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "input-group" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model.trim",
                                                  value: _vm.share_password,
                                                  expression: "share_password",
                                                  modifiers: { trim: true },
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "password",
                                                id: "share_password",
                                              },
                                              domProps: {
                                                value: _vm.share_password,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.share_password =
                                                    $event.target.value.trim()
                                                },
                                                blur: function ($event) {
                                                  return _vm.$forceUpdate()
                                                },
                                              },
                                            }),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-gradient btn-success ml-2",
                                                attrs: {
                                                  type: "submit",
                                                  disabled: _vm.login_loading,
                                                },
                                              },
                                              [
                                                _vm._v("Unlock "),
                                                _vm.login_loading
                                                  ? _c("i", {
                                                      staticClass:
                                                        "ion-load-c spin ml-1",
                                                    })
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.share
                        ? _c("div", [
                            _vm.share.message && _vm.share.message != ""
                              ? _c(
                                  "div",
                                  { staticClass: "share-header text-center" },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "bl pl-3",
                                        staticStyle: {
                                          display: "inline-block",
                                        },
                                      },
                                      [
                                        _c("i", [
                                          _vm._v(_vm._s(_vm.share.message)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c("div", { staticClass: "share-contents" }, [
                              _vm.nav_stack && _vm.nav_stack.length > 1
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-middle mb-3",
                                    },
                                    _vm._l(
                                      _vm.nav_stack,
                                      function (folder, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: folder.id,
                                            staticClass: "d-inline-block",
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-flat btn-info",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.navigate_to(
                                                      folder,
                                                      false
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                folder.is_root
                                                  ? _c("i", {
                                                      staticClass:
                                                        "icon ion-home",
                                                    })
                                                  : _c("span", [
                                                      _vm._v(
                                                        _vm._s(folder.name)
                                                      ),
                                                    ]),
                                              ]
                                            ),
                                            index < _vm.nav_stack.length - 1
                                              ? _c("span", [_vm._v(">")])
                                              : _vm._e(),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              _vm.folders &&
                              _vm.folders.length > 0 &&
                              !_vm.share.contents_loading
                                ? _c(
                                    "div",
                                    { staticClass: "row" },
                                    _vm._l(_vm.folders, function (folder) {
                                      return _c(
                                        "div",
                                        {
                                          key: folder.id,
                                          staticClass:
                                            "col-12 col-md-6 col-lg-4 folder-box",
                                          on: {
                                            click: function ($event) {
                                              return _vm.navigate_to(
                                                folder,
                                                true
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "cardbox",
                                              class: [
                                                "color-" +
                                                  folder.color +
                                                  "-400",
                                              ],
                                              staticStyle: {
                                                "border-left": "4px solid",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "cardbox-body",
                                                  attrs: { title: folder.name },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-content-start align-items-center",
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "ion-folder mr-2",
                                                        staticStyle: {
                                                          "font-size": "25px",
                                                          "line-height": "25px",
                                                        },
                                                      }),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "nowrap",
                                                          staticStyle: {
                                                            overflow: "hidden",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(folder.name)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e(),
                              !_vm.share.contents_loading
                                ? _c("div", [
                                    _vm.share.contents.length > 0
                                      ? _c(
                                          "table",
                                          { staticClass: "table table-hover" },
                                          [
                                            _c("thead", [
                                              _c("th", {
                                                staticStyle: { width: "10px" },
                                              }),
                                              _c(
                                                "th",
                                                { staticClass: "text-left" },
                                                [_vm._v("Filename")]
                                              ),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "d-none d-md-table-cell",
                                                },
                                                [_vm._v("Type")]
                                              ),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "d-none d-md-table-cell",
                                                },
                                                [_vm._v("Size")]
                                              ),
                                              _vm.share.download_limit
                                                ? _c(
                                                    "th",
                                                    {
                                                      staticClass:
                                                        "d-none d-md-table-cell",
                                                    },
                                                    [_vm._v("Downloads")]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "d-none d-lg-table-cell",
                                                },
                                                [_vm._v("Uploaded")]
                                              ),
                                              _c("th"),
                                            ]),
                                            _vm.files && _vm.files.length > 0
                                              ? _vm._l(
                                                  _vm.files,
                                                  function (file) {
                                                    return _c(
                                                      "tbody",
                                                      { key: file.id },
                                                      [
                                                        _c(
                                                          "tr",
                                                          {
                                                            class: {
                                                              "text-gray-light":
                                                                file.loading,
                                                              "text-muted":
                                                                _vm.share
                                                                  .download_limit &&
                                                                file.downloads_num >=
                                                                  _vm.share
                                                                    .download_limit,
                                                            },
                                                          },
                                                          [
                                                            _c("td", [
                                                              _c("img", {
                                                                attrs: {
                                                                  src: _vm._f(
                                                                    "filetype_img_src"
                                                                  )(
                                                                    file.extension
                                                                  ),
                                                                },
                                                              }),
                                                            ]),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-left text-bold table-cell cell-full cell-ellipsis",
                                                              },
                                                              [
                                                                _vm.share
                                                                  .download_limit &&
                                                                file.downloads_num >=
                                                                  _vm.share
                                                                    .download_limit
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "c-not-allowed",
                                                                        attrs: {
                                                                          title:
                                                                            "Cannot download this file any more, the download limit has been reached.",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              file.name
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "ion-alert-circled ml-1",
                                                                          }
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _c("div", [
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          staticClass:
                                                                            "file_download_link",
                                                                          attrs:
                                                                            {
                                                                              href: "#",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.download_file(
                                                                                  file
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              file.name
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      file.loading
                                                                        ? _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "text-muted ml-2",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "(" +
                                                                                  _vm._s(
                                                                                    file.loading.toFixed(
                                                                                      1
                                                                                    )
                                                                                  ) +
                                                                                  "%)"
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ]),
                                                              ]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "nowrap text-gray-light text-sm d-none d-md-table-cell",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "text-transform":
                                                                          "uppercase",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        file.extension
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "nowrap text-gray-light text-sm d-none d-md-table-cell",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "size"
                                                                      )(
                                                                        file.size
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm.share
                                                              .download_limit
                                                              ? _c(
                                                                  "td",
                                                                  {
                                                                    staticClass:
                                                                      "text-gray-light text-sm d-none d-md-table-cell text-center",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          file.downloads_num
                                                                        ) +
                                                                        " / " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .share
                                                                            .download_limit
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "nowrap text-gray-light text-sm d-none d-lg-table-cell",
                                                              },
                                                              [
                                                                _c(
                                                                  "timestamp",
                                                                  {
                                                                    attrs: {
                                                                      timestamp:
                                                                        file.timestamp,
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "nowrap text-right",
                                                              },
                                                              [
                                                                file.cancel
                                                                  ? _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn text-sm btn-danger btn-flat",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return file.cancel()
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "CANCEL "
                                                                        ),
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "ion-close-round ml-1",
                                                                          }
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "section",
                                                                      [
                                                                        file.viewer
                                                                          ? _c(
                                                                              "button",
                                                                              {
                                                                                staticClass:
                                                                                  "btn text-sm btn-flat",
                                                                                class:
                                                                                  [
                                                                                    file
                                                                                      .viewer
                                                                                      .btn_class,
                                                                                  ],
                                                                                attrs:
                                                                                  {
                                                                                    disabled:
                                                                                      _vm
                                                                                        .share
                                                                                        .download_limit &&
                                                                                      file.downloads_num >=
                                                                                        _vm
                                                                                          .share
                                                                                          .download_limit,
                                                                                    title:
                                                                                      "Open with " +
                                                                                      file
                                                                                        .viewer
                                                                                        .label,
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      $event.preventDefault()
                                                                                      return _vm.open_file(
                                                                                        file
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    file
                                                                                      .viewer
                                                                                      .verb
                                                                                  ) +
                                                                                    " "
                                                                                ),
                                                                                _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticClass:
                                                                                      "icon-lg ml-2",
                                                                                    class:
                                                                                      file
                                                                                        .viewer
                                                                                        .icon_class,
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _c(
                                                                          "button",
                                                                          {
                                                                            staticClass:
                                                                              "btn btn-info btn-gradient text-white ml-0",
                                                                            attrs:
                                                                              {
                                                                                disabled:
                                                                                  _vm
                                                                                    .share
                                                                                    .download_limit &&
                                                                                  file.downloads_num >=
                                                                                    _vm
                                                                                      .share
                                                                                      .download_limit,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.download_file(
                                                                                    file
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                staticClass:
                                                                                  "ion-arrow-down-a",
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ml-2",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Download"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        file.loading
                                                          ? _c(
                                                              "tr",
                                                              {
                                                                staticClass:
                                                                  "file-loading",
                                                              },
                                                              [
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    attrs: {
                                                                      colspan:
                                                                        "7",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "progress",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "progress-bar progress-bar-striped progress-bar-animated",
                                                                            style:
                                                                              {
                                                                                width:
                                                                                  Math.round(
                                                                                    file.loading
                                                                                  ) +
                                                                                  "%",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                role: "progressbar",
                                                                                "aria-valuenow":
                                                                                  Math.round(
                                                                                    file.loading
                                                                                  ),
                                                                                "aria-valuemin":
                                                                                  "0",
                                                                                "aria-valuemax":
                                                                                  "100",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  }
                                                )
                                              : _vm._e(),
                                            _vm.files && _vm.files.length === 0
                                              ? _c("tbody", [_vm._m(1)])
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _c("div", { staticClass: "row" }, [
                                          _c("div", { staticClass: "col-3" }),
                                          _vm._m(2),
                                        ]),
                                  ])
                                : _c("div", [
                                    _vm._v(" Loading... "),
                                    _c("i", { staticClass: "ion-load-c spin" }),
                                  ]),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "cardbox-footer d-flex align-items-center justify-content-between",
                    },
                    [
                      _c(
                        "div",
                        {},
                        [
                          _vm.share && _vm.share.locations
                            ? _c("locations", {
                                attrs: {
                                  locations: _vm.share.locations,
                                  placement: "top",
                                  "team-name": _vm.share.team_info.name,
                                  circles: true,
                                  "text-start": _vm.locations_text,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._m(3),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "modal", attrs: { id: "viewer-modal" } }, [
        _vm.view_file
          ? _c(
              "div",
              {
                staticClass: "modal-dialog modal-lg",
                staticStyle: { "max-width": "80%" },
              },
              [
                _c("div", { staticClass: "modal-content" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "modal-header bg-gradient-primary text-white",
                    },
                    [
                      _c("h5", { staticClass: "mt-0 modal-title" }, [
                        _c("b", [_vm._v(_vm._s(_vm.view_file.file.name))]),
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "close",
                          attrs: { type: "button", "aria-label": "Close" },
                          on: {
                            click: function ($event) {
                              _vm.view_file = null
                              _vm.close_modal("viewer-modal")
                            },
                          },
                        },
                        [_c("span", [_vm._v("×")])]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "modal-body" },
                    [
                      _c("file-preview", {
                        attrs: {
                          file: _vm.view_file.file,
                          file_list: _vm.view_file.file_list,
                          file_downloaded_callback: (file) => {
                            if (file.downloads_num !== undefined) {
                              ++file.downloads_num
                            }
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("i", { staticClass: "ion-lock-combination lock-icon" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "7" } }, [
        _c("i", [_vm._v("No files in this folder")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6" }, [
      _c("div", { staticClass: "cardbox text-white bg-gradient-warning b0" }, [
        _c("div", { staticClass: "cardbox-body text-center" }, [
          _c("div", { staticClass: "text-bold display-3" }, [
            _c("i", { staticClass: "ion-qr-scanner" }),
          ]),
          _c("p", { staticClass: "text-bold text-md" }, [
            _vm._v("Empty folder"),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        attrs: {
          href: "https://www.skyflok.com",
          target: "_BLANK",
          rel: "noopener",
        },
      },
      [
        _c("img", {
          attrs: {
            src: require("@/assets/img/skyflok_logo_black.png"),
            height: "32",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }