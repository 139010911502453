var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "section-container" }, [
    _vm.user && _vm.user.team_settings && !_vm.user.team_settings.is_free_trial
      ? _c("div", { staticClass: "fw text-center text-14 pt-5" }, [
          _vm._m(0),
          _vm._v(" Contact us at "),
          _c(
            "a",
            {
              staticClass: "text-initial text-underline",
              staticStyle: { color: "initial" },
              attrs: { href: "mailto:support@skyflok.com" },
            },
            [_vm._v("support@skyflok.com")]
          ),
          _vm._v(" if you have a question or you want to change it. "),
        ])
      : _c("div", { staticClass: "container" }, [
          _vm.loading
            ? _c("div", { staticClass: "fw text-center text-14 pt-5" }, [
                _vm._v(" Loading subscription options... "),
                _c("i", { staticClass: "ion-load-c spin ml-2" }),
              ])
            : !_vm.available_plans.length
            ? _c(
                "div",
                { staticClass: "fw text-center text-14 pt-5 text-danger" },
                [_vm._v(" No plans found. ")]
              )
            : _c(
                "div",
                { staticClass: "row pt-5" },
                [
                  _vm.available_plans.length == 2
                    ? _c("div", { staticClass: "offset-1 d-none d-md-block" })
                    : _vm._e(),
                  _vm._l(_vm.available_plans, function (plan) {
                    return _c(
                      "div",
                      {
                        key: plan._key,
                        staticClass: "col-12 col-md-6 col-lg-5",
                      },
                      [
                        _c("div", { staticClass: "cardbox b plan-box" }, [
                          _c(
                            "div",
                            { staticClass: "cardbox-body px-5" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-center color-skyflok text-14",
                                },
                                [_vm._v(_vm._s(plan.name))]
                              ),
                              _c(
                                "div",
                                { staticClass: "text-center my-4 text-bold" },
                                [
                                  _c("div", { staticClass: "mt-2 text-14" }, [
                                    _vm._v(
                                      _vm._s(plan.pricing.monthly_price) +
                                        " / month"
                                    ),
                                  ]),
                                  plan.pricing.annual_savings
                                    ? _c(
                                        "small",
                                        { staticClass: "text-gray" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              plan.pricing.annual_savings
                                            ) + " with annual billing"
                                          ),
                                        ]
                                      )
                                    : _c("small", [_vm._v(" ")]),
                                ]
                              ),
                              _vm._l(plan.features, function (feature, idx) {
                                return _c("p", { key: idx }, [
                                  _c("i", {
                                    staticClass: "ion-checkmark-round mr-3",
                                  }),
                                  _vm._v(_vm._s(feature) + " "),
                                ])
                              }),
                              _c("p", { staticClass: "text-center mt-4" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-gradient btn-skyflok btn-block text-bold text-12",
                                    class: _vm.plan_btn_class(plan),
                                    on: {
                                      click: function ($event) {
                                        return _vm.plan_selected(plan)
                                      },
                                    },
                                  },
                                  [_vm._v(" Select ")]
                                ),
                              ]),
                            ],
                            2
                          ),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              ),
          !_vm.loading
            ? _c("div", { staticClass: "row mt-5" }, [
                _c("div", { staticClass: "offset-2 d-none d-md-block" }),
                _vm._m(1),
              ])
            : _vm._e(),
        ]),
    _c("div", { staticClass: "modal", attrs: { id: "instructions_modal" } }, [
      _vm.show_instructions
        ? _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(2),
              _c("div", { staticClass: "modal-body px-4" }, [
                _vm._m(3),
                _c("ol", { staticClass: "mx-3" }, [
                  _vm._m(4),
                  _vm._m(5),
                  _c("li", { staticClass: "mb-2" }, [
                    _vm._v("Use your email address "),
                    _c(
                      "span",
                      {
                        staticClass: "badge badge-dark",
                        staticStyle: { "font-size": "100%" },
                      },
                      [_vm._v(_vm._s(_vm.user ? _vm.user.email_address : ""))]
                    ),
                    _vm._v(" under "),
                    _c("strong", [_vm._v("Billing Details")]),
                  ]),
                ]),
                _c("p", { staticClass: "mt-4 px-5" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-gradient btn-block",
                      class: _vm.plan_btn_class(_vm.selected_plan),
                      attrs: {
                        href: _vm.selected_plan.pricing.checkout_url,
                        target: "_blank",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.close_modal()
                        },
                      },
                    },
                    [
                      _vm._v(" Open Checkout Form "),
                      _c("i", { staticClass: "ion-arrow-right-c ml-1" }),
                    ]
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-success mb-4" }, [
      _c("i", { staticClass: "text-success ion-checkmark-circled mr-2" }),
      _vm._v(" Your team has an active SkyFlok subscription. "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 col-lg-8" }, [
      _c("div", { staticClass: "cardbox" }, [
        _c("div", { staticClass: "cardbox-body" }, [
          _c("div", { staticClass: "text-center color-skyflok text-14" }, [
            _vm._v("Need more? No problem!"),
          ]),
          _c("div", { staticClass: "text-center" }, [
            _c("div", { staticClass: "mt-2" }, [
              _vm._v(" Contact us on "),
              _c(
                "a",
                {
                  staticClass: "text-initial text-underline",
                  staticStyle: { color: "initial" },
                  attrs: { href: "mailto:upgrade@skyflok.com" },
                },
                [_vm._v("upgrade@skyflok.com")]
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "modal-header bg-gradient-info text-white" },
      [
        _c("h5", { staticClass: "mt-0 modal-title" }, [
          _vm._v("Checkout Form Instructions"),
        ]),
        _c(
          "button",
          {
            staticClass: "close",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close",
            },
          },
          [_c("span", [_vm._v("×")])]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "pt-3" }, [
      _vm._v(" Please use our webshop to start a SkyFlok subscription. "),
      _c("br"),
      _vm._v("On the Checkout Form: "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "mb-2" }, [
      _vm._v("Select "),
      _c("strong", [_vm._v("Yearly")]),
      _vm._v(" or "),
      _c("strong", [_vm._v("Monthly")]),
      _vm._v(" billing"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "mb-2" }, [
      _vm._v("Click the "),
      _c(
        "span",
        {
          staticClass: "badge badge-dark",
          staticStyle: { "font-size": "100%" },
        },
        [_vm._v("Add to cart")]
      ),
      _vm._v(" button"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }