var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "cardbox text-white b0",
      class: _vm.cardbox_class,
      on: {
        click: function ($event) {
          _vm.clicked ? _vm.clicked() : false
        },
      },
    },
    [
      _c("div", { staticClass: "cardbox-body text-center" }, [
        _vm.icon
          ? _c("div", { staticClass: "text-bold display-1" }, [
              _c("i", { class: [_vm.icon] }),
            ])
          : _vm._e(),
        _vm.text
          ? _c("p", { staticClass: "text-bold text-md" }, [
              _vm._v(" " + _vm._s(_vm.text) + " "),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }