var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "section-container", attrs: { id: "install_apps" } },
    [
      _c("div", { staticClass: "container pt-5" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 col-md-6 col-xl-4" }, [
            _c("div", { staticClass: "cardbox b" }, [
              _vm._m(0),
              _vm._m(1),
              _c("div", { staticClass: "cardbox-body text-center" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-outline-primary",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        return _vm.coming_soon()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "ion-social-windows icon-lg mr-2" }),
                    _vm._v("Coming soon!"),
                  ]
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-12 col-md-6 col-xl-4" }, [
            _c("div", { staticClass: "cardbox b" }, [
              _vm._m(2),
              _vm._m(3),
              _c("div", { staticClass: "cardbox-body text-center" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-outline-secondary btn-gradient",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        return _vm.coming_soon()
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "ion-social-apple-outline icon-lg mr-2",
                    }),
                    _vm._v(" Coming soon!"),
                  ]
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-12 col-md-6 col-xl-4" }, [
            _c("div", { staticClass: "cardbox b" }, [
              _vm._m(4),
              _vm._m(5),
              _c("div", { staticClass: "cardbox-body text-center" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-outline-danger btn-gradient",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        return _vm.coming_soon()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "ion-social-tux icon-lg mr-2" }),
                    _vm._v(" Coming soon!"),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "p-2" }, [
      _c(
        "div",
        {
          staticClass:
            "cardbox-body bg-gradient-primary text-center text-white rounded",
        },
        [
          _c("em", { staticClass: "ion-social-windows icon-5x" }),
          _c("div", { staticClass: "text-bold text-lg" }, [_vm._v("Windows")]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cardbox-body" }, [
      _c("p", { staticClass: "mb-3" }, [
        _c("em", { staticClass: "ion-checkmark-round mx-3" }),
        _c("span", [_vm._v("Runs in the background")]),
      ]),
      _c("p", { staticClass: "mb-3" }, [
        _c("em", { staticClass: "ion-checkmark-round mx-3" }),
        _c("span", [_vm._v("Sync only the selected folders")]),
      ]),
      _c("p", { staticClass: "mb-3" }, [
        _c("em", { staticClass: "ion-checkmark-round mx-3" }),
        _c("span", [_vm._v("Password and/or PIN code")]),
      ]),
      _c("p", { staticClass: "mb-3" }, [
        _c("em", { staticClass: "ion-checkmark-round mx-3" }),
        _c("span", [_vm._v("Accidental overwrite protection")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "p-2" }, [
      _c(
        "div",
        {
          staticClass:
            "cardbox-body bg-gradient-secondary text-center text-white rounded",
        },
        [
          _c("em", { staticClass: "ion-social-apple-outline icon-5x" }),
          _c("div", { staticClass: "text-bold text-lg" }, [_vm._v("macOS")]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cardbox-body" }, [
      _c("p", { staticClass: "mb-3" }, [
        _c("em", { staticClass: "ion-checkmark-round mx-3" }),
        _c("span", [_vm._v("Runs in the background")]),
      ]),
      _c("p", { staticClass: "mb-3" }, [
        _c("em", { staticClass: "ion-checkmark-round mx-3" }),
        _c("span", [_vm._v("Sync only the selected folders")]),
      ]),
      _c("p", { staticClass: "mb-3" }, [
        _c("em", { staticClass: "ion-checkmark-round mx-3" }),
        _c("span", [_vm._v("Password and/or PIN code")]),
      ]),
      _c("p", { staticClass: "mb-3" }, [
        _c("em", { staticClass: "ion-checkmark-round mx-3" }),
        _c("span", [_vm._v("Accidental overwrite protection")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "p-2" }, [
      _c(
        "div",
        {
          staticClass:
            "cardbox-body bg-gradient-danger text-center text-white rounded",
        },
        [
          _c("em", { staticClass: "ion-social-tux icon-5x" }),
          _c("div", { staticClass: "text-bold text-lg" }, [_vm._v("Linux")]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cardbox-body" }, [
      _c("p", { staticClass: "mb-3" }, [
        _c("em", { staticClass: "ion-checkmark-round mx-3" }),
        _c("span", [_vm._v("Runs in the background")]),
      ]),
      _c("p", { staticClass: "mb-3" }, [
        _c("em", { staticClass: "ion-checkmark-round mx-3" }),
        _c("span", [_vm._v("Sync only the selected folders")]),
      ]),
      _c("p", { staticClass: "mb-3" }, [
        _c("em", { staticClass: "ion-checkmark-round mx-3" }),
        _c("span", [_vm._v("Password and/or PIN code")]),
      ]),
      _c("p", { staticClass: "mb-3" }, [
        _c("em", { staticClass: "ion-checkmark-round mx-3" }),
        _c("span", [_vm._v("Accidental overwrite protection")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }