var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-12 col-xl-6" }, [
    _c("div", { staticClass: "cardbox" }, [
      _c("div", {
        staticClass: "pb-1",
        class: {
          "bg-gradient-danger": !_vm.share.active,
          "bg-gradient-warning": _vm.share.active && _vm.share.type == 1,
          "bg-gradient-info": _vm.share.active && _vm.share.type == 0,
        },
      }),
      _c(
        "div",
        { staticClass: "cardbox-body" },
        [
          _vm.share.active
            ? _c("div", { staticClass: "float-right action-btns" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-warning mr-1",
                    attrs: { title: "Disable this share immediately" },
                    on: {
                      click: function ($event) {
                        return _vm.revokeHandler(_vm.share)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "ion-alert-circled mr-1" }),
                    _vm._v(" Disable "),
                  ]
                ),
              ])
            : _c("div", { staticClass: "float-right action-btns" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-success mr-1",
                    attrs: {
                      disabled: _vm.activatedisabled,
                      title: _vm.activatedisabled
                        ? "You reached the limit of active Shares. Upgrade your Team subscription to use more Shares!"
                        : "Activate this Share",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.activateHandler(_vm.share)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "ion-minus-circled mr-1" }),
                    _vm._v(" Activate "),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger btn-gradient",
                    attrs: { title: "Delete this share" },
                    on: {
                      click: function ($event) {
                        return _vm.deleteHandler(_vm.share)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "ion-minus-circled mr-1" }),
                    _vm._v(" Delete "),
                  ]
                ),
              ]),
          _vm.share.message
            ? _c(
                "p",
                {
                  staticClass: "bl pl-3 mb-3",
                  staticStyle: { display: "inline-block" },
                },
                [
                  _vm._v("Message: "),
                  _c("i", [_vm._v(_vm._s(_vm.share.message))]),
                ]
              )
            : _vm._e(),
          _vm.share.contents
            ? _vm._l(_vm.share.contents, function (group) {
                return _c(
                  "div",
                  { key: group.folder, staticClass: "mb-3" },
                  [
                    group.folder
                      ? _c(
                          "div",
                          { staticClass: "mb-2 text-muted text-sm" },
                          [
                            _c("router-link", {
                              staticClass: "text-muted",
                              attrs: {
                                to:
                                  "files#" +
                                  group.folder
                                    .replace(/ > /g, "/")
                                    .replace("Home", ""),
                              },
                              domProps: { innerHTML: _vm._s(group.folder) },
                            }),
                            _vm._v(" > "),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._l(group.files, function (file) {
                      return _c("shared-file", {
                        key: file.id,
                        attrs: { entity: file },
                      })
                    }),
                  ],
                  2
                )
              })
            : _c("i", { staticClass: "ion-load-c spin" }),
          _vm.share.active
            ? _c(
                "div",
                {
                  staticClass: "text-center",
                  staticStyle: { "white-space": "nowrap" },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-gradient btn-secondary mr-2",
                      on: {
                        click: function ($event) {
                          return _vm.str_to_clipboard(_vm.link)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "ion-clipboard icon-lg mr-1" }),
                      _vm._v(" Copy Share Link"),
                    ]
                  ),
                  _vm.share.password
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-gradient btn-secondary mr-1",
                          on: {
                            click: function ($event) {
                              return _vm.str_to_clipboard(_vm.share.password)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "ion-clipboard icon-lg mr-1",
                          }),
                          _vm._v(" Copy Password"),
                        ]
                      )
                    : _c("span", [
                        _c("i", { staticClass: "text-muted text-sm mr-1" }, [
                          _vm._v("No password"),
                        ]),
                      ]),
                  _c(
                    "a",
                    {
                      staticClass: "ml-1",
                      attrs: {
                        href: _vm.link,
                        target: "_BLANK",
                        title: "Open this share in a new tab",
                      },
                    },
                    [
                      _vm._v(" Open"),
                      _c("i", { staticClass: "ion-share ml-1" }),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "row text-center mt-3" }, [
            _c("div", { staticClass: "col-3" }, [
              _c("div", { staticClass: "text-muted" }, [_vm._v("Expires")]),
              _c("div", { staticClass: "h5" }, [
                _vm.share.expire
                  ? _c("span", [
                      _vm._v(_vm._s(_vm._f("timestamp_rel")(_vm.share.expire))),
                    ])
                  : _c("span", [_vm._v("Never")]),
              ]),
            ]),
            _c("div", { staticClass: "col-3 bl created-col" }, [
              _c("div", { staticClass: "text-muted" }, [_vm._v("Opened")]),
              _c("div", { staticClass: "h5" }, [
                _vm._v(_vm._s(_vm.share.accessed_count) + "x"),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "col bl" },
              [
                _vm.user && _vm.user.team
                  ? _c("modified-box", {
                      attrs: {
                        "user-id": _vm.share.created_by,
                        timestamp: _vm.share.created,
                        team: _vm.user.team,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }