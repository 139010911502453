var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "layout-container" }, [
    _c("div", { staticClass: "page-container" }, [
      _c(
        "div",
        { staticClass: "d-flex align-items-center align-items-center-ie" },
        [
          _c("div", { staticClass: "fw container container-sm" }, [
            _c("div", { staticClass: "cardbox text-bold" }, [
              _c("div", {
                staticClass: "pb-1",
                class: [_vm.status_ribbon_class],
              }),
              _c(
                "div",
                { staticClass: "p-4" },
                [
                  _c("router-view", {
                    on: {
                      changeStatus: (new_status) =>
                        _vm.status_changed(new_status),
                    },
                  }),
                ],
                1
              ),
              _vm._m(0),
              _c("div", {
                staticClass: "pt-1",
                class: [_vm.status_ribbon_class],
              }),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "d-flex align-items-center justify-content-center fw mb-2 text-gray text-sm",
      },
      [
        _vm._v(" Made in Scandinavia "),
        _c("img", {
          staticClass: "ml-2",
          attrs: {
            src: require("@/assets/img/flags/DK.png"),
            height: "20",
            alt: "Danish flag",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }