var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("file-list-view", {
    attrs: { user: _vm.user, "share-allowed": true },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }