var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-3" }),
    _c("div", { staticClass: "col-6" }, [
      _c(
        "div",
        { staticClass: "cardbox text-white bg-gradient-warning b0 mt-5" },
        [
          _c("div", { staticClass: "cardbox-body text-center" }, [
            _vm._m(0),
            _c("p", { staticClass: "text-bold text-md" }, [
              _vm._v("Upgrade your Team to use " + _vm._s(_vm.feature) + "!"),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "display-3" }, [
      _c("i", { staticClass: "ion-wand" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }