var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "section-container" }, [
    _c("div", { staticClass: "container container-lg" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-2" }),
        _c("div", { staticClass: "col-12 col-lg-8" }, [
          _c(
            "div",
            { staticClass: "cardbox text-white bg-gradient-danger b0" },
            [
              _c("div", { staticClass: "cardbox-body text-center" }, [
                _vm._m(0),
                _c("p", { staticClass: "text-bold text-md" }, [
                  _vm._v(" Nothing here. "),
                ]),
                _c(
                  "p",
                  { staticClass: "text-bold text-md" },
                  [
                    _vm._v(
                      " If you think this is a system error, please file a "
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "text-white",
                        staticStyle: {
                          "text-decoration": "underline",
                          "white-space": "nowrap",
                        },
                        attrs: { to: "/bugreport" },
                      },
                      [_vm._v("Bug Report")]
                    ),
                    _vm._v(" and tell us what happened. "),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-bold display-1" }, [
      _c("i", { staticClass: "ion-sad" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }