var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-check" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.model,
          expression: "model",
        },
      ],
      staticClass: "form-check-input",
      attrs: { type: "checkbox", name: _vm.name, id: `checkbox-${_vm.divid}` },
      domProps: {
        checked: Array.isArray(_vm.model)
          ? _vm._i(_vm.model, null) > -1
          : _vm.model,
      },
      on: {
        change: function ($event) {
          var $$a = _vm.model,
            $$el = $event.target,
            $$c = $$el.checked ? true : false
          if (Array.isArray($$a)) {
            var $$v = null,
              $$i = _vm._i($$a, $$v)
            if ($$el.checked) {
              $$i < 0 && (_vm.model = $$a.concat([$$v]))
            } else {
              $$i > -1 &&
                (_vm.model = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
            }
          } else {
            _vm.model = $$c
          }
        },
      },
    }),
    _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: `checkbox-${_vm.divid}` },
      },
      [_vm._v(_vm._s(_vm.label))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }