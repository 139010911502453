var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm.events_loading
      ? _c("div", { staticClass: "fw text-center m-2" }, [
          _vm._v(" Loading... "),
          _c("i", { staticClass: "ion-load-c spin ml-1" }),
        ])
      : _vm.events && !_vm.events.length
      ? _c("div", { staticClass: "fw text-center" }, [
          _c("b", [_vm._v("No events")]),
        ])
      : _c("div", [
          _c("table", { staticClass: "table mb-0" }, [
            _vm._m(0),
            _c(
              "tbody",
              _vm._l(_vm.events_page, function (event) {
                return _c("tr", { key: event.id }, [
                  _c("td", { staticClass: "text-sm width-0 nowrap" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("timestamp_time")(event.timestamp)) +
                        " " +
                        _vm._s(_vm._f("timestamp_date")(event.timestamp)) +
                        " "
                    ),
                  ]),
                  _c("td", [
                    event.event_type == 1
                      ? _c("span", [
                          _vm._v(
                            "Two-Factor Authentication enabled with " +
                              _vm._s(_vm._f("mfa_kind")(event.extra1))
                          ),
                        ])
                      : _vm._e(),
                    event.event_type == 2
                      ? _c("span", [
                          _vm._v("Two-Factor Authentication disabled"),
                        ])
                      : _vm._e(),
                    event.event_type == 3
                      ? _c("span", [
                          _vm._v("Additional backup codes generated"),
                        ])
                      : _vm._e(),
                    event.event_type == 4
                      ? _c("span", [
                          _vm._v(
                            "Successful login with " +
                              _vm._s(_vm._f("mfa_kind")(event.extra1))
                          ),
                        ])
                      : _vm._e(),
                    event.event_type == 5
                      ? _c(
                          "span",
                          [
                            _vm._v(
                              "Failed login with " +
                                _vm._s(_vm._f("mfa_kind")(event.extra1)) +
                                " "
                            ),
                            event.extra2
                              ? _c("info-icon", {
                                  attrs: {
                                    content: "IP address: " + event.extra2,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ])
              }),
              0
            ),
          ]),
          _c(
            "div",
            { staticClass: "text-right" },
            [
              _c(
                "div",
                {
                  staticClass: "d-inline-block p-1 m-1 c-pointer",
                  class: { "disabled text-muted": _vm.page <= 1 },
                  on: {
                    click: function ($event) {
                      _vm.page > 1 ? (_vm.page = _vm.page - 1) : false
                    },
                  },
                },
                [_c("i", { staticClass: "ion-chevron-left" })]
              ),
              _vm._l(_vm.range(_vm.total_pages), function (p) {
                return _c(
                  "div",
                  {
                    key: p,
                    staticClass: "d-inline-block px-2 c-pointer",
                    class: { "bg-primary text-white": p == _vm.page },
                    on: {
                      click: () => {
                        _vm.page = p
                      },
                    },
                  },
                  [_vm._v(_vm._s(p))]
                )
              }),
              _c(
                "div",
                {
                  staticClass: "d-inline-block p-1 m-1 c-pointer",
                  class: { "disabled text-muted": _vm.page >= _vm.total_pages },
                  on: {
                    click: function ($event) {
                      _vm.page < _vm.total_pages
                        ? (_vm.page = _vm.page + 1)
                        : false
                    },
                  },
                },
                [_c("i", { staticClass: "ion-chevron-right" })]
              ),
            ],
            2
          ),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "width-0" }, [_vm._v("Timestamp")]),
        _c("th", [_vm._v("Event")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }