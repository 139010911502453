var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "section-container",
      class: { drag_valid: _vm.dropzone_active },
      attrs: { id: "files" },
      on: {
        click: function ($event) {
          return _vm.remove_file_highlight()
        },
      },
    },
    [
      !_vm.disable_upload
        ? _c(
            "div",
            {
              attrs: { id: "dropzone" },
              on: {
                dragenter: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                },
                dragover: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                },
                dragleave: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  _vm.dropzone_active = false
                },
                drop: function ($event) {
                  $event.preventDefault()
                  return _vm.something_dropped.apply(null, arguments)
                },
              },
            },
            [_vm._v(" Drop your file to upload ")]
          )
        : _vm._e(),
      _vm.user &&
      !_vm.user.is_client_space_client &&
      _vm.buckets &&
      _vm.buckets.length == 0
        ? _c(
            "div",
            { staticClass: "container mt-3", staticStyle: { top: "15px" } },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-2 d-none d-md-table-cell" }),
                _c("div", { staticClass: "col-12 col-lg-8" }, [
                  _c(
                    "div",
                    {
                      staticClass: "jumbotron text-white",
                      class: {
                        "bg-gradient-info": _vm.user.is_admin,
                        "bg-gradient-warning": !_vm.user.is_admin,
                      },
                    },
                    [
                      _c("div", { staticClass: "container" }, [
                        _vm.user.is_admin
                          ? _c("section", [
                              _c("h1", [
                                _vm._v("Hello and welcome to SkyFlok!"),
                              ]),
                              _c("h4", { staticClass: "mt-3" }, [
                                _vm._v(
                                  "To get started, first you need to set where your data will be stored."
                                ),
                              ]),
                              _c("h4", { staticClass: "mt-3" }, [
                                _vm._v("Please go to "),
                                _c(
                                  "u",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "text-white",
                                        attrs: { to: "/team-setup" },
                                      },
                                      [_vm._v("Team Setup")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" and set up your account now!"),
                              ]),
                            ])
                          : _c("section", [
                              _c("h4", [
                                _vm._v(
                                  "This Team account is not ready to upload files, please ask one of the Team Administrators to complete the initial setup!"
                                ),
                              ]),
                            ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]
          )
        : _c(
            "div",
            { staticClass: "container-fluid", attrs: { id: "file_list" } },
            [
              _vm.show_clouds_ready_box
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-2" }),
                    _vm._m(0),
                  ])
                : _vm._e(),
              false && _vm.is_admin_folder
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-2" }),
                    _vm._m(1),
                  ])
                : _vm._e(),
              _vm.nav_stack.length > 1
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 breadcrumb" },
                      [
                        _vm._l(
                          _vm.nav_stack.slice(0, _vm.nav_stack.length - 1),
                          function (folder, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "d-inline-block nowrap",
                                on: {
                                  dragover: function ($event) {
                                    $event.preventDefault()
                                    folder.dragover = true
                                  },
                                  dragleave: function ($event) {
                                    $event.preventDefault()
                                    folder.dragover = false
                                  },
                                  drop: function ($event) {
                                    $event.preventDefault()
                                    return _vm.drop($event, folder)
                                  },
                                },
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-flat btn-info btn-sm",
                                    class: { "bg-success": folder.dragover },
                                    attrs: {
                                      type: "button",
                                      title:
                                        "Go back to " +
                                        (folder.id == -1
                                          ? "the root folder"
                                          : folder.name + " directory"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.change_folder_to(folder)
                                      },
                                    },
                                  },
                                  [
                                    folder.id == -1
                                      ? _c("i", {
                                          staticClass: "icon ion-home",
                                        })
                                      : _c(
                                          "span",
                                          {
                                            staticClass:
                                              "d-flex align-items-center",
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "ion-folder mr-2",
                                            }),
                                            _vm._v(
                                              " " + _vm._s(folder.name) + " "
                                            ),
                                          ]
                                        ),
                                  ]
                                ),
                                index < _vm.nav_stack.length - 1
                                  ? _c("i", {
                                      staticClass: "ion-chevron-right v-middle",
                                    })
                                  : _vm._e(),
                              ]
                            )
                          }
                        ),
                        _c("div", { staticClass: "d-inline-block nowrap" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "btn btn-flat btn-sm btn-secondary c-default text-bold",
                              attrs: {
                                title:
                                  "You are in " +
                                  _vm.nav_stack[_vm.nav_stack.length - 1].name +
                                  " directory",
                                disabled: true,
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c("i", { staticClass: "ion-folder mr-2" }),
                                  _vm._v(
                                    _vm._s(
                                      _vm.nav_stack[_vm.nav_stack.length - 1]
                                        .name
                                    ) + " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "row", attrs: { id: "folders" } },
                [
                  _vm._l(_vm.folders, function (folder) {
                    return _c(
                      "div",
                      {
                        key: folder.id,
                        staticClass:
                          "folder-box col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3",
                        class: { dragover: folder.dragover },
                        attrs: { id: `skyflok-folder-${folder.id}` },
                        on: {
                          click: function ($event) {
                            folder.rename ? false : _vm.change_folder_to(folder)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            class: ["cardbox", _vm.get_folderbox_class(folder)],
                            staticStyle: { "border-left": "4px solid" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "cardbox-body",
                                attrs: { title: folder.name },
                              },
                              [
                                !folder.rename
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-start align-items-center",
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "ion-folder mr-2",
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "mb-0 foldername" },
                                          [_vm._v(_vm._s(folder.name))]
                                        ),
                                        !_vm.disable_share ||
                                        !_vm.disable_namespace_ops
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "ml-auto",
                                                attrs: {
                                                  title: "Show folder options",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    $event.stopPropagation()
                                                    return _vm.folderbox_dropdown(
                                                      "folder_" +
                                                        folder.id +
                                                        "_dropdown"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "dropdown btn-group",
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-sm btn-secondary btn-flat mb-0 mt-0 folder-overflow-btn",
                                                        attrs: {
                                                          id:
                                                            "folder_" +
                                                            folder.id +
                                                            "_dropdown",
                                                          type: "button",
                                                          "data-toggle":
                                                            "dropdown",
                                                          "aria-expanded":
                                                            "false",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "ion-more text-gray-light",
                                                        }),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "dropdown-menu dropdown-menu-right mt-2",
                                                        attrs: { role: "menu" },
                                                      },
                                                      [
                                                        !_vm.disable_share &&
                                                        _vm.folder_shares_enabled
                                                          ? _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "dropdown-item",
                                                                attrs: {
                                                                  href: "#",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.open_share_form(
                                                                        folder
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "ion-share icon-lg mr-3",
                                                                }),
                                                                _vm._v(
                                                                  "Share Folder"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        !_vm.disable_namespace_ops
                                                          ? _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "dropdown-item",
                                                                attrs: {
                                                                  href: "#",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.rename_file(
                                                                        folder
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "ion-edit icon-lg mr-3",
                                                                }),
                                                                _vm._v(
                                                                  "Rename"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        !_vm.disable_namespace_ops
                                                          ? _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "dropdown-item",
                                                                attrs: {
                                                                  href: "#",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.copy_move_file(
                                                                        folder,
                                                                        "move_folder"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "ion-forward icon-lg mr-3",
                                                                }),
                                                                _vm._v("Move"),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        !_vm.disable_namespace_ops
                                                          ? _c("div", {
                                                              staticClass:
                                                                "dropdown-divider",
                                                            })
                                                          : _vm._e(),
                                                        !_vm.disable_namespace_ops
                                                          ? _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "dropdown-item text-danger",
                                                                attrs: {
                                                                  href: "#",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.delete_file(
                                                                        folder
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm.deleteAction
                                                                  ? _c("i", {
                                                                      class: [
                                                                        _vm
                                                                          .deleteAction
                                                                          .icon,
                                                                        "icon-lg mr-3",
                                                                      ],
                                                                    })
                                                                  : _c("i", {
                                                                      staticClass:
                                                                        "ion-trash-a icon-lg mr-3",
                                                                    }),
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.deleteAction
                                                                      ? _vm
                                                                          .deleteAction
                                                                          .label
                                                                      : "Delete"
                                                                  ) + " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _c("div", { staticClass: "nowrap" }, [
                                      _c(
                                        "form",
                                        {
                                          on: {
                                            submit: function ($event) {
                                              $event.preventDefault()
                                              return _vm.rename_submit(folder)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "input-group" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: folder.rename.name,
                                                    expression:
                                                      "folder.rename.name",
                                                  },
                                                ],
                                                staticClass:
                                                  "form-control form-control-sm",
                                                attrs: {
                                                  id:
                                                    "file-rename-" + folder.id,
                                                  type: "text",
                                                },
                                                domProps: {
                                                  value: folder.rename.name,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      folder.rename,
                                                      "name",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                              !folder.rename.loading
                                                ? _c("span", [
                                                    _vm._m(2, true),
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-sm btn-warning btn-gradient fh",
                                                        attrs: {
                                                          type: "button",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            $event.stopPropagation()
                                                            folder.rename = false
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "ion-close-round",
                                                        }),
                                                      ]
                                                    ),
                                                  ])
                                                : _c("i", {
                                                    staticClass:
                                                      "ion-load-c spin",
                                                  }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      folder.rename.error
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-sm text-danger",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(folder.rename.error)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  !_vm.disable_namespace_ops
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "folder-box add_folder col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "cardbox text-white bg-gradient-secondary",
                            },
                            [
                              !_vm.new_folder_mode
                                ? _c("div", { staticClass: "cardbox-body" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-start align-items-center nowrap",
                                        on: {
                                          click: function ($event) {
                                            return _vm.new_folder_form(true)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "ion-plus mr-2",
                                        }),
                                        _c("p", { staticClass: "mb-0" }, [
                                          _vm._v("Create new folder"),
                                        ]),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.new_folder_mode
                                ? _c("div", { staticClass: "cardbox-body" }, [
                                    _c(
                                      "form",
                                      {
                                        on: {
                                          submit: function ($event) {
                                            $event.preventDefault()
                                            return _vm.create_folder(
                                              _vm.new_folder_name
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "input-group" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.new_folder_name,
                                                  expression: "new_folder_name",
                                                },
                                              ],
                                              staticClass:
                                                "form-control form-control-sm",
                                              attrs: {
                                                type: "text",
                                                id: "new_folder_name_input",
                                              },
                                              domProps: {
                                                value: _vm.new_folder_name,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.new_folder_name =
                                                    $event.target.value
                                                },
                                              },
                                            }),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-sm btn-success btn-gradient ml-1",
                                                attrs: {
                                                  type: "submit",
                                                  disabled:
                                                    _vm.new_folder_loading,
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  class: {
                                                    "ion-checkmark-round":
                                                      !_vm.new_folder_loading,
                                                    "ion-load-c spin":
                                                      _vm.new_folder_loading,
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-sm btn-warning btn-gradient ml-1",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.new_folder_form(
                                                      false
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "ion-close-round",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
              _c("div", { staticClass: "row", attrs: { id: "files" } }, [
                _vm.files.length > 0
                  ? _c("div", { staticClass: "col-12" }, [
                      _c("div", { staticClass: "cardbox" }, [
                        _c("div", { staticClass: "cardbox-heading" }, [
                          _c("div", { staticClass: "float-left filters" }, [
                            _vm.list_filters.types.length > 0
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "btn btn-oval bg-blue-500 mx-1 my-1",
                                  },
                                  [
                                    _vm._v(
                                      "Type: " +
                                        _vm._s(
                                          _vm._f("join")(_vm.list_filters.types)
                                        ) +
                                        " "
                                    ),
                                    _c("i", {
                                      staticClass: "ion-close-round ml-2",
                                      on: {
                                        click: function ($event) {
                                          _vm.list_filters.types = []
                                        },
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "float-right text-right action-buttons",
                            },
                            [
                              false
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-secondary btn-gradient mx-1 my-1 d-none d-xl-inline-block",
                                      attrs: {
                                        "data-toggle": "modal",
                                        "data-target": "#filter-modal",
                                        "data-backdrop": "",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "ion-funnel mr-1 icon-lg",
                                      }),
                                      _vm._v(" Filters "),
                                    ]
                                  )
                                : _vm._e(),
                              !(_vm.disable_download || _vm.is_mobile)
                                ? _c("span", [
                                    _vm.files_multiselect_on
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "mr-2 text-sm",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                _vm.files_multiselect_on = false
                                              },
                                            },
                                          },
                                          [_vm._v("Cancel")]
                                        )
                                      : _vm._e(),
                                    !_vm.files_multiselect_on
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-success btn-gradient mx-1 my-1",
                                            on: {
                                              click: function ($event) {
                                                _vm.files_multiselect_on = true
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "ion-arrow-down-a mr-1 icon-lg",
                                            }),
                                            _vm._v(" Files "),
                                          ]
                                        )
                                      : !_vm.files_multidownload_in_progress
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-skyflok mx-1 my-1",
                                            on: {
                                              click: function ($event) {
                                                return _vm.download_all_selected()
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "ion-arrow-down-a mr-1 icon-lg",
                                            }),
                                            _vm._v(
                                              " Download " +
                                                _vm._s(
                                                  _vm.selected_files.length
                                                ) +
                                                " files "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-danger mx-1 my-1",
                                            on: {
                                              click: function ($event) {
                                                return _vm.download_all_cancel()
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "ion-close mr-1 icon-lg",
                                            }),
                                            _vm._v(" Cancel "),
                                          ]
                                        ),
                                  ])
                                : _vm._e(),
                              !_vm.disable_upload
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-primary btn-gradient mx-1 my-1",
                                      on: {
                                        click: function ($event) {
                                          return _vm.file_upload_el.click()
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "ion-arrow-up-a mr-1 icon-lg",
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.is_mobile ? "Upload" : "Files"
                                          )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              !_vm.disable_upload && !_vm.is_mobile
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-info btn-gradient mx-1 my-1",
                                      on: {
                                        click: function ($event) {
                                          return _vm.folder_upload_el.click()
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "ion-arrow-up-a mr-1 icon-lg",
                                      }),
                                      _vm._v(" Folder"),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "file-list" }, [
                          _c(
                            "table",
                            { staticClass: "table table-hover" },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _vm.files_multiselect_on
                                    ? _c(
                                        "th",
                                        { staticClass: "width-0" },
                                        [
                                          _c("checkbox", {
                                            staticClass: "mr-0",
                                            attrs: {
                                              value: _vm.files_select_all,
                                            },
                                            on: {
                                              changed: (newval) =>
                                                (_vm.files_select_all = newval),
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("th", { staticClass: "width-0" }),
                                  _c(
                                    "th",
                                    { staticClass: "text-left nowrap" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "sort_header",
                                          on: {
                                            click: function ($event) {
                                              return _vm.sort_by("name")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v("Filename "),
                                          _vm.list_sort.attr === "name"
                                            ? _c("i", {
                                                staticClass:
                                                  "text-gray-light ml-1",
                                                class: {
                                                  "ion-arrow-up-c":
                                                    _vm.list_sort.asc,
                                                  "ion-arrow-down-c":
                                                    !_vm.list_sort.asc,
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "d-none d-md-table-cell nowrap",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "sort_header",
                                          on: {
                                            click: function ($event) {
                                              return _vm.sort_by("extension")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v("Type "),
                                          _vm.list_sort.attr === "extension"
                                            ? _c("i", {
                                                staticClass:
                                                  "text-gray-light ml-1",
                                                class: {
                                                  "ion-arrow-up-c":
                                                    _vm.list_sort.asc,
                                                  "ion-arrow-down-c":
                                                    !_vm.list_sort.asc,
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "d-none d-xl-table-cell nowrap",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "sort_header",
                                          on: {
                                            click: function ($event) {
                                              return _vm.sort_by("size")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v("Size "),
                                          _vm.list_sort.attr === "size"
                                            ? _c("i", {
                                                staticClass:
                                                  "text-gray-light ml-1",
                                                class: {
                                                  "ion-arrow-up-c":
                                                    _vm.list_sort.asc,
                                                  "ion-arrow-down-c":
                                                    !_vm.list_sort.asc,
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "d-none d-md-table-cell nowrap",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "sort_header",
                                          on: {
                                            click: function ($event) {
                                              return _vm.sort_by(
                                                "last_modified"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v("Uploaded "),
                                          _vm.list_sort.attr === "last_modified"
                                            ? _c("i", {
                                                staticClass:
                                                  "text-gray-light ml-1",
                                                class: {
                                                  "ion-arrow-up-c":
                                                    _vm.list_sort.asc,
                                                  "ion-arrow-down-c":
                                                    !_vm.list_sort.asc,
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.are_files_with_multiple_versions
                                    ? _c(
                                        "th",
                                        {
                                          staticClass:
                                            "d-none d-xl-table-cell nowrap",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "sort_header",
                                              attrs: {
                                                title:
                                                  "Number of previous versions",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.sort_by(
                                                    "versions_num"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v("Versions "),
                                              _vm.list_sort.attr ===
                                              "versions_num"
                                                ? _c("i", {
                                                    staticClass:
                                                      "text-gray-light ml-1",
                                                    class: {
                                                      "ion-arrow-up-c":
                                                        _vm.list_sort.asc,
                                                      "ion-arrow-down-c":
                                                        !_vm.list_sort.asc,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.disable_share
                                    ? _c(
                                        "th",
                                        {
                                          staticClass:
                                            "d-none d-xl-table-cell nowrap",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "sort_header",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.sort_by(
                                                    "shares_num"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v("Shares "),
                                              _vm.list_sort.attr ===
                                              "shares_num"
                                                ? _c("i", {
                                                    staticClass:
                                                      "text-gray-light ml-1",
                                                    class: {
                                                      "ion-arrow-up-c":
                                                        _vm.list_sort.asc,
                                                      "ion-arrow-down-c":
                                                        !_vm.list_sort.asc,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.disable_download
                                    ? _c("th", {
                                        staticClass:
                                          "d-none d-md-table-cell width-0",
                                      })
                                    : _vm._e(),
                                  _c("th", { staticClass: "width-0" }),
                                ]),
                              ]),
                              _vm._l(_vm.files, function (file) {
                                return _c(
                                  "tbody",
                                  {
                                    key: file.id,
                                    class: { open: file.show_details },
                                    attrs: { id: `skyflok-file-${file.id}` },
                                  },
                                  [
                                    _c(
                                      "tr",
                                      {
                                        staticClass: "file-row",
                                        class: {
                                          "text-gray-light": file.loading,
                                          highlighted: file.highlighted,
                                          "bg-cyan-50": file.selected,
                                        },
                                        attrs: { id: "file-row-" + file.id },
                                        on: {
                                          click: (evt) => {
                                            if (_vm.files_multiselect_on) {
                                              file.selected = !file.selected
                                              evt.stopPropagation()
                                            }
                                          },
                                        },
                                      },
                                      [
                                        _vm.files_multiselect_on
                                          ? _c(
                                              "td",
                                              { staticClass: "width-0" },
                                              [
                                                _c("checkbox", {
                                                  staticClass: "mr-0",
                                                  attrs: {
                                                    value: file.selected,
                                                  },
                                                  on: {
                                                    changed: (newval) =>
                                                      (file.selected = newval),
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c("td", [
                                          _c("img", {
                                            staticClass: "file-icon",
                                            attrs: {
                                              src: _vm._f("filetype_img_src")(
                                                file.extension
                                              ),
                                            },
                                          }),
                                        ]),
                                        _c("td", { staticClass: "text-left" }, [
                                          !_vm.disable_download
                                            ? _c("span", [
                                                !file.rename
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-bold filename",
                                                        attrs: {
                                                          title:
                                                            "Click to download " +
                                                            file.name,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "file_download_link",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.download_file(
                                                                  file
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(file.name)
                                                            ),
                                                            !file.loading
                                                              ? _c("i", {
                                                                  staticClass:
                                                                    "file-download-icon icon ion-arrow-down-a",
                                                                })
                                                              : _vm._e(),
                                                            file.loading
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "ml-2",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "(" +
                                                                        _vm._s(
                                                                          Math.round(
                                                                            file.loading
                                                                          )
                                                                        ) +
                                                                        "%)"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "div",
                                                      { staticClass: "nowrap" },
                                                      [
                                                        _c(
                                                          "form",
                                                          {
                                                            on: {
                                                              submit: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.rename_submit(
                                                                  file
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    file.rename
                                                                      .name,
                                                                  expression:
                                                                    "file.rename.name",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control form-control-sm d-inline-block",
                                                              staticStyle: {
                                                                width:
                                                                  "calc(100% - 60px)",
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                                id:
                                                                  "file-rename-" +
                                                                  file.id,
                                                              },
                                                              domProps: {
                                                                value:
                                                                  file.rename
                                                                    .name,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      file.rename,
                                                                      "name",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                            !file.rename.loading
                                                              ? _c("span", [
                                                                  _vm._m(
                                                                    3,
                                                                    true
                                                                  ),
                                                                  _c(
                                                                    "button",
                                                                    {
                                                                      staticClass:
                                                                        "btn btn-sm btn-warning btn-gradient",
                                                                      attrs: {
                                                                        type: "button",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            file.rename = false
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "ion-close-round",
                                                                      }),
                                                                    ]
                                                                  ),
                                                                ])
                                                              : _c("i", {
                                                                  staticClass:
                                                                    "ion-load-c spin",
                                                                }),
                                                          ]
                                                        ),
                                                        file.rename.error
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-sm text-danger",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    file.rename
                                                                      .error
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                              ])
                                            : _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-muted text-bold",
                                                  staticStyle: {
                                                    cursor: "not-allowed",
                                                  },
                                                  attrs: { title: file.name },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(file.name) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                        ]),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "d-none d-md-table-cell",
                                          },
                                          [
                                            file.extension
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-sm text-upper",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(file.extension)
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "span",
                                                  { staticClass: "text-muted" },
                                                  [_vm._v("-")]
                                                ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "d-none d-xl-table-cell",
                                          },
                                          [
                                            !file.size
                                              ? _c("div", [
                                                  _c("i", {
                                                    staticClass:
                                                      "ion-load-c spin text-muted",
                                                  }),
                                                ])
                                              : _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-muted text-sm",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("format_bytes")(
                                                          file.size
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "d-none d-md-table-cell width-0",
                                          },
                                          [
                                            file.last_modified_by === undefined
                                              ? _c("div", [
                                                  _c("i", {
                                                    staticClass:
                                                      "ion-load-c spin text-muted",
                                                  }),
                                                ])
                                              : _c(
                                                  "span",
                                                  { staticClass: "text-sm" },
                                                  [
                                                    _vm.user && _vm.user.team
                                                      ? _c("modified-box", {
                                                          attrs: {
                                                            "user-id":
                                                              file.last_modified_by,
                                                            team: _vm.user.team,
                                                            timestamp:
                                                              file.last_modified,
                                                            pullRight: true,
                                                            "external-actor":
                                                              _vm.modifiedBoxExternal,
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                          ]
                                        ),
                                        _vm.are_files_with_multiple_versions
                                          ? _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "d-none d-xl-table-cell",
                                              },
                                              [
                                                file.versions_num === undefined
                                                  ? _c("div", [
                                                      _c("i", {
                                                        staticClass:
                                                          "ion-load-c spin text-muted",
                                                      }),
                                                    ])
                                                  : _c(
                                                      "a",
                                                      {
                                                        staticStyle: {
                                                          color: "inherit",
                                                        },
                                                        attrs: {
                                                          href: "#",
                                                          title:
                                                            "List all versions",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            file.show_details ==
                                                            "versions"
                                                              ? (file.show_details = false)
                                                              : (file.show_details =
                                                                  "versions")
                                                          },
                                                        },
                                                      },
                                                      [
                                                        file.versions_num > 1
                                                          ? _c(
                                                              "span",
                                                              {
                                                                attrs: {
                                                                  "data-toggle":
                                                                    "popover",
                                                                  "data-placement":
                                                                    "top",
                                                                  "data-content":
                                                                    "<center>There " +
                                                                    (file.versions_num >
                                                                    2
                                                                      ? "are"
                                                                      : "is") +
                                                                    " <b>" +
                                                                    (file.versions_num -
                                                                      1) +
                                                                    " previous version" +
                                                                    (file.versions_num >
                                                                    2
                                                                      ? "s"
                                                                      : "") +
                                                                    "</b> of <i>" +
                                                                    file.name +
                                                                    "</i><br/>Click to see details.</center>",
                                                                  "data-html":
                                                                    "true",
                                                                  "data-trigger":
                                                                    "hover",
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "ion-ios-copy-outline icon-lg mr-2",
                                                                }),
                                                                _vm._v(
                                                                  _vm._s(
                                                                    file.versions_num -
                                                                      1
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-muted",
                                                              },
                                                              [_vm._v("-")]
                                                            ),
                                                      ]
                                                    ),
                                              ]
                                            )
                                          : _vm._e(),
                                        !_vm.disable_share
                                          ? _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "d-none d-xl-table-cell",
                                              },
                                              [
                                                file.shares_num === undefined
                                                  ? _c("div", [
                                                      _c("i", {
                                                        staticClass:
                                                          "ion-load-c spin text-muted",
                                                      }),
                                                    ])
                                                  : file.shares_num > 0
                                                  ? _c(
                                                      "a",
                                                      {
                                                        staticStyle: {
                                                          color: "inherit",
                                                        },
                                                        attrs: {
                                                          href: "#",
                                                          title:
                                                            "List shares of this file",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            file.show_details ==
                                                            "shares"
                                                              ? (file.show_details = false)
                                                              : (file.show_details =
                                                                  "shares")
                                                          },
                                                        },
                                                      },
                                                      [
                                                        file.shares_num > 0
                                                          ? _c(
                                                              "span",
                                                              {
                                                                attrs: {
                                                                  "data-toggle":
                                                                    "popover",
                                                                  "data-placement":
                                                                    "top",
                                                                  "data-content":
                                                                    file.shares_num +
                                                                    " active share" +
                                                                    (file.shares_num ==
                                                                    1
                                                                      ? ""
                                                                      : "s") +
                                                                    "<br/>(click to see details)",
                                                                  "data-html":
                                                                    "true",
                                                                  "data-trigger":
                                                                    "hover",
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "ion-link icon-lg mr-2",
                                                                }),
                                                                _vm._v(
                                                                  _vm._s(
                                                                    file.shares_num
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "c-pointer text-muted",
                                                        attrs: {
                                                          title:
                                                            "Share this file",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.open_share_form(
                                                              file
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "ion-plus-round",
                                                        }),
                                                      ]
                                                    ),
                                              ]
                                            )
                                          : _vm._e(),
                                        !_vm.disable_download
                                          ? _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "d-none d-md-table-cell",
                                              },
                                              [
                                                file.cancel
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn text-sm btn-danger btn-flat",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return file.cancel()
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" CANCEL "),
                                                        _c("i", {
                                                          staticClass:
                                                            "ion-close-round ml-1",
                                                        }),
                                                      ]
                                                    )
                                                  : _c("section", [
                                                      file.viewer && file.id > 0
                                                        ? _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn text-sm btn-flat",
                                                              class: [
                                                                file.viewer
                                                                  .btn_class,
                                                              ],
                                                              attrs: {
                                                                title:
                                                                  "Open with " +
                                                                  file.viewer
                                                                    .label,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.open_file(
                                                                      file
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  file.viewer
                                                                    .verb
                                                                ) + " "
                                                              ),
                                                              _c("i", {
                                                                staticClass:
                                                                  "icon-lg ml-2",
                                                                class:
                                                                  file.viewer
                                                                    .icon_class,
                                                              }),
                                                            ]
                                                          )
                                                        : _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn btn-secondary btn-flat disabled",
                                                              attrs: {
                                                                disabled: "",
                                                                title:
                                                                  "Cannot preview " +
                                                                  (file.extension
                                                                    ? file.extension.toUpperCase()
                                                                    : "these types of ") +
                                                                  " files yet :(",
                                                              },
                                                            },
                                                            [_vm._v("-")]
                                                          ),
                                                    ]),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c("td", [
                                          !_vm.files_multiselect_on &&
                                          file.id > 0
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "dropdown btn-group",
                                                },
                                                [
                                                  _vm._m(4, true),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "dropdown-menu dropdown-menu-right",
                                                      attrs: { role: "menu" },
                                                    },
                                                    [
                                                      !_vm.disable_share
                                                        ? _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "dropdown-item",
                                                              attrs: {
                                                                href: "#",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.open_share_form(
                                                                      file
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "ion-share icon-lg mr-3",
                                                              }),
                                                              _vm._v("Share"),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      !_vm.disable_download
                                                        ? _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "dropdown-item",
                                                              attrs: {
                                                                href: "#",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.download_file(
                                                                      file
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "ion-arrow-down-a icon-lg mr-3",
                                                              }),
                                                              _vm._v(
                                                                "Download"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      !_vm.disable_namespace_ops
                                                        ? _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "dropdown-item",
                                                              attrs: {
                                                                href: "#",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.rename_file(
                                                                      file
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "ion-edit icon-lg mr-3",
                                                              }),
                                                              _vm._v("Rename"),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      !_vm.disable_namespace_ops
                                                        ? _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "dropdown-item",
                                                              attrs: {
                                                                href: "#",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.copy_move_file(
                                                                      file,
                                                                      "move_file"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "ion-forward icon-lg mr-3",
                                                              }),
                                                              _vm._v("Move"),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      !_vm.disable_namespace_ops
                                                        ? _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "dropdown-item",
                                                              attrs: {
                                                                href: "#",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.copy_move_file(
                                                                      file,
                                                                      "copy_file"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "ion-plus-circled icon-lg mr-3",
                                                              }),
                                                              _vm._v(
                                                                "Duplicate"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "dropdown-item",
                                                          attrs: { href: "#" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              _vm.is_mobile
                                                                ? _vm.open_file_info_modal(
                                                                    file
                                                                  )
                                                                : (file.show_details =
                                                                    "info")
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "ion-information-circled icon-lg mr-3",
                                                          }),
                                                          _vm._v("File info"),
                                                        ]
                                                      ),
                                                      !_vm.disable_namespace_ops
                                                        ? _c("div", {
                                                            staticClass:
                                                              "dropdown-divider",
                                                          })
                                                        : _vm._e(),
                                                      !_vm.disable_namespace_ops
                                                        ? _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "dropdown-item text-danger",
                                                              attrs: {
                                                                href: "#",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.delete_file(
                                                                      file
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm.deleteAction &&
                                                              _vm.deleteAction
                                                                .icon
                                                                ? _c("i", {
                                                                    class: [
                                                                      _vm
                                                                        .deleteAction
                                                                        .icon,
                                                                      "icon-lg mr-3",
                                                                    ],
                                                                  })
                                                                : _c("i", {
                                                                    staticClass:
                                                                      "ion-trash-a icon-lg mr-3",
                                                                  }),
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.deleteAction
                                                                    ? _vm
                                                                        .deleteAction
                                                                        .label
                                                                    : "Delete"
                                                                ) + " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]
                                    ),
                                    file.loading
                                      ? _c(
                                          "tr",
                                          { staticClass: "file-loading" },
                                          [
                                            _c(
                                              "td",
                                              { attrs: { colspan: "9" } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "progress" },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "progress-bar progress-bar-striped progress-bar-animated",
                                                      style: {
                                                        width:
                                                          Math.round(
                                                            file.loading
                                                          ) + "%",
                                                      },
                                                      attrs: {
                                                        role: "progressbar",
                                                        "aria-valuenow":
                                                          file.loading,
                                                        "aria-valuemin": "0",
                                                        "aria-valuemax": "100",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "tr",
                                      {
                                        staticClass: "file-details-row",
                                        class: { open: file.show_details },
                                      },
                                      [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "file-details-cell",
                                            attrs: { colspan: "9" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "float-right" },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "btn btn-secondary btn-flat btn-sm text-sm",
                                                    attrs: {
                                                      title: "Close info panel",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        file.show_details = false
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(" Close "),
                                                    _c("i", {
                                                      staticClass:
                                                        "ion-close-round ml-2",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "ul",
                                              {
                                                staticClass:
                                                  "nav nav-pills mb-4 justify-content-center",
                                                attrs: { role: "tablist" },
                                              },
                                              [
                                                _c(
                                                  "li",
                                                  {
                                                    staticClass: "nav-item btn",
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass: "nav-link",
                                                        class: {
                                                          active:
                                                            file.show_details ==
                                                            "versions",
                                                        },
                                                        attrs: {
                                                          "data-toggle": "tab",
                                                          href:
                                                            "#file_" +
                                                            file.id +
                                                            "_versions",
                                                          role: "tab",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            file.show_details =
                                                              "versions"
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Version History"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                !_vm.disable_share
                                                  ? _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "nav-item btn",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "nav-link",
                                                            class: {
                                                              active:
                                                                file.show_details ==
                                                                "shares",
                                                            },
                                                            attrs: {
                                                              "data-toggle":
                                                                "tab",
                                                              href:
                                                                "#file_" +
                                                                file.id +
                                                                "_shares",
                                                              role: "tab",
                                                              "aria-expanded":
                                                                "true",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                file.show_details =
                                                                  "shares"
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Sharing Status"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "li",
                                                  {
                                                    staticClass: "nav-item btn",
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass: "nav-link",
                                                        class: {
                                                          active:
                                                            file.show_details ==
                                                            "info",
                                                        },
                                                        attrs: {
                                                          "data-toggle": "tab",
                                                          href:
                                                            "#file_" +
                                                            file.id +
                                                            "_info",
                                                          role: "tab",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            file.show_details =
                                                              "info"
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "File Information"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "tab-content" },
                                              [
                                                !_vm.disable_share
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass: "tab-pane",
                                                        class: {
                                                          "show active":
                                                            file.show_details ==
                                                            "shares",
                                                        },
                                                        attrs: {
                                                          id:
                                                            "file_" +
                                                            file.id +
                                                            "_shares",
                                                          role: "tabpanel",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-center",
                                                          },
                                                          [
                                                            file.show_details ==
                                                            "shares"
                                                              ? _c(
                                                                  "file-sharing-status",
                                                                  {
                                                                    attrs: {
                                                                      user: _vm.user,
                                                                      file: file,
                                                                      shares_updated:
                                                                        (
                                                                          shares
                                                                        ) => {
                                                                          file.shares =
                                                                            shares
                                                                        },
                                                                    },
                                                                  }
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "tab-pane",
                                                    class: {
                                                      "show active":
                                                        file.show_details ==
                                                        "versions",
                                                    },
                                                    attrs: {
                                                      id:
                                                        "file_" +
                                                        file.id +
                                                        "_versions",
                                                      role: "tabpanel",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "fw text-center",
                                                      },
                                                      [
                                                        file.show_details ==
                                                        "versions"
                                                          ? _c(
                                                              "file-versions-table",
                                                              {
                                                                attrs: {
                                                                  file: file,
                                                                  user: _vm.user,
                                                                  modifiedBoxExternal:
                                                                    _vm.modifiedBoxExternal,
                                                                  open_file_fn:
                                                                    _vm.disable_download
                                                                      ? null
                                                                      : _vm.open_file,
                                                                  download_file_fn:
                                                                    _vm.disable_download
                                                                      ? null
                                                                      : _vm.download_file,
                                                                  versions_num_changed:
                                                                    (num) => {
                                                                      file.versions_num =
                                                                        num
                                                                    },
                                                                },
                                                              }
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "tab-pane",
                                                    class: {
                                                      "show active":
                                                        file.show_details ==
                                                        "info",
                                                    },
                                                    attrs: {
                                                      id:
                                                        "file_" +
                                                        file.id +
                                                        "_info",
                                                      role: "tabpanel",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "table",
                                                      {
                                                        staticClass:
                                                          "table file-details-table",
                                                        staticStyle: {
                                                          width: "auto",
                                                          margin: "0px auto",
                                                        },
                                                      },
                                                      [
                                                        _c("tbody", [
                                                          _c("tr", [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-bold",
                                                              },
                                                              [_vm._v("Name")]
                                                            ),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  file.name
                                                                )
                                                              ),
                                                            ]),
                                                          ]),
                                                          _c("tr", [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-bold",
                                                              },
                                                              [_vm._v("Size")]
                                                            ),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "format_bytes"
                                                                  )(file.size)
                                                                ) + " "
                                                              ),
                                                              _c("small", [
                                                                _vm._v(
                                                                  "(" +
                                                                    _vm._s(
                                                                      Number(
                                                                        file.size
                                                                      ).toLocaleString()
                                                                    ) +
                                                                    " bytes)"
                                                                ),
                                                              ]),
                                                            ]),
                                                          ]),
                                                          _c("tr", [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-bold",
                                                              },
                                                              [_vm._v("Type")]
                                                            ),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  file.mime_type
                                                                )
                                                              ),
                                                            ]),
                                                          ]),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm.files && _vm.files.length == 0 && !_vm.loading_filelist
                ? _c("section", [
                    _vm.disable_upload
                      ? _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-4" }),
                          _c(
                            "div",
                            { staticClass: "col-12 col-lg-4" },
                            [
                              _c("hero-box", {
                                attrs: {
                                  "bg-class": "bg-gradient-secondary",
                                  icon: "ion-qr-scanner",
                                  text: "Empty directory",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-1" }),
                          _c(
                            "div",
                            { staticClass: "col-12 col-lg-5" },
                            [
                              _c("hero-box", {
                                attrs: {
                                  "bg-class": "bg-gradient-primary",
                                  icon: "ion-ios-copy-outline",
                                  text: "Upload files",
                                  clicked: () => {
                                    _vm.file_upload_el.click()
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12 col-lg-5" },
                            [
                              _c("hero-box", {
                                attrs: {
                                  "bg-class": "bg-gradient-info",
                                  icon: "ion-ios-folder-outline",
                                  text: "Upload a complete folder",
                                  clicked: () => {
                                    _vm.folder_upload_el.click()
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                  ])
                : _vm._e(),
              _vm.namespace && _vm.namespace.length == 0 && _vm.loading_filelist
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-3" }),
                    _c(
                      "div",
                      { staticClass: "col-12 col-lg-6" },
                      [
                        _c("hero-box", {
                          attrs: {
                            "bg-class": "bg-gradient-primary",
                            icon: "loader d-block",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          ),
      _c(
        "div",
        { staticClass: "modal share-modal", attrs: { id: "share-modal" } },
        [
          !_vm.disable_share &&
          _vm.entities_to_share &&
          _vm.entities_to_share.length > 0
            ? _c("div", { staticClass: "modal-dialog modal-lg" }, [
                _c("div", { staticClass: "modal-content" }, [
                  _c(
                    "div",
                    { staticClass: "modal-header bg-gradient-info text-white" },
                    [
                      _c("h5", { staticClass: "mt-0 modal-title" }, [
                        _vm._v("Create Share"),
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "close text-white",
                          attrs: { type: "button", "aria-label": "Close" },
                          on: {
                            click: function ($event) {
                              return _vm.close_modal("share-modal")
                            },
                          },
                        },
                        [_c("span", [_vm._v("×")])]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "modal-body" },
                    [
                      _c("share-form", {
                        attrs: {
                          entities: _vm.entities_to_share,
                          teamSettings: _vm.user && _vm.user.team_settings,
                          "share-created": _vm.share_created,
                          close: _vm.create_share_ready,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "modal modal-right fade",
          attrs: { id: "filter-modal" },
        },
        [
          _c("div", { staticClass: "modal-dialog modal-lg" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(5),
              _c("div", { staticClass: "modal-body" }, [
                _c("p", [_vm._v("File types")]),
                _c(
                  "div",
                  { staticClass: "setting-color" },
                  _vm._l(_vm.file_types, function (ext, idx) {
                    return _c(
                      "span",
                      {
                        key: idx,
                        staticClass:
                          "btn btn-sm multi_select_btn rounded d-inline-block",
                        class: {
                          "bg-blue-500":
                            _vm.list_filters.types.indexOf(ext) >= 0,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toggle_filter("types", ext)
                          },
                        },
                      },
                      [_vm._v(_vm._s(ext))]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "modal", attrs: { id: "viewer-modal" } }, [
        _vm.view_file
          ? _c(
              "div",
              {
                staticClass: "modal-dialog modal-lg",
                staticStyle: { "max-width": "80%" },
              },
              [
                _c("div", { staticClass: "modal-content" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "modal-header bg-gradient-primary text-white",
                    },
                    [
                      _c("h5", { staticClass: "mt-0 modal-title" }, [
                        _c("b", [_vm._v(_vm._s(_vm.view_file.file.name))]),
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "close text-white",
                          attrs: { type: "button", "aria-label": "Close" },
                          on: {
                            click: () => {
                              if (
                                _vm.view_file.file &&
                                _vm.view_file.file.cancel
                              ) {
                                _vm.view_file.file.cancel()
                              }
                              _vm.view_file.file.loading = false
                              _vm.view_file = null
                              _vm.close_modal("viewer-modal")
                            },
                          },
                        },
                        [_c("span", [_vm._v("×")])]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "modal-body" },
                    [
                      _c("file-preview", {
                        attrs: {
                          file: _vm.view_file.file,
                          version_id: _vm.view_file.version_id,
                          file_list: _vm.view_file.file_list,
                        },
                        on: {
                          close: () => {
                            if (
                              _vm.view_file.file &&
                              _vm.view_file.file.cancel
                            ) {
                              _vm.view_file.file.cancel()
                            }
                            _vm.view_file.file.loading = false
                            _vm.view_file = null
                            _vm.close_modal("viewer-modal")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "modal", attrs: { id: "move-copy-modal" } }, [
        _vm.show_folder_tree
          ? _c(
              "div",
              {
                staticClass: "modal-dialog modal-lg",
                staticStyle: { "max-height": "90%", overflow: "auto" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "modal-content",
                    staticStyle: { border: "none" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "modal-header bg-gradient-info text-white",
                      },
                      [
                        _c("h5", { staticClass: "mt-0 modal-title" }, [
                          _vm._v(
                            _vm._s(
                              _vm.show_folder_tree.action.startsWith("copy")
                                ? "Copy"
                                : "Move"
                            ) + " "
                          ),
                          _c("b", [
                            _vm._v(_vm._s(_vm.show_folder_tree.file.name)),
                          ]),
                        ]),
                        _c(
                          "button",
                          {
                            staticClass: "close text-white",
                            attrs: { type: "button", "aria-label": "Close" },
                            on: {
                              click: function ($event) {
                                _vm.show_folder_tree = null
                                _vm.close_modal("move-copy-modal")
                              },
                            },
                          },
                          [_c("span", [_vm._v("×")])]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "modal-body" },
                      [
                        _c("namespace-tree", {
                          attrs: {
                            action: _vm.show_folder_tree.action,
                            "target-file": _vm.show_folder_tree.file,
                            is_user_admin: _vm.user.is_admin,
                            is_client_space_client:
                              _vm.user.is_client_space_client,
                          },
                          on: {
                            ready: (operation) => {
                              _vm.copy_move_ready(
                                _vm.show_folder_tree.file,
                                operation
                              )
                            },
                            close: function ($event) {
                              _vm.close_modal("move-copy-modal")
                              _vm.show_folder_tree = null
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "modal", attrs: { id: "file-info-modal" } }, [
        _vm.info_modal_file
          ? _c(
              "div",
              {
                staticClass: "modal-dialog modal-lg",
                staticStyle: { "max-height": "90%", overflow: "auto" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "modal-content",
                    staticStyle: { border: "none" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "modal-header bg-gradient-info text-white",
                      },
                      [
                        _c("h5", { staticClass: "mt-0 modal-title" }, [
                          _c("img", {
                            staticClass: "file-icon mr-1",
                            attrs: {
                              src: _vm._f("filetype_img_src")(
                                _vm.info_modal_file.extension
                              ),
                            },
                          }),
                          _c("b", [_vm._v(_vm._s(_vm.info_modal_file.name))]),
                        ]),
                        _c(
                          "button",
                          {
                            staticClass: "close text-white",
                            attrs: { type: "button", "aria-label": "Close" },
                            on: {
                              click: function ($event) {
                                return _vm.close_modal("file-info-modal")
                              },
                            },
                          },
                          [_c("span", [_vm._v("×")])]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "modal-body" },
                      [
                        _c("file-info-modal", {
                          attrs: {
                            file: _vm.info_modal_file,
                            hide_shares: _vm.disable_share,
                            modifiedBoxExternal: _vm.modifiedBoxExternal,
                            download_file_fn: _vm.disable_download
                              ? null
                              : _vm.download_file,
                            user: _vm.user,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]),
      _c("input", {
        staticStyle: { display: "none" },
        attrs: { type: "file", id: "file_upload", multiple: "" },
        on: { change: _vm.files_selected },
      }),
      _c("input", {
        staticStyle: { display: "none" },
        attrs: { type: "file", id: "folder_upload", webkitdirectory: "" },
        on: { change: _vm.folder_selected },
      }),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 col-lg-8" }, [
      _c("div", { staticClass: "cardbox text-white bg-gradient-success b0" }, [
        _c(
          "div",
          { staticClass: "cardbox-body text-center text-bold text-12" },
          [
            _vm._v(" Congratulations, your SkyFlok account is fully set up! "),
            _c("br"),
            _vm._v(
              "Upload some files by clicking on the large blue button below, "
            ),
            _c("br"),
            _vm._v("or drag and drop them into the window! "),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 col-lg-8" }, [
      _c("div", { staticClass: "cardbox text-white bg-gradient-warning b0" }, [
        _c(
          "div",
          { staticClass: "cardbox-body text-center text-bold text-12" },
          [
            _vm._v(
              " This is a safe folder that is only visible and accessible for Team Administrators. "
            ),
            _c("br"),
            _vm._v(
              " It's an ideal place for things you don't want to share with the whole team, like passwords, invoices, contracts, Non-Disclosure Agreements and similar files. "
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-sm btn-success btn-gradient ml-1 fh",
        attrs: { type: "submit" },
      },
      [_c("i", { staticClass: "ion-checkmark-round" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-sm btn-success btn-gradient",
        attrs: { type: "submit" },
      },
      [_c("i", { staticClass: "ion-checkmark-round" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-secondary btn-flat",
        staticStyle: { "font-size": "1rem" },
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-expanded": "false",
        },
      },
      [
        _c("i", {
          staticClass: "ion-more icon-lg text-muted",
          staticStyle: { "vertical-align": "initial" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "mt-0 modal-title" }, [
        _c("span", [_vm._v("Filter file list")]),
      ]),
      _c(
        "div",
        {
          staticClass: "float-right clickable",
          attrs: { "data-dismiss": "modal" },
        },
        [_c("em", { staticClass: "ion-close-round text-soft" })]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }