var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.qr_url
    ? _c("div", [
        _vm._v(" Setting up... "),
        _c("i", { staticClass: "ion-load-c spin ml-2" }),
      ])
    : !_vm.verified
    ? _c("show-verify-qr", {
        attrs: { url: _vm.qr_url },
        on: {
          verified: () => {
            _vm.verified = true
          },
        },
      })
    : _c(
        "div",
        [
          _c("mfa-recovery-codes", {
            staticClass: "my-3",
            attrs: {
              recovery_codes: _vm.backup_codes,
              is_setup: true,
              user_email: _vm.user_email,
            },
          }),
          _c("div", { staticClass: "fw text-center-mt-4" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                on: {
                  click: () => {
                    _vm.$emit("completed")
                  },
                },
              },
              [_vm._v("Finish setup")]
            ),
          ]),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }