var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sharing_status" }, [
    _vm.loading
      ? _c("i", { staticClass: "ion-load-c spin" })
      : _c("div", [
          _vm.shares && _vm.shares.length > 0
            ? _c("section", [
                _c(
                  "table",
                  {
                    staticClass:
                      "table table-sm table-striped d-none d-xl-block",
                    staticStyle: { width: "fit-content" },
                  },
                  [
                    _vm._m(0),
                    _c(
                      "tbody",
                      _vm._l(_vm.file.shares, function (share) {
                        return _c(
                          "tr",
                          {
                            key: share.id,
                            class: { "text-muted": !share.active },
                          },
                          [
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm._f("timestamp_rel")(share.created))
                              ),
                            ]),
                            _c(
                              "td",
                              [
                                _c("user-box", {
                                  attrs: {
                                    userId: share.created_by,
                                    team: _vm.user.team,
                                    pullLeft: true,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("td", [
                              share.expire
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("timestamp_rel")(share.expire)
                                      )
                                    ),
                                  ])
                                : _c("span", [_vm._v("Never")]),
                            ]),
                            _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    share.download_limit
                                      ? share.download_limit
                                      : "No limit"
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("td", [
                              _vm._v(
                                " " + _vm._s(share.active ? "Yes" : "No") + " "
                              ),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "input-group" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: share.link,
                                      expression: "share.link",
                                    },
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  staticStyle: { width: "100px" },
                                  attrs: { type: "text" },
                                  domProps: { value: share.link },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        share,
                                        "link",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-sm btn-secondary btn-gradient",
                                    attrs: { title: "Copy link to clipboard" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.to_clipboard(share.link)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "ion-clipboard icon-lg",
                                    }),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("td", [
                              share.password
                                ? _c("div", { staticClass: "input-group" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: share.password,
                                          expression: "share.password",
                                        },
                                      ],
                                      staticClass:
                                        "form-control form-control-sm",
                                      staticStyle: { width: "100px" },
                                      attrs: { type: "text" },
                                      domProps: { value: share.password },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            share,
                                            "password",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-secondary btn-gradient",
                                        attrs: {
                                          title: "Copy password to clipboard",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.to_clipboard(
                                              share.password
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "ion-clipboard icon-lg",
                                        }),
                                      ]
                                    ),
                                  ])
                                : _c("div", [_c("i", [_vm._v("No password")])]),
                            ]),
                            _c("td"),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "d-block d-xl-none" },
                  _vm._l(_vm.shares, function (share, idx) {
                    return _c("div", { key: share.id }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "share-header text-bold d-flex align-items-center justify-content-between mt-2 mb-2",
                        },
                        [
                          _c("span", [_vm._v("Share " + _vm._s(idx + 1))]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center mb-1",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-sm text-sm btn-outline-warning mr-1",
                                  attrs: { disabled: share.disable_loading },
                                  on: {
                                    click: function ($event) {
                                      return _vm.disable_share(share)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "ion-alert-circled mr-1",
                                  }),
                                  _vm._v(" Disable "),
                                  share.disable_loading
                                    ? _c("i", {
                                        staticClass: "ion-load-c ml-1",
                                      })
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "btn text-sm btn-sm btn-primary",
                                  attrs: { href: share.link, target: "_BLANK" },
                                },
                                [
                                  _c("i", { staticClass: "ion-share icon-lg" }),
                                  _c("span", { staticClass: "ml-1" }, [
                                    _vm._v("Open"),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "share-details ml-2" }, [
                        share.error
                          ? _c("div", {
                              staticClass:
                                "alert alert-danger alert-sm text-sm",
                              domProps: { innerHTML: _vm._s(share.error) },
                            })
                          : _vm._e(),
                        _c(
                          "div",
                          [
                            _c("b", [_vm._v("Created by: ")]),
                            _vm.user && _vm.user.team
                              ? _c("user-box", {
                                  attrs: {
                                    team: _vm.user.team,
                                    "user-id": share.created_by,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("div", [
                          _c("b", [_vm._v("Expires: ")]),
                          _vm._v(" "),
                          share.expire
                            ? _c("span", { staticClass: "text-lower" }, [
                                _vm._v(
                                  _vm._s(_vm._f("timestamp_rel")(share.expire))
                                ),
                              ])
                            : _c("span", [_vm._v("Never")]),
                        ]),
                        _c("div", [
                          _c("b", [_vm._v("Password: ")]),
                          share.password
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: share.password,
                                    expression: "share.password",
                                  },
                                ],
                                staticClass: "form-control form-control-sm",
                                staticStyle: { width: "100px" },
                                attrs: { type: "text" },
                                domProps: { value: share.password },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      share,
                                      "password",
                                      $event.target.value
                                    )
                                  },
                                },
                              })
                            : _c("span", [_vm._v("None")]),
                        ]),
                      ]),
                    ])
                  }),
                  0
                ),
              ])
            : _c("div", { staticClass: "text-success text-center" }, [
                _c("i", { staticClass: "ion-locked mr-2" }),
                _vm._v("This file is not shared "),
              ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("th", [_vm._v("Created")]),
      _c("th", [_vm._v("Shared by")]),
      _c("th", [_vm._v("Expires")]),
      _c("th", [_vm._v("Download limit")]),
      _c("th", [_vm._v("Active")]),
      _c("th", [_vm._v("Share link")]),
      _c("th", [_vm._v("Password")]),
      _c("th"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }