var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-3" }),
      _c("div", { staticClass: "col-6" }, [
        _c(
          "div",
          { staticClass: "cardbox text-white bg-gradient-secondary b0 mt-5" },
          [
            _c("div", { staticClass: "cardbox-body text-center" }, [
              _c("div", { staticClass: "display-3" }, [
                _c("i", { staticClass: "ion-wand" }),
              ]),
              _c("p", { staticClass: "text-bold text-md" }, [
                _vm._v(
                  "This function is available for Team Administrators only!"
                ),
              ]),
            ]),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }