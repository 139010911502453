var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "layout-container" }, [
    _c("div", { staticClass: "page-container" }, [
      _c(
        "div",
        { staticClass: "d-flex align-items-center align-items-center-ie" },
        [
          _c("div", { staticClass: "fw container container-sm" }, [
            _c(
              "div",
              { staticClass: "cardbox text-bold" },
              [
                _c("div", { staticClass: "pb-1 bg-gradient-info" }),
                _c("div", { staticClass: "p-4" }, [
                  _vm._m(0),
                  !_vm.is_success
                    ? _c("div", { staticClass: "cardbox-body" }, [
                        _vm.error
                          ? _c("div", {
                              staticClass: "alert alert-danger mt-4",
                              domProps: { innerHTML: _vm._s(_vm.error) },
                            })
                          : _vm._e(),
                        _vm.team_info
                          ? _c("div", [
                              _c("h2", { staticClass: "text-center mb-4" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.is_pending_registration
                                        ? "Welcome to"
                                        : "Join"
                                    ) +
                                    " "
                                ),
                                _c("b", [_vm._v(_vm._s(_vm.team_info.name))]),
                                _vm.team_info.logo_url
                                  ? _c("img", {
                                      staticClass: "ml-1",
                                      staticStyle: { "max-height": "50px" },
                                      attrs: {
                                        src: _vm.team_info.logo_url,
                                        alt: _vm.team_info.name,
                                      },
                                    })
                                  : _vm._e(),
                              ]),
                              _vm.form
                                ? _c(
                                    "form",
                                    {
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                          return _vm.submit_form.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "label",
                                        { attrs: { for: "u_name" } },
                                        [_vm._v("Your Name")]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.form.name,
                                            expression: "form.name",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "u_name",
                                          type: "text",
                                          autocomplete: "name",
                                          maxlength: "255",
                                        },
                                        domProps: { value: _vm.form.name },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.form,
                                              "name",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        { attrs: { for: "u_email" } },
                                        [_vm._v("Email Address")]
                                      ),
                                      _vm.form.email_readonly
                                        ? _c("div", [
                                            _c("i", {
                                              staticClass: "ion-email mr-2",
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "text-bold" },
                                              [_vm._v(_vm._s(_vm.form.email))]
                                            ),
                                            _vm._v(" "),
                                            _vm._m(1),
                                          ])
                                        : _c("span", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.form.email,
                                                  expression: "form.email",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                id: "u_email",
                                                type: "email",
                                                disabled:
                                                  _vm.form.email_readonly,
                                                autocomplete: "username email",
                                              },
                                              domProps: {
                                                value: _vm.form.email,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.form,
                                                    "email",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _vm.email_suggested
                                              ? _c("small", [
                                                  _vm._v("Did you mean "),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "text-underline text-bold",
                                                      attrs: { href: "#" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          _vm.form.email =
                                                            _vm.email_suggested
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.email_suggested
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v("?"),
                                                ])
                                              : _vm._e(),
                                          ]),
                                      _vm.is_pending_registration &&
                                      _vm.form.has_password &&
                                      !_vm.show_password_section
                                        ? _c("div", { staticClass: "mt-4" }, [
                                            _c("div", [
                                              _vm._v(
                                                "You already have a password, but you can change it now, if you want."
                                              ),
                                            ]),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "fw text-center mt-2",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-secondary",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.show_password_section = true
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("Change my password")]
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                      !_vm.form.has_password ||
                                      _vm.show_password_section
                                        ? _c("section", [
                                            _c(
                                              "label",
                                              { attrs: { for: "u_password" } },
                                              [_vm._v("Password")]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.form.password,
                                                  expression: "form.password",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                id: "u_password",
                                                type: "password",
                                                autocomplete: "new-password",
                                                placeholder:
                                                  "At least 8 characters",
                                              },
                                              domProps: {
                                                value: _vm.form.password,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.form,
                                                    "password",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ])
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-check d-flex align-items-start justify-content-center flex-column gap-4",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.form.terms_accepted,
                                                expression:
                                                  "form.terms_accepted",
                                              },
                                            ],
                                            staticClass: "form-check-input",
                                            attrs: {
                                              type: "checkbox",
                                              id: "checkbox-terms-accepted",
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.form.terms_accepted
                                              )
                                                ? _vm._i(
                                                    _vm.form.terms_accepted,
                                                    null
                                                  ) > -1
                                                : _vm.form.terms_accepted,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a =
                                                    _vm.form.terms_accepted,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.form,
                                                        "terms_accepted",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.form,
                                                        "terms_accepted",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "terms_accepted",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "form-check-label pl-2",
                                              attrs: {
                                                for: "checkbox-terms-accepted",
                                              },
                                            },
                                            [
                                              _vm._v("I understand that "),
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(_vm.team_info.name)
                                                ),
                                              ]),
                                              _vm._v(" accepted the "),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: "https://www.skyflok.com/legal",
                                                    target: "_BLANK",
                                                    rel: "noopener",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "Terms of Service and Privacy Policy"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                " of SkyFlok, which applies to how my personal data is handled. "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "text-center mt-4" },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-lg text-bold bg-skyflok text-white text-12",
                                              staticStyle: { width: "50%" },
                                              attrs: {
                                                type: "submit",
                                                disabled: _vm.form_loading,
                                              },
                                            },
                                            [
                                              _vm.is_pending_registration
                                                ? _c("span", [
                                                    _vm._v(
                                                      "Activate your account"
                                                    ),
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      "Join " +
                                                        _vm._s(
                                                          _vm.team_info.name
                                                        )
                                                    ),
                                                  ]),
                                              _vm.form_loading
                                                ? _c("i", {
                                                    staticClass:
                                                      "ion-load-c ml-2 spin",
                                                  })
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : !_vm.error
                          ? _c("div", { staticClass: "text-center" }, [
                              _c("i", { staticClass: "ion-load-c spin mr-2" }),
                              _vm._v("Loading, hang on.. "),
                            ])
                          : _vm._e(),
                      ])
                    : _c(
                        "div",
                        { staticClass: "cardbox-body pt-0 text-center" },
                        [
                          !_vm.redirect_to_login
                            ? _c("section", [
                                _c("img", {
                                  staticClass: "width-25p my-4",
                                  class: { fw: _vm.Utils.is_mobile() },
                                  attrs: {
                                    src: require("@/assets/img/illustrations/mail_sent-success.svg"),
                                  },
                                }),
                                _vm._m(2),
                              ])
                            : _c(
                                "div",
                                { staticClass: "text-14 text-success" },
                                [
                                  _vm._v("Success!"),
                                  _c("br"),
                                  _vm._v("Redirecting to login..."),
                                ]
                              ),
                        ]
                      ),
                ]),
                _c("reg-footer"),
                _c("div", { staticClass: "pb-1 bg-gradient-info" }),
              ],
              1
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cardbox-heading" }, [
      _c("div", { staticClass: "cardbox-title text-center" }, [
        _c("a", { attrs: { href: "https://skyflok.com", target: "_blank" } }, [
          _c("img", {
            staticClass: "mr-2",
            staticStyle: { "max-height": "40px" },
            attrs: { src: require("@/assets/img/skyflok_logo_black.png") },
          }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      {
        staticClass: "badge text-success ml-2",
        staticStyle: { background: "white" },
      },
      [
        _vm._v("Confirmed "),
        _c("i", { staticClass: "ion-checkmark-circled text-success ml-1" }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-14 px-5" }, [
      _c("div", { staticClass: "text-success mb-2" }, [_vm._v("Success!")]),
      _vm._v(
        " Please activate your new SkyFlok account with the link we have sent in email. "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }