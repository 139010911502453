var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "section-container", attrs: { id: "team-setup-container" } },
    [
      _c("div", {
        staticClass: "container-overlap bg-gradient-info d-none d-sm-block",
      }),
      _c(
        "div",
        {
          staticClass: "container container-md d-block carousel",
          attrs: {
            id: "setup_form_carousel",
            "data-interval": "0",
            "data-wrap": "false",
            "data-pause": "false",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "cardbox fh pt-3",
              staticStyle: { "min-height": "42em" },
            },
            [
              _c("div", { staticClass: "cardbox-body px-5 pb-0" }, [
                _c(
                  "div",
                  {
                    staticClass: "progress",
                    class: {
                      "bg-white":
                        _vm.current_slide_idx == 0 || _vm.current_slide_idx > 2,
                    },
                    staticStyle: { height: "5px" },
                  },
                  [
                    _vm.current_slide_idx <= _vm.total_steps_num
                      ? _c("div", {
                          staticClass: "progress-bar bg-skyflok",
                          style: {
                            width:
                              (_vm.current_slide_idx / _vm.total_steps_num) *
                                100 +
                              "%",
                          },
                          attrs: { role: "progressbar" },
                        })
                      : _vm._e(),
                  ]
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "carousel-inner",
                  staticStyle: { overflow: "visible" },
                },
                [
                  _c("div", { staticClass: "carousel-item active p-4" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "cardbox-body fw fh text-center welcome-header",
                        class: {
                          "p-0": _vm.Utils.is_mobile(),
                          "px-5": !_vm.Utils.is_mobile(),
                        },
                      },
                      [
                        _vm._m(0),
                        _c("p", { staticClass: "pt-5 mt-5 text-12" }, [
                          _vm._v(
                            "Thank you for signing up, we are glad you are here!"
                          ),
                        ]),
                        _c("p", { staticClass: "pt-2 text-12" }, [
                          _vm._v(
                            "Before you can start using SkyFlok, there are a few things to set up."
                          ),
                        ]),
                        _c("div", { staticClass: "pt-5 mt-5 text-center" }, [
                          _vm.invite_error && !_vm.invite_loading
                            ? _c(
                                "div",
                                { staticClass: "alert alert-danger text-bold" },
                                [_vm._v(_vm._s(_vm.invite_error))]
                              )
                            : _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-lg text-12 btn-skyflok wizard-nav px-4",
                                  attrs: {
                                    disabled: _vm.invite_loading,
                                    "data-slide": "next",
                                    href: "#setup_form_carousel",
                                  },
                                },
                                [
                                  _vm._v(" Start"),
                                  _c("i", {
                                    class: {
                                      "ion-load-c spin": _vm.invite_loading,
                                      "ion-arrow-right-c": _vm.invite,
                                    },
                                  }),
                                ]
                              ),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "carousel-item p-4" }, [
                    _c(
                      "div",
                      {
                        staticClass: "cardbox-body fw",
                        class: { "p-0": _vm.Utils.is_mobile() },
                      },
                      [
                        _c("h4", { staticClass: "px-4" }, [
                          _vm._v("Our Unique Technology"),
                        ]),
                        _c(
                          "p",
                          {
                            staticClass: "px-1 py-3",
                            class: {
                              "text-center": _vm.Utils.is_mobile(),
                              "px-4": !_vm.Utils.is_mobile(),
                            },
                          },
                          [
                            _vm._v(
                              " SkyFlok uses a unique patented technology to store your files. They are encrypted in your browser and distributed to multiple locations for improved security, availability and privacy. "
                            ),
                            _c("br"),
                            _vm._v(
                              "You can select these locations in the next step. "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "fw text-center mt-4",
                            class: { "mb-3": _vm.Utils.is_mobile() },
                          },
                          [
                            _c(
                              "video",
                              {
                                class: {
                                  fw: _vm.Utils.is_mobile(),
                                  "width-80p": !_vm.Utils.is_mobile(),
                                },
                                attrs: {
                                  id: "explainer-video",
                                  autoplay: "",
                                  muted: "",
                                  loop: "",
                                  playsinline: "",
                                },
                                domProps: { muted: true },
                              },
                              [
                                _c("source", {
                                  attrs: {
                                    src: "assets/videos/skyflok-file-upload.webm",
                                    type: "video/webm",
                                  },
                                }),
                                _c("source", {
                                  attrs: {
                                    src: "assets/videos/skyflok-file-upload.mov",
                                    type: "video/quicktime",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _vm._m(1),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "carousel-item p-4" }, [
                    _c(
                      "div",
                      {
                        staticClass: "cardbox-body fw",
                        class: { "p-0": _vm.Utils.is_mobile() },
                      },
                      [
                        _c("h4", { staticClass: "px-4" }, [
                          _vm._v("File Locations"),
                        ]),
                        _c(
                          "p",
                          {
                            staticClass: "px-1 py-3",
                            class: {
                              "text-center": _vm.Utils.is_mobile(),
                              "px-4": !_vm.Utils.is_mobile(),
                            },
                          },
                          [
                            _vm._v(
                              " The following selection of our 50+ worldwide storage locations provide the fastest file transfers in your region. "
                            ),
                          ]
                        ),
                        !_vm.customize_locations
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "fw d-flex align-items-center justify-content-center",
                                class: { "px-3": !_vm.Utils.is_mobile() },
                              },
                              [
                                _vm.location_suggestion_loading
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center justify-content-center",
                                        staticStyle: { height: "25em" },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v("Loading recommendation"),
                                        ]),
                                        _c("i", {
                                          staticClass:
                                            "ion-load-c spin ml-2 color-skyflok text-md",
                                        }),
                                      ]
                                    )
                                  : _vm.suggested_locations.length
                                  ? _c(
                                      "table",
                                      {
                                        staticClass: "table table-skyflok m-0",
                                        class: {
                                          "width-75p": !_vm.Utils.is_mobile(),
                                        },
                                      },
                                      [
                                        _c("thead", [
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [_vm._v("Provider")]
                                          ),
                                          _c("th", [_vm._v("Location")]),
                                          _c(
                                            "th",
                                            {
                                              staticClass:
                                                "text-center width-0 nowrap",
                                            },
                                            [
                                              _vm._v("GDPR "),
                                              _c("info-icon", {
                                                attrs: {
                                                  content:
                                                    "Whether this location is GDPR-compliant",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _c(
                                          "tbody",
                                          _vm._l(
                                            _vm.suggested_locations,
                                            function (location) {
                                              return _c(
                                                "tr",
                                                { key: location.id },
                                                [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                    },
                                                    [
                                                      location.provider_logo
                                                        ? _c("img", {
                                                            staticClass:
                                                              "cloud-provider-logo",
                                                            attrs: {
                                                              src: _vm.Utils.cloud_provider_logo_src(
                                                                location.provider_logo
                                                              ),
                                                            },
                                                          })
                                                        : _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                location.provider_name
                                                              )
                                                            ),
                                                          ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c(
                                                        "storage-location-cell",
                                                        {
                                                          attrs: {
                                                            location:
                                                              location.location,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass: "text-12",
                                                        class: {
                                                          "ion-checkmark-circled color-skyflok":
                                                            location.is_gdpr,
                                                          "ion-close-circled text-danger":
                                                            !location.is_gdpr,
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          : _c("div", [
                              !_vm.all_locations.length
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-center",
                                      staticStyle: { height: "25em" },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("Loading all locations..."),
                                      ]),
                                      _c("i", {
                                        staticClass:
                                          "ion-load-c spin color-skyflok ml-2 text-md",
                                      }),
                                    ]
                                  )
                                : _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "fw d-flex align-items-top justify-content-center",
                                        class: {
                                          "px-3": !_vm.Utils.is_mobile(),
                                        },
                                        staticStyle: { "min-height": "20em" },
                                      },
                                      [
                                        !_vm.show_map
                                          ? _c(
                                              "table",
                                              {
                                                staticClass:
                                                  "table table-skyflok m-0",
                                              },
                                              [
                                                _c("thead", [
                                                  _c("th", {
                                                    staticClass: "width-0",
                                                  }),
                                                  _c(
                                                    "th",
                                                    {
                                                      staticClass:
                                                        "text-center dropdown",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "dropdown-toggle c-pointer nowrap",
                                                          attrs: {
                                                            "data-toggle":
                                                              "dropdown",
                                                          },
                                                        },
                                                        [_vm._v(" Provider")]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "dropdown-menu mt-1 px-3",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return (() => {}).apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.location_filter
                                                            .providers,
                                                          function (provider) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: provider.id,
                                                                staticClass:
                                                                  "m-3",
                                                                class: {
                                                                  "my-3":
                                                                    !_vm.Utils.is_mobile(),
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-check",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                provider.checked,
                                                                              expression:
                                                                                "provider.checked",
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "form-check-input",
                                                                        attrs: {
                                                                          type: "checkbox",
                                                                          id: `checkbox-provider-${provider.id}`,
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            checked:
                                                                              Array.isArray(
                                                                                provider.checked
                                                                              )
                                                                                ? _vm._i(
                                                                                    provider.checked,
                                                                                    null
                                                                                  ) >
                                                                                  -1
                                                                                : provider.checked,
                                                                          },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              var $$a =
                                                                                  provider.checked,
                                                                                $$el =
                                                                                  $event.target,
                                                                                $$c =
                                                                                  $$el.checked
                                                                                    ? true
                                                                                    : false
                                                                              if (
                                                                                Array.isArray(
                                                                                  $$a
                                                                                )
                                                                              ) {
                                                                                var $$v =
                                                                                    null,
                                                                                  $$i =
                                                                                    _vm._i(
                                                                                      $$a,
                                                                                      $$v
                                                                                    )
                                                                                if (
                                                                                  $$el.checked
                                                                                ) {
                                                                                  $$i <
                                                                                    0 &&
                                                                                    _vm.$set(
                                                                                      provider,
                                                                                      "checked",
                                                                                      $$a.concat(
                                                                                        [
                                                                                          $$v,
                                                                                        ]
                                                                                      )
                                                                                    )
                                                                                } else {
                                                                                  $$i >
                                                                                    -1 &&
                                                                                    _vm.$set(
                                                                                      provider,
                                                                                      "checked",
                                                                                      $$a
                                                                                        .slice(
                                                                                          0,
                                                                                          $$i
                                                                                        )
                                                                                        .concat(
                                                                                          $$a.slice(
                                                                                            $$i +
                                                                                              1
                                                                                          )
                                                                                        )
                                                                                    )
                                                                                }
                                                                              } else {
                                                                                _vm.$set(
                                                                                  provider,
                                                                                  "checked",
                                                                                  $$c
                                                                                )
                                                                              }
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "form-check-label nowrap",
                                                                        attrs: {
                                                                          for: `checkbox-provider-${provider.id}`,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            provider.name
                                                                          )
                                                                        ),
                                                                        _c(
                                                                          "small",
                                                                          [
                                                                            _vm._v(
                                                                              " (" +
                                                                                _vm._s(
                                                                                  provider.locations_num
                                                                                ) +
                                                                                ")"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "th",
                                                    { staticClass: "dropdown" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "dropdown-toggle c-pointer nowrap",
                                                          attrs: {
                                                            "data-toggle":
                                                              "dropdown",
                                                          },
                                                        },
                                                        [_vm._v(" Location ")]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "dropdown-menu mt-1 px-3",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return (() => {}).apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.location_filter
                                                            .countries,
                                                          function (country) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: country.countrycode,
                                                                staticClass:
                                                                  "m-2",
                                                                class: {
                                                                  "my-3":
                                                                    !_vm.Utils.is_mobile(),
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-check",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                country.checked,
                                                                              expression:
                                                                                "country.checked",
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "form-check-input",
                                                                        attrs: {
                                                                          type: "checkbox",
                                                                          id: `checkbox-location-${country.countrycode}`,
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            checked:
                                                                              Array.isArray(
                                                                                country.checked
                                                                              )
                                                                                ? _vm._i(
                                                                                    country.checked,
                                                                                    null
                                                                                  ) >
                                                                                  -1
                                                                                : country.checked,
                                                                          },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              var $$a =
                                                                                  country.checked,
                                                                                $$el =
                                                                                  $event.target,
                                                                                $$c =
                                                                                  $$el.checked
                                                                                    ? true
                                                                                    : false
                                                                              if (
                                                                                Array.isArray(
                                                                                  $$a
                                                                                )
                                                                              ) {
                                                                                var $$v =
                                                                                    null,
                                                                                  $$i =
                                                                                    _vm._i(
                                                                                      $$a,
                                                                                      $$v
                                                                                    )
                                                                                if (
                                                                                  $$el.checked
                                                                                ) {
                                                                                  $$i <
                                                                                    0 &&
                                                                                    _vm.$set(
                                                                                      country,
                                                                                      "checked",
                                                                                      $$a.concat(
                                                                                        [
                                                                                          $$v,
                                                                                        ]
                                                                                      )
                                                                                    )
                                                                                } else {
                                                                                  $$i >
                                                                                    -1 &&
                                                                                    _vm.$set(
                                                                                      country,
                                                                                      "checked",
                                                                                      $$a
                                                                                        .slice(
                                                                                          0,
                                                                                          $$i
                                                                                        )
                                                                                        .concat(
                                                                                          $$a.slice(
                                                                                            $$i +
                                                                                              1
                                                                                          )
                                                                                        )
                                                                                    )
                                                                                }
                                                                              } else {
                                                                                _vm.$set(
                                                                                  country,
                                                                                  "checked",
                                                                                  $$c
                                                                                )
                                                                              }
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "form-check-label nowrap",
                                                                        attrs: {
                                                                          for: `checkbox-location-${country.countrycode}`,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              country.name
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                        _c(
                                                                          "img",
                                                                          {
                                                                            staticClass:
                                                                              "ml-2",
                                                                            attrs:
                                                                              {
                                                                                src: _vm.Utils.flag_img_src(
                                                                                  country.countrycode
                                                                                ),
                                                                                height:
                                                                                  "20",
                                                                              },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "small",
                                                                          {
                                                                            staticClass:
                                                                              "ml-2",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " (" +
                                                                                _vm._s(
                                                                                  country.num
                                                                                ) +
                                                                                ")"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "th",
                                                    {
                                                      staticClass:
                                                        "text-center width-0 nowrap dropdown",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "dropdown-toggle c-pointer nowrap",
                                                          attrs: {
                                                            "data-toggle":
                                                              "dropdown",
                                                          },
                                                        },
                                                        [_vm._v(" GDPR ")]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "dropdown-menu mt-1 px-3",
                                                          class: {
                                                            "dropdown-menu-right":
                                                              _vm.Utils.is_mobile(),
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return (() => {}).apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mx-2 my-2",
                                                              class: {
                                                                "my-3":
                                                                  !_vm.Utils.is_mobile(),
                                                              },
                                                            },
                                                            [
                                                              _c("checkbox", {
                                                                attrs: {
                                                                  label:
                                                                    "GDPR locations",
                                                                  value:
                                                                    _vm
                                                                      .location_filter
                                                                      .gdpr.yes,
                                                                },
                                                                on: {
                                                                  changed: (
                                                                    newval
                                                                  ) =>
                                                                    (_vm.location_filter.gdpr.yes =
                                                                      newval),
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mx-2 my-2",
                                                              class: {
                                                                "my-3":
                                                                  !_vm.Utils.is_mobile(),
                                                              },
                                                            },
                                                            [
                                                              _c("checkbox", {
                                                                attrs: {
                                                                  label:
                                                                    "Non-GDPR locations",
                                                                  value:
                                                                    _vm
                                                                      .location_filter
                                                                      .gdpr.no,
                                                                },
                                                                on: {
                                                                  changed: (
                                                                    newval
                                                                  ) =>
                                                                    (_vm.location_filter.gdpr.no =
                                                                      newval),
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.all_locations.length &&
                                                  _vm.all_locations[0]
                                                    .distance_km !== null
                                                    ? _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "width-0 d-none d-sm-table-cell nowrap",
                                                        },
                                                        [
                                                          _vm._v(" Distance "),
                                                          _c("info-icon", {
                                                            attrs: {
                                                              content:
                                                                "Approximate distance from you, estimated from your IP address.",
                                                              custom_class:
                                                                "text-gray",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]),
                                                _c(
                                                  "tbody",
                                                  [
                                                    _vm.is_filtered
                                                      ? _c("tr", [
                                                          _c(
                                                            "td",
                                                            {
                                                              attrs: {
                                                                colspan: "5",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "p-1",
                                                                },
                                                                [
                                                                  _vm._l(
                                                                    _vm.location_filter.providers.filter(
                                                                      (p) =>
                                                                        p.checked
                                                                    ),
                                                                    function (
                                                                      provider
                                                                    ) {
                                                                      return _c(
                                                                        "span",
                                                                        {
                                                                          key:
                                                                            "provider-" +
                                                                            provider.id,
                                                                          staticClass:
                                                                            "btn btn-sm btn-oval btn-skyflok-outline m-1",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                provider.checked = false
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                provider.name
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "ion-close ml-1",
                                                                            }
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  _vm._l(
                                                                    _vm.location_filter.countries.filter(
                                                                      (p) =>
                                                                        p.checked
                                                                    ),
                                                                    function (
                                                                      country
                                                                    ) {
                                                                      return _c(
                                                                        "span",
                                                                        {
                                                                          key:
                                                                            "country-" +
                                                                            country.countrycode,
                                                                          staticClass:
                                                                            "btn btn-sm btn-oval btn-skyflok-outline m-1",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                country.checked = false
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                country.name
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "ion-close ml-1",
                                                                            }
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  _vm
                                                                    .location_filter
                                                                    .gdpr.yes
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "btn btn-sm btn-oval btn-skyflok-outline m-1",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                _vm.location_filter.gdpr.yes = false
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " GDPR "
                                                                          ),
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "ion-close ml-1",
                                                                            }
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm
                                                                    .location_filter
                                                                    .gdpr.no
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "btn btn-sm btn-oval btn-skyflok-outline m-1",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                _vm.location_filter.gdpr.no = false
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " Not GDPR "
                                                                          ),
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "ion-close ml-1",
                                                                            }
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                2
                                                              ),
                                                            ]
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _vm._l(
                                                      _vm.locations_page,
                                                      function (location) {
                                                        return _c(
                                                          "tr",
                                                          {
                                                            key: location.id,
                                                            class: {
                                                              "selected-row":
                                                                location.selected,
                                                              "c-pointer":
                                                                location.selected ||
                                                                _vm
                                                                  .custom_locations_selected
                                                                  .length < 6,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                location.selected =
                                                                  !location.selected
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("td", [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "m-0 ml-3 p-0",
                                                                },
                                                                [
                                                                  _c(
                                                                    "checkbox",
                                                                    {
                                                                      attrs: {
                                                                        value:
                                                                          location.selected,
                                                                      },
                                                                      on: {
                                                                        changed:
                                                                          (
                                                                            newval
                                                                          ) =>
                                                                            (location.selected =
                                                                              newval),
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-center",
                                                              },
                                                              [
                                                                !_vm.Utils.is_mobile() &&
                                                                location.provider_logo
                                                                  ? _c("img", {
                                                                      staticClass:
                                                                        "cloud-provider-logo",
                                                                      attrs: {
                                                                        src: _vm.Utils.cloud_provider_logo_src(
                                                                          location.provider_logo
                                                                        ),
                                                                      },
                                                                    })
                                                                  : _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          location.provider_name
                                                                        )
                                                                      ),
                                                                    ]),
                                                              ]
                                                            ),
                                                            _c(
                                                              "td",
                                                              [
                                                                _c(
                                                                  "storage-location-cell",
                                                                  {
                                                                    attrs: {
                                                                      location:
                                                                        location.location,
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "text-center",
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "text-12",
                                                                  class: {
                                                                    "ion-checkmark-circled color-skyflok":
                                                                      location.is_gdpr,
                                                                    "ion-close-circled":
                                                                      !location.is_gdpr,
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                            location.distance_km !==
                                                            null
                                                              ? _c(
                                                                  "td",
                                                                  {
                                                                    staticClass:
                                                                      "d-none d-sm-table-cell",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "round_up_100"
                                                                          )(
                                                                            location.distance_km
                                                                          )
                                                                        ) +
                                                                        " km "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex items-center justify-content-end mt-1",
                                        class: {
                                          "px-3": !_vm.Utils.is_mobile(),
                                        },
                                      },
                                      [
                                        false
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-sm btn-skyflok-outline my-1 ml-0",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: () => {
                                                    _vm.show_map = !_vm.show_map
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "mr-1",
                                                  class: {
                                                    "ion-map": !_vm.show_map,
                                                    "ion-navicon-round":
                                                      _vm.show_map,
                                                  },
                                                }),
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.show_map
                                                      ? "Table"
                                                      : "Map"
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          [
                                            !_vm.show_map
                                              ? _c("table-pager", {
                                                  attrs: {
                                                    data: _vm.all_locations_filtered,
                                                    is_filtered:
                                                      _vm.is_filtered,
                                                    update: (data_page) => {
                                                      _vm.locations_page =
                                                        data_page
                                                    },
                                                    initial_page_size: 8,
                                                    disable_pagesize_selector: true,
                                                    disable_filtered_label: true,
                                                    records_name: "locations",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]),
                            ]),
                        _c("div", { staticClass: "text-center text-sm mt-2" }, [
                          !_vm.customize_locations
                            ? _c(
                                "a",
                                {
                                  staticClass: "color-skyflok",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      _vm.customize_locations = true
                                    },
                                  },
                                },
                                [_vm._v("Customize file locations")]
                              )
                            : !_vm.location_suggestion_failed
                            ? _c(
                                "a",
                                {
                                  staticClass: "color-skyflok text-12",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      _vm.customize_locations = false
                                    },
                                  },
                                },
                                [_vm._v("Back to suggested locations")]
                              )
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "row mt-3" }, [
                          _vm._m(2),
                          _c("div", { staticClass: "col-6 text-right" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-lg btn-skyflok wizard-nav",
                                attrs: {
                                  disabled: !_vm.locations_ok,
                                  "data-slide": "next",
                                  href: "#setup_form_carousel",
                                },
                              },
                              [
                                _vm.locations_ok
                                  ? _c("span", [
                                      _vm._v("Next "),
                                      _c("i", {
                                        staticClass: "ion-arrow-right-c",
                                      }),
                                    ])
                                  : _vm.custom_locations_selected.length < 6
                                  ? _c("span", [
                                      _vm._v(
                                        "Select " +
                                          _vm._s(
                                            6 -
                                              _vm.custom_locations_selected
                                                .length
                                          ) +
                                          " more"
                                      ),
                                    ])
                                  : _vm.custom_locations_selected.length > 6
                                  ? _c("span", [
                                      _vm._v(
                                        "Remove " +
                                          _vm._s(
                                            _vm.custom_locations_selected
                                              .length - 6
                                          ) +
                                          " location" +
                                          _vm._s(
                                            _vm.custom_locations_selected
                                              .length -
                                              6 ==
                                              1
                                              ? ""
                                              : "s"
                                          )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "carousel-item p-4" }, [
                    _c(
                      "div",
                      {
                        staticClass: "cardbox-body fw mx-1",
                        class: {
                          "p-0": _vm.Utils.is_mobile(),
                          "mx-4": !_vm.Utils.is_mobile(),
                        },
                      },
                      [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return _vm.submit_form.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("h4", [_vm._v("Your Account")]),
                            _c(
                              "p",
                              {
                                staticClass: "pt-1 pb-3",
                                class: {
                                  "text-justify": _vm.Utils.is_mobile(),
                                },
                              },
                              [
                                _vm._v(
                                  " Choose a name for your SkyFlok Team and fill the details of your user account. "
                                ),
                              ]
                            ),
                            _vm.user_form
                              ? _c(
                                  "div",
                                  { staticClass: "row mt-4 user-form" },
                                  [
                                    _vm.form_error
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "col-12",
                                            class: {
                                              "pr-4": !_vm.Utils.is_mobile(),
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "alert alert-danger",
                                              },
                                              [_vm._v(_vm._s(_vm.form_error))]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col-12 col-md-6",
                                        class: {
                                          "pr-4": !_vm.Utils.is_mobile(),
                                        },
                                      },
                                      [
                                        _c("input", {
                                          attrs: {
                                            type: "hidden",
                                            name: "storage_location_ids",
                                          },
                                          domProps: {
                                            value:
                                              _vm.selected_location_ids.join(
                                                ","
                                              ),
                                          },
                                        }),
                                        _c("input", {
                                          attrs: {
                                            type: "hidden",
                                            name: "invite_key",
                                          },
                                          domProps: { value: _vm.invite_key },
                                        }),
                                        _c(
                                          "label",
                                          {
                                            staticStyle: {
                                              "padding-top": "0px",
                                            },
                                            attrs: {
                                              for: "register_team_name pt-0 mt-0",
                                            },
                                          },
                                          [_vm._v("SkyFlok Team Name")]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.user_form.team_name,
                                              expression: "user_form.team_name",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            id: "register_team_name",
                                            name: "team_name",
                                            maxlength: "255",
                                          },
                                          domProps: {
                                            value: _vm.user_form.team_name,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.user_form,
                                                "team_name",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                        _c(
                                          "label",
                                          {
                                            attrs: {
                                              for: "register_name pt-0 mt-0",
                                            },
                                          },
                                          [_vm._v("Your Name")]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.user_form.name,
                                              expression: "user_form.name",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            id: "register_name",
                                            name: "person_name",
                                            maxlength: "255",
                                            autocomplete: "name",
                                          },
                                          domProps: {
                                            value: _vm.user_form.name,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.user_form,
                                                "name",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                        _c(
                                          "label",
                                          { attrs: { for: "register_email" } },
                                          [_vm._v("Email Address")]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.user_form.email,
                                              expression: "user_form.email",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "email",
                                            id: "register_email",
                                            readonly: "",
                                            name: "email",
                                            autocomplete: "username email",
                                          },
                                          domProps: {
                                            value: _vm.user_form.email,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.user_form,
                                                "email",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                        _c(
                                          "label",
                                          {
                                            attrs: { for: "register_password" },
                                          },
                                          [_vm._v("Password")]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.user_form.password,
                                              expression: "user_form.password",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "password",
                                            id: "register_password",
                                            name: "password",
                                            autocomplete: "new-password",
                                            placeholder:
                                              "At least 8 characters",
                                          },
                                          domProps: {
                                            value: _vm.user_form.password,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.user_form,
                                                "password",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                        _c("password-strength-meter", {
                                          attrs: {
                                            password_strength:
                                              _vm.password_strength,
                                          },
                                        }),
                                        _c("div", { staticClass: "fw" }, [
                                          _c(
                                            "div",
                                            { staticClass: "form-check" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.user_form
                                                        .terms_accepted,
                                                    expression:
                                                      "user_form.terms_accepted",
                                                  },
                                                ],
                                                staticClass: "form-check-input",
                                                attrs: {
                                                  type: "checkbox",
                                                  name: "terms_accepted",
                                                  id: "terms-accepted",
                                                },
                                                domProps: {
                                                  value: 1,
                                                  checked: Array.isArray(
                                                    _vm.user_form.terms_accepted
                                                  )
                                                    ? _vm._i(
                                                        _vm.user_form
                                                          .terms_accepted,
                                                        1
                                                      ) > -1
                                                    : _vm.user_form
                                                        .terms_accepted,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.user_form
                                                          .terms_accepted,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = 1,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.user_form,
                                                            "terms_accepted",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.user_form,
                                                            "terms_accepted",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.user_form,
                                                        "terms_accepted",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _vm._m(3),
                                            ]
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col-12 col-md-6",
                                        class: {
                                          "pl-4 mt-4": !_vm.Utils.is_mobile(),
                                          "pt-3": _vm.Utils.is_mobile(),
                                        },
                                      },
                                      [
                                        _c("invite-config-box", {
                                          attrs: { invite: _vm.invite },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c("div", { staticClass: "row mt-5" }, [
                              _c("div", { staticClass: "col-4 text-left" }, [
                                !_vm.form_loading
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-lg btn-secondary",
                                        attrs: {
                                          type: "button",
                                          "data-slide": "prev",
                                          href: "#setup_form_carousel",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "ion-arrow-left-c",
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "d-none d-md-inline-block ml-2",
                                          },
                                          [_vm._v("Back")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                              _c("div", { staticClass: "col-4 text-center" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-lg text-12 btn-skyflok",
                                    attrs: {
                                      type: "submit",
                                      disabled:
                                        !_vm.user_form_ok || _vm.form_loading,
                                    },
                                  },
                                  [
                                    _vm._v(" Finish Setup "),
                                    _vm.form_loading
                                      ? _c("i", {
                                          staticClass: "ml-2 ion-load-c spin",
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                                _vm.submit_error_msg
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-danger text-center mt-1 text-sm",
                                      },
                                      [_vm._v(_vm._s(_vm.submit_error_msg))]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "carousel-item p-4" }, [
                    _c(
                      "div",
                      {
                        staticClass: "cardbox-body fw",
                        staticStyle: { height: "20em" },
                      },
                      [
                        _c("div", { staticClass: "fw fh text-center pt-5" }, [
                          _c("h2", { staticClass: "text-success" }, [
                            _vm._v("Your Account is Ready!"),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "py-5 d-flex justify-content-center",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "width-25p",
                                  class: { "width-75p": _vm.Utils.is_mobile() },
                                },
                                [
                                  _c("img", {
                                    staticStyle: { "max-width": "100%" },
                                    attrs: {
                                      src: require("@/assets/img/illustrations/moving_forward-success.svg"),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm.invite
                            ? _c(
                                "div",
                                { staticClass: "mt-5 fw text-center text-14" },
                                [
                                  _vm._v(" Redirecting to "),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "color-skyflok text-underline",
                                      attrs: {
                                        to:
                                          "/login?email=" +
                                          encodeURIComponent(
                                            _vm.invite.invited_email_address
                                          ),
                                      },
                                    },
                                    [_vm._v("Login")]
                                  ),
                                  _vm._v(
                                    " in... " +
                                      _vm._s(_vm.redirect_timer_sec) +
                                      " "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "h2",
      { staticClass: "py-3 d-flex align-items-center justify-content-center" },
      [
        _c("span", [
          _vm._v("Welcome to "),
          _c("span", { staticClass: "color-skyflok mx-2" }, [
            _vm._v("SkyFlok "),
          ]),
        ]),
        _c("img", {
          attrs: {
            src: require("@/assets/img/skyflok-badge.png"),
            width: "40px",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fw text-right mt-3" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-lg btn-skyflok wizard-nav",
          attrs: { "data-slide": "next", href: "#setup_form_carousel" },
        },
        [_vm._v(" Next"), _c("i", { staticClass: "ion-arrow-right-c" })]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6 text-left" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-lg btn-secondary",
          attrs: {
            type: "button",
            "data-slide": "prev",
            href: "#setup_form_carousel",
          },
        },
        [
          _c("i", { staticClass: "ion-arrow-left-c" }),
          _c("span", { staticClass: "d-none d-md-inline-block ml-2" }, [
            _vm._v("Back"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label nowrap",
        attrs: { for: "terms-accepted" },
      },
      [
        _vm._v(" I accept the "),
        _c(
          "a",
          {
            staticClass: "color-skyflok",
            attrs: { href: "https://www.skyflok.com/legal/", target: "_blank" },
          },
          [_vm._v("SkyFlok Terms & Conditions")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }