var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "section-container", attrs: { id: "team_members" } },
    [
      _c("div", { staticClass: "container-fluid" }, [
        _vm.user &&
        _vm.user.team_settings &&
        (_vm.user.team_settings.name || _vm.user.team_settings.url)
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-3" }, [_vm._v(" ")]),
              _c("div", { staticClass: "col-md-6" }, [
                _c(
                  "div",
                  { staticClass: "cardbox text-white bg-gradient-primary b0" },
                  [
                    _c("div", { staticClass: "cardbox-body text-center" }, [
                      _c("div", { staticClass: "text-bold text-lg" }, [
                        _vm._v(_vm._s(_vm.user.team_settings.name)),
                      ]),
                      _vm.user.team_settings.url
                        ? _c("div", {}, [
                            _c(
                              "a",
                              {
                                staticClass: "text-white",
                                attrs: {
                                  href: _vm._f("fix_url")(
                                    _vm.user.team_settings.url
                                  ),
                                  target: "_BLANK",
                                  rel: "noopener",
                                },
                              },
                              [
                                _c("em", [
                                  _vm._v(_vm._s(_vm.user.team_settings.url)),
                                ]),
                                _vm._v(" "),
                                _c("i", { staticClass: "ion-share ml-2" }),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "container-fluid" }, [
        _vm.user && _vm.user.team
          ? _c(
              "div",
              { staticClass: "row" },
              _vm._l(_vm.user.team, function (member) {
                return _c(
                  "div",
                  {
                    key: member.id,
                    staticClass: "col-sm-12 col-md-6 col-xl-4",
                  },
                  [
                    _c("div", { staticClass: "cardbox" }, [
                      _c("div", {
                        staticClass: "pb-1",
                        class: _vm._f("highlight_class")(member.name),
                      }),
                      _c("div", { staticClass: "cardbox-body" }, [
                        _c("div", { staticClass: "d-flex" }, [
                          _c("div", [
                            _c("div", { staticClass: "lead mb-2" }, [
                              _vm._v(_vm._s(member.name)),
                              member.is_admin
                                ? _c(
                                    "small",
                                    { staticClass: "text-gray-light ml-2" },
                                    [_vm._v("(Admin)")]
                                  )
                                : _vm._e(),
                            ]),
                            _c("p", [
                              _c("span", [
                                _vm._v(_vm._s(member.job_title || " ")),
                              ]),
                            ]),
                            _c("p", [
                              _c("em", {
                                staticClass:
                                  "ion-email mr-2 icon-lg text-primary",
                              }),
                              _c("span", [
                                _vm._v(_vm._s(member.email_address)),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "ml-auto" }, [
                            _c("img", {
                              staticClass:
                                "shadow-z5 thumb80 rounded fit-cover",
                              attrs: { src: member.avatar_url, alt: "user" },
                            }),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }