var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "section-container", attrs: { id: "recycle_bin" } },
    [
      _c("div", { staticClass: "container pt-3" }, [
        false
          ? _c("div", { staticClass: "row pt-3" }, [
              _c("div", { staticClass: "col-1" }),
              _vm._m(0),
            ])
          : _vm._e(),
        _c("div", { staticClass: "row", attrs: { id: "files" } }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _vm.user && _vm.user.is_admin
                ? _c(
                    "ul",
                    {
                      staticClass: "nav nav-tabs mb-4 justify-content-center",
                      attrs: { role: "tablist", id: "namespace_selector" },
                    },
                    [
                      _c(
                        "li",
                        {
                          staticClass: "nav-item",
                          attrs: { role: "presentation" },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link text-gray",
                              class: { active: !_vm.is_admin_folder() },
                              attrs: {
                                href: "#home",
                                "aria-controls": "home",
                                role: "tab",
                                "data-toggle": "tab",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.set_admin_folder(false)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "ion-document-text mr-2",
                              }),
                              _vm._v("Team Files "),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "li",
                        {
                          staticClass: "nav-item",
                          attrs: { role: "presentation" },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link text-gray",
                              class: { active: _vm.is_admin_folder() },
                              attrs: {
                                href: "#profile",
                                "aria-controls": "profile",
                                role: "tab",
                                "data-toggle": "tab",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.set_admin_folder(true)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "ion-lock-combination mr-2",
                              }),
                              _vm._v("Admin Folder"),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _c("deleted-groups", {
                ref: "deletedGroups",
                attrs: { user: _vm.user },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 col-lg-10" }, [
      _c("div", { staticClass: "cardbox text-white bg-gradient-warning b0" }, [
        _c(
          "div",
          { staticClass: "cardbox-body text-center text-bold text-12" },
          [
            _vm._v(
              " Deleted files and directories are stored here for 30 days. "
            ),
            _c("br"),
            _vm._v(
              " You can recover them during this period, afterwards they are deleted automatically. "
            ),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }