var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "layout-container", class: { hidden: _vm.sidebar_hidden } },
    [
      !_vm.is_team_setup
        ? _c("aside", { staticClass: "sidebar-container" }, [
            _c(
              "div",
              {
                staticClass: "p-3 c-pointer text-muted",
                staticStyle: { position: "absolute" },
                attrs: { title: "Close sidebar" },
                on: {
                  click: function ($event) {
                    _vm.sidebar_hidden = true
                  },
                },
              },
              [_c("em", { staticClass: "ion-chevron-left" })]
            ),
            _c(
              "div",
              {
                staticClass:
                  "brand-header d-flex align-items-center justify-content-center",
              },
              [
                _c(
                  "router-link",
                  {
                    attrs: { to: "/files" },
                    on: {
                      "!click": function ($event) {
                        return _vm.menu_click.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm.show_team_logo
                      ? _c("img", {
                          staticStyle: {
                            "max-height": "50px",
                            "max-width": "200px",
                          },
                          attrs: { src: _vm.user.team_settings.logo_url },
                        })
                      : _c("img", {
                          attrs: {
                            src: require("@/assets/img/skyflok_logo.png"),
                            width: "130",
                          },
                        }),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "sidebar-content" }, [
              _c("div", { staticClass: "sidebar-toolbar" }, [
                _c("div", { staticClass: "sidebar-toolbar-background" }),
                _c(
                  "div",
                  { staticClass: "sidebar-toolbar-content text-center" },
                  [
                    _c(
                      "router-link",
                      {
                        staticStyle: {
                          "text-decoration": "none",
                          color: "white",
                        },
                        attrs: {
                          to: "/settings",
                          title: "Click to edit your settings",
                        },
                        on: {
                          "!click": function ($event) {
                            return _vm.menu_click.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm.user
                          ? _c("img", {
                              staticClass: "rounded-circle thumb64 fit-cover",
                              staticStyle: { "box-shadow": "0 0 5px 0 #888" },
                              attrs: {
                                src: _vm.user.avatar_url,
                                alt: "Profile",
                              },
                            })
                          : _vm._e(),
                        _c("div", { staticClass: "mt-3" }, [
                          _c("div", { staticClass: "lead text-bolder" }, [
                            _vm._v(_vm._s(_vm.user ? _vm.user.name : "")),
                          ]),
                          _c("div", {}, [
                            _vm._v(
                              _vm._s(_vm.user ? _vm.user.company_name : "")
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("nav", { staticClass: "sidebar-nav" }, [
                _c("ul", [
                  _c(
                    "li",
                    {
                      class: {
                        active: _vm.path === "/files",
                        disabled: _vm.no_buckets,
                      },
                      on: {
                        "!click": function ($event) {
                          return _vm.menu_click($event, "files")
                        },
                      },
                    },
                    [
                      _c("router-link", { attrs: { to: "/files" } }, [
                        _c("span", { staticClass: "nav-icon" }, [
                          _c("em", { staticClass: "ion-document-text" }),
                        ]),
                        _c("span", [_vm._v("Team Files")]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "recycle_bin",
                      class: {
                        active: _vm.path === "/recycle_bin",
                        disabled: _vm.no_buckets,
                        dragover: _vm.dragover_recycle_bin,
                      },
                      on: {
                        drop: function ($event) {
                          $event.preventDefault()
                          return _vm.drop_to_bin($event)
                        },
                        dragover: function ($event) {
                          $event.preventDefault()
                          return _vm.dragover_bin($event)
                        },
                        dragleave: function ($event) {
                          $event.preventDefault()
                          _vm.dragover_recycle_bin = false
                        },
                        "!click": function ($event) {
                          return _vm.menu_click.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("router-link", { attrs: { to: "/recycle_bin" } }, [
                        _c("span", { staticClass: "nav-icon" }, [
                          _c("em", { staticClass: "ion-trash-a" }),
                        ]),
                        _c("span", [_vm._v("Recycle Bin")]),
                      ]),
                    ],
                    1
                  ),
                  _vm.share_enabled
                    ? _c(
                        "li",
                        {
                          class: {
                            active: _vm.path === "/shares",
                            disabled: _vm.no_buckets,
                          },
                          on: {
                            "!click": function ($event) {
                              return _vm.menu_click.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("router-link", { attrs: { to: "/shares" } }, [
                            _c("span", { staticClass: "nav-icon" }, [
                              _c("em", { staticClass: "ion-link" }),
                            ]),
                            _c("span", [_vm._v("Shares")]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.client_spaces_enabled
                    ? _c(
                        "li",
                        {
                          class: {
                            active: _vm.path === "/client_spaces",
                            disabled: _vm.no_buckets,
                          },
                          on: {
                            "!click": function ($event) {
                              return _vm.menu_click.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/client_spaces" } },
                            [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("em", { staticClass: "ion-filing" }),
                              ]),
                              _c("span", [_vm._v("Client Spaces")]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.client_spaces_enabled && _vm.is_admin
                    ? _c(
                        "li",
                        {
                          class: {
                            active: _vm.path === "/admin_folder",
                            disabled: _vm.no_buckets,
                          },
                          on: {
                            "!click": function ($event) {
                              return _vm.menu_click.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/admin_folder" } },
                            [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("em", {
                                  staticClass: "ion-lock-combination",
                                }),
                              ]),
                              _c("span", [_vm._v("Admin Folder")]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "li",
                    {
                      class: {
                        active: _vm.path === "/team",
                        disabled: _vm.no_buckets,
                      },
                      on: {
                        "!click": function ($event) {
                          return _vm.menu_click.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("router-link", { attrs: { to: "/team" } }, [
                        _c("span", { staticClass: "nav-icon" }, [
                          _c("em", { staticClass: "ion-person-stalker" }),
                        ]),
                        _c("span", [_vm._v("Team Members")]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    {
                      class: {
                        active: _vm.path === "/settings",
                        disabled: _vm.no_buckets,
                      },
                      on: {
                        "!click": function ($event) {
                          return _vm.menu_click.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("router-link", { attrs: { to: "/settings" } }, [
                        _c("span", { staticClass: "nav-icon" }, [
                          _c("em", { staticClass: "ion-wrench" }),
                        ]),
                        _c("span", [_vm._v("My Settings")]),
                        _vm.user &&
                        _vm.user.menu_warning &&
                        _vm.user.menu_warning.indexOf("settings") >= 0
                          ? _c(
                              "span",
                              { staticClass: "float-right nav-label pr-3" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "badge-rounded badge-warning",
                                  },
                                  [_vm._v("!")]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                  _vm.is_admin
                    ? _c("li", [
                        _c(
                          "div",
                          {
                            staticClass: "sidebar-nav-heading text-muted pb-1",
                          },
                          [_vm._v("TEAM MANAGEMENT")]
                        ),
                      ])
                    : _vm._e(),
                  _vm.is_admin &&
                  _vm.user.team_settings &&
                  _vm.user.team_settings.is_free_trial
                    ? _c(
                        "li",
                        {
                          class: {
                            active: _vm.path === "/upgrade-team",
                            "bg-gradient-warning text-bold text-gray-dark":
                              _vm.path !== "/upgrade-team",
                          },
                          on: {
                            "!click": function ($event) {
                              return _vm.menu_click.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/upgrade-team" } },
                            [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("em", {
                                  staticClass: "ion-star",
                                  class: {
                                    "text-gray-dark":
                                      _vm.path !== "/upgrade-team",
                                  },
                                }),
                              ]),
                              _c("span", [_vm._v("Upgrade Account")]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.is_admin
                    ? _c(
                        "li",
                        {
                          class: {
                            active: _vm.path === "/team-settings",
                            disabled: _vm.no_buckets,
                          },
                          on: {
                            "!click": function ($event) {
                              return _vm.menu_click.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/team-settings" } },
                            [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("em", { staticClass: "ion-gear-a" }),
                              ]),
                              _c("span", [_vm._v("Team Settings")]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.is_admin
                    ? _c(
                        "li",
                        {
                          class: {
                            active: _vm.path === "/user-management",
                            disabled: _vm.no_buckets,
                          },
                          on: {
                            "!click": function ($event) {
                              return _vm.menu_click.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/user-management" } },
                            [
                              _c("span", { staticClass: "nav-icon" }, [
                                _c("em", { staticClass: "ion-person-add" }),
                              ]),
                              _c("span", [_vm._v("User Management")]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "nav-footer" }, [
                false && _vm.data_locations && _vm.data_locations.length > 0
                  ? _c(
                      "div",
                      { staticClass: "mb-3", attrs: { id: "data-locations" } },
                      [
                        _c("location-flags", {
                          attrs: {
                            locations: _vm.data_locations,
                            disable_popover: _vm.is_mobile,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.user && _vm.user.team_settings
                  ? _c("div", [
                      _vm.user.team_settings.max_storage !== null
                        ? _c("div", { staticClass: "usage_container mb-5" }, [
                            _c("div", {
                              staticClass: "storage_used bg-gradient-primary",
                              style: {
                                width:
                                  Math.max(
                                    0,
                                    Math.round(
                                      (_vm.user.team_storage_used /
                                        _vm.user.team_settings.max_storage) *
                                        100
                                    )
                                  ) + "%",
                              },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("format_bytes")(
                                    _vm.user.team_storage_used
                                  )
                                ) +
                                " of " +
                                _vm._s(
                                  _vm._f("format_bytes")(
                                    _vm.user.team_settings.max_storage
                                  )
                                ) +
                                " used "
                            ),
                          ])
                        : _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("format_bytes")(
                                    _vm.user.team_storage_used
                                  )
                                ) +
                                " storage used "
                            ),
                          ]),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-center",
                  },
                  [
                    _c("div", { staticClass: "mr-1" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-sm text-sm",
                          on: {
                            click: function ($event) {
                              return _vm.set_theme("theme-default")
                            },
                          },
                        },
                        [
                          _vm._v("Bright"),
                          !_vm.theme || _vm.theme == "theme-default"
                            ? _c("i", { staticClass: "ion-checkmark ml-1" })
                            : _vm._e(),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "ml-1" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-sm btn-outline-secondary text-sm",
                          on: {
                            click: function ($event) {
                              return _vm.set_theme("theme-dark")
                            },
                          },
                        },
                        [
                          _vm._v("Dark"),
                          _vm.theme == "theme-dark"
                            ? _c("i", { staticClass: "ion-checkmark ml-1" })
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]
                ),
                _vm.show_team_logo
                  ? _c("div", { staticClass: "text-center mt-4" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/skyflok_logo.png"),
                          height: "30",
                        },
                      }),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "sidebar-layout-obfuscator" }),
      _c(
        "main",
        { staticClass: "main-container" },
        [
          !_vm.is_team_setup
            ? _c(
                "header",
                {
                  staticClass: "header-container",
                  class: { hidden: _vm.file_dragged },
                },
                [
                  _c("global-alert", { attrs: { alert: _vm.alert } }),
                  _c("nav", [
                    _vm.sidebar_hidden
                      ? _c("ul", [
                          _c(
                            "li",
                            {
                              attrs: { title: "Open sidebar" },
                              on: {
                                click: function ($event) {
                                  _vm.sidebar_hidden = false
                                },
                              },
                            },
                            [_vm._m(0)]
                          ),
                        ])
                      : _vm._e(),
                    _vm._m(1),
                    _vm._m(2),
                    !_vm.is_mobile && !_vm.hide_route_name
                      ? _c("h2", { staticClass: "header-title mr-auto" }, [
                          _vm._v(" " + _vm._s(_vm.$route.name) + " "),
                          false
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "d-inline-block badge badge-sm badge-secondary",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "d-block d-sm-none" },
                                    [_vm._v("XS")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-none d-sm-block d-md-none",
                                    },
                                    [_vm._v("SM")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-none d-md-block d-lg-none",
                                    },
                                    [_vm._v("MD")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-none d-lg-block d-xl-none",
                                    },
                                    [_vm._v("LG")]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "d-none d-xl-block" },
                                    [_vm._v("XL")]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.header_info_icon
                            ? _c("i", {
                                staticClass:
                                  "ml-1 ion-information-circled p-0 c-help",
                                staticStyle: { "font-size": ".7em" },
                                attrs: {
                                  "data-container": "body",
                                  "data-toggle": "popover",
                                  "data-placement": "bottom",
                                  "data-html": true,
                                  "data-trigger": _vm.is_mobile
                                    ? "click"
                                    : "hover",
                                  "data-content": _vm.header_info_icon,
                                },
                              })
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "float-right mr-2" },
                      [
                        _c("search-box"),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.user,
                                expression: "user",
                              },
                            ],
                            staticClass: "dropdown d-inline-block ml-2",
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-toggle text-gray",
                                attrs: { href: "#", "data-toggle": "dropdown" },
                              },
                              [
                                _vm.user
                                  ? _c("img", {
                                      staticClass:
                                        "header-user-image fit-cover initial32",
                                      attrs: { src: _vm.user.avatar_url },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "dropdown-menu dropdown-menu-right dropdown-scale mt-4",
                              },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: { to: "/settings" },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "ion-gear-a icon-lg text-primary mr-3",
                                    }),
                                    _vm._v("My Settings "),
                                  ]
                                ),
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: { to: "/bugreport" },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "ion-bug icon-lg text-primary mr-3",
                                    }),
                                    _vm._v("Feedback, Bug Report "),
                                  ]
                                ),
                                _vm._m(3),
                                _c("div", {
                                  staticClass: "dropdown-divider",
                                  attrs: { role: "presentation" },
                                }),
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "dropdown-item c-pointer text-danger",
                                    on: {
                                      click: function ($event) {
                                        return _vm.logout()
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "ion-log-out icon-lg mr-3",
                                    }),
                                    _vm._v("Log out "),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.plan_expires_days >= 0 && _vm.plan_expires_days <= 14
            ? _c(
                "div",
                {
                  staticClass:
                    "bottom-container bg-gradient-warning text-white py-3",
                },
                [
                  _c("div", { staticClass: "text-center text-bold" }, [
                    _vm._v(
                      " Your " +
                        _vm._s(
                          _vm.user.team_settings.plan_key == "skyflok-trial"
                            ? "free trial"
                            : "team subscription"
                        ) +
                        " will end " +
                        _vm._s(_vm._f("days_rel")(_vm.plan_expires_days)) +
                        "! "
                    ),
                    _vm._m(4),
                  ]),
                ]
              )
            : _vm._e(),
          _c("router-view", {
            attrs: {
              user: _vm.user,
              route: _vm.$route,
              features: _vm.features,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "#" } }, [
      _c("span", [_c("em", { staticClass: "ion-chevron-right" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "d-inline-block d-lg-none text-left" }, [
      _c("li", [
        _c(
          "a",
          {
            staticClass: "sidebar-toggler menu-link menu-link-close",
            attrs: { href: "#" },
          },
          [_c("span", [_c("em")])]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "d-none d-xs-block text-left" }, [
      _c("li", [
        _c(
          "a",
          {
            staticClass: "covermode-toggler menu-link menu-link-close",
            attrs: { href: "#" },
          },
          [_c("span", [_c("em")])]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "dropdown-item c-pointer",
        attrs: {
          href: "https://www.skyflok.com/legal",
          target: "_BLANK",
          rel: "noopener",
        },
      },
      [
        _c("i", { staticClass: "ion-quote icon-lg text-primary mr-3" }),
        _vm._v("Terms & Privacy"),
        _c("i", { staticClass: "ion-share ml-1" }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "ml-2 text-gray-darker",
        attrs: {
          href: "https://www.skyflok.com/#Pricing",
          target: "_BLANK",
          rel: "noopener",
        },
      },
      [_vm._v("Upgrade "), _c("i", { staticClass: "ion-share ml-1" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }