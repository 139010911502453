var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "small",
      { staticClass: "d-block fw text-center mb-2 text-gray" },
      [
        _vm._v("Made in Denmark "),
        _c("img", {
          staticClass: "ml-1",
          staticStyle: { "vertical-align": "center" },
          attrs: {
            src: require("@/assets/img/flags/DK.png"),
            height: "20",
            alt: "Danish flag",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }