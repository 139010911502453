var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.entity
    ? _c(
        "span",
        { staticClass: "d-inline-block mr-2 mb-2 bg-gray-lighter px-2 py-1" },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-content-center align-items-center" },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm.is_file
                  ? _c("img", {
                      staticClass: "thumb32",
                      attrs: {
                        src: _vm._f("filetype_img_src")(
                          _vm._f("extension")(_vm.entity.name)
                        ),
                      },
                    })
                  : _c("i", { staticClass: "ion-folder" }),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.entity.name))]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }