import { render, staticRenderFns } from "./deleted_groups.vue?vue&type=template&id=3845edca"
import script from "./deleted_groups.vue?vue&type=script&lang=js"
export * from "./deleted_groups.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/mfeher/Code/SkyFlok/FileManager/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3845edca')) {
      api.createRecord('3845edca', component.options)
    } else {
      api.reload('3845edca', component.options)
    }
    module.hot.accept("./deleted_groups.vue?vue&type=template&id=3845edca", function () {
      api.rerender('3845edca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/deleted_groups.vue"
export default component.exports