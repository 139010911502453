var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "text-center" }, [
    _vm.mfa_cooldown_seconds && _vm.mfa_cooldown_seconds > 0
      ? _c("div", [
          _vm._v(" " + _vm._s(_vm.mfa_cooldown_seconds) + " seconds to go "),
        ])
      : _vm._e(),
    _vm.mfa_mode == _vm.MFA_MODE_APP
      ? _c("section", [
          _c(
            "div",
            {
              staticClass:
                "my-3 text-center d-flex align-items-center justify-content-center",
            },
            [
              _c("i", { staticClass: "ion-iphone mr-2 text-md" }),
              _c("div", { staticClass: "mr-2" }, [
                _vm._v("Authentication Code:"),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.code,
                    expression: "code",
                  },
                ],
                staticClass: "form-control form-control-inverse",
                staticStyle: { width: "160px" },
                attrs: {
                  id: "mfa_code",
                  type: "number",
                  min: "0",
                  max: "999999",
                  maxlength: "6",
                  required: "",
                  placeholder: "123456",
                },
                domProps: { value: _vm.code },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.code = $event.target.value
                  },
                },
              }),
            ]
          ),
          _c("div", { staticClass: "mt-2" }, [
            _c("i", { staticClass: "ion-information-circled mr-1" }),
            _vm._v(
              ' Copy the 6-digit code from your Authenticator mobile app for "' +
                _vm._s(
                  _vm.user_email ? "SkyFlok, " + _vm.user_email : "SkyFlok"
                ) +
                '" '
            ),
          ]),
        ])
      : _vm.mfa_mode == _vm.MFA_MODE_SMS
      ? _c("section", [
          _c("div", { staticClass: "m-2 text-12" }, [
            _vm._v(" Copy the code that you received in SMS to "),
            _c("span", { staticClass: "nowrap" }, [
              _vm._v(_vm._s(_vm.sms_phone_number)),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass:
                "my-3 text-center d-flex align-items-center justify-content-center",
            },
            [
              _c("i", { staticClass: "ion-chatbox-working mr-2 text-md" }),
              _c("div", { staticClass: "mr-2" }, [_vm._v("SMS Code:")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.code,
                    expression: "code",
                  },
                ],
                staticClass: "form-control form-control-inverse",
                staticStyle: { width: "160px" },
                attrs: {
                  id: "mfa_code",
                  type: "number",
                  maxlength: "6",
                  required: "",
                  placeholder: "123456",
                  autocomplete: "one-time-code",
                },
                domProps: { value: _vm.code },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.code = $event.target.value
                  },
                },
              }),
            ]
          ),
        ])
      : _vm.mfa_mode == "backup"
      ? _c("section", [
          _c(
            "div",
            {
              staticClass:
                "my-3 text-center d-flex align-items-center justify-content-center",
            },
            [
              _c("div", { staticClass: "mr-2" }, [_vm._v("Backup Code:")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.code,
                    expression: "code",
                  },
                ],
                staticClass: "form-control form-control-inverse",
                staticStyle: { width: "160px" },
                attrs: {
                  id: "mfa_code",
                  type: "text",
                  required: "",
                  placeholder: "aa11bb22",
                },
                domProps: { value: _vm.code },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.code = $event.target.value
                  },
                },
              }),
            ]
          ),
          _c("div", { staticClass: "fw text-center mt-3" }, [
            _c(
              "a",
              {
                staticClass: "text-white",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return (() => {
                      _vm.$emit("change_mfa_mode", _vm.original_mfa_mode)
                    }).apply(null, arguments)
                  },
                },
              },
              [
                _c("i", { staticClass: "ion-arrow-left-c mr-2" }),
                _vm._v(" Back to "),
                _vm.original_mfa_mode == _vm.MFA_MODE_APP
                  ? _c("span", [_vm._v("authentication code")])
                  : _vm.original_mfa_mode == _vm.MFA_MODE_SMS
                  ? _c("span", [_vm._v("SMS code")])
                  : _vm._e(),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm.mfa_mode != "backup"
      ? _c("div", { staticClass: "mt-4" }, [
          _c(
            "a",
            {
              staticClass: "text-white text-underline",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("change_mfa_mode", "backup")
                },
              },
            },
            [_vm._v("Use a backup code instead")]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }