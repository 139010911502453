var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "section-container" }, [
    _vm._m(0),
    _c("div", { staticClass: "container-fluid" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-2" }),
        _c("div", { staticClass: "col-12 col-lg-8" }, [
          _c("div", { staticClass: "cardbox" }, [
            _c("div", { staticClass: "cardbox-body" }, [
              _vm.form_sent
                ? _c("div", [
                    _c("h2", { staticClass: "text-center mt-4 mb-4" }, [
                      _vm._v("Your report has been sent successfully!"),
                    ]),
                  ])
                : _c(
                    "form",
                    {
                      staticClass: "mt-3",
                      attrs: { action: "" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.submit_form.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("What went wrong?")]),
                        _c("div", { staticClass: "input-group" }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.text,
                                expression: "form.text",
                              },
                            ],
                            staticClass: "form-control fw",
                            staticStyle: { height: "14em" },
                            attrs: { id: "bugreport_text" },
                            domProps: { value: _vm.form.text },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(_vm.form, "text", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _vm._m(1),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _vm.user && _vm.user.team_settings
                          ? _c("div", { staticClass: "flex" }, [
                              _c("label", { staticClass: "mr-2" }, [
                                _vm._v("Reported by:"),
                              ]),
                              _c("img", {
                                staticClass:
                                  "shadow-z2 thumb32 rounded fit-cover",
                                attrs: { src: _vm.user.avatar_url },
                              }),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.user.name) +
                                    ", " +
                                    _vm._s(_vm.user.team_settings.name)
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("checkbox", {
                            attrs: {
                              value: _vm.form.response_requested,
                              label:
                                "I would like to be contacted about this issue",
                            },
                            on: {
                              changed: (newval) => {
                                _vm.form.response_requested = newval
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-success",
                            attrs: {
                              disabled: _vm.form_loading,
                              type: "submit",
                            },
                          },
                          [
                            _vm._v("Send bug report!"),
                            _vm.form_loading
                              ? _c("i", { staticClass: "ion-load-c spin ml-2" })
                              : _vm._e(),
                          ]
                        ),
                        _vm.error
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(_vm.error)),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "container-overlap bg-gradient-success text-center" },
      [
        _c("div", { staticClass: "mb-3" }),
        _c("div", { staticClass: "text-white" }, [
          _c("div", { staticClass: "text-lg" }, [
            _c("i", { staticClass: "ion-bug" }),
          ]),
          _c("div", { staticClass: "h3" }, [
            _vm._v(
              "Sorry for your inconvenience, please tell us what happened!"
            ),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-right" }, [
      _c("small", [
        _vm._v("Please describe the problem with as much detail as you can!"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }