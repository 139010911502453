var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.mfa_content == "status"
    ? _c("section", { staticClass: "fw text-center my-2" }, [
        _vm.mfa_status
          ? _c("div", [
              _vm.mfa_status.has_2fa && _vm.mfa_status.verified
                ? _c(
                    "section",
                    {
                      staticClass: "text-success",
                      class: { "text-14": _vm.is_client_space },
                    },
                    [
                      _c("i", { staticClass: "ion-locked mr-2" }),
                      _vm._v(
                        " Enabled, using " +
                          _vm._s(
                            _vm.mfa_status.kind == 1
                              ? "mobile authenticator app"
                              : "SMS"
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm.mfa_status.has_2fa && !_vm.mfa_status.verified
                ? _c("section", { staticClass: "text-warning" }, [
                    _c("i", { staticClass: "ion-alert-circled mr-1" }),
                    _vm._v(
                      " Enabled using " +
                        _vm._s(
                          _vm.mfa_status.kind == 1
                            ? "mobile authenticator app"
                            : "SMS"
                        ) +
                        ", but not verified! "
                    ),
                    _c("br"),
                    _c(
                      "a",
                      {
                        staticClass: "ml-1",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return (() => {
                              _vm.$emit(
                                "mfa_content_change",
                                _vm.mfa_status.kind == 1
                                  ? "show_qr"
                                  : "change_phone_number"
                              )
                            }).apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("Verify now")]
                    ),
                  ])
                : _c("section", [
                    _vm._v(
                      " Your account is not protected by two factor authentication. "
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success mt-3",
                        on: {
                          click: () => {
                            _vm.$emit("mfa_content_change", "setup")
                            _vm.setup_mfa_step = 1
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "ion-locked mr-2" }),
                        _vm._v("Enable Two-Factor Authentication "),
                      ]
                    ),
                  ]),
            ])
          : _c("div", [
              _vm._v(" Loading... "),
              _c("i", { staticClass: "ion-load-c spin ml-1" }),
            ]),
      ])
    : _vm.mfa_content == "setup"
    ? _c(
        "div",
        { staticClass: "fw text-center" },
        [
          _vm.setup_mfa_step == 1
            ? _c("section", [
                _c("div", { staticClass: "alert alert-secondary" }, [
                  _vm._v(
                    " You can use either an Authenticator app on your phone, or SMS to receive one time passcodes when you are logging in from a new device or browser. This significantly improves security of your account. "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "fw d-flex justify-content-center mt-3" },
                  [
                    _c("div", { staticClass: "mr-1" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          on: {
                            click: function ($event) {
                              _vm.setup_mfa_step = "app"
                            },
                          },
                        },
                        [_vm._v("Use an app on your phone")]
                      ),
                      _c("br"),
                      _vm._m(0),
                    ]),
                    _c("div", { staticClass: "ml-1" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn",
                          attrs: { disabled: true, title: "Coming soon" },
                          on: {
                            click: function ($event) {
                              _vm.setup_mfa_step = "sms"
                            },
                          },
                        },
                        [_vm._v("Receive codes in SMS")]
                      ),
                    ]),
                  ]
                ),
              ])
            : _vm.setup_mfa_step == "app"
            ? _c("mfa-setup-app", {
                attrs: { user_email: _vm.email_address, show_error: _vm.error },
                on: {
                  completed: () => {
                    _vm.mfa_setup_ready()
                  },
                },
              })
            : _vm.setup_mfa_step == "sms"
            ? _c("section", { staticClass: "my-3" }, [
                _vm._v(
                  " Two factor authentication with SMS is not supported yet "
                ),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm.mfa_content == "delete"
    ? _c(
        "div",
        [
          _c("mfa-disable", {
            attrs: {
              show_success: _vm.success,
              hash_password:
                _vm.is_client_space === undefined ? false : _vm.is_client_space,
            },
            on: {
              disabled: () => {
                _vm.mfa_disabled()
              },
              cancelled: () => {
                _vm.$emit("mfa_content_change", "status")
              },
            },
          }),
        ],
        1
      )
    : _vm.mfa_content == "lost_phone"
    ? _c("div", { staticClass: "mt-2" }, [
        _c("div", { staticClass: "alert alert-secondary" }, [
          _c("p", [
            _vm._v(
              " If you lost the phone that was set up for " +
                _vm._s(
                  _vm.mfa_status.kind == 1
                    ? "generating codes"
                    : "receiving codes in SMS"
                ) +
                ", do the following: "
            ),
          ]),
          _c("ol", { staticClass: "lost_phone_instructions" }, [
            _c("li", [
              _vm._v("First "),
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return (() => {
                        _vm.$emit("mfa_content_change", "delete")
                      }).apply(null, arguments)
                    },
                  },
                },
                [_vm._v("disable two-factor authentication")]
              ),
              _vm._v(
                " so the lost phone won't be able to " +
                  _vm._s(
                    _vm.mfa_status.kind == 1
                      ? "generate codes"
                      : "receive codes"
                  ) +
                  " for your account"
              ),
            ]),
            _c("li", [
              _vm._v(
                "Then, set up two-factor auth again using your new phone."
              ),
            ]),
            _c("li", [
              _vm._v(
                "Finally, review your active SkyFlok sessions below and delete the ones from the lost phone."
              ),
            ]),
            _c("li", [
              _vm._v(
                "(it's good practice to change all of your passwords when you lose a personal device, just to be safe)"
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "fw text-center mt-2" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-secondary",
              on: {
                click: () => {
                  _vm.$emit("mfa_content_change", "status")
                },
              },
            },
            [_c("i", { staticClass: "ion-arrow-left-c mr-2" }), _vm._v("Back ")]
          ),
        ]),
      ])
    : _vm.mfa_content == "show_qr"
    ? _c(
        "div",
        [
          _c("show-verify-qr", {
            attrs: { url: _vm.mfa_app_url, show_error: _vm.error },
            on: {
              verified: () => {
                _vm.mfa_setup_ready()
              },
            },
          }),
        ],
        1
      )
    : _vm.mfa_content == "recovery_codes"
    ? _c(
        "div",
        [
          _c("mfa-recovery-codes", {
            attrs: {
              user_email: _vm.email_address,
              show_success: _vm.success,
              show_error: _vm.error,
            },
          }),
          _c("div", { staticClass: "fw text-center mt-2" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                on: {
                  click: () => {
                    _vm.$emit("mfa_content_change", "status")
                  },
                },
              },
              [
                _c("i", { staticClass: "ion-arrow-left-c mr-2" }),
                _vm._v("Back "),
              ]
            ),
          ]),
        ],
        1
      )
    : _vm.mfa_content == "history"
    ? _c(
        "div",
        [
          _c("mfa-history", {
            staticClass: "mt-3",
            attrs: {
              is_client_space: _vm.is_client_space,
              show_success: _vm.success,
              show_error: _vm.error,
            },
          }),
          _c("div", { staticClass: "fw text-center" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                on: {
                  click: () => {
                    _vm.$emit("mfa_content_change", "status")
                  },
                },
              },
              [
                _c("i", { staticClass: "ion-arrow-left-c mr-2" }),
                _vm._v("Back "),
              ]
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("small", { staticClass: "text-success" }, [
      _c("i", { staticClass: "ion-locked mr-1" }),
      _vm._v("This is the more secure option"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }