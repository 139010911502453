var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.success
    ? _c("section", [
        _vm._m(0),
        _c(
          "form",
          {
            staticClass: "text-center",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submit_delete()
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-items-center justify-content-center mt-2",
                staticStyle: { margin: "0px auto" },
              },
              [
                _c("div", { staticClass: "mr-2" }, [_vm._v("Password:")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.password,
                      expression: "password",
                    },
                  ],
                  staticClass: "form-control width-50p",
                  attrs: { type: "password" },
                  domProps: { value: _vm.password },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.password = $event.target.value
                    },
                  },
                }),
              ]
            ),
            _c(
              "div",
              { staticClass: "mt-2" },
              [
                _c("checkbox", {
                  attrs: {
                    value: _vm.failsafe,
                    label:
                      "I understand the risk of disabling two-factor authentication",
                  },
                  on: { changed: (newval) => (_vm.failsafe = newval) },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-items-center justify-content-center mt-3",
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary mr-1",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return (() => {
                          _vm.$emit("cancelled")
                        }).apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Cancel")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger btn-gradient ml-1",
                    attrs: { disabled: !_vm.form_ok || _vm.loading },
                  },
                  [
                    _vm._v(" Disable two-factor authentication "),
                    _vm.loading
                      ? _c("i", { staticClass: "ion-load-c spin ml-1" })
                      : _vm._e(),
                  ]
                ),
              ]
            ),
            _vm.error
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.error)),
                ])
              : _vm._e(),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "alert alert-warning text-bold" }, [
      _vm._v(" You are about to weaken the security of your account!"),
      _c("br"),
      _vm._v(
        " Proceed only if you have a good reason, for example your phone is lost. "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }