var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "layout-container" }, [
    _c("div", { staticClass: "page-container" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c(
          "div",
          {
            staticClass: "fw py-3 text-bold",
            staticStyle: {
              position: "fixed",
              top: "0px",
              "z-index": "1000",
              background: "#f6fbfe",
            },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-items-center justify-content-center gap-2",
              },
              [
                _c("button", {
                  staticClass: "btn btn-flat text-gray ion-chevron-left",
                  class: { hidden: !_vm.nav_back_enabled },
                  attrs: { disabled: !_vm.nav_back_enabled },
                  on: {
                    click: function ($event) {
                      return _vm.move_date_range("back")
                    },
                  },
                }),
                _c("div", { staticClass: "text-12" }, [
                  _vm._v(
                    _vm._s(_vm.date_range.year) +
                      " " +
                      _vm._s(_vm.selected_month_name)
                  ),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-flat text-gray",
                    class: { hidden: !_vm.nav_fwd_enabled },
                    attrs: { disabled: !_vm.nav_fwd_enabled },
                    on: {
                      click: function ($event) {
                        return _vm.move_date_range("fwd")
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "ion-chevron-right",
                      attrs: { "data-pack": "default" },
                    }),
                  ]
                ),
              ]
            ),
          ]
        ),
        _c("div", { staticClass: "row mt-5" }, [
          _c("div", { staticClass: "col-1" }),
          _c("div", { staticClass: "col-12 col-lg-10" }, [
            !_vm.selected_month_msmts || !_vm.backends_with_data.length
              ? _c("div", [_vm._v("Loading performance data...")])
              : _c("div", [
                  _c(
                    "div",
                    { staticClass: "text-bold text-gray-darker px-4" },
                    [_vm._v("Average Provider Speed")]
                  ),
                  _c("div", { staticClass: "text-muted px-4 text-sm" }, [
                    _vm._v(
                      "Download and upload speed across all locations of the provider."
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "px-2",
                      staticStyle: {
                        "overflow-x": "scroll",
                        "overflow-y": "hidden",
                        "white-space": "nowrap",
                      },
                    },
                    _vm._l(_vm.providers_with_speeds, function (p, idx) {
                      return _c(
                        "div",
                        {
                          key: idx,
                          staticClass:
                            "col-11 col-sm-5 col-lg-4 col-xl-3 d-inline-block pt-3 pb-5",
                          attrs: { "data-provider-name": p.name },
                        },
                        [
                          _c("div", { staticClass: "cardbox mb-0" }, [
                            _c(
                              "div",
                              { staticClass: "cardbox-body text-center" },
                              [
                                p.logo
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center justify-content-center",
                                        staticStyle: { height: "50px" },
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: {
                                            "max-width": "150px",
                                            "max-height": "40px",
                                          },
                                          attrs: {
                                            src: _vm.Utils.cloud_provider_logo_src(
                                              p.logo
                                            ),
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _c("div", { staticClass: "fw pt-3" }, [
                                  false
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "mb-0 text-center text-bold",
                                        },
                                        [_vm._v(_vm._s(p.name))]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-between",
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(Math.round(p.downloads_avg)) +
                                            " Mbps "
                                        ),
                                        _c("i", {
                                          staticClass:
                                            "ion-arrow-down-a text-primary",
                                        }),
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(Math.round(p.uploads_avg)) +
                                            " Mbps "
                                        ),
                                        _c("i", {
                                          staticClass:
                                            "ion-arrow-up-a text-success",
                                        }),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    {},
                    [
                      _c("div", { staticClass: "text-bold text-gray-darker" }, [
                        _vm._v("Speed Map"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "fw py-3 d-flex justify-content-start" },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "30%",
                                "min-width": "300px",
                              },
                            },
                            [
                              _c("div", {
                                style: {
                                  height: "6px",
                                  "border-radius": "3px",
                                  background: `linear-gradient(to right, rgb(${_vm.colorscale.min.join(
                                    ","
                                  )}), rgb(${_vm.colorscale.max.join(",")}))`,
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-center justify-content-between text-sm",
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.speedscale.min) + " Mbps"
                                    ),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.speedscale.max) + " Mbps"
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "gmap-map",
                        {
                          ref: "map",
                          staticStyle: { width: "100%", height: "550px" },
                          attrs: {
                            center: _vm.mapCenter,
                            zoom: _vm.mapZoom,
                            disableDefaultUI: true,
                            options: {
                              styles: _vm.map_style,
                              disableDefaultUI: true,
                            },
                          },
                        },
                        [
                          _c("gmap-info-window", {
                            attrs: {
                              options: _vm.infoOptions,
                              position: _vm.infoWindowPos,
                              opened: _vm.infoWinOpen,
                            },
                            on: {
                              closeclick: function ($event) {
                                _vm.infoWinOpen = false
                              },
                            },
                          }),
                          _vm._l(
                            _vm.backends_with_data.filter(
                              (b) =>
                                b.download_speeds && b.download_speeds.length
                            ),
                            function (b, index) {
                              return _c("gmap-marker", {
                                key: index,
                                attrs: {
                                  title: `${b.provider} ${b.name}`,
                                  position: b,
                                  icon: {
                                    path: "M 100, 100m-75, 0a 75,75 0 1,0 150,0a 75,75 0 1,0 -150,0",
                                    fillColor: `rgb(${_vm
                                      .pickHex(
                                        _vm.scaleDownloadSpeed(
                                          _vm.Utils.avg(b.download_speeds)
                                        )
                                      )
                                      .join(",")})`,
                                    fillOpacity: 1,
                                    strokeWeight: 0,
                                    rotation: 0,
                                    scale: 0.1,
                                  },
                                  draggable: false,
                                  clickable: true,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleInfoWindow(b)
                                  },
                                },
                              })
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-5" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "mb-4 d-flex align-items-center justify-content-between",
                        },
                        [
                          _c("div", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.search,
                                  expression: "search",
                                },
                              ],
                              staticClass: "form-control",
                              staticStyle: { width: "300px" },
                              attrs: {
                                type: "search",
                                placeholder: "Provider or location",
                              },
                              domProps: { value: _vm.search },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.search = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center gap-3",
                            },
                            _vm._l(
                              _vm.backends_categories,
                              function (cat, idx) {
                                return _c(
                                  "button",
                                  {
                                    key: idx,
                                    staticClass: "btn",
                                    class: {
                                      "btn-skyflok":
                                        _vm.backend_filter == cat.value,
                                      "btn-skyflok-outline":
                                        _vm.backend_filter != cat.value,
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.backend_filter = cat.value
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(cat.label))]
                                )
                              }
                            ),
                            0
                          ),
                          _c("div", { staticStyle: { width: "300px" } }),
                        ]
                      ),
                      _vm._l(
                        _vm.backends_with_data_filtered,
                        function (b, idx) {
                          return _c(
                            "div",
                            {
                              key: idx,
                              staticClass: "cardbox",
                              style: {
                                display:
                                  `${b.provider} ${b.name}`
                                    .toLowerCase()
                                    .search(_vm.search.toLowerCase()) < 0
                                    ? "none"
                                    : "block",
                              },
                            },
                            [
                              _c("div", { staticClass: "cardbox-body" }, [
                                _c("p", { staticClass: "text-bold" }, [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.Utils.highlight_search(
                                          `${b.provider} ${b.name}`,
                                          _vm.search
                                        )
                                      ),
                                    },
                                  }),
                                  _c("img", {
                                    staticClass: "ml-1",
                                    attrs: {
                                      src: _vm.Utils.flag_img_src(
                                        b.countrycode
                                      ),
                                    },
                                  }),
                                ]),
                                b.download_speeds && b.download_speeds.length
                                  ? _c("section", [
                                      !_vm.eu_countrycodes.includes(
                                        b.countrycode
                                      )
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-warning text-sm mb-3",
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "ion-alert-circled mr-1",
                                              }),
                                              _vm._v(
                                                " Note: Transfer speeds and latency are currently measured from Europe and are "
                                              ),
                                              _c("u", [
                                                _vm._v("not representative"),
                                              ]),
                                              _vm._v(
                                                " of the performance of this storage location. "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("div", {
                                        staticClass: "ml-5",
                                        attrs: {
                                          id: `flotchart-speed-${b.id}-legend`,
                                        },
                                      }),
                                      _c("div", {
                                        staticClass: "flot-chart",
                                        attrs: {
                                          id: `flotchart-speed-${b.id}`,
                                        },
                                      }),
                                      _c("div", {
                                        staticClass: "mt-4 ml-5",
                                        attrs: {
                                          id: `flotchart-latency-${b.id}-legend`,
                                        },
                                      }),
                                      _c("div", {
                                        staticClass: "flot-chart latency",
                                        attrs: {
                                          id: `flotchart-latency-${b.id}`,
                                        },
                                      }),
                                    ])
                                  : _c("section", [
                                      _c(
                                        "div",
                                        { staticClass: "text-center" },
                                        [_vm._v("No data available")]
                                      ),
                                    ]),
                              ]),
                            ]
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ]),
          ]),
        ]),
        false
          ? _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _vm._v(" Cached: "),
                  _vm._l(Object.entries(_vm.cache), function ([date, msmts]) {
                    return _c("div", { key: date }, [
                      _vm._v(
                        " " + _vm._s(date) + ": " + _vm._s(msmts.length) + "x "
                      ),
                    ])
                  }),
                ],
                2
              ),
            ])
          : _vm._e(),
        false
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _vm._v(" " + _vm._s(_vm.selected_month_msmts) + " "),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }