var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "fw" }, [
    _c("div", [_vm._v("When a Space is archived:")]),
    _vm._m(0),
    _vm._m(1),
    _vm._m(2),
    _vm._m(3),
    _vm._m(4),
    _c("div", { staticClass: "mt-3 fw d-flex justify-content-center" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-lg btn-warning btn-gradient text-bold mr-1",
          attrs: { disabled: _vm.loading },
          on: {
            click: function ($event) {
              return _vm.archive_space(false)
            },
          },
        },
        [
          _vm._v(" Archive "),
          _c("i", {
            class: {
              "ion-load-c spin": _vm.action == "archive" && _vm.loading,
              "ion-archive": !_vm.loading,
            },
          }),
        ]
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-lg btn-danger btn-gradient text-bold ml-1",
          attrs: { disabled: _vm.loading },
          on: {
            click: function ($event) {
              return _vm.archive_space(true)
            },
          },
        },
        [
          _vm._v(" Delete "),
          _c("i", {
            class: {
              "ion-load-c spin": _vm.action == "delete" && _vm.loading,
              "ion-trash-b": !_vm.loading,
            },
          }),
        ]
      ),
    ]),
    _vm.error
      ? _c("div", { staticClass: "text-danger" }, [_vm._v(_vm._s(_vm.error))])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mt-3 mb-1" }, [
      _c("i", { staticClass: "ion-checkmark mr-2 ml-4" }),
      _vm._v(" The Client cannot access it any more. "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-1" }, [
      _c("i", { staticClass: "ion-checkmark mr-2 ml-4" }),
      _vm._v(
        " You can open it, but cannot download and upload files or change anything. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-1" }, [
      _c("i", { staticClass: "ion-checkmark mr-2 ml-4" }),
      _vm._v(
        " Can be re-activated any time, but it might take a few minutes. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-1" }, [
      _c("i", { staticClass: "ion-checkmark mr-2 ml-4" }),
      _vm._v(" Optionally, you can "),
      _c("b", [_vm._v("delete the Space and files in it after 30 days")]),
      _vm._v(" mandatory time period. "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-2 text-right" }, [
      _c(
        "a",
        {
          attrs: {
            href: "#",
            "data-container": "body",
            "data-toggle": "popover",
            "data-placement": "top",
            "data-html": "true",
            "data-trigger": "hover",
            "data-content":
              "To protect you and your Clients from malware, ransomware and viruses, SkyFlok doesn't allow deleting data immediately. <br/><br/>If you need to delete the Space and its contents sooner, please contact us via email at <i>support@skyflok.com</i>",
          },
        },
        [_vm._v("Can I delete the Space immediately?")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }