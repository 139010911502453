var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm.success
      ? _c("div", { staticClass: "alert alert-success" }, [
          _vm._v(_vm._s(_vm.success)),
        ])
      : _vm._e(),
    _vm.error
      ? _c("div", { staticClass: "alert alert-danger" }, [
          _vm._v(_vm._s(_vm.error)),
        ])
      : _vm._e(),
    !_vm.codes
      ? _c("section", { staticClass: "fw text-center" }, [
          _vm._v(" Loading... "),
          _c("i", { staticClass: "ion-load-c spin ml-1" }),
        ])
      : _c("section", [
          !_vm.space_id
            ? _c("div", { staticClass: "alert alert-info" }, [
                _c("p", [
                  _vm._v(
                    "If you lose your phone, the only way to login is using one of these backup code. Keep them as secure as your password! "
                  ),
                ]),
                _vm._v(
                  " It's highly recommended to store them in a trusted password manager like "
                ),
                _c(
                  "a",
                  {
                    staticClass: "text-bold",
                    attrs: { href: "https://lastpass.com", target: "_BLANK" },
                  },
                  [_vm._v("LastPass")]
                ),
                _vm._v(", "),
                _c(
                  "a",
                  {
                    staticClass: "text-bold",
                    attrs: { href: "https://1password.com", target: "_BLANK" },
                  },
                  [_vm._v("1Password")]
                ),
                _vm._v(" or "),
                _c(
                  "a",
                  {
                    staticClass: "text-bold",
                    attrs: {
                      href: "https://keepersecurity.com",
                      target: "_BLANK",
                    },
                  },
                  [_vm._v("Keeper")]
                ),
                _vm._v(". "),
              ])
            : _vm._e(),
          _c("div", { staticClass: "text-bold my-2 text-center" }, [
            _c(
              "span",
              {
                class: {
                  "text-warning": _vm.few_codes,
                  "text-success": !_vm.few_codes,
                },
              },
              [
                _vm.few_codes
                  ? _c("i", { staticClass: "ion-alert-circled mr-1" })
                  : _vm._e(),
                _vm._v(
                  " " +
                    _vm._s(_vm.client_name ? _vm.client_name : "You") +
                    " " +
                    _vm._s(_vm.few_codes ? "only " : "") +
                    _vm._s(_vm.client_name ? "has" : "have") +
                    " " +
                    _vm._s(_vm.codes.length) +
                    " backup codes "
                ),
                !_vm.few_codes
                  ? _c("i", { staticClass: "ion-checkmark-circled ml-1" })
                  : _vm._e(),
              ]
            ),
            _vm.few_codes
              ? _c("span", { staticClass: "ml-4" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-gradient btn-secondary",
                      attrs: { disabled: _vm.generate_loading },
                      on: {
                        click: function ($event) {
                          return _vm.generate_additional_codes()
                        },
                      },
                    },
                    [
                      _vm._v(" Generate more codes "),
                      _vm.generate_loading
                        ? _c("i", { staticClass: "ion-load-c spin ml-1" })
                        : _vm._e(),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
          _c(
            "div",
            { staticClass: "row codes" },
            _vm._l(_vm.codes, function (code) {
              return _c(
                "div",
                { key: code, staticClass: "col-6 col-lg-4 text-center" },
                [_vm._v(" " + _vm._s(code) + " ")]
              )
            }),
            0
          ),
          _vm.codes && _vm.codes.length
            ? _c("div", { staticClass: "mt-2 row" }, [
                _c("div", { staticClass: "col-6 text-right" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function ($event) {
                          return _vm.copy_codes()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "ion-clipboard mr-2" }),
                      _vm._v("Copy "),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "col-6 text-left" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function ($event) {
                          return _vm.download_codes()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "ion-arrow-down-a mr-2" }),
                      _vm._v("Save "),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }