var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm._v(" Scan this QR code with your authenticator mobile app: "),
    _c("div", { staticClass: "fw text-center mt-2" }, [
      !_vm.qr_ready
        ? _c(
            "div",
            {
              staticClass: "d-flex align-items-center justify-content-center",
              staticStyle: {
                width: "200px",
                height: "200px",
                margin: "0px auto",
                background: "#ebebeb",
              },
            },
            [_c("i", { staticClass: "ion-load-c spin" })]
          )
        : _c("canvas", {
            staticStyle: { background: "#ebebeb" },
            attrs: { id: "qr_code", width: "200", height: "200" },
          }),
    ]),
    !_vm.success
      ? _c("div", { staticClass: "mt-2 fw text-center" }, [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.verify()
                },
              },
            },
            [
              _vm._v(" Input the 6-digit code that your app is showing: "),
              _c(
                "div",
                { staticClass: "input-group col-12 col-lg-6 offset-lg-3 mt-2" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.mfa_code_verify,
                        expression: "mfa_code_verify",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "number",
                      id: _vm.input_id,
                      placeholder: "123456",
                    },
                    domProps: { value: _vm.mfa_code_verify },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.mfa_code_verify = $event.target.value
                      },
                    },
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-gradient ml-2",
                      attrs: {
                        type: "submit",
                        disabled:
                          !_vm.mfa_code_verify ||
                          _vm.mfa_code_verify.length != 6 ||
                          _vm.loading,
                      },
                    },
                    [
                      _vm._v(" Verify "),
                      _vm.loading
                        ? _c("i", { staticClass: "ion-load-c spin ml-1" })
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
              _vm.error
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.error)),
                  ])
                : _vm._e(),
            ]
          ),
        ])
      : _c("div", { staticClass: "fw text-center text-success text-bold" }, [
          _vm._v(" Setup successful! "),
        ]),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "alert alert-secondary text-left mt-3" }, [
      _vm._v(
        " You can use any mobile application that can generate time-based one time passwords (TOTP), such as: "
      ),
      _c("div", { staticClass: "row mt-3 auth_app_logos" }, [
        _c("div", { staticClass: "col-4 col-lg-2 text-center" }, [
          _c("img", {
            staticClass: "auth_app_logo",
            attrs: {
              src: require("@/assets/img/authenticator_logos/google.png"),
            },
          }),
          _c("small", [_vm._v("Google Authenticator")]),
        ]),
        _c("div", { staticClass: "col-4 col-lg-2 text-center" }, [
          _c("img", {
            staticClass: "auth_app_logo",
            attrs: {
              src: require("@/assets/img/authenticator_logos/authy.png"),
            },
          }),
          _c("small", [_vm._v("Twilio Authy")]),
        ]),
        _c("div", { staticClass: "col-4 col-lg-2 text-center" }, [
          _c("img", {
            staticClass: "auth_app_logo",
            attrs: {
              src: require("@/assets/img/authenticator_logos/1pass.png"),
            },
          }),
          _c("small", [_vm._v("1Password")]),
        ]),
        _c("div", { staticClass: "col-4 col-lg-2 text-center" }, [
          _c("img", {
            staticClass: "auth_app_logo",
            attrs: {
              src: require("@/assets/img/authenticator_logos/lastpass.png"),
            },
          }),
          _c("small", [_vm._v("LastPass Authenticator")]),
        ]),
        _c("div", { staticClass: "col-4 col-lg-2 text-center" }, [
          _c("img", {
            staticClass: "auth_app_logo",
            attrs: {
              src: require("@/assets/img/authenticator_logos/microsoft.png"),
            },
          }),
          _c("small", [_vm._v("Microsoft Authenticator")]),
        ]),
        _c("div", { staticClass: "col-4 col-lg-2 text-center" }, [
          _c("img", {
            staticClass: "auth_app_logo",
            attrs: {
              src: require("@/assets/img/authenticator_logos/freeotp.png"),
            },
          }),
          _c("small", [_vm._v("FreeOTP Authenticator")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }