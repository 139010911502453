var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user
    ? _c("span", { staticClass: "user_box", attrs: { title: _vm.user.name } }, [
        _vm.user.avatar_url && !_vm.no_avatar && _vm.avatar_left
          ? _c("img", {
              staticClass: "thumb32 rounded-circle shadow-z2 mr-2",
              staticStyle: { "object-fit": "cover" },
              attrs: { src: _vm.user.avatar_url },
            })
          : _vm._e(),
        _c(
          "span",
          { class: { "text-muted text-italic": _vm.user.deleted === true } },
          [_vm._v(_vm._s(_vm.user.name))]
        ),
        _vm.user.avatar_url && !_vm.no_avatar && !_vm.avatar_left
          ? _c("img", {
              staticClass: "thumb32 rounded-circle shadow-z2 ml-2",
              staticStyle: { "object-fit": "cover" },
              attrs: { src: _vm.user.avatar_url },
            })
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }