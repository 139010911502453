var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c("section", { staticClass: "d-none d-xl-block" }, [
      _vm.form
        ? _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit_form.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "table",
                { staticClass: "table client_space_form settings-table mb-0" },
                [
                  _vm.form_error
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "2" } }, [
                          _c("div", {
                            staticClass: "alert alert-danger",
                            domProps: { innerHTML: _vm._s(_vm.form_error) },
                          }),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.batch_mode && !_vm.raw_csv
                    ? _c("tr", [
                        _vm._m(0),
                        _c("td", [
                          !_vm.raw_csv
                            ? _c("input", {
                                staticClass: "form-control form-control-sm",
                                attrs: { type: "file" },
                                on: { change: _vm.read_csv },
                              })
                            : _c(
                                "button",
                                {
                                  staticClass: "btn btn-sm btn-secondary",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      _vm.raw_csv = null
                                      _vm.parsed_contacts = null
                                    },
                                  },
                                },
                                [_vm._v("Load a new CSV")]
                              ),
                          _vm._m(1),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.batch_mode
                    ? _c("tr", [
                        _c("th", [
                          _vm._v("Clients"),
                          _vm.parsed_contacts
                            ? _c("span", [
                                _vm._v(
                                  " (" +
                                    _vm._s(_vm.parsed_contacts.length) +
                                    ")"
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _c("td", [
                          !_vm.parsed_contacts
                            ? _c(
                                "div",
                                { staticClass: "muted text-sm text-italic" },
                                [
                                  _vm._v(
                                    "You will see the list of imported clients here after loading a CSV file"
                                  ),
                                ]
                              )
                            : _c("div", [
                                _vm.parsed_contacts.length !=
                                _vm.valid_batch_clients.length
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "m-2 text-warning text-sm",
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "ion-alert-circled mr-1",
                                        }),
                                        _vm._v(
                                          " There is a problem with " +
                                            _vm._s(
                                              _vm.parsed_contacts.length -
                                                _vm.valid_batch_clients.length
                                            ) +
                                            " out of " +
                                            _vm._s(_vm.parsed_contacts.length) +
                                            " contacts. We cannot create a Space for them unless you fix the problems manually. "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "max-height": "300px",
                                      "overflow-y": "auto",
                                    },
                                  },
                                  [
                                    _c(
                                      "table",
                                      {
                                        staticClass:
                                          "table table-sm table-bordered",
                                      },
                                      [
                                        _c("thead", [
                                          _c("th", {
                                            staticClass: "text-center",
                                            staticStyle: { width: "1px" },
                                          }),
                                          _c(
                                            "th",
                                            { staticStyle: { width: "1px" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "c-pointer",
                                                  attrs: {
                                                    "data-container": "body",
                                                    "data-toggle": "popover",
                                                    "data-placement": "top",
                                                    "data-html": "true",
                                                    "data-trigger": "hover",
                                                    "data-content":
                                                      "Send an invite email to the client.<br/><br/>Click to check/uncheck all",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.parsed_contacts[0].send_email =
                                                        !_vm.parsed_contacts[0]
                                                          .send_email
                                                      _vm.parsed_contacts.map(
                                                        (c) => {
                                                          c.send_email =
                                                            _vm.parsed_contacts[0].send_email
                                                        }
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "ion-email",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [_vm._v("Client Name")]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [_vm._v("E-mail address")]
                                          ),
                                          _c("th", {}),
                                        ]),
                                        _c(
                                          "tbody",
                                          [
                                            _vm._l(
                                              _vm.parsed_contacts,
                                              function (contact, index) {
                                                return _c(
                                                  "tr",
                                                  { key: index },
                                                  [
                                                    _c(
                                                      "td",
                                                      { staticClass: "nowrap" },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(index + 1) +
                                                            ". "
                                                        ),
                                                        contact.name == "" ||
                                                        !_vm.validate_email(
                                                          contact.email
                                                        )
                                                          ? _c("i", {
                                                              staticClass:
                                                                "ion-alert-circled text-warning",
                                                              attrs: {
                                                                title:
                                                                  "There is a problem with this contact, please fix if you want to create a Space for him/her!",
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c("td", [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              contact.send_email,
                                                            expression:
                                                              "contact.send_email",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control form-control-sm",
                                                        attrs: {
                                                          type: "checkbox",
                                                        },
                                                        domProps: {
                                                          checked:
                                                            Array.isArray(
                                                              contact.send_email
                                                            )
                                                              ? _vm._i(
                                                                  contact.send_email,
                                                                  null
                                                                ) > -1
                                                              : contact.send_email,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                contact.send_email,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    contact,
                                                                    "send_email",
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    contact,
                                                                    "send_email",
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                contact,
                                                                "send_email",
                                                                $$c
                                                              )
                                                            }
                                                          },
                                                        },
                                                      }),
                                                    ]),
                                                    _c(
                                                      "td",
                                                      {
                                                        class: {
                                                          "table-danger":
                                                            contact.name == "",
                                                        },
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                contact.name,
                                                              expression:
                                                                "contact.name",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control form-control-sm",
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          domProps: {
                                                            value: contact.name,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.$set(
                                                                contact,
                                                                "name",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        class: {
                                                          "table-danger":
                                                            !_vm.validate_email(
                                                              contact.email
                                                            ),
                                                        },
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                contact.email,
                                                              expression:
                                                                "contact.email",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control form-control-sm",
                                                          attrs: {
                                                            type: "email",
                                                          },
                                                          domProps: {
                                                            value:
                                                              contact.email,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.$set(
                                                                contact,
                                                                "email",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-center",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "text-danger",
                                                            attrs: {
                                                              href: "#",
                                                              title:
                                                                "Remove this row",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.parsed_contacts.splice(
                                                                  index,
                                                                  1
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "ion-close-round",
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            _c("tr", [
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "text-center",
                                                  attrs: { colspan: "6" },
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass: "text-sm",
                                                      attrs: { href: "#" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.parsed_contacts.push(
                                                            {
                                                              name: "",
                                                              email: "",
                                                              send_email: true,
                                                            }
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "ion-plus-round mr-1",
                                                      }),
                                                      _vm._v(
                                                        "Add a client manually"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                        ]),
                      ])
                    : _vm._e(),
                  !_vm.batch_mode &&
                  (_vm.form.id === null ||
                    (_vm.space && !_vm.space.edit_managers_only))
                    ? _c("tr", [
                        _c("th", [_vm._v("Client name *")]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.client_name,
                                expression: "form.client_name",
                              },
                            ],
                            staticClass: "form-control form-control-sm",
                            attrs: {
                              type: "text",
                              id: "form-client_name",
                              required: "",
                              maxlength: "255",
                            },
                            domProps: { value: _vm.form.client_name },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.form,
                                  "client_name",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ])
                    : _vm._e(),
                  !_vm.batch_mode &&
                  (_vm.form.id === null ||
                    (_vm.space && !_vm.space.edit_managers_only))
                    ? _c("tr", [
                        _c("th", [_vm._v("Client e-mail *")]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.client_email,
                                expression: "form.client_email",
                              },
                            ],
                            staticClass: "form-control form-control-sm",
                            attrs: {
                              type: "email",
                              id: "form-client_email",
                              required: "",
                              maxlength: "255",
                            },
                            domProps: { value: _vm.form.client_email },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.form,
                                  "client_email",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.form.id === null ||
                  (_vm.space && !_vm.space.edit_managers_only)
                    ? _c("tr", [
                        _c("th", { staticClass: "v-top" }, [
                          _vm._v("Client logo"),
                          _vm.batch_mode
                            ? _c("div", [
                                _c("small", [
                                  _vm._v("(applies to all spaces)"),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                        _c(
                          "td",
                          {
                            staticStyle: { width: "80%", "padding-top": "0px" },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { id: "client_space_logos_carousel" } },
                              _vm._l(_vm.logos, function (logo) {
                                return _c(
                                  "div",
                                  {
                                    key: logo.path,
                                    staticClass: "slide d-inline-block",
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "logo c-pointer m-1",
                                      class: { selected: logo.selected },
                                      attrs: {
                                        title:
                                          logo.name +
                                          " (Click to " +
                                          (logo.selected ? "un" : "") +
                                          "select)",
                                        src: logo.path,
                                        width: "84",
                                        height: "84",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.select_logo(logo)
                                        },
                                      },
                                    }),
                                  ]
                                )
                              }),
                              0
                            ),
                            _vm.space !== null && _vm.custom_logo_url
                              ? _c("div", { staticClass: "mt-2" }, [
                                  _c("span", { staticClass: "text-sm mr-2" }, [
                                    _vm._v("Custom logo:"),
                                  ]),
                                  _c("img", {
                                    staticClass:
                                      "shadow-z5 thumb64 rounded fit-cover",
                                    attrs: { src: _vm.custom_logo_url },
                                  }),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-sm btn-secondary",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          _vm.custom_logo_url = null
                                        },
                                      },
                                    },
                                    [_vm._v("Clear")]
                                  ),
                                ])
                              : _c(
                                  "div",
                                  { staticClass: "mt-2" },
                                  [
                                    !_vm.custom_logo_url
                                      ? _c(
                                          "span",
                                          { staticClass: "text-sm mr-2" },
                                          [_vm._v("Upload custom logo:")]
                                        )
                                      : _vm._e(),
                                    _c("profile-image-selector", {
                                      staticClass: "d-inline-block",
                                      attrs: {
                                        avatar_changed: (avatar_url) => {
                                          _vm.custom_logo_url = avatar_url
                                          _vm.logos.forEach((logo) => {
                                            logo.selected = false
                                          })
                                        },
                                        max_dimension_px: 128,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm.form && _vm.form.client_readonly !== undefined
                    ? _c("tr", [
                        _c("th", { staticClass: "v-top" }, [
                          _vm._v("Client role"),
                          _vm.batch_mode
                            ? _c("div", [
                                _c("small", [
                                  _vm._v("(applies to all spaces)"),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                        _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "btn mb-2",
                              class: {
                                "btn-success": !_vm.form.client_readonly,
                                "btn-secondary": _vm.form.client_readonly,
                              },
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.form.client_readonly = false
                                },
                              },
                            },
                            [
                              _c("b", [_vm._v("Upload, download and delete")]),
                              _c("br"),
                              _vm._v(
                                " The client can upload, download & delete files"
                              ),
                              _c("br"),
                              _vm._v("and change the folder structure. "),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              class: {
                                "btn-primary": _vm.form.client_readonly,
                                "btn-secondary": !_vm.form.client_readonly,
                              },
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.form.client_readonly = true
                                },
                              },
                            },
                            [
                              _c("b", [_vm._v("Download only")]),
                              _c("br"),
                              _vm._v(" The client cannot change anything "),
                              _c("br"),
                              _vm._v(
                                "or upload new files, just browse and download. "
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _c("tr", [
                    _c("th", { staticClass: "v-top nowrap" }, [
                      _vm._v(" Space Managers "),
                      _c("i", {
                        staticClass: "ion-information-circled icon-lg ml-1",
                        attrs: {
                          "data-container": "body",
                          "data-toggle": "popover",
                          "data-placement": "top",
                          "data-html": "true",
                          "data-trigger": "hover",
                          "data-content":
                            "Team Members who are allowed to upload files to this Space.<br/><br/>Note that Team Administrators can see and open every Space, but even they cannot upload/download if they are not Space Managers.",
                        },
                      }),
                      _vm.batch_mode
                        ? _c("div", [
                            _c("small", [_vm._v("(applies to all spaces)")]),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "text-normal",
                          class: {
                            "text-danger":
                              _vm.members.filter((m) => {
                                return m.selected
                              }).length === 0,
                          },
                        },
                        [
                          _c("i", { staticClass: "text-sm" }, [
                            _vm._v(
                              _vm._s(
                                _vm.members.filter((m) => {
                                  return m.selected
                                }).length
                              ) + " selected"
                            ),
                          ]),
                        ]
                      ),
                      _c("div", [
                        _c("small", [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  _vm.members.filter((m) => {
                                    return m.selected
                                  }).length < _vm.members.length
                                    ? _vm.members.map((m) => {
                                        m.selected = true
                                      })
                                    : _vm.members.map((m) => {
                                        m.selected = false
                                      })
                                },
                              },
                            },
                            [
                              _vm._v(
                                "Select " +
                                  _vm._s(
                                    _vm.members.filter((m) => {
                                      return m.selected
                                    }).length < _vm.members.length
                                      ? "all"
                                      : "none"
                                  )
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _c(
                      "td",
                      _vm._l(_vm.members, function (member) {
                        return _c(
                          "div",
                          {
                            key: member.user_id,
                            staticClass: "float-left member-box m-1",
                            class: {
                              "bg-info text-white active text-bold":
                                member.selected,
                            },
                            on: {
                              click: function ($event) {
                                member.selected = !member.selected
                              },
                            },
                          },
                          [
                            _c("user-box", {
                              staticClass: "m-1 ml-2 mr-2",
                              attrs: {
                                team: _vm.team,
                                "user-id": member.user_id,
                                "no-prefix": true,
                                title:
                                  "Click to " +
                                  (member.selected ? "remove" : "add") +
                                  " " +
                                  member.name,
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Notifications")]),
                    _c("td", [
                      _c(
                        "button",
                        {
                          staticClass: "btn text-bold mr-1",
                          class: {
                            "btn-success": _vm.form.watch_managers,
                            "btn-secondary": !_vm.form.watch_managers,
                          },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.form.watch_managers = true
                            },
                          },
                        },
                        [
                          _vm._v("Yes "),
                          _c("i", {
                            staticClass: "ion-android-notifications ml-2",
                          }),
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn text-bold",
                          class: {
                            "btn-secondary btn-gradient":
                              !_vm.form.watch_managers,
                            "btn-secondary": _vm.form.watch_managers,
                          },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.form.watch_managers = false
                            },
                          },
                        },
                        [
                          _vm._v("No "),
                          _c("i", {
                            staticClass: "ion-android-notifications-off ml-2",
                          }),
                        ]
                      ),
                      _c("br"),
                      _c("small", [
                        _vm._v(
                          "Space Managers get an email notification when the Client uploads new files"
                        ),
                      ]),
                    ]),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      { staticClass: "text-right", attrs: { colspan: "2" } },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary mr-2",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.closeDialog()
                              },
                            },
                          },
                          [_vm._v("Cancel")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-info btn-gradient",
                            attrs: {
                              type: "submit",
                              disabled:
                                _vm.form_loading || !_vm.is_submit_allowed,
                            },
                          },
                          [
                            _vm.form.id !== null
                              ? _c("span", [_vm._v("Update Space")])
                              : !_vm.batch_mode
                              ? _c("span", [
                                  _vm._v("Create Space & Send Invite "),
                                  _c("i", {
                                    staticClass: "ion-paper-airplane ml-1",
                                  }),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    "Create " +
                                      _vm._s(_vm.valid_batch_clients.length) +
                                      " Space" +
                                      _vm._s(
                                        _vm.valid_batch_clients.length === 1
                                          ? ""
                                          : "s"
                                      ) +
                                      " & Send Invitation" +
                                      _vm._s(
                                        _vm.valid_batch_clients.length === 1
                                          ? ""
                                          : "s"
                                      )
                                  ),
                                ]),
                            _vm.form_loading
                              ? _c("i", { staticClass: "ml-2 ion-load-c spin" })
                              : _vm._e(),
                          ]
                        ),
                        _vm.form.id == null
                          ? _c("div", [
                              _c("small", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "c-help",
                                    attrs: {
                                      href: "#",
                                      "data-container": "body",
                                      "data-toggle": "popover",
                                      "data-placement": "top",
                                      "data-html": "true",
                                      "data-trigger": "hover",
                                      "data-content":
                                        "When you click the Create button below: <ul><li>The Space is created, you can start using it immediately.</li><li>We send an email to your Client with an invitation link. The link expires in 24 hours.</li><li>When your Client clicks the link, they can set their own password and you get an email notification.</li><li>If the invitation expires without the Client accepting it, you get an email notification and you can send a new invite.</li></ul>",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return (() => {
                                          return false
                                        }).apply(null, arguments)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "What happens when I click create? "
                                    ),
                                    _c("i", {
                                      staticClass: "ion-information-circled",
                                    }),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]),
    _c("section", { staticClass: "d-block d-xl-none" }, [
      _vm.form_error
        ? _c("div", {
            staticClass: "alert alert-danger",
            domProps: { innerHTML: _vm._s(_vm.form_error) },
          })
        : _vm._e(),
      _vm.form
        ? _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit_form.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "mobile-form-field" }, [
                _c("div", { staticClass: "text-bold" }, [
                  _vm._v("Client Name"),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.client_name,
                      expression: "form.client_name",
                    },
                  ],
                  staticClass: "form-control form-control-sm",
                  attrs: {
                    type: "text",
                    id: "form-client_name",
                    required: "",
                    maxlength: "255",
                  },
                  domProps: { value: _vm.form.client_name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "client_name", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "mobile-form-field" }, [
                _c("div", { staticClass: "text-bold" }, [
                  _vm._v("Client E-mail"),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.client_email,
                      expression: "form.client_email",
                    },
                  ],
                  staticClass: "form-control form-control-sm",
                  attrs: {
                    type: "email",
                    id: "form-client_email",
                    required: "",
                    maxlength: "255",
                  },
                  domProps: { value: _vm.form.client_email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "client_email", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm.form.id === null ||
              (_vm.space && !_vm.space.edit_managers_only)
                ? _c("div", { staticClass: "mobile-form-field" }, [
                    _c("div", { staticClass: "text-bold" }, [_vm._v("Logo")]),
                    _c(
                      "div",
                      {
                        staticClass: "mb-2",
                        attrs: { id: "client_space_logos_carousel" },
                      },
                      _vm._l(_vm.logos, function (logo) {
                        return _c(
                          "div",
                          {
                            key: logo.path,
                            staticClass: "slide d-inline-block",
                          },
                          [
                            _c("img", {
                              staticClass: "builtin-logo logo c-pointer m-1",
                              class: { selected: logo.selected },
                              attrs: {
                                title:
                                  logo.name +
                                  " (Click to " +
                                  (logo.selected ? "un" : "") +
                                  "select)",
                                src: logo.path,
                                width: "50",
                                height: "50",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.select_logo(logo)
                                },
                              },
                            }),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm.space !== null && _vm.custom_logo_url
                      ? _c("div", [
                          _c("span", { staticClass: "text-sm mr-2" }, [
                            _vm._v("Custom logo:"),
                          ]),
                          _c("img", {
                            staticClass: "shadow-z5 thumb64 rounded fit-cover",
                            attrs: { src: _vm.custom_logo_url },
                          }),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-sm btn-secondary",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.custom_logo_url = null
                                },
                              },
                            },
                            [_vm._v("Clear")]
                          ),
                        ])
                      : _c(
                          "div",
                          [
                            !_vm.custom_logo_url
                              ? _c("span", { staticClass: "text-sm mr-2" }, [
                                  _vm._v("Or upload custom image:"),
                                ])
                              : _vm._e(),
                            _c("profile-image-selector", {
                              staticClass: "d-inline-block",
                              attrs: {
                                max_dimension_px: 128,
                                avatar_changed: (avatar_url) => {
                                  _vm.custom_logo_url = avatar_url
                                  _vm.logos.forEach((logo) => {
                                    logo.selected = false
                                  })
                                },
                              },
                            }),
                          ],
                          1
                        ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "mobile-form-field" }, [
                _c("div", { staticClass: "text-bold" }, [
                  _vm._v("Client role"),
                  _vm.batch_mode
                    ? _c("span", [
                        _c("small", [_vm._v("(applies to all spaces)")]),
                      ])
                    : _vm._e(),
                ]),
                _c(
                  "button",
                  {
                    staticClass:
                      "btn fw mb-2 btn-sm text-sm client-role-button",
                    class: {
                      "btn-success": !_vm.form.client_readonly,
                      "btn-secondary": _vm.form.client_readonly,
                    },
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.form.client_readonly = false
                      },
                    },
                  },
                  [
                    _c("b", [_vm._v("Upload, download and delete")]),
                    _c("div", { staticClass: "break-word d-block" }, [
                      _vm._v(
                        "The client can upload, download & delete files and change the folder structure."
                      ),
                    ]),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn fw btn-sm text-sm client-role-button",
                    class: {
                      "btn-primary": _vm.form.client_readonly,
                      "btn-secondary": !_vm.form.client_readonly,
                    },
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.form.client_readonly = true
                      },
                    },
                  },
                  [
                    _c("b", [_vm._v("Download only")]),
                    _c("br"),
                    _vm._v(
                      " The client cannot change anything or upload new files, just browse and download. "
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "mobile-form-field d-block" }, [
                _c("div", { staticClass: "text-bold" }, [
                  _vm._v(" Space Managers "),
                  _c(
                    "span",
                    {
                      staticClass: "text-normal ml-2",
                      class: {
                        "text-danger":
                          _vm.members.filter((m) => {
                            return m.selected
                          }).length === 0,
                      },
                    },
                    [
                      _c("i", { staticClass: "text-sm" }, [
                        _vm._v(
                          _vm._s(
                            _vm.members.filter((m) => {
                              return m.selected
                            }).length
                          ) + " selected"
                        ),
                      ]),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "fw" },
                  _vm._l(_vm.members, function (member) {
                    return _c(
                      "div",
                      {
                        key: member.user_id,
                        staticClass: "member-box m-1 d-inline-block",
                        class: {
                          "bg-info text-white active text-bold":
                            member.selected,
                        },
                        on: {
                          click: function ($event) {
                            member.selected = !member.selected
                          },
                        },
                      },
                      [
                        _c("user-box", {
                          staticClass: "m-1 ml-2 mr-2",
                          attrs: {
                            team: _vm.team,
                            "user-id": member.user_id,
                            "no-prefix": true,
                            title:
                              "Click to " +
                              (member.selected ? "remove" : "add") +
                              " " +
                              member.name,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "mobile-form-field" }, [
                _c("div", { staticClass: "text-bold" }, [
                  _vm._v("Notifications"),
                ]),
                _c("div", { staticClass: "fw" }, [
                  _c("span", { staticClass: "d-block text-sm mb-2" }, [
                    _vm._v(
                      "Get email notifications when the Client uploads new files"
                    ),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "btn text-bold btn-sm text-sm mr-1",
                      class: {
                        "btn-success": _vm.form.watch_managers,
                        "btn-secondary": !_vm.form.watch_managers,
                      },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.form.watch_managers = true
                        },
                      },
                    },
                    [
                      _vm._v("Yes "),
                      _c("i", {
                        staticClass: "ion-android-notifications ml-2",
                      }),
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn text-bold btn-sm text-sm",
                      class: {
                        "btn-secondary btn-gradient": !_vm.form.watch_managers,
                        "btn-secondary": _vm.form.watch_managers,
                      },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.form.watch_managers = false
                        },
                      },
                    },
                    [
                      _vm._v("No "),
                      _c("i", {
                        staticClass: "ion-android-notifications-off ml-2",
                      }),
                    ]
                  ),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "fw d-flex align-items-center justify-content-center mt-3",
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary mr-1",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.closeDialog()
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-info btn-gradient ml-1",
                      attrs: {
                        type: "submit",
                        disabled: _vm.form_loading || !_vm.is_submit_allowed,
                      },
                    },
                    [
                      _vm.form.id !== null
                        ? _c("span", [
                            _vm._v("Update Space"),
                            _vm.form_loading
                              ? _c("i", { staticClass: "ml-1 ion-load-c spin" })
                              : _vm._e(),
                          ])
                        : !_vm.batch_mode
                        ? _c("span", [
                            _vm._v("Create Space"),
                            _vm.form_loading
                              ? _c("i", { staticClass: "ml-1 ion-load-c spin" })
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]),
    _vm.form === null && _vm.new_space !== null
      ? _c("div", [
          _c("div", { staticClass: "alert alert-success" }, [
            _c("p", [
              _c("b", [
                _vm._v(
                  "Invitation sent to " + _vm._s(_vm.new_space.client_email)
                ),
              ]),
            ]),
            _vm._v(" You will receive an email when "),
            _c("b", [_vm._v(_vm._s(_vm.new_space.client_name))]),
            _vm._v(" joins the Space or when the invitation expires. "),
          ]),
          _c("div", { staticClass: "fw text-center" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary mr-1",
                on: {
                  click: () => {
                    _vm.closeDialog()
                  },
                },
              },
              [_vm._v("Close")]
            ),
            _c(
              "a",
              {
                staticClass: "btn btn-primary ml-1",
                attrs: {
                  href: _vm._f("client_space_link")(_vm.new_space.key),
                  target: "_BLANK",
                },
                on: {
                  click: () => {
                    _vm.closeDialog()
                  },
                },
              },
              [
                _vm._v("Open Space "),
                _c("i", { staticClass: "ion-share ml-1" }),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm.form === null && _vm.batch_result !== null
      ? _c("div", [
          _c("h5", { staticClass: "text-center mb-3" }, [
            _c("b", [
              _vm._v(
                _vm._s(
                  _vm.batch_result.filter((client) => {
                    return client.space_saved
                  }).length
                ) +
                  " out of " +
                  _vm._s(_vm.parsed_contacts.length)
              ),
            ]),
            _vm._v(" Client Spaces created successfully. "),
          ]),
          _c(
            "div",
            { staticStyle: { "max-height": "300px", "overflow-y": "auto" } },
            [
              _c("table", { staticClass: "table table-sm" }, [
                _vm._m(2),
                _c(
                  "tbody",
                  _vm._l(_vm.batch_result, function (space, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [_vm._v(_vm._s(index + 1) + ".")]),
                      _c("td", [_vm._v(" " + _vm._s(space.client_name) + " ")]),
                      _c("td", [
                        _vm._v(" " + _vm._s(space.client_email) + " "),
                      ]),
                      _c("td", { staticClass: "text-center" }, [
                        space.space_saved
                          ? _c("i", {
                              staticClass: "text-success ion-checkmark-circled",
                            })
                          : _c("i", {
                              staticClass: "text-warning ion-alert-circled",
                            }),
                      ]),
                      _c("td", { staticClass: "text-center" }, [
                        space.email_sent
                          ? _c("i", {
                              staticClass: "text-success ion-checkmark-circled",
                            })
                          : _c("i", {
                              staticClass: "ion-minus-circled text-muted",
                            }),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
            ]
          ),
          _c("div", { staticClass: "text-center mt-4" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                on: {
                  click: function ($event) {
                    return _vm.closeDialog()
                  },
                },
              },
              [_vm._v("Close this window")]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", {}, [
      _vm._v(" Outlook contacts"),
      _c("br"),
      _vm._v("CSV file "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "mt-2 text-sm d-flex align-items-center justify-content-between",
      },
      [
        _c(
          "a",
          {
            attrs: {
              href: "https://support.office.com/en-us/article/export-contacts-from-outlook-10f09abd-643c-4495-bb80-543714eca73f",
              target: "_BLANK",
            },
          },
          [
            _c("i", { staticClass: "ion-share mr-1" }),
            _vm._v("How to export contacts from Outlook"),
          ]
        ),
        _c("div", [
          _c(
            "a",
            {
              attrs: {
                href: "assets/static/skyflok_client_space_import_simple.csv",
                title: "Download a simple example CSV file",
                download: "skyflok_client_space_import_simple.csv",
              },
            },
            [_vm._v("Example csv 1")]
          ),
          _c(
            "a",
            {
              staticClass: "ml-1",
              attrs: {
                href: "assets/static/outlook_contacts_exported.csv",
                title: "Download an example for Outlook exported contacts",
                download: "outlook_contacts_exported.csv",
              },
            },
            [_vm._v("Example csv 2")]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("th", { staticClass: "width-0" }),
      _c("th", {}, [_vm._v("Client Name")]),
      _c("th", {}, [_vm._v("E-mail address")]),
      _c("th", { staticClass: "text-center nowrap width-0" }, [
        _vm._v("Space created"),
      ]),
      _c("th", { staticClass: "text-center nowrap width-0" }, [
        _vm._v("Invite sent"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }