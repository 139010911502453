var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "alert top-alert", class: _vm.get_class() }, [
    _c("div", { domProps: { innerHTML: _vm._s(_vm.html) } }),
    _c("i", {
      staticClass: "ion-close-round",
      attrs: { title: "close" },
      on: {
        click: function ($event) {
          _vm.visible = false
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }