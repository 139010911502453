var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c(
      "div",
      {
        staticClass: "c-pointer",
        on: {
          click: function ($event) {
            _vm.basic_info_open = !_vm.basic_info_open
          },
        },
      },
      [
        _c("span", { staticClass: "text-12" }, [_vm._v("Basic info")]),
        _c("i", {
          staticClass: "ml-2",
          class: {
            "ion-chevron-up": _vm.basic_info_open,
            "ion-chevron-down": !_vm.basic_info_open,
          },
        }),
      ]
    ),
    _c(
      "table",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.basic_info_open,
            expression: "basic_info_open",
          },
        ],
        staticClass: "table table-sm mt-2 file-details-table mb-0",
      },
      [
        _c("tbody", [
          _c("tr", [
            _c("th", [_vm._v("Name")]),
            _c("td", [_vm._v(_vm._s(_vm.file.name))]),
          ]),
          _c("tr", [
            _c("th", [_vm._v("Size")]),
            _c("td", [
              _vm._v(_vm._s(_vm._f("format_bytes")(_vm.file.size)) + " "),
              _c("small", [
                _vm._v(
                  "(" +
                    _vm._s(Number(_vm.file.size).toLocaleString()) +
                    " bytes)"
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("th", [_vm._v("Type")]),
            _c("td", { staticClass: "text-monospace break-word" }, [
              _vm._v(_vm._s(_vm.file.mime_type)),
            ]),
          ]),
        ]),
      ]
    ),
    !_vm.hide_shares
      ? _c("section", [
          _c(
            "div",
            {
              staticClass: "c-pointer mt-4",
              on: {
                click: function ($event) {
                  _vm.sharing_open = !_vm.sharing_open
                },
              },
            },
            [
              _c("span", { staticClass: "text-12" }, [
                _vm._v("Sharing"),
                _vm.shares_num !== null
                  ? _c("span", { staticClass: "ml-2 badge badge-primary" }, [
                      _vm._v(_vm._s(_vm.shares_num)),
                    ])
                  : _vm._e(),
              ]),
              _c("i", {
                staticClass: "ml-2",
                class: {
                  "ion-chevron-up": _vm.sharing_open,
                  "ion-chevron-down": !_vm.sharing_open,
                },
              }),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.sharing_open,
                  expression: "sharing_open",
                },
              ],
              staticClass: "mt-2",
            },
            [
              _c("file-sharing-status", {
                attrs: {
                  user: _vm.user,
                  file: _vm.file,
                  shares_num_changed: (num) => {
                    _vm.shares_num = num
                  },
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    !_vm.hide_versions
      ? _c("section", [
          _c(
            "div",
            {
              staticClass: "c-pointer mt-4",
              on: {
                click: function ($event) {
                  _vm.versions_open = !_vm.versions_open
                },
              },
            },
            [
              _c("span", { staticClass: "text-12" }, [
                _vm._v("Versions"),
                _vm.versions_num !== null
                  ? _c("span", { staticClass: "ml-2 badge badge-primary" }, [
                      _vm._v(_vm._s(_vm.versions_num)),
                    ])
                  : _vm._e(),
              ]),
              _c("i", {
                staticClass: "ml-2",
                class: {
                  "ion-chevron-up": _vm.versions_open,
                  "ion-chevron-down": !_vm.versions_open,
                },
              }),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.versions_open,
                  expression: "versions_open",
                },
              ],
              staticClass: "mt-2",
            },
            [
              _c("file-versions-table", {
                attrs: {
                  file: _vm.file,
                  user: _vm.user,
                  modifiedBoxExternal: _vm.modifiedBoxExternal,
                  download_file_fn: _vm.download_file_fn,
                  open_file_fn: null,
                  versions_num_changed: (num) => {
                    _vm.versions_num = num
                  },
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }