var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "nowrap d-inline-block" }, [
    !_vm.is_narrow_screen
      ? _c("section", [
          _vm.loading
            ? _c("i", { staticClass: "ion-load-c spin text-muted mr-2" })
            : _vm._e(),
          _c(
            "span",
            {
              on: {
                mouseover: function ($event) {
                  _vm.search_focused = true
                },
                mouseout: function ($event) {
                  _vm.search_focused = false
                },
                focus: function ($event) {
                  _vm.search_focused = true
                },
                blur: function ($event) {
                  _vm.search_focused = false
                },
              },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.search_term,
                    expression: "search_term",
                  },
                ],
                staticClass: "form-control",
                class: { wide: _vm.wide_search, hidden: _vm.hideinput },
                attrs: {
                  type: "text",
                  name: "skyflok-search",
                  id: "skyflok-search-input",
                  placeholder: _vm.placeholder || "Search Team Files",
                },
                domProps: { value: _vm.search_term },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.search_term = $event.target.value
                  },
                },
              }),
              _vm.search_term != ""
                ? _c("i", {
                    staticClass: "ion-close-round c-pointer",
                    staticStyle: { "margin-left": "-20px" },
                    on: {
                      click: function ($event) {
                        _vm.search_term = ""
                        _vm.search_focused = false
                      },
                    },
                  })
                : _vm._e(),
              _c("button", {
                staticClass: "ml-2 btn btn-secondary btn-gradient ion-search",
                attrs: { id: "searchbutton" },
                on: {
                  click: ($evt) => $evt.target.parentNode.children[0].focus(),
                },
              }),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.search_term != "" && _vm.results,
                  expression: "search_term != '' && results",
                },
              ],
              staticClass: "list-group mb-4 search_results shadow-z5",
            },
            _vm._l(_vm.results, function (result) {
              return _c(
                "a",
                {
                  key: result.id,
                  staticClass: "list-group-item",
                  attrs: {
                    href: "#",
                    title:
                      result.entity_type == _vm.FOLDER_TYPE
                        ? "Navigate to " + result.path
                        : "Show " + result.name,
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.search_result_clicked(result)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "text-center",
                      staticStyle: { width: "40px" },
                    },
                    [
                      result.entity_type == _vm.FOLDER_TYPE
                        ? _c("i", { staticClass: "ion-folder" })
                        : _vm._e(),
                      result.entity_type == _vm.FILE_TYPE
                        ? _c("img", {
                            staticClass: "file-icon",
                            attrs: {
                              src: _vm._f("filetype_img_src")(result.extension),
                            },
                          })
                        : _vm._e(),
                    ]
                  ),
                  _c("div", { staticClass: "ml-2 text-left" }, [
                    _c("div", { staticClass: "badge mr-2 bg-primary" }, [
                      _vm._v(_vm._s(_vm._f("path")(result.path))),
                    ]),
                    _c("div", {}, [
                      _c("strong", [_vm._v(_vm._s(result.name))]),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
        ])
      : _c("section", [
          _c(
            "button",
            {
              staticClass: "btn btn-secondary btn-gradient",
              on: {
                click: function ($event) {
                  return _vm.open_search_dialog()
                },
              },
            },
            [_c("i", { staticClass: "ion-search" })]
          ),
          _c("div", { staticClass: "modal", attrs: { id: "search-modal" } }, [
            _c(
              "div",
              {
                staticClass: "modal-dialog modal-lg cardbox mt-5",
                staticStyle: { overflow: "auto", "box-shadow": "none" },
              },
              [
                _c("div", { staticClass: "pb-1 bg-gradient-info" }),
                _c(
                  "div",
                  {
                    staticClass: "modal-content",
                    staticStyle: { border: "none" },
                  },
                  [
                    _c("div", { staticClass: "modal-body" }, [
                      _vm._m(0),
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c("b", { staticClass: "text-12" }, [
                              _vm._v(
                                _vm._s(_vm.placeholder || "Search Team Files")
                              ),
                            ]),
                            _vm.loading
                              ? _c("i", { staticClass: "ion-load-c spin ml-2" })
                              : _vm.search_term != ""
                              ? _c(
                                  "span",
                                  { staticClass: "badge badge-info ml-2" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.results.length) +
                                        " result" +
                                        _vm._s(
                                          _vm.results.length === 1 ? "" : "s"
                                        )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.search_term,
                              expression: "search_term",
                            },
                          ],
                          staticClass: "form-control form-control-sm",
                          attrs: {
                            type: "text",
                            name: "skyflok-search",
                            id: "skyflok-search-input",
                            placeholder: "Search for file or folder name",
                          },
                          domProps: { value: _vm.search_term },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.search_term = $event.target.value
                            },
                          },
                        }),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.search_term != "" && _vm.results,
                              expression: "search_term != '' && results",
                            },
                          ],
                          staticClass: "list-group search_results",
                          staticStyle: { position: "relative" },
                        },
                        _vm._l(_vm.results, function (result) {
                          return _c(
                            "a",
                            {
                              key: result.id,
                              staticClass: "list-group-item",
                              attrs: {
                                href: "#",
                                title:
                                  result.entity_type == _vm.FOLDER_TYPE
                                    ? "Navigate to " + result.path
                                    : "Show " + result.name,
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.search_result_clicked(result)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "text-center",
                                  staticStyle: { width: "40px" },
                                },
                                [
                                  result.entity_type == _vm.FOLDER_TYPE
                                    ? _c("i", { staticClass: "ion-folder" })
                                    : _vm._e(),
                                  result.entity_type == _vm.FILE_TYPE
                                    ? _c("img", {
                                        staticClass: "file-icon",
                                        attrs: {
                                          src: _vm._f("filetype_img_src")(
                                            result.extension
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                              _c("div", { staticClass: "ml-2 text-left" }, [
                                _c(
                                  "div",
                                  { staticClass: "badge mr-2 bg-primary" },
                                  [_vm._v(_vm._s(_vm._f("path")(result.path)))]
                                ),
                                _c("div", {}, [
                                  _c("strong", [_vm._v(_vm._s(result.name))]),
                                ]),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]
                ),
              ]
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "aria-label": "Close",
          "data-dismiss": "modal",
        },
      },
      [_c("span", [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }