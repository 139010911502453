var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.locations && _vm.locations.length > 0
    ? _c("section", [
        !_vm.disable_popover
          ? _c(
              "div",
              {
                staticClass: "d-inline-block text-center fw c-help",
                attrs: {
                  "data-toggle": "popover",
                  "data-html": "true",
                  "data-title": "Data Location",
                  "data-trigger": "hover",
                  "data-placement": _vm.placement || "right",
                  "data-content": _vm.popover_content,
                },
              },
              _vm._l(_vm.locations_sorted, function (location) {
                return _c("img", {
                  key: location.id,
                  staticClass: "mx-1",
                  class: {
                    "thumb32 rounded-circle rounded-circle img-thumbnail":
                      _vm.circles,
                  },
                  attrs: { src: _vm.Utils.flag_img_src(location.countrycode) },
                })
              }),
              0
            )
          : _c(
              "div",
              {
                staticClass: "fw text-center c-pointer",
                on: {
                  click: function ($event) {
                    return _vm.open_modal()
                  },
                },
              },
              [
                _vm._l(_vm.locations_sorted, function (location) {
                  return _c("img", {
                    key: location.id,
                    staticClass: "mx-1",
                    class: {
                      "thumb32 rounded-circle rounded-circle img-thumbnail":
                        _vm.circles,
                    },
                    attrs: {
                      src: _vm.Utils.flag_img_src(location.countrycode),
                    },
                  })
                }),
                _c(
                  "div",
                  {
                    staticClass: "modal",
                    attrs: { id: "data-locations-modal" },
                  },
                  [
                    _c("div", { staticClass: "modal-dialog" }, [
                      _c("div", { staticClass: "modal-content" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "modal-header bg-gradient-info text-white",
                          },
                          [
                            _c(
                              "h5",
                              { staticClass: "mt-0 modal-title text-bold" },
                              [_vm._v("Data location")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "close",
                                attrs: {
                                  type: "button",
                                  "aria-label": "Close",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.close_modal(
                                      "data-locations-modal"
                                    )
                                  },
                                },
                              },
                              [_c("span", [_vm._v("×")])]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "modal-body" },
                          [
                            _c("locations-content", {
                              attrs: {
                                teamName: _vm.teamName,
                                textStart: _vm.textStart,
                                locations: _vm.locations,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                ),
              ],
              2
            ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }