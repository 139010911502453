var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "data-location-flags-container" } }, [
    _vm.textStart
      ? _c("span", { domProps: { innerHTML: _vm._s(_vm.textStart) } })
      : _vm._e(),
    _c("div", [
      _vm.teamName
        ? _c("span", [
            _vm._v(
              " Every file in this shared folder is encrypted and distributed in the following locations, selected by the administrators of " +
                _vm._s(_vm.teamName) +
                " "
            ),
          ])
        : _c("b", [
            _vm._v(
              " Your files are distributed in " +
                _vm._s(_vm.locations.length) +
                " different locations "
            ),
          ]),
    ]),
    _c(
      "ul",
      _vm._l(_vm.locations, function (loc) {
        return _c("li", { key: loc.id }, [
          _c("img", {
            staticClass: "mr-1",
            attrs: { src: _vm.Utils.flag_img_src(loc.countrycode) },
          }),
          _vm._v(_vm._s(loc.name) + ", " + _vm._s(loc.country) + " "),
        ])
      }),
      0
    ),
    _vm.teamName
      ? _c("div", [
          _vm._v(
            " SkyFlok's unique patented technology allows you to choose where your data is stored. Keep it close to you or spread it across the globe, it is s up to you."
          ),
          _c("br"),
          _c("br"),
          _vm._v(" Visit "),
          _c(
            "a",
            {
              staticClass: "text-bold",
              attrs: { href: "https://skyflok.com", target: "_BLANK" },
            },
            [_vm._v("skyflok.com")]
          ),
          _vm._v(" to learn more and try SkyFlok for free! "),
        ])
      : _c("div", [
          _c("i", { staticClass: "text-sm" }, [
            _vm._v(
              "These locations were selected by your Team's Administrators"
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }