var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-12 col-md-6 col-lg-6 col-xl-3" }, [
    _c(
      "div",
      {
        staticClass: "cardbox mx-2 mb-4",
        class: { "text-muted": _vm.space.is_archived },
      },
      [
        _c("div", { staticClass: "pb-1", class: [_vm.color] }),
        _c("div", { staticClass: "cardbox-body pb-0 pb-2" }, [
          _c(
            "div",
            {
              staticClass: "d-flex align-items-center justify-content-between",
            },
            [
              _c("div", { staticClass: "text-center" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-secondary btn-flat btn-flat-icon d-block c-pointer",
                    attrs: {
                      type: "button",
                      "data-toggle": "popover",
                      "data-animation": "false",
                      "data-trigger": "hover",
                      "data-placement": "top",
                      "data-content": _vm.watch_toggle_popover_text,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.watch_clicked()
                      },
                    },
                  },
                  [
                    _c("i", {
                      class: {
                        "ion-android-notifications": _vm.space.watch_managers,
                        "ion-android-notifications-none text-muted":
                          !_vm.space.watch_managers,
                      },
                    }),
                  ]
                ),
              ]),
              _c("div", { staticClass: "dropdown" }, [
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "dropdown-menu", attrs: { role: "menu" } },
                  [
                    !_vm.space.is_archived
                      ? _c(
                          "a",
                          {
                            staticClass: "dropdown-item",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.$emit("edit")
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "ion-edit mr-2" }),
                            _vm._v(" Edit Space"),
                          ]
                        )
                      : _vm._e(),
                    !_vm.space.is_archived
                      ? _c(
                          "a",
                          {
                            staticClass: "dropdown-item",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.$emit(
                                  "open_dialog",
                                  "reset_password"
                                )
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "ion-refresh mr-2" }),
                            _vm._v(" Reset Client Password"),
                          ]
                        )
                      : _vm._e(),
                    _vm.space.mfa_enabled && !_vm.space.is_archived
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "dropdown-item d-flex align-items-center",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.$emit(
                                  "open_dialog",
                                  "mfa_backup_codes"
                                )
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "ion-help-buoy mr-2" }),
                            _vm._v(" 2FA Backup Codes"),
                          ]
                        )
                      : _vm._e(),
                    !_vm.space.is_archived
                      ? _c("div", {
                          staticClass: "dropdown-divider",
                          attrs: { role: "separator" },
                        })
                      : _vm._e(),
                    !_vm.space.is_archived
                      ? _c(
                          "a",
                          {
                            staticClass: "dropdown-item text-danger",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.$emit("open_dialog", "archive_space")
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "ion-trash-a mr-2" }),
                            _vm._v(" Archive or Delete..."),
                          ]
                        )
                      : _vm.activatedisabled !== true
                      ? _c(
                          "a",
                          {
                            staticClass: "dropdown-item text-success",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.activateclicked(_vm.space)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "ion-wand mr-2" }),
                            _vm._v(" Activate Space"),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "cardbox-body pt-0 text-center" }, [
          _c(
            "a",
            {
              staticStyle: { color: "inherit", "text-decoration": "none" },
              attrs: {
                href: _vm._f("client_space_link")(_vm.space.key),
                target: "_BLANK",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-table-cell v-middle",
                  staticStyle: { height: "128px" },
                },
                [
                  _vm.space.client_logo
                    ? _c("img", {
                        staticClass:
                          "shadow-z3 rounded fit-contain d-inline-block",
                        staticStyle: {
                          "max-width": "128px",
                          "max-height": "128px",
                        },
                        attrs: {
                          src: _vm.ClientSpacesService.is_skyflok_logo(
                            _vm.space
                          )
                            ? _vm.Utils.client_space_logo_src(
                                _vm.space.client_logo
                              )
                            : _vm.space.client_logo,
                        },
                      })
                    : _c(
                        "div",
                        {
                          staticClass:
                            "shadow-z3 thumb128 rounded bg-gradient-secondary d-inline-block",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center fh fw text-white text-bold",
                            },
                            [
                              _c("h1", { staticClass: "p-0 m-0" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.space.client_name
                                      .split(" ")
                                      .map((frag) => {
                                        return frag.length > 0
                                          ? frag[0].toUpperCase()
                                          : ""
                                      })
                                      .splice(0, 3)
                                      .join("")
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "h3 text-bold mt-3",
                  attrs: {
                    title:
                      _vm.space.client_name.length > 15
                        ? _vm.space.client_name
                        : "",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.space.client_name.substr(0, 15)) +
                      _vm._s(_vm.space.client_name.length > 15 ? "…" : "") +
                      " "
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "text-sm",
                  class: {
                    "text-gray text-italic":
                      _vm.client_state == "INVITE_PENDING",
                    "text-danger": _vm.client_state == "INVITE_EXPIRED",
                  },
                  attrs: {
                    "data-toggle":
                      _vm.client_popover_text != "" ? "popover" : "",
                    "data-content": _vm.client_popover_text,
                    "data-animation": "false",
                    "data-trigger": "hover",
                    "data-placement": "top",
                  },
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.space.client_email))]),
                  _vm.client_state != ""
                    ? _c("i", {
                        staticClass: "ml-1",
                        class: {
                          "ion-clock": _vm.client_state == "INVITE_PENDING",
                          "ion-alert-circled":
                            _vm.client_state == "INVITE_EXPIRED",
                          "ion-checkmark-circled text-success":
                            _vm.client_state == "JOINED",
                        },
                      })
                    : _vm._e(),
                ]
              ),
            ]
          ),
          _vm.space.delete_at
            ? _c(
                "div",
                {
                  staticClass: "alert alert-warning alert-sm text-center mb-0",
                },
                [
                  _c("small", [
                    _vm._v(
                      "This Space will be automatically deleted " +
                        _vm._s(_vm._f("timestamp_rel")(_vm.space.delete_at))
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
        _c("div", { staticClass: "cardbox-body pt-0 text-center" }, [
          _c(
            "div",
            {
              staticClass:
                "image-list d-flex align-items-center justify-content-center nowrap",
            },
            [
              _vm._l(_vm.space_managers.splice(0, 3), function (member) {
                return _c("img", {
                  key: member.id,
                  staticClass: "rounded-circle thumb48 fit-cover shadow-z2",
                  staticStyle: { border: "2px solid white" },
                  attrs: { src: member.avatar_url, alt: member.name },
                })
              }),
              _vm.space.members.length > 3
                ? _c(
                    "div",
                    {
                      staticClass:
                        "rounded-circle thumb48 shadow-z2 bg-gray-lighter d-inline-block text-center d-flex align-items-center justify-content-center",
                      staticStyle: {
                        border: "2px solid white",
                        "margin-left": "-8px",
                      },
                    },
                    [
                      _c("span", [
                        _vm._v("+" + _vm._s(_vm.space.members.length - 3)),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
          _c("small", { staticClass: "nowrap" }, [_vm._v("Managers")]),
        ]),
        _c("div", { staticClass: "cardbox-body pt-0" }, [
          !_vm.space.is_archived
            ? _c(
                "a",
                {
                  class: ["btn btn-block text-white", _vm.color],
                  attrs: {
                    target: "_BLANK",
                    href: _vm._f("client_space_link")(_vm.space.key),
                    title: "Open this Space in a new tab",
                  },
                },
                [_vm._m(1)]
              )
            : _c(
                "a",
                {
                  staticClass: "btn btn-block bg-gradient-secondary text-white",
                  attrs: {
                    href: _vm._f("client_space_link")(_vm.space.key),
                    target: "_BLANK",
                    title: "Browse this Space in a new tab",
                  },
                },
                [_vm._m(2)]
              ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-secondary btn-flat btn-sm btn-flat-icon",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-expanded": "false",
        },
      },
      [_c("i", { staticClass: "ion-more text-gray-light" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("b", [
      _vm._v("Open Space "),
      _c("i", { staticClass: "ion-share ml-1" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("b", [
      _vm._v("Browse Space "),
      _c("i", { staticClass: "ion-share ml-1" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }