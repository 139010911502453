var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-flex align-items-center justify-contents-middle fw py-2 mr-2",
      class: { "c-pointer": _vm.is_clickable },
      attrs: { title: _vm.is_clickable ? "Click to show file" : false },
      on: {
        click: () => {
          _vm.is_clickable ? _vm.$emit("file_clicked", _vm.event.file) : false
        },
      },
    },
    [
      _vm.event_user
        ? _c("div", { staticClass: "thumb32" }, [
            _vm.event_user.avatar_url || _vm.event_user.logo_url
              ? _c("img", {
                  staticClass: "thumb32 fit-contain",
                  class: {
                    "rounded-circle": !_vm.event_user.is_client,
                    rounded: _vm.event_user.is_client,
                  },
                  attrs: {
                    src: _vm.event_user.avatar_url
                      ? _vm.event_user.is_builtin_logo
                        ? _vm.Utils.client_space_logo_src(
                            _vm.event_user.avatar_url
                          )
                        : _vm.event_user.avatar_url
                      : _vm.event_user.logo_url,
                  },
                })
              : _vm._e(),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "ml-2 fw text-sm nowrap",
          staticStyle: { "line-height": "1.1em", overflow: "hidden" },
        },
        [
          _vm.event.event_type == "file_download" ||
          _vm.event.event_type == "file_upload"
            ? _c("div", [
                _c("i", {
                  staticClass: "mr-1",
                  class: {
                    "ion-arrow-down-a text-success":
                      _vm.event.event_type == "file_download",
                    "ion-arrow-up-a text-info":
                      _vm.event.event_type == "file_upload",
                  },
                }),
                _vm.event.file
                  ? _c("img", {
                      staticClass: "mr-1",
                      attrs: {
                        src: _vm._f("filetype_img_src")(
                          _vm.get_extension(_vm.event_file.name)
                        ),
                        height: "20",
                      },
                    })
                  : _vm._e(),
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.event_file.name) },
                }),
              ])
            : _vm._e(),
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(
              " " + _vm._s(_vm._f("timestamp_rel")(_vm.event.timestamp)) + " "
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }