var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.data && _vm.data.length
    ? _c("section", { staticClass: "d-inline-block" }, [
        _c("div", { staticClass: "pager noselect mb-2 d-block" }, [
          _c("div", { staticClass: "text-right" }, [
            _vm.is_filtered && !_vm.disable_filtered_label
              ? _c("span", { staticClass: "text-right mr-2" }, [
                  _c("small", [
                    _vm._v("Showing "),
                    _c("b", [
                      _vm._v(
                        _vm._s(_vm.data_page.length) +
                          " of " +
                          _vm._s(_vm.data.length)
                      ),
                    ]),
                    _vm._v(
                      " filtered " +
                        _vm._s(
                          _vm.records_name ? _vm.records_name : "records"
                        ) +
                        "."
                    ),
                  ]),
                ])
              : _vm._e(),
            !_vm.disable_pagesize_selector
              ? _c("small", [
                  _vm._v(" Page size: "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.page_size,
                          expression: "page_size",
                        },
                      ],
                      staticClass:
                        "form-control form-control-sm d-inline-block p-1",
                      staticStyle: { width: "55px" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.page_size = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _c("option", { domProps: { value: 5 } }, [_vm._v("5")]),
                      _c("option", { domProps: { value: 10 } }, [_vm._v("10")]),
                      _c("option", { domProps: { value: 20 } }, [_vm._v("20")]),
                      _c("option", { domProps: { value: 50 } }, [_vm._v("50")]),
                      _c("option", { domProps: { value: 100 } }, [
                        _vm._v("100"),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
          _c(
            "div",
            { staticClass: "text-right pager-button" },
            [
              _c(
                "div",
                {
                  staticClass: "d-inline-block p-1 m-1 c-pointer",
                  class: { disabled: _vm.page <= 1 },
                  on: {
                    click: function ($event) {
                      _vm.page > 1 ? (_vm.page = _vm.page - 1) : false
                    },
                  },
                },
                [_c("i", { staticClass: "ion-chevron-left" })]
              ),
              _vm._l(_vm.range(_vm.total_pages), function (p) {
                return _c(
                  "div",
                  {
                    key: p,
                    staticClass: "d-inline-block px-2 c-pointer",
                    class: { "bg-skyflok text-white active": p == _vm.page },
                    on: {
                      click: function ($event) {
                        _vm.page = p
                      },
                    },
                  },
                  [_vm._v(_vm._s(p))]
                )
              }),
              _c(
                "div",
                {
                  staticClass: "d-inline-block p-1 m-1 c-pointer",
                  class: { disabled: _vm.page >= _vm.total_pages },
                  on: {
                    click: function ($event) {
                      _vm.page < _vm.total_pages
                        ? (_vm.page = _vm.page + 1)
                        : false
                    },
                  },
                },
                [_c("i", { staticClass: "ion-chevron-right" })]
              ),
            ],
            2
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }