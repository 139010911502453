var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.error
      ? _c("div", { staticClass: "alert alert-danger" }, [
          _vm._v(_vm._s(_vm.error)),
        ])
      : _vm._e(),
    _vm.success
      ? _c("div", { staticClass: "alert alert-success" }, [
          _vm._v(_vm._s(_vm.success)),
        ])
      : _vm._e(),
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit_form.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "table",
          {
            staticClass: "table mb-0 settings-table",
            class: { "": !_vm.editMode },
          },
          [
            _vm.user && _vm.form
              ? _c("tbody", [
                  _c("tr", [
                    _c("th", { staticClass: "width-50p" }, [_vm._v("Name: ")]),
                    _c("td", [
                      !_vm.editMode
                        ? _c("div", [_vm._v(_vm._s(_vm.user.name))])
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.name,
                                expression: "form.name",
                              },
                            ],
                            staticClass: "form-control form-control-sm",
                            attrs: { type: "text", id: "user_name_input" },
                            domProps: { value: _vm.form.name },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(_vm.form, "name", $event.target.value)
                              },
                            },
                          }),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Job title: ")]),
                    _c("td", [
                      !_vm.editMode
                        ? _c("div", [_vm._v(_vm._s(_vm.user.job_title || "-"))])
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.job_title,
                                expression: "form.job_title",
                              },
                            ],
                            staticClass: "form-control form-control-sm",
                            attrs: {
                              type: "text",
                              placeholder:
                                "E.g. CTO, software engineer, marketing trainee",
                            },
                            domProps: { value: _vm.form.job_title },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.form,
                                  "job_title",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                    ]),
                  ]),
                  _vm.editMode
                    ? _c("tr", [
                        _c("th", [_vm._v("New profile picture:")]),
                        _c(
                          "td",
                          [
                            _c("profile-image-selector", {
                              attrs: {
                                avatar_changed: (avatar_url) => {
                                  _vm.form.avatar_url = avatar_url
                                },
                                imgclass:
                                  "wd-sm rounded-circlewd-sm rounded-circle",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("tr", [
                    _c("th", [_vm._v("E-mail address: ")]),
                    _c("td", [
                      _vm._v(" " + _vm._s(_vm.user.email_address) + " "),
                      _c("div", [
                        _vm.editMode
                          ? _c("small", [
                              _c("i", [
                                _vm._v(
                                  _vm._s(
                                    _vm.isAdmin
                                      ? "Cannot change the email address"
                                      : "You can't change your email address"
                                  )
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                  !_vm.editMode || !_vm.psw_change
                    ? _c("tr", [
                        _c(
                          "th",
                          { staticStyle: { "vertical-align": "middle" } },
                          [_vm._v("Password: ")]
                        ),
                        _c("td", [
                          _vm.editMode
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      _vm.psw_change = true
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.isAdmin === true
                                        ? "Change password of " + _vm.user.name
                                        : "I want to change my password"
                                    )
                                  ),
                                ]
                              )
                            : _c(
                                "div",
                                _vm._l([1, 2, 3, 4, 5], function (i) {
                                  return _c("i", {
                                    key: i,
                                    staticClass:
                                      "ion-asterisk text-muted mr-1 text-sm",
                                  })
                                }),
                                0
                              ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.editMode && _vm.psw_change && !_vm.isAdmin
                    ? _c("tr", [
                        _c("th", [_vm._v("Current password:")]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.current_psw,
                                expression: "form.current_psw",
                              },
                            ],
                            staticClass: "form-control form-control-sm",
                            attrs: {
                              type: "password",
                              id: "current_password_input",
                            },
                            domProps: { value: _vm.form.current_psw },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.form,
                                  "current_psw",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.editMode && _vm.psw_change
                    ? _c("tr", [
                        _c("th", [_vm._v("New password: ")]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.new_password,
                                expression: "form.new_password",
                              },
                            ],
                            staticClass: "form-control form-control-sm",
                            attrs: { type: "password" },
                            domProps: { value: _vm.form.new_password },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.form,
                                  "new_password",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _c("small", { staticStyle: { "font-size": "75%" } }, [
                            _vm._v(
                              "At least 8 characters, add numbers and symbols for more safety"
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.editMode && _vm.psw_change
                    ? _c("tr", [
                        _c("th", [_vm._v("Repeat new password:")]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.new_password_2,
                                expression: "form.new_password_2",
                              },
                            ],
                            staticClass: "form-control form-control-sm",
                            attrs: { type: "password" },
                            domProps: { value: _vm.form.new_password_2 },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.form,
                                  "new_password_2",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ])
                    : _vm._e(),
                  !_vm.editMode
                    ? _c("tr", [
                        _c("th", [_vm._v("Registered at:")]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm._f("timestamp")(_vm.user.created)) + " "
                          ),
                          _c("small", [
                            _vm._v(
                              "(" +
                                _vm._s(
                                  _vm._f("timestamp_rel")(_vm.user.created)
                                ) +
                                ")"
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.editMode
                    ? _c("tr", [
                        _c(
                          "td",
                          {
                            staticClass: "text-center",
                            staticStyle: { "border-top": "none" },
                            attrs: { colspan: "2" },
                          },
                          [
                            _c("div", [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary mr-2",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.closeForm()
                                    },
                                  },
                                },
                                [_vm._v("Cancel")]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-info btn-gradient",
                                  attrs: {
                                    type: "submit",
                                    disabled: _vm.form_loading,
                                  },
                                },
                                [
                                  _vm._v("Save"),
                                  _vm.form_loading
                                    ? _c("i", {
                                        staticClass: "ml-2 ion-load-c spin",
                                      })
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ]
        ),
      ]
    ),
    _vm.editMode && _vm.user.team_settings
      ? _c("div", [
          _vm._m(0),
          _c("hr"),
          _c("div", { staticStyle: { width: "90%", margin: "0px auto" } }, [
            _c("small", [
              _vm._v(
                " Deleting your account is a permanent operation that cannot be reversed. "
              ),
              _c("br"),
              _vm._v(
                " Your SkyFlok account and all your personal information is deleted and you won't be able to log in any more. You lose access to every file too."
              ),
              _c("br"),
              _vm._v(
                " Your past actions are kept in the system until the SkyFlok Team of " +
                  _vm._s(_vm.user.team_settings.name) +
                  " exists, but your name and photo are replaced by '"
              ),
              _c("i", [_vm._v("a deleted member")]),
              _vm._v("' "),
            ]),
          ]),
          _c("div", { staticClass: "text-center mt-2" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-danger btn-gradient",
                attrs: { disabled: _vm.delete_loading },
                on: {
                  click: function ($event) {
                    return _vm.delete_my_account()
                  },
                },
              },
              [
                _vm._v("I understand, delete my SkyFlok account"),
                _vm.delete_loading
                  ? _c("i", { staticClass: "ion-load-c spin ml-2" })
                  : _vm._e(),
              ]
            ),
            _vm.delete_error
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.delete_error)),
                ])
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-info text-center mt-1" }, [
      _c("small", [
        _c("i", { staticClass: "ion-information-circled text-info mr-1" }),
        _vm._v(
          " Do you have questions or requests about privacy, how your personal information is handled or what happened to files you uploaded? Would you like to get a copy of all your personal data? "
        ),
        _c("br"),
        _vm._v(" We are happy to help! Contact us at "),
        _c(
          "a",
          { attrs: { href: "mailto:SkyFlok Support<support@skyflok.com>" } },
          [_vm._v("support@skyflok.com")]
        ),
        _vm._v("! "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }